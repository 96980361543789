<div class="container" [class.left-panel-active]="leftPanelActive">
    <div class="row custom-row">
        <div class="panel-left p_panel-left display-none">
            <!-- <div class="left-panel-hide" (click)="hideLeftPanel()">
                        <figure><img src="assets/images/close.png" alt=""></figure>
                    </div> -->
            <app-order-sidebar (changeleftPanelValue)='displayLeftPanel($event)' (selectedItemData)="onSelectShowOrderItemDetail($event)"></app-order-sidebar>
        </div>
        <div class="panel-right p_panel-right mobile-move mobile-move1 mobile-panel">
            <form [formGroup]="cancellationForm">
                <div class="ordered-items">
                    <div class="item-list">
                        <div class="item-info">
                            <div class="item-name">
                            <button class="close-track" (click)=" setBackToOrder()"></button>
                            <span class="order-heading">
                                <!-- <a (click)=" setBackToOrder()">
                                    <img src="../../../../assets/images/icon-arrow-left.svg" /></a> -->
                                Cancel Item
                            </span>
                            <ng-container *ngIf="(userSettings?.supportPhone && userSettings?.supportPhone!='null') || (userSettings?.supportEmail && userSettings?.supportEmail!='null') || (userSettings?.supportUrl && userSettings?.supportUrl!='null')">
                                <a  (click)="openHelpModal()" (mouseover) ="mouseEnter('addColor') "  (mouseout) ="mouseLeave('removeColor')"  [ngStyle]="{color:Helpcolor?getColor:'#828282'} ">Help ?</a>
                            </ng-container>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- <div class="left-panel-show" (click)="showLeftPanel()">
                    <figure><img src="assets/images/arrow-left.png" alt=""></figure>
                    <span> Order </span>
                </div> -->
                <div class="cancel-order-wrp scroll-content" #scroll (scroll)="onScroll()" [class.box-shadowcss]="isBoxshadow">
                    <div class="product-detail-mob" *ngIf="cancelOrderItemDetails">
                        <div class="prod-img">
                            <img src="{{ cancelOrderItemDetails && cancelOrderItemDetails.productImageUrl ? cancelOrderItemDetails.productImageUrl:'assets/images/item-image.jpg' }}"
                                alt="product image">
                        </div>
                        <div class="prod-info">
                            <h4>{{ cancelOrderItemDetails ? cancelOrderItemDetails.productName:''}}</h4>
                            <p>{{rupeeSymbol}} <Span>
                                    {{ cancelOrderItemDetails ? transformDecimal(cancelOrderItemDetails.customerPrice):''}}</Span>
                                    <!-- <span class="size-class" *ngIf="cancelOrderItemDetails.productSize">{{cancelOrderItemDetails ? cancelOrderItemDetails.productSize:''}}</span> -->
                                    <span  *ngIf="cancelOrderItemDetails.productSize"> • Size {{cancelOrderItemDetails ? cancelOrderItemDetails.productSize:''}}</span>

                            </p>
                        </div>
                    </div>
                    <div class=" cancle-order">
                        <!-- Bank Details Start -->
                        <h5>Let us know the reason for cancellation?</h5>
                        <p class="special-para">Please choose the correct reason for canceling the item. This information is used only to improve our services.</p>
                        <div class="cancel-container">
                            <mat-radio-group class="custom-radio-btn-group" formControlName="cancellationReasons">
                                <mat-radio-button (change)="radioChange(i == defaultSelected)" matInput class="custom-radio-btn" value="{{item}}" 
                                *ngFor="let item of userSettings?.cancellationReasons ; let i = index" [checked]="i == defaultSelected">
                                    {{item}}
                                </mat-radio-button>
                            </mat-radio-group>

                            <div class="additional-comments">
                                <p>Additional comments (optional)</p>
                                <mat-form-field >
                                    <textarea name="AdditionalComments"  formControlName="AdditionalComments" matInput placeholder="Enter additional comments for your reason" ></textarea>
                                    <mat-error 
                                    class="error-message" 
                                    *ngIf="formControls.AdditionalComments.invalid"
                                    >
                                    Remarks can be at most 100 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            
             <div class="btn-bottom" [ngClass]="showFooter ? 'buttonup':''">
                <!-- <a class="arrow-top" (click)="showFooterClick()" *ngIf="ifarrowTop"><img src="https://ik.imagekit.io/2gwij97w0o/POE/arrow-top.svg" /></a>  
                <a class="arrow-top" (click)="hideFooterClick()" *ngIf="ifarrowBottom"><img src="https://ik.imagekit.io/2gwij97w0o/POE/arrow-bottom.svg" /></a> -->
               
                <input type="button" value="Cancel" class="btn-cancel" (click)="setBackToOrder()">  
                <!-- <input type="button" value="Continue" class="btn-continue" disabled> -->
                <ng-container *ngIf="loading;else continueButton" >
                    <figure class="d-flex btn-loader btn-continue" [ngStyle]="{background:!cancellationForm.valid?'#E0E0E0':getColor} ">
                        <div class="loader-css"></div>
                    </figure>
                </ng-container>
                <ng-template #continueButton>
                    <input class="btn-continue" type="button" (click)="onSubmitReason()" [disabled]="!cancellationForm.valid"
                        value="NEXT">
                </ng-template>
            
            </div>
        </form>
    </div>
</div>
<!-- <div class="mob-footer">
    <p> Need further assistance? <a href="mailto:{{ (userSettings && userSettings.contactMailId) ? userSettings.contactMailId:'' }}"> Contact us </a> </p>
    <app-mob-header></app-mob-header>
</div> -->
</div>
  <!-- <div class="mob-footer" *ngIf="showFooter">
        <div class="footer-underline">
            <p>
                <ng-container *ngFor="let keys of footerKey;let i=index" >
                    <a target="_blank"    (click)="open_ace_com(keys[1])">
                        {{keys[0]}}
                      </a>
                    </ng-container>
             
            </p>
          

        </div>

        <div *ngIf="userSettings?.websiteUrl">
            <p class="second-para">
                <a (click)="open_ace_com(userSettings?.websiteUrl)">
                     <span class="mobile-store-link"> Go to store </span> </a>

            </p>
</div>
</div> -->
<!-- 
<div class="poweredby-mobile" *ngIf="showFooter">
    <a href="https://www.eshopbox.com/" target="_blank">
        <p style="word-wrap: no-wrap;"><a href="https://www.eshopbox.com/" target="_blank">   <img src="https://ik.imagekit.io/2gwij97w0o/POE/eshopbox-monogram.svg" alt=""></a>Powered by Eshopbox</p>

    </a>
    
 
</div> -->


<div class="help-box" (click)="closeHelpModal()" [class.helpShow]="isHelpOpendelay">
</div>
<div class="main-helpbox" [ngStyle]="{display:isHelpOpendelay ? 'block': ''} " [class.helpShow]="isHelpOpen">
    <div class="help-top-box">
        <h2>Help ?</h2>
        <button (click)="closeHelpModal()"></button>
            </div>

            <div class="help-bottom-box">
                <div class="text-block" *ngIf="userSettings?.supportUrl && userSettings?.supportUrl!='null'">
                    <h1>Support URL</h1>
                    <a (click)="openSite(userSettings?.supportUrl)">{{userSettings?.supportUrl}}</a>
                </div>
                <div class="text-block" *ngIf="userSettings?.supportEmail && userSettings?.supportEmail!='null'">
                    <h1>Support email</h1>
                    <a href="mailto:{{ (userSettings && userSettings.supportEmail) ? userSettings.supportEmail:'' }}">{{userSettings?.supportEmail}}</a>
                </div>
                <div class="text-block" *ngIf="userSettings?.supportPhone && userSettings?.supportPhone!='null'">
                    <h1>Support phone number</h1>
                    <a  href="tel:{{userSettings?.supportPhone}}">{{userSettings?.supportPhone}}</a>
                </div>
            </div>
</div>
  
