import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'reverse' })

export class ReverseValuePipe implements PipeTransform {

  transform(value) {
    return value.slice().reverse();
  }

}
