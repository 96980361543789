"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ActionTypes = /** @class */ (function () {
    function ActionTypes() {
    }
    ActionTypes.LOGOUT = "[App] logout";
    return ActionTypes;
}());
exports.ActionTypes = ActionTypes;
var Logout = /** @class */ (function () {
    function Logout() {
        this.type = ActionTypes.LOGOUT;
    }
    return Logout;
}());
exports.Logout = Logout;
