"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app.component.sass.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./shared/components/notice-message-header/notice-message-header.component.ngfactory");
var i3 = require("./shared/components/notice-message-header/notice-message-header.component");
var i4 = require("@angular/router");
var i5 = require("@ngrx/store");
var i6 = require("./shared/services/alert.service");
var i7 = require("@angular/platform-browser");
var i8 = require("ngx-device-detector");
var i9 = require("./shared/components/alert/alert.component.ngfactory");
var i10 = require("./shared/components/alert/alert.component");
var i11 = require("@angular/common");
var i12 = require("./shared/components/header/header.component.ngfactory");
var i13 = require("./shared/components/header/header.component");
var i14 = require("./shared/services/shared.service");
var i15 = require("./shared/components/footer/footer.component.ngfactory");
var i16 = require("./shared/components/footer/footer.component");
var i17 = require("./app.component");
var i18 = require("@angular/service-worker");
var i19 = require("./shared/services/auth.service");
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-notice-message-header", [], null, null, null, i2.View_NoticeMessageHeaderComponent_0, i2.RenderType_NoticeMessageHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.NoticeMessageHeaderComponent, [i4.Router, i5.Store, i6.AlertService, i4.ActivatedRoute, i7.Title, i8.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-web-alert", [], null, null, null, i9.View_AlertComponent_0, i9.RenderType_AlertComponent)), i1.ɵdid(1, 245760, null, 0, i10.AlertComponent, [i6.AlertService, i4.ActivatedRoute, i4.Router], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-header", [], null, null, null, i12.View_HeaderComponent_0, i12.RenderType_HeaderComponent)), i1.ɵdid(5, 245760, null, 0, i13.HeaderComponent, [i5.Store, i14.SharedService, i11.LocationStrategy, i8.DeviceDetectorService], null, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i15.View_FooterComponent_0, i15.RenderType_FooterComponent)), i1.ɵdid(9, 114688, null, 0, i16.FooterComponent, [i5.Store, i4.Router], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (_co.currentRoute != _co.currentOrigin); _ck(_v, 3, 0, currVal_0); _ck(_v, 5, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i17.AppComponent, [i18.SwUpdate, i19.AuthService, i7.Title, i4.Router, i4.ActivatedRoute, i1.Renderer2, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i1.ɵccf("app-root", i17.AppComponent, View_AppComponent_Host_0, {}, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;
