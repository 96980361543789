"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var services_1 = require("../../services"); //SharedService
var MobHeaderComponent = /** @class */ (function () {
    function MobHeaderComponent(router, auth, store) {
        this.router = router;
        this.auth = auth;
        this.store = store;
        this.showLoader = false;
        this.subscriptions$ = new rxjs_1.Subscription();
    }
    MobHeaderComponent.prototype.ngOnInit = function () {
        // this.sharedService.loaderListner.subscribe( showLoader => {
        //   this.showLoader = showLoader.status;
        // });
        var _this = this;
        //user settings
        // this.store.dispatch(new GetUserSettings());
        //   this.subscriptions$.add(
        //     this.store.pipe(select(getUserSettings)).subscribe(result => {
        //       if (result) {
        //         this.userSettings = result;
        //         console.log("this.userSettings",this.userSettings);
        //        this.change_favicon(this.userSettings.faviconUrl);
        //       }else {
        //         this.userSettings = [];
        //       }
        //     })
        // );
        this.store.select(function (state) { return state.orderPlaced.userSettings; }).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
            }
        });
    };
    MobHeaderComponent.prototype.change_favicon = function (img) {
        var favicon = document.querySelector('link[rel="shortcut icon"]');
        if (!favicon) {
            favicon = document.createElement('link');
            favicon.setAttribute('rel', 'shortcut icon');
            var head = document.querySelector('head');
            head.appendChild(favicon);
        }
        favicon.setAttribute('type', 'image/png');
        favicon.setAttribute('href', img);
    };
    MobHeaderComponent.prototype.ngOnDestroy = function () {
        this.subscriptions$.unsubscribe();
    };
    return MobHeaderComponent;
}());
exports.MobHeaderComponent = MobHeaderComponent;
