"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Convert24hrsTo12hrsPipe = /** @class */ (function () {
    function Convert24hrsTo12hrsPipe() {
    }
    Convert24hrsTo12hrsPipe.prototype.transform = function (time) {
        var time24To12 = function (a) {
            //below date doesn't matter.
            return (new Date("1955-11-05T" + a + "Z")).toLocaleTimeString("bestfit", {
                timeZone: "UTC",
                hour12: !0,
                hour: "numeric",
                minute: "numeric"
            });
        };
        return time24To12(time);
    };
    return Convert24hrsTo12hrsPipe;
}());
exports.Convert24hrsTo12hrsPipe = Convert24hrsTo12hrsPipe;
