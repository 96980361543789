"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var users = require("./users.json");
var route_summary = require("./route-summary.json");
var delivery_associates = require("./delivery-associates.json");
var simulated_summary = require("./simulated-routes.json");
var picklist_summary = require("./picklist_summary.json");
var complete_consignment_list = require("../mockdata/complete-consign-list.json");
var picklist_by_id = require("./picklist_by_id.json");
var cancelProductReasonList = require("../mockdata/cancel-product-reason.json");
exports.usersResponse = users;
exports.routeSummary = route_summary;
exports.deliveryAssociates = delivery_associates;
exports.simulatedSummary = simulated_summary;
exports.picklistSummary = picklist_summary;
exports.completeConsignmentList = complete_consignment_list;
exports.picklistById = picklist_by_id;
exports.cancelReasonList = cancelProductReasonList;
