"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["@media only screen and (max-width:479px){.panel-right[_ngcontent-%COMP%]{left:00%!important}.panel-right[_ngcontent-%COMP%]   form[_ngcontent-%COMP%]{position:absolute;left:00%!important}}"];
exports.styles = styles;
