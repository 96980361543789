import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { Configuration } from "../configuration/config";
import { HttpHeaders } from "@angular/common/http";
import { PROXY_HEADER } from "../constants/constants";
import { LocalStorageService } from "../../shared/services/index";

@Injectable({
  providedIn: "root"
})
export class OrderService {
  private API_URL = environment.API_URL;
  private USER_SETTING = environment.USER_SETTING;
  public headers = new HttpHeaders();
  env = environment.prodEnv;
  constructor(private api: ApiService, private config: Configuration) {}

  getOrders(params = {}) {
    return this.api
      .get(`${this.API_URL}orders`, params)
      .pipe(map(response => response["data"]));
  }

  getOrder(id) {
    return this.api.get(`${this.API_URL}order/${encodeURIComponent(id)}`);
  }

  getUserSettings() {
    return this.api.get(`${this.USER_SETTING}poe-setting`);
  }

  getOrdersCount() {
    return this.api
      .get(`${this.API_URL}orders/count`)
      .pipe(map(response => response["data"]));
  }
  markAsDelivered(data: any): Promise<any> {
    let url = this.env
      ? "https://wms.eshopbox.com"
      : "https://eshopbox-wms-api-dot-eshopbox-wms-staging.el.r.appspot.com";
    return this.api
      .post(`${url}/api/update-status`, data, this.headers)
      .toPromise()
      .then(res => {
        if (res) {
          return res;
        }
        throw res;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }
}
