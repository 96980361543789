import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
// import { LoginComponent } from "./auth/components/login/login.component";
// import { HomeLayoutComponent } from "./components/_layouts/home-layout/home-layout.component";
import { OrderPlacedComponent } from "./order-placed/order-placed.component";
// import { OrderCancelComponent } from "./order-cancel/order-cancel.component";
import { OrderListingComponent } from "./order-listing/order-listing.component";
import { OrderReturnComponent } from "./order-return/order-return.component";
import { OtpConfirmationComponent } from "./shared/components/otp-confirmation/otp-confirmation.component";
import { RequestSubmissionComponent } from "./shared/components/request-submission/request-submission.component";
import { RedirectionComponent } from "./redirection/redirection.component";
import { OrderReturnCancelComponent } from "./order-return-cancel/order-return-cancel.component";
import { OrderFailedDeliveryComponent } from "./order-failed-delivery/order-failed-delivery.component";
import { OrderFailedPickupComponent } from "./order-failed-pickup/order-failed-pickup.component";
import { OrderCancelComponent } from "./order-cancel-page/components/order-cancel/order-cancel.component";
import { CancelStepOneComponent } from "./order-cancel-page/components/cancel-step-one.component/cancel-step-one.component";
// import { SidebarComponent } from "./shared/components/sidebar/sidebar.component";

const routes: Routes = [
  // {
  //   path: "",
  //   component: LoginLayoutComponent,
  //   children: [
  //     {
  //       path: "",
  //       loadChildren: "./auth/auth.module#AuthModule"
  //     }
  //   ]
  // },
  // {
  //   path: "",
  //   component: HomeLayoutComponent,
  //   children: [
  //     {
  //       path: "dashboard",
  //       loadChildren: "./dashboard/index#DashboardModule"
  //     },
  //     {
  //       path: "order-placed",
  //       component: OrderPlacedComponent,
  //     }
  //   ]
  // },
  
  // {
  //   path: "login",
  //   component: LoginComponent,
  //   data: {title: 'Login'},
  //   pathMatch: 'full'
  // },
  {
    path: "order-list",
    component: OrderListingComponent,
    data: {title: 'Order Listing'},
    pathMatch: 'full'
  },
  {
    path: "",
    loadChildren: './search-order/search-order.module#SearchOrderModule'
  },
  {
    path: ":encodedKey",
    component: RedirectionComponent,
    data: {title: 'Eshopbox'}
  }, 
  {
    path: "track/:orderId/:orderItemId",  //:orderId/:orderItemId/:token
    component: OrderPlacedComponent,
    data: {title: 'Track Order'}
  },
  {
    path: "track/:orderId",  //:orderId/:orderItemId/:token
    component: OrderPlacedComponent,
    data: {title: 'Track Order'}
  },
  {
    path: "cancel/:orderId/:orderItemId",
    component: OrderCancelComponent,
    // data: {title: 'Cancel Product'}
  },
  {
    path: "cancel-step-one/:orderId/:orderItemId",
    component: CancelStepOneComponent,
    // data: {title: 'Cancel Product'}
  },
  {
    path: "return/:orderId/:orderItemId",
    component: OrderReturnComponent,
    data: {title: 'Return Item'}
  },
  {
    path: "otp/:orderId/:orderItemId",
    component: OtpConfirmationComponent,
    data: {title: 'Enter OTP'}
  },
  {
    path: "success/:orderId/:orderItemId",
    component: RequestSubmissionComponent,
      // data: {title: 'Success Page'}
  },
  {
    path: "return-cancel/:orderId/:orderItemId",
    component: OrderReturnCancelComponent, 
  },
  // {
  //   path: "failed-delivery/:orderId/:orderItemId",
  //   component: OrderFailedDeliveryComponent
  // },
  {
    path: "failed-pickup/:orderId/:orderItemId",
    component: OrderFailedPickupComponent
  },
  {
    path: 'refund-exchange/:orderId/:orderItemId',
    loadChildren: './refund-and-exchange/refund-exchange.module#RefundExchangeModule'
  },
  {
    path: 'failed-delivery/:orderId/:orderItemId',
    loadChildren: './failed-delivery/failed-delivery.module#FailedDeliveryModule'
  },
  {
    path: "verifyotp/:orderId",
    component: OtpConfirmationComponent,
    data: {title: 'Enter OTP'}
  },
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
