"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./information-popover.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./information-popover.component");
var styles_InformationPopoverComponent = [i0.styles];
var RenderType_InformationPopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InformationPopoverComponent, data: {} });
exports.RenderType_InformationPopoverComponent = RenderType_InformationPopoverComponent;
function View_InformationPopoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "user-deatil"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["More information"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["It appears that the delivery address you have provided is incomplete or inaccurate, possibly due to missing information. Please ensure that your address includes the following details:"])), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "infoAddress"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "landmark"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" A landmark "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "infoAddress"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "street"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Street name "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "infoAddress"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "building"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Building or apartment number "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "infoAddress"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "div", [["class", "city"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" City, town, or village "])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "div", [["class", "button"]], null, null, null, null, null))], null, null); }
exports.View_InformationPopoverComponent_0 = View_InformationPopoverComponent_0;
function View_InformationPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-information-popover", [], null, null, null, View_InformationPopoverComponent_0, RenderType_InformationPopoverComponent)), i1.ɵdid(1, 114688, null, 0, i2.InformationPopoverComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_InformationPopoverComponent_Host_0 = View_InformationPopoverComponent_Host_0;
var InformationPopoverComponentNgFactory = i1.ɵccf("app-information-popover", i2.InformationPopoverComponent, View_InformationPopoverComponent_Host_0, {}, {}, []);
exports.InformationPopoverComponentNgFactory = InformationPopoverComponentNgFactory;
