<div class="edit-address-popup" [ngClass]="deliveryPopupdelay ? 'show' : 'hide'"
  (click)="closeEditAddresspopup($event)"></div>
<div class="address-popup" *ngIf="deliveryPopup" [ngStyle]="{ display: deliveryPopupdelay ? 'block' : '' }"
  [ngClass]="deliveryPopup ? 'show' : 'hide'">
  <app-change-delivery-address [addressFormValue]="addressFormValue" [customerOrderNumber]="customerOrderNumber"
    [(orderData)]="orderData" [poeSetting]="userSettings" (editAddrError)="editAddrError($event)"
    (closeEditAddresspopup)="closeEditAddresspopup($event)"
    (editdetailspaydataevent)="editdetailspaydataevent($event)"></app-change-delivery-address>
</div>

<ng-container *ngIf="verifyOTP && !isMobile">
  <div class="sidebar-container">
    <ng-container *ngIf="
        orderData.channelSlug == 'test_test_ajeet98' ||
          orderData.channelSlug == 'pantone';
        else other
      ">
      <h2 class="heading side-bar-heading" *ngIf="orderData.customerOrderNumber">
        <!-- <img class="mob-icon" src="../../../../assets/images/back-arrow.png" /> -->
        Order summary
        <!-- <span>{{orderData ? orderData.customerOrderNumber:''}}</span>  -->
      </h2>
      <p class="purshase-details" *ngIf="orderData.items.length > 0">
        You purchased
        <span>{{ orderData && orderData.items ? orderData.items.length : "" }}
          {{ orderData && orderData.items.length > 1 ? " items" : " item" }}
        </span>
        in order
        <span> {{ orderData ? orderData.customerOrderNumber : "" }} </span>
        <!-- on <span> {{ (orderData ? replaceDate(orderData.orderDate) :'') }} </span>  -->
        <ng-container *ngIf="orderData.orderDate">
          on
          <span>
            {{ orderData ? replaceDate(orderData.orderDate) : "" }}
          </span></ng-container>
        <span style="filter: blur(5px);">
          for
          <span class="blur">
            {{ rupeeSymbol }} {{ orderData ? orderData.orderTotal : "" }}</span>
          via
          <span>
            {{
            orderData && orderData.isCOD === 1
            ? " Cash on delivery"
            : "Prepaid mode"
            }}</span>
        </span>
      </p>
    </ng-container>

    <ng-template #other>
      <h2 class="heading" *ngIf="orderData.customerOrderNumber || orderData.vendorOrderNumber">
        <!-- <img class="mob-icon" src="../../../../assets/images/back-arrow.png" /> -->
        Order summary
        <!-- <span>{{orderData ? orderData.customerOrderNumber:''}}</span>  -->
      </h2>
      <p class="purshase-details" *ngIf="orderData.items.length > 0">
        You purchased
        <span>{{ orderData && orderData.items ? orderData.items.length : "" }}
          {{ orderData && orderData.items.length > 1 ? " items" : " item" }}
        </span>
        in order
        <span>
          {{
          orderData.vendorOrderNumber
          ? orderData.vendorOrderNumber
          : orderData.customerOrderNumber
          }}
        </span>
        <!-- on <span>{{ (orderData ? replaceDate(orderData.orderDate) :'') }} </span>  -->
        <ng-container *ngIf="orderData.orderDate">
          on
          <span>
            {{ orderData ? replaceDate(orderData.orderDate) : "" }}
          </span></ng-container>
        <span style="filter: blur(5px);">
          for
          <span>
            {{ rupeeSymbol }} {{ orderData ? orderData.orderTotal : "" }}</span>
          via
          <span>
            {{
            orderData && orderData.isCOD === 1
            ? " Cash on delivery"
            : "Prepaid mode"
            }}</span>
        </span>
      </p>
    </ng-template>
    <div class="verifyotpsidebar">
      <div class="otpscreenside">
        <div class="img">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M28.5 16.5H7.5C5.84315 16.5 4.5 17.8431 4.5 19.5V30C4.5 31.6569 5.84315 33 7.5 33H28.5C30.1569 33 31.5 31.6569 31.5 30V19.5C31.5 17.8431 30.1569 16.5 28.5 16.5Z"
              stroke="#777777" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M10.5 16.5V10.5C10.5 8.51088 11.2902 6.60322 12.6967 5.1967C14.1032 3.79018 16.0109 3 18 3C19.9891 3 21.8968 3.79018 23.3033 5.1967C24.7098 6.60322 25.5 8.51088 25.5 10.5V16.5"
              stroke="#777777" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div class="content">
          Verify OTP to view order details.
        </div>
        <div class="button">
          <input type="button" value="SEND OTP" class="btn-cancel" (click)="sendOTP()">
        </div>
      </div>
    </div>
  </div>

</ng-container>
<ng-container *ngIf="orderData && orderData.customerOrderNumber && verifyOTP == false">
  <div class="sidebar-container">
    <ng-container *ngIf="
        orderData.channelSlug == 'test_test_ajeet98' ||
          orderData.channelSlug == 'pantone';
        else other
      ">
      <h2 class="heading side-bar-heading" *ngIf="orderData.customerOrderNumber">
        <!-- <img class="mob-icon" src="../../../../assets/images/back-arrow.png" /> -->
        Order summary
        <!-- <span>{{orderData ? orderData.customerOrderNumber:''}}</span>  -->
      </h2>
      <p class="purshase-details" *ngIf="orderData.items.length > 0">
        You purchased
        <span>{{ orderData && orderData.items ? orderData.items.length : "" }}
          {{ orderData && orderData.items.length > 1 ? " items" : " item" }}
        </span>
        in order
        <span> {{ orderData ? orderData.customerOrderNumber : "" }} </span>
        <!-- on <span> {{ (orderData ? replaceDate(orderData.orderDate) :'') }} </span>  -->
        <ng-container *ngIf="orderData.orderDate">
          on
          <span>
            {{ orderData ? replaceDate(orderData.orderDate) : "" }}
          </span></ng-container>
        for
        <span>
          {{ rupeeSymbol }} {{ orderData ? orderData.orderTotal : "" }}</span>
        via
        <span>
          {{
          orderData && orderData.isCOD === 1
          ? " Cash on delivery"
          : "Prepaid mode"
          }}</span>
      </p>
    </ng-container>

    <ng-template #other>
      <h2 class="heading" *ngIf="orderData.customerOrderNumber || orderData.vendorOrderNumber">
        <!-- <img class="mob-icon" src="../../../../assets/images/back-arrow.png" /> -->
        Order summary
        <!-- <span>{{orderData ? orderData.customerOrderNumber:''}}</span>  -->
      </h2>
      <p class="purshase-details" *ngIf="orderData.items.length > 0">
        You purchased
        <span>{{ orderData && orderData.items ? orderData.items.length : "" }}
          {{ orderData && orderData.items.length > 1 ? " items" : " item" }}
        </span>
        in order
        <span>
          {{
          orderData.vendorOrderNumber
          ? orderData.vendorOrderNumber
          : orderData.customerOrderNumber
          }}
        </span>
        <!-- on <span>{{ (orderData ? replaceDate(orderData.orderDate) :'') }} </span>  -->
        <ng-container *ngIf="orderData.orderDate">
          on
          <span>
            {{ orderData ? replaceDate(orderData.orderDate) : "" }}
          </span></ng-container>
        for<span>
          {{ rupeeSymbol }} {{ orderData ? orderData.orderTotal : "" }}</span>
        via
        <span>
          {{
          orderData && orderData.isCOD === 1
          ? " Cash on delivery"
          : "Prepaid mode"
          }}</span>
      </p>
    </ng-template>

    <!-- <div class="buyer-info">
        <span class="location-icon"> <img src="../../../../assets/images/location-ic.png" /></span>
        <div class="buyer-detail">
            <h3> {{ (orderData && orderData.shippingAddress) ? orderData.shippingAddress.customerName:'' }} </h3>
            <p class="purshase-details">
                {{ (orderData && orderData.shippingAddress) ? orderData.shippingAddress.addressLine1 +', ':'' }}
                {{ (orderData && orderData.shippingAddress) ? orderData.shippingAddress.addressLine2+', ':'' }}
                {{ (orderData && orderData.shippingAddress) ? orderData.shippingAddress.city +'-'+orderData.shippingAddress.postalCode:'' }}
            </p>
        </div>
    </div> -->

    <!-- updated address -->

    <!-- <ng-container *ngIf="selectedItemDetails.ndrResolutionDetails && selectedItemDetails.ndrResolutionDetails.shippingDetails">
        <div class="updated-address" *ngIf="selectedItemDetails.ndrResolutionDetails.shippingDetails.updatedAddress">
            <div class="heading location-icon"> <i class="fa fa-map-marker" aria-hidden="true"></i> Updated Address</div>
            <p class="purshase-details">{{ selectedItemDetails.ndrResolutionDetails.shippingDetails.updatedAddress }}</p>
        </div>
        <div class="address-landark" *ngIf="selectedItemDetails.ndrResolutionDetails.shippingDetails.landmark">
            <div class="heading location-icon"> <i class="fa fa-map-marker" aria-hidden="true"></i> Updated Landmark</div>
            <p class="purshase-details">Landmark: {{ selectedItemDetails.ndrResolutionDetails.shippingDetails.landmark }}</p>
        </div>
    </ng-container>
    <div class="alternate-number" *ngIf="selectedItemDetails.ndrResolutionDetails && selectedItemDetails.ndrResolutionDetails.contactPhoneNumber">
        <div class="heading">Alternate Contact Number</div>
        <ul class="update-contact-list">
            <li class="call-icon"><i class="fa fa-phone" aria-hidden="true"></i>
                <a href="tel">+91 {{ selectedItemDetails.ndrResolutionDetails.contactPhoneNumber }}</a></li>
        </ul>
    </div> -->

    <!-- <div class="heading m-size">Updates sent to</div>
    <ul class="update-contact-list">
        <div class="refresh-icon"> <img src="../../../../assets/images/refresh-ic.png" /></div>
        <li class="call-icon">
            +91 {{ (orderData && orderData.shippingAddress) ? orderData.shippingAddress.contactPhone:'' }}</li>
        <li class="email-icon">
            {{ (orderData && orderData.shippingAddress) ? orderData.shippingAddress.email:'' }}
        </li>
    </ul> -->

    <div class="heading m-size">
      {{ orderData.items.length == 1 ? "ITEM" : "ITEMS" }} IN THIS ORDER
    </div>

    <div class="ordered-items" *ngIf="orderData && orderData.items && orderData.items.length > 0">
      <ng-container *ngFor="let item of orderData.items">
        <div [ngClass]="
            item.orderItemID === selectedItemId
              ? 'item-list active'
              : 'item-list'
          " (click)="onClickItems(item)">
          <ng-container *ngIf="item && item.productImageUrl">
            <img src="{{
                item.productImageUrl
                  ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                    item.productImageUrl +
                    '?tr=w-300'
                  : 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' +
                    item.productImageUrl +
                    '?tr=w-300'
              }}" alt="" height="94px" width="67px" style="border-radius: 4px;" onerror="this.style.display='none'" />
          </ng-container>

          <div class="item-info">
            <div class="item-name">
              {{ item ? item.productName : "" }}
            </div>
            <!-- <div class="item-color-size">   Color: blue | Size : XXL </div> -->
            <div class="item-price">
              {{ rupeeSymbol }} {{
              item?.invoiceTotal
              ? transformDecimal(
              item?.invoiceTotal
              )
              : transformDecimal(item?.lineItemTotal)
              }}
              <!-- {{ rupeeSymbol }} {{ item ? item.lineItemTotal : "" }} -->
              <!-- <span class="size-class" *ngIf="item.productSize">Size&nvap;{{ item ? item.productSize : ''}}</span> -->
              <span *ngIf="item.productSize">| Size {{ item ? item.productSize : "" }}</span>
            </div>

            <!-- <ng-container *ngIf="item.latestStatus">
                        <ng-container
                            *ngIf="item.timeLine == 'refundTimeLine' || item.timeLine == 'reverseTrackingTimeline' || item.timeLine == 'forwardTrackingTimeline' || item.timeLine == 'returnTrackingTimeline'; "> -->
            <!--Refund Timeline-->
            <!-- <ng-container *ngIf="item.timeLine == 'refundTimeLine'">
                                <ng-container *ngIf="item.refundTimeLine">
                                    <div class="item-status status-yellow-color">REFUND</div>
                                </ng-container>
                            </ng-container> -->
            <!--Reverse Timeline-->
            <!-- <ng-container *ngIf="item.timeLine == 'reverseTrackingTimeline'">
                                <ng-container *ngIf="item.reverseTrackingTimeline">
                                    <div *ngIf="item.latestStatus == 'created'" class="item-status status-blue-color">
                                        RETURN</div> -->
            <!-- <div *ngIf="reverseIntransitStatus.includes(item.latestStatus) || item.latestStatus == 'approved' || item.latestStatus == 'order_placed' || item.latestStatus == 'picked_up'"
                                        class="item-status status-yellow-color">IN TRANSIT</div> -->
            <!-- <div *ngIf="item.latestStatus == 'picked_up' || item.latestStatus == 'intransit'"
                                        class="item-status status-yellow-color">IN TRANSIT</div>
                                    <div *ngIf="remarkStatusArray.includes(item.latestStatus) && isBadInventory(item)"
                                    class="item-status status-red-color">
                                        {{ (item.returnType == returnTypeExchange) ? 'Exchange':(item.returnType == returnTypeReplace ? 'Exchange': 'Return') }}
                                    </div>
                                    <div *ngIf="remarkStatusArray.includes(item.latestStatus) && !isBadInventory(item)"
                                        [ngClass]="(item.returnType == returnTypeExchange || item.returnType == returnTypeReplace) ? 'item-status status-green-color' : 'item-status status-blue-color'" >
                                        {{ (item.returnType == returnTypeExchange) ? 'Exchange':(item.returnType == returnTypeReplace ? 'Exchange': 'Return') }}
                                    </div> -->

            <!-- <div *ngIf="item.latestStatus == 'delivered_warehouse' && item.qc != '1'" class="item-status cancelled">
                                            REJECTED</div> -->
            <!-- <div *ngIf="item.latestStatus == 'return_cancelled'" class="item-status cancelled">
                                        RETURN CANCELLED</div> -->
            <!-- <div *ngIf="item.latestStatus == 'return_cancelled'" class="item-status cancelled" style="color: seagreen;">
                                            DELIVERED</div>
                                </ng-container>
                            </ng-container> -->

            <!--Return Timeline-->
            <!-- <ng-container *ngIf="item.timeLine == 'returnTrackingTimeline'">
        <ng-container *ngIf="item.reverseTrackingTimeline">


            <div *ngIf="warehouse_status.includes(item.latestStatus)"
            [ngClass]="(item.returnType == 'return' || item.returnType == 'return') ? 'item-status status-green-color' : 'item-status status-blue-color'" >
            {{ (item.returnType == 'return') ? 'Returned':(item.returnType == 'return' ? 'Returned': 'Returned') }}
            </div> -->
            <!-- <div *ngIf="item.latestStatus == 'delivered_warehouse' && item.qc != '1'" class="item-status cancelled">
                    REJECTED</div> -->
            <!-- <div *ngIf="item.latestStatus == 'return_cancelled'" class="item-status cancelled">
                RETURN CANCELLED</div> -->

            <!-- </ng-container>
    </ng-container> -->

            <!--Forward Timeline-->
            <!-- <ng-container *ngIf="item.timeLine == 'forwardTrackingTimeline'">
                                <ng-container
                                    *ngIf="cancellationStatusArray.includes(item.latestStatus);else secondtype">
                                    <div  class="item-status cancelled"> CANCELLED</div>
                                </ng-container>
                                <ng-template #secondtype>
                                    <ng-container *ngIf="item.forwardTrackingTimeline"> -->
            <!-- <div *ngIf="item.latestStatus == 'created'"
                                            class="item-status status-blue-color">APPROVED</div> -->
            <!-- <div *ngIf="forwardIntransitStatus.includes(item.latestStatus) "
                                            class="item-status status-yellow-color">IN TRANSIT</div>
                                        <div *ngIf="item.latestStatus == 'delivered'"
                                            class="item-status status-green-color">DELIVERED</div>
                                        <div *ngIf="item.latestStatus == 'rto_delivered_warehouse'"
                                            class="item-status cancelled">CANCELLED</div>
                                    </ng-container>
                                </ng-template>
                            </ng-container>
                        </ng-container> -->
            <!-- <ng-template #defaultremark>
                            <div class="status-note"> {{ item.latestStatus }}</div>
                        </ng-template> -->
            <!-- </ng-container> -->
          </div>
        </div>
      </ng-container>
    </div>

    <div class="side-details" style="margin-top:16px;border-top: 1px solid rgba(0, 0, 0, 0.1);">
      <ng-container *ngIf="
          selectedItemDetails?.reverseTrackingTimeline?.pickupAddress &&
          !isEmptyKeys(
            selectedItemDetails?.reverseTrackingTimeline?.pickupAddress
          )
        ">
        <div class="secondary-info secondary-info-unset">
          <div class="buyer-info">
            <h2 class="heading">Pickup Details</h2>
            <!-- <span class="location-icon"> <img src="../../../../assets/images/location-ic.png" /></span> -->
            <div class="buyer-detail">
              <h3>
                {{
                selectedItemDetails.reverseTrackingTimeline.pickupAddress
                ? selectedItemDetails.reverseTrackingTimeline.pickupAddress
                .customerName
                : ""
                }}
              </h3>

              <p class="purshase-details1">
                +91
                {{
                selectedItemDetails.reverseTrackingTimeline.pickupAddress
                ? selectedItemDetails.reverseTrackingTimeline.pickupAddress
                .contactPhone
                : ""
                }}
                |
                {{
                selectedItemDetails.reverseTrackingTimeline.pickupAddress
                ? selectedItemDetails.reverseTrackingTimeline.pickupAddress
                .email
                : ""
                }}
              </p>
              <p class="purshase-details">
                {{
                selectedItemDetails.reverseTrackingTimeline.pickupAddress
                ? selectedItemDetails.reverseTrackingTimeline.pickupAddress
                .addressLine1 + ", "
                : ""
                }}
                {{
                selectedItemDetails.reverseTrackingTimeline.pickupAddress
                ? selectedItemDetails.reverseTrackingTimeline.pickupAddress
                .addressLine2 + ", "
                : ""
                }}
                {{
                selectedItemDetails.reverseTrackingTimeline.pickupAddress
                ? selectedItemDetails.reverseTrackingTimeline.pickupAddress
                .city +
                "-" +
                selectedItemDetails.reverseTrackingTimeline.pickupAddress
                .postalCode
                : ""
                }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- updated address -->
      <ng-container *ngIf="
          selectedItemDetails?.ndrResolutionDetails &&
          selectedItemDetails?.ndrResolutionDetails?.shippingDetails
        ">
        <div class="secondary-info secondary-info-unset ">
          <div class="buyer-info" [ngStyle]="
              updatedAddr
                ? {
                    border: '1px Solid #5DB075',
                    'border-radius': '10px',
                    'box-sizing': 'border-box',
                    border: '1px Solid #5DB075',
                    width: 'calc(100% - 10px)',
                    padding: '12px 15px',
                    display: 'inline-block',
                    'margin-left': '10px',
                    'margin-top': '10px'
                  }
                : {}
            ">
            <h2 class="heading" [ngStyle]="updatedAddr ? { 'margin-top': '0' } : {}">
              Updated delivery details
            </h2>
            <div class="buyer-detail">
              <h3>
                {{
                selectedItemDetails.ndrResolutionDetails?.shippingDetails.name
                ? selectedItemDetails?.ndrResolutionDetails?.shippingDetails
                .name
                : ""
                }}
              </h3>
              <p class="purshase-details1" *ngIf="
                  selectedItemDetails?.ndrResolutionDetails?.contactPhoneNumber
                ">
                +91
                {{
                selectedItemDetails?.ndrResolutionDetails?.contactPhoneNumber
                }}
                <ng-container *ngIf="
                    selectedItemDetails?.ndrResolutionDetails.shippingDetails
                      .email
                  ">|
                  {{
                  selectedItemDetails.ndrResolutionDetails?.shippingDetails
                  .email
                  }}</ng-container>
              </p>
              <p class="purshase-details">
                {{
                selectedItemDetails?.ndrResolutionDetails?.shippingDetails
                ?.updatedAddress
                ? selectedItemDetails?.ndrResolutionDetails?.shippingDetails
                ?.updatedAddress
                : ""
                }}
              </p>
              <p class="purshase-details">
                <ng-container *ngIf="
                    selectedItemDetails?.ndrResolutionDetails?.shippingDetails
                      ?.landmark
                  ">
                  {{
                  selectedItemDetails?.ndrResolutionDetails?.shippingDetails
                  ?.landmark
                  ? selectedItemDetails?.ndrResolutionDetails
                  ?.shippingDetails?.landmark
                  : ""
                  }}
                </ng-container>
              </p>

              <ng-container *ngIf="
                selectedItemDetails?.ndrResolutionDetails?.shippingDetails
                  ?.notes
              ">
                <div class="user_notes">
                  <span class="label"> Additional delivery notes: </span>
                  <span class="value"> {{
                    selectedItemDetails?.ndrResolutionDetails?.shippingDetails
                    ?.notes
                    ? selectedItemDetails?.ndrResolutionDetails
                    ?.shippingDetails?.notes
                    : ""
                    }} </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="buyer-info" [ngStyle]="
          updatedAddr
            ? {
                border: '1px Solid #5DB075',
                'border-radius': '10px',
                'box-sizing': 'border-box',
                border: '1px Solid #5DB075',
                width: 'calc(100% - 10px)',
                padding: '12px 15px',
                display: 'inline-block',
                'margin-left': '10px',
                'margin-top': '10px'
              }
            : {}
        ">
        <h2 class="heading">
          Delivery details
          <!-- <ng-container *ngIf="orderData && orderData.items && orderData.items.length > 0">-->
          <ng-container>
            <!-- <ng-container *ngIf="trackingID"> -->
            <ng-container *ngIf="editaddresbutton && trackingID">
              <a (click)="showEditDetails()" class="edit-details-btn"><img
                  src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/edit-payment.svg" />Edit
                details</a>
            </ng-container>
          </ng-container>
          <!-- </ng-container> -->
        </h2>

        <!-- <span class="location-icon"> <img src="../../../../assets/images/location-ic.png" /></span> -->
        <div class="buyer-detail">
          <h3>
            {{
            orderData && orderData.shippingAddress
            ? orderData.shippingAddress.customerName
            : ""
            }}
          </h3>
          <p class="purshase-details1">
            +91
            {{
            orderData && orderData.shippingAddress
            ? orderData.shippingAddress.contactPhone
            : ""
            }}
            |
            {{
            orderData && orderData.shippingAddress
            ? orderData.shippingAddress.email
            : ""
            }}
          </p>
          <p class="purshase-details">
            {{
            orderData && orderData.shippingAddress
            ? orderData.shippingAddress.addressLine1 + ", "
            : ""
            }}
            <ng-container *ngIf="orderData.shippingAddress.addressLine2">{{
              orderData && orderData.shippingAddress
              ? orderData.shippingAddress.addressLine2 + ", "
              : ""
              }}</ng-container>
            {{
            orderData && orderData.shippingAddress
            ? orderData.shippingAddress.city +
            "-" +
            orderData.shippingAddress.postalCode
            : ""
            }}
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="heading m-size">Updates sent to</div>
    <ul class="update-contact-list">
        <div class="refresh-icon"> <img src="../../../../assets/images/refresh-ic.png" /></div>
        <li class="call-icon">
            +91 {{ (orderData && orderData.shippingAddress) ? orderData.shippingAddress.contactPhone:'' }} | {{ (orderData && orderData.shippingAddress) ? orderData.shippingAddress.email:'' }}
        </li>
        <li class="email-icon">
            {{ (orderData && orderData.shippingAddress) ? orderData.shippingAddress.email:'' }}
        </li>
    </ul> -->
  </div>
</ng-container>
