"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./readmore.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./readmore.component");
var styles_ReadMoreComponent = [i0.styles];
var RenderType_ReadMoreComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReadMoreComponent, data: {} });
exports.RenderType_ReadMoreComponent = RenderType_ReadMoreComponent;
function View_ReadMoreComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleContent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["see more"]))], null, null); }
function View_ReadMoreComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleContent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["see Less"]))], null, null); }
function View_ReadMoreComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", "\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\u00A0"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isContentToggled; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.isContentToggled; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 0, 0, currVal_0); }); }
exports.View_ReadMoreComponent_0 = View_ReadMoreComponent_0;
function View_ReadMoreComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-read-more", [], null, null, null, View_ReadMoreComponent_0, RenderType_ReadMoreComponent)), i1.ɵdid(1, 114688, null, 0, i3.ReadMoreComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ReadMoreComponent_Host_0 = View_ReadMoreComponent_Host_0;
var ReadMoreComponentNgFactory = i1.ɵccf("app-read-more", i3.ReadMoreComponent, View_ReadMoreComponent_Host_0, { content: "content", limit: "limit", completeWords: "completeWords" }, {}, []);
exports.ReadMoreComponentNgFactory = ReadMoreComponentNgFactory;
