import { Action } from "@ngrx/store";

export enum OrderReturnActionTypes {
  GET_RETURN_REASON = "[Return Reason API] Get Return Reason",
  GET_RETURN_REASON_SUCCESS = "[Return Reason API] Get Return Reason Success",
  GET_RETURN_REASON_FAILED = "[Return Reason API] Get Return Reason Failed",

}

export class GetReturnReason implements Action {
  readonly type = OrderReturnActionTypes.GET_RETURN_REASON;

  constructor(public payload: string) {}
}

export class GetReturnReasonSuccess implements Action {
  readonly type = OrderReturnActionTypes.GET_RETURN_REASON_SUCCESS;

  constructor(public payload: { orderPlaced: any }) {}
}

export class GetReturnReasonFailed implements Action {
  readonly type = OrderReturnActionTypes.GET_RETURN_REASON_FAILED;
}

export type OrderReturnUnion =
  | GetReturnReason
  | GetReturnReasonSuccess
  | GetReturnReasonFailed;
