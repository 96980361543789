<div class="container" [class.left-panel-active]="leftPanelActive">
  <div class="row custom-row">
    <div class="panel-left p_panel-left display-none">
      <!-- <div class="left-panel-hide" (click)="hideLeftPanel()">
        <figure><img src="assets/images/close.png" alt="" /></figure>
      </div> -->
      <app-order-sidebar (changeleftPanelValue)='displayLeftPanel($event)' (selectedItemData)="onSelectShowOrderItemDetail($event)"></app-order-sidebar>
    </div>
    <div class="panel-right mr-btm-0 p_panel-right mobile-move">
      
      <form [formGroup]="returnItemForm">
        <div class="ordered-items">
          <div class="item-list">
        <div class="item-info">
          <div class="item-name">
  
  <button class="close-track" (click)="setBackToOrder()"></button>
  
           <span class="order-heading">Return Item</span>
           <ng-container *ngIf="(userSettings?.supportPhone && userSettings?.supportPhone!='null') || (userSettings?.supportEmail && userSettings?.supportEmail!='null') || (userSettings?.supportUrl && userSettings?.supportUrl!='null')">
            <a  (click)="openHelpModal()" (mouseover) ="mouseEnter('addColor') "  (mouseout) ="mouseLeave('removeColor')"  [ngStyle]="{color:Helpcolor?getColor:'#828282'} ">Help ?</a>

           </ng-container>
          
    
          </div>
          </div>
          </div>
          </div>


        <!-- <div class="left-panel-show" (click)="showLeftPanel()">
          <figure><img src="assets/images/back-arrow.png" alt="" /></figure>
          <span> Order {{orderDetails ? orderDetails?.customerOrderNumber : ''}}  </span>
        </div> -->
        <div class="return-mob-wrp scroll-content" #scroll (scroll)="onScroll()" [class.box-shadowcss]="isBoxshadow">
        <div class="product-detail-mob" *ngIf="returnOrderItemDetails">
          <div class="prod-img">
            <img src="{{ returnOrderItemDetails && returnOrderItemDetails.productImageUrl ? returnOrderItemDetails.productImageUrl:'assets/images/item-image.jpg' }}" alt="product image">
          </div>
          <div class="prod-info">
              <h4>{{ returnOrderItemDetails ? returnOrderItemDetails.productName:''}}</h4>
              <p>{{rupeeSymbol}} <Span> {{ returnOrderItemDetails ? transformDecimal(returnOrderItemDetails.customerPrice):''}}</Span>
                <!-- <span class="size-class" *ngIf="returnOrderItemDetails.productSize">{{returnOrderItemDetails ? returnOrderItemDetails.productSize:''}}</span> -->
                <span  *ngIf="returnOrderItemDetails.productSize"> • Size {{returnOrderItemDetails ? returnOrderItemDetails.productSize:''}}</span>

              </p>
          </div>
        </div>
        <div class="return-item-pad">
       
        <!-- <div > -->
          <!-- Bank Details Start -->
          <h5>Please let us know why do you wish to return?</h5>

<div class="form-row-box mb-30">
  <div class="p-form-row left-form-field">
    <div>
      <mat-form-field class="p-full-width" [ngStyle]="{border:( formControls.returnReason.touched &&
        formControls.returnReason.invalid)?'1px solid #EB5757':''}">
        <mat-label class="custom-label"> Tell us the reason </mat-label>
        <mat-select disableOptionCentering  matInput name="returnReason" id="returnReason" formControlName="returnReason"
          (selectionChange)="onChangeReturnReason($event.value, true)">
          <mat-option *ngFor="let reason of userSettings?.returnReasons" value="{{ reason }}">
            {{ reason }}
          </mat-option>
         
        </mat-select>


       
        <mat-error *ngIf="
            formControls.returnReason.touched &&
            formControls.returnReason.invalid
          ">
          <mat-error class="error-message" *ngIf="formControls.returnReason.errors.required">This field is
            mandatory</mat-error>
        </mat-error>
      </mat-form-field>
      <!-- [(value)]="selected" -->
    </div>
  </div>


  <div class="p-form-row mt-20" >
    <div>
      <mat-form-field class="p-full-width" style="line-height: 19px;">
        <label class="p-custom-label">Additional Remarks </label>
        <!-- <mat-select disableOptionCentering  matInput name="returnSubReason" id="returnSubReason" formControlName="returnSubReason"
          (selectionChange)="onChangeSubReason($event.value, true)">
          <mat-option *ngFor="let subreason of subReason" value="{{ subreason }}">
            {{ subreason }}
          </mat-option>
        </mat-select> -->
        <input matInput name="returnSubReason" id="returnSubReason"   formControlName="returnSubReason">

        <!-- <mat-error *ngIf="
            formControls.returnSubReason.touched &&
            formControls.returnSubReason.invalid
          ">
          <mat-error class="error-message" *ngIf="formControls.returnSubReason.errors.required">This field is
            mandatory</mat-error>
        </mat-error> -->
      </mat-form-field>
    </div>
  </div>
</div>

          

          <div  *ngIf="showSubReason" class="mb-30">
            <h5>Let us know how would you like to proceed?</h5>
            <div class="p-form-row">
              <mat-form-field class="p-full-width" [ngStyle]="{border:( formControls.returnAction.touched &&
                formControls.returnAction.invalid)?'1px solid #EB5757':''}">
                <mat-label class="custom-label">Select return action</mat-label>
                <mat-select disableOptionCentering  matInput name="returnAction" id="returnAction" formControlName="returnAction"
                  (selectionChange)="onActionChange($event.value, true)">
                  <!-- <mat-option *ngFor="let action of action" value="{{ action }}">
                   Provide {{ action }}
                  </mat-option> -->
                  <mat-option *ngIf="userSettings?.returnResolutionPolicy.exchange" value="exchange">

                    <!-- <mat-option *ngIf="userSettings?.returnResolutionPolicy.exchange && isStockAvailable" value="exchange"> -->
                      I want an exchange
                      </mat-option>
                
                  <ng-container *ngIf="userSettings?.returnResolutionPolicy.refund && orderDetails.isCOD">
                    <mat-option *ngIf='userSettings?.returnRefundPolicyCod?.bank || userSettings?.returnRefundPolicyCod?.originalPaymentMode || userSettings?.returnRefundPolicyCod?.storeCredit' value="refund">
                      I want a refund
                      </mat-option>
                  </ng-container>
                  
                  <ng-container *ngIf="userSettings?.returnResolutionPolicy.refund && !orderDetails.isCOD">
                    <mat-option *ngIf='userSettings?.returnRefundPolicyPrepaid?.bank || userSettings?.returnRefundPolicyPrepaid?.originalPaymentMode || userSettings?.returnRefundPolicyPrepaid?.storeCredit' value="refund">
                      I want a refund

                      </mat-option>
                  </ng-container>


                  <!-- <mat-option>None</mat-option>
                                    <mat-option value="Provide Replacement">Provide Replacement</mat-option>
                                    <mat-option value="bank">Refund</mat-option> -->
                </mat-select>
                <mat-error *ngIf="
                    formControls.returnAction.touched &&
                    formControls.returnAction.invalid
                  ">
                  <mat-error class="error-message" *ngIf="formControls.returnAction.errors.required">This field is
                    mandatory</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>


          <div *ngIf="showExchangeOptions && exchangeOptionData && !orderDetails?.isPoeOldFlow" class="mb-30">
            <h5>Please enter the variant of your choice</h5>
            <div class="p-form-row" >
              <mat-form-field class="p-full-width">
                <input matInput  placeholder="Enter variant" name="size" id="size"
                  formControlName="size" (input)="getValue(size.value)"  #size/>
                  <mat-error *ngIf="
                  formControls.size.touched &&
                  formControls.size.invalid
                ">
                <mat-error class="error-message" *ngIf="formControls.size.errors.required">This field is
                  mandatory</mat-error>
              </mat-error>
              </mat-form-field>
            </div>
            <!-- <div class="prev-ordered-size" *ngIf="returnOrderItemDetails.additionalProductInfo && returnOrderItemDetails.additionalProductInfo.size">
              <h4>
                You previously ordered for
                <span class="prev-size">size {{ returnOrderItemDetails.additionalProductInfo ? returnOrderItemDetails.additionalProductInfo.size:'' }}</span>
              </h4>
          </div> -->
          </div>

         

           
          <div class="form-row mb-30" *ngIf="showExchangeOptions && exchangeOptionData && orderDetails?.isPoeOldFlow">
            <h5 >Please select a variant of your choice</h5>
              <div class="form-column form-column-third">
                  <ng-container *ngIf="exchangeOptionData">
                    <mat-radio-group matInput class="size-radio-btn example-full-width  sizes-full-width " formControlName="exchangeOption" name="exchangeOption" id="exchangeOption" >
                       <ng-container *ngFor="let exchange of exchangeOptionData">
                          <mat-radio-button  class="radio-btn" [ngClass]="exchange.stock <=0 ?'radio-disabled':''" value="{{ exchange.size }}" [checked]="radioSelected === exchange.size ? 'true' : 'false'"  [disabled]="exchange.stock <=0 ? true :false" (click)="handleExchangeEvent(exchange)">
                            <!-- <input type="hidden" formControlName="exchangeSkuID"  name="exchangeSkuID" value="{{ exchange.sku }}" > -->
                            <!-- <input type="hidden" formControlName="exchangeItemId" name="exchangeItemId" value="{{ exchange.itemID }}" > -->
                            <span>{{ exchange.size }}</span>
                          </mat-radio-button>
                        
                       </ng-container>
                    </mat-radio-group>
                    <mat-error *ngIf="formControls.exchangeOption.touched && formControls.exchangeOption.invalid">
                      <mat-error class="error-message" *ngIf="formControls.exchangeOption.errors.required">This field is mandatory</mat-error>
                    </mat-error>
                  </ng-container>
              </div>
              <div class="prev-ordered-size" *ngIf="returnOrderItemDetails.additionalProductInfo && returnOrderItemDetails.additionalProductInfo.size">
                  <h4>
                    You previously ordered for
                    <span class="prev-size">size {{ returnOrderItemDetails.additionalProductInfo ? returnOrderItemDetails.additionalProductInfo.size:'' }}</span>
                  </h4>
              </div>
          </div>

          <div *ngIf="showBankDropdown" class="mb-30">
            <h5>How would you like the refund?</h5>
            <div class="p-form-row">
              <mat-form-field class="p-full-width" [ngStyle]="{border:( formControls.refundMode.touched &&
                formControls.refundMode.invalid)?'1px solid #EB5757':''}">
                <mat-label class="custom-label">Select your mode of refund</mat-label>
                <mat-select disableOptionCentering  matInput name="refundMode" id="refundMode" formControlName="refundMode"
                  (selectionChange)="onPaymentModeChange($event.value, true)">
                  <!-- <mat-option *ngFor="
                      let paymentmode of paymentOptionList.productPaymentOption
                    " value="{{ paymentmode.value }}">
                    {{ paymentmode.name }}</mat-option> -->
                   <ng-container *ngIf='orderDetails.isCOD'>
                    <mat-option  *ngIf="userSettings?.returnRefundPolicyCod?.bank " value="bank">
                      Add bank account details</mat-option>
                   <mat-option  *ngIf="userSettings?.returnRefundPolicyCod?.originalPaymentMode" value="originalPaymentMode">
                    Back to the account used in payment</mat-option>
                  <mat-option  *ngIf="userSettings?.returnRefundPolicyCod?.storeCredit" value="storeCredits">
                    Back as store credits</mat-option>
                   </ng-container>

                   <ng-container *ngIf='!orderDetails.isCOD'>
                    <mat-option  *ngIf="userSettings?.returnRefundPolicyPrepaid?.bank " value="bank">
                      Add bank account details</mat-option>
                   <mat-option  *ngIf="userSettings?.returnRefundPolicyPrepaid?.originalPaymentMode" value="originalPaymentMode">
                    Back to the account used in payment</mat-option>
                  <mat-option  *ngIf="userSettings?.returnRefundPolicyPrepaid?.storeCredit" value="storeCredits">
                    Back as store credits</mat-option>
                   </ng-container>
                   


                </mat-select>
                <mat-error *ngIf="
                    formControls.refundMode.touched &&
                    formControls.refundMode.invalid
                  ">
                  <mat-error class="error-message" *ngIf="formControls.refundMode.errors.required">This field is
                    mandatory</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="account-holder-detail">
            <ng-container *ngIf="showBankDetailsForm">
              <div class="form-row-box mb-12 special-field">
                <div class="p-form-row ">
                  <mat-form-field class="p-full-width" [ngStyle]="{border:(formControls.accountHolderName.touched &&
                    formControls.accountHolderName.invalid)?'1px solid #EB5757':''}">
                      <!-- readonly="true" value="{{
                        (orderDetails.billingAddress
                          ? orderDetails.billingAddress.customerName
                          : '') | titlecase
                      }}"-->
                    <input matInput placeholder="Account holder name" name="accountHolderName"
                      id="accountHolderName" formControlName="accountHolderName" />
                    <mat-error *ngIf="
                        formControls.accountHolderName.touched &&
                        formControls.accountHolderName.invalid
                      ">
                      <mat-error class="error-message" *ngIf="formControls.accountHolderName.errors.required">This field
                        is mandatory</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row-box mb-12">
                <div class="p-form-row mr-18">
                  <mat-form-field class="p-full-width" [ngStyle]="{border:(formControls.accountNumber.touched &&
                    formControls.accountNumber.invalid)?'1px solid #EB5757':''}">
                    <input matInput placeholder="Account number" autocomplete="off" name="accountNumber" id="accountNumber"
                      formControlName="accountNumber" />
                    <mat-error *ngIf="
                        formControls.accountNumber.touched &&
                        formControls.accountNumber.invalid
                      ">
                      <mat-error class="error-message" *ngIf="formControls.accountNumber.errors.required">This field is
                        mandatory</mat-error>
                      <mat-error class="error-message" *ngIf="formControls.accountNumber.errors.pattern">This field is
                        invalid</mat-error>
                      <mat-error class="error-message" *ngIf="formControls.accountNumber.errors.minlength">Min Length - 5
                      </mat-error>
                      <mat-error class="error-message" *ngIf="formControls.accountNumber.errors.maxlength">Max Length - 25
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="p-form-row">
                  <mat-form-field class="p-full-width" [ngStyle]="{border:(formControls.confirmAccountNumber.touched &&
                    formControls.confirmAccountNumber.invalid)?'1px solid #EB5757':''}">
                    <input matInput placeholder="Confirm Account Number" autocomplete="off" name="confirmAccountNumber"
                      id="confirmAccountNumber" formControlName="confirmAccountNumber" />
                    <mat-error *ngIf="
                        formControls.confirmAccountNumber.touched &&
                        formControls.confirmAccountNumber.invalid
                      ">
                      <mat-error class="error-message" *ngIf="formControls.confirmAccountNumber.errors.required">This
                        field is mandatory</mat-error>
                      <mat-error class="error-message" *ngIf="formControls.confirmAccountNumber.errors.pattern">This field
                        is invalid</mat-error>
                      <mat-error class="error-message" *ngIf="formControls.confirmAccountNumber.errors.mustMatch">Confirm
                        account number not matched.</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
  
              <div class="form-row-box mb-12">
                <div class="p-form-row mr-18">
                  <mat-form-field class="p-full-width" [ngStyle]="{border:(formControls.ifscCode.touched &&
                    formControls.ifscCode.invalid)?'1px solid #EB5757':''}">
                    <input matInput placeholder="IFSC code" autocomplete="off" name="ifscCode" id="ifscCode" formControlName="ifscCode"
                      (input)="callIfscCodeCheck($event)" />
                    <mat-error *ngIf="
                        formControls.ifscCode.touched &&
                        formControls.ifscCode.invalid
                      ">
                      <mat-error class="error-message" *ngIf="formControls.ifscCode.errors.required">This field is
                        mandatory</mat-error>
                      <mat-error class="error-message" *ngIf="formControls.ifscCode.errors.pattern">This field is invalid
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
  
                  <figure class="check-ifsc-loader" *ngIf="ifscShowLoader">
                    <img src="assets/images/loader-blue.gif" alt="IFSC Loader" />
                  </figure>
                </div>
                <div class="p-form-row">
                  <mat-form-field class="p-full-width">
                    <input matInput readonly="true"  placeholder="Bank Name" name="bankName" id="bankName"
                      formControlName="bankName" />
                    <input type="hidden"  formControlName="bankName" />
                 
                  </mat-form-field>
                </div>
              </div>
              <input type="hidden" formControlName="bankCity" />
              <div class="form-row-box mb-30">
                <div class="p-form-row mr-18">
                  <mat-form-field class="p-full-width">
                    <input matInput readonly="true"  placeholder="Branch" name="branch" id="branch"
                      formControlName="branch" />
                    <input type="hidden"  formControlName="branch" />
               
                  </mat-form-field>
                </div>
                <div class="p-form-row">
                  <mat-form-field class="p-full-width" [ngStyle]="{border:(formControls.accountType.touched &&
                    formControls.accountType.invalid)?'1px solid #EB5757':''}">
                    <mat-label class="custom-label">Account type</mat-label>
                    <mat-select disableOptionCentering  name="accountType" id="accountType" formControlName="accountType">
                      <mat-option *ngFor="let account of (accountTypes | keyvalue)" value="{{ account.key }}">
                        {{ account.value }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                        formControls.accountType.touched &&
                        formControls.accountType.invalid
                      ">
                      <mat-error class="error-message" *ngIf="formControls.accountType.errors.required">This field is
                        mandatory</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
  
            </ng-container>
          
            <!-- Bank Details Close -->
            <!-- Address section start here-->
            <ng-container *ngIf="showAddressForm" >
              <h5>Where would you like us to pick the order from?</h5>
              <app-change-address [isCod]="orderType" [parentFormType]="formType" [addressFormValue]='addressFormValue' [dynamicdata]="showAddressFormHtml" (formReady)="formInitialized('addressInfo', $event)"></app-change-address>
            </ng-container>
            <!-- Address section end here -->
          </div>
        <!-- </div> -->
      </div>
       
      </div>
      <div class="btn-bottom">
         
        <!-- <input class="btn-continue" type="button" (click)="onSubmit()" [disabled]="!returnItemForm.valid"
          value="Continue" /> -->
          <!--  -->
          <input type="button" value="Cancel" class="btn-cancel" (click)="setBackToOrder()" />
        <ng-container *ngIf="loading;else continueButton">

          <figure class="d-flex btn-loader btn-continue" [ngStyle]="{background:!returnItemForm.valid?'#E0E0E0':getColor} ">
            <div class="loader-css"></div>
          </figure>
        </ng-container>
        <ng-template #continueButton style="border: 1px solid black;">          
          <div class="tooltip">
          <input class="btn-continue "  type="button" (click)="onSubmit()"  [ngStyle]="{background:!returnItemForm.valid?'#E0E0E0':getColor} "  [disabled]="!returnItemForm.valid" value="Continue"  />
              <span class="tooltiptext" *ngIf='!returnItemForm.valid && showToolTip'>Address details are mandatory</span>
            </div>
        </ng-template>   
       
        
      </div>
      <!-- <div class="mob-footer">
        <p> Need further assistance1?  <a href="mailto:{{ (userSettings && userSettings.contactMailId) ? userSettings.contactMailId:'' }}">  Contact us </a>  </p>
        <app-mob-header></app-mob-header>
    </div> -->
      </form>
    </div>
  </div>
</div>


<div class="mob-footer">
  <div class="footer-underline">
      <p>
          <ng-container *ngFor="let keys of footerKey;let i=index" >
              <a target="_blank"    (click)="open_ace_com(keys[1])">
                  {{keys[0]}}
                </a>
              </ng-container>
       
      </p>
    

  </div>

  <div *ngIf="userSettings?.websiteUrl">
      <p class="second-para" >
          <a (click)="open_ace_com(userSettings?.websiteUrl)">
               <span class="mobile-store-link"> Go to store </span> </a>

      </p>
</div>
</div>
    <!-- *ngIf="showLoader" -->
<div class="loader-layover" *ngIf="showLoader">
  <figure>
    <img [src]="(isTablet || isMobile) ? 'assets/images/mobile-loader.gif' :'assets/images/track-page-loader.gif'" alt="Loader">
</figure>
</div>
<div class="poweredby-mobile">
  <a href="https://www.eshopbox.com/" target="_blank">
    <p style="word-wrap: no-wrap;"><a href="https://www.eshopbox.com/" target="_blank">   <img src="https://ik.imagekit.io/2gwij97w0o/POE/eshopbox-monogram.svg" alt=""></a>Powered by Eshopbox</p>
</a> 
</div>

<div class="help-box" (click)="closeHelpModal()" [class.helpShow]="isHelpOpendelay">
 

</div>

<div class="main-helpbox" [ngStyle]="{display:isHelpOpendelay ? 'block': ''} " [class.helpShow]="isHelpOpen">
  <div class="help-top-box">
      <h2>Help?</h2>
      <button (click)="closeHelpModal()"></button>
  </div>

  <div class="help-bottom-box">
    <div class="text-block" *ngIf="userSettings?.supportUrl && userSettings?.supportUrl!='null'">
        <h1>Support URL</h1>
        <a (click)="openSite(userSettings?.supportUrl)">{{userSettings?.supportUrl}}</a>
    </div>
    <div class="text-block" *ngIf="userSettings?.supportEmail && userSettings?.supportEmail!='null'">
        <h1>Support email</h1>
        <a href="mailto:{{ (userSettings && userSettings.supportEmail) ? userSettings.supportEmail:'' }}">{{userSettings?.supportEmail}}</a>
    </div>
    <div class="text-block" *ngIf="userSettings?.supportPhone && userSettings?.supportPhone!='null'">
        <h1>Support phone number</h1>
        <a  href="tel:{{userSettings?.supportPhone}}">{{userSettings?.supportPhone}}</a>
    </div>
</div>
</div>