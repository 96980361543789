<div class="container" [class.left-panel-active]="leftPanelActive">
    <div class="row">
        <div class="panel-left">
            <app-order-sidebar (changeleftPanelValue)='displayLeftPanel($event)'></app-order-sidebar>
        </div>
        <div class="panel-right">
            <form [formGroup]="failedPickupForm" class="failed-delivery">
                <div class="left-panel-show" (click)="showLeftPanel()">
                    <figure><img src="https://ik.imagekit.io/2gwij97w0o/POE/arrow-left.png" alt=""></figure>
                </div>
                <div class="scroll-content">
                    <div class="failed-delivery-content">
                        <div class="courier-details">
                            <div class="courier-name"
                                *ngIf="selectedOrderItem && selectedOrderItem.reverseTrackingTimeline.courierName">
                                Courier Name: <span>{{ selectedOrderItem.reverseTrackingTimeline.courierName }}</span>
                            </div>
                            <div class="track-id"
                                *ngIf="selectedOrderItem && selectedOrderItem.reverseTrackingTimeline.trackingID">
                                Tracking ID: <span>{{ selectedOrderItem.reverseTrackingTimeline.trackingID}} </span>
                            </div>
                        </div>
                        <h5>We were unable to pickup your product as the courier partner stated
                            "{{ ((selectedOrderItem.ndrDetails && selectedOrderItem.ndrDetails.reason) ? selectedOrderItem.ndrDetails.reason :'') | titlecase }}".
                        </h5>
                        <div class="form-row">
                            <div class="form-column form-column-third">
                                <ng-container
                                    *ngIf="selectedOrderItem.ndrDetails && selectedOrderItem.ndrDetails.resolutions.length > 0">
                                    <mat-radio-group class="custom-radio-btn-group" formControlName="resolutionCode">
                                        <ng-container
                                            *ngFor="let item of selectedOrderItem.ndrDetails.resolutions ; let i=index">
                                            <mat-radio-button matInput class="custom-radio-btn"
                                                [value]="item.resolutionCode"
                                                (change)="radioChange(item.resolutionCode)">
                                                {{ item.action }}
                                            </mat-radio-button>

                                            <ng-container
                                                *ngIf="item.resolutionCode == allResolutionCode.DIFFERENT_ADDRESS && resolutionValue == allResolutionCode.DIFFERENT_ADDRESS">
                                                <mat-form-field class="example-full-width  pl-25">
                                                    <input matInput placeholder="Address" name="updatedAddress"
                                                        id="updatedAddress" formControlName="updatedAddress"
                                                        autocomplete="off">
                                                    <mat-error
                                                        *ngIf="formControls.updatedAddress.touched && formControls.updatedAddress.invalid">
                                                        <mat-error class="error-message"
                                                            *ngIf="formControls.updatedAddress.errors.required">This
                                                            field is mandatory</mat-error>
                                                    </mat-error>

                                                </mat-form-field>
                                                <div class="mtn-6 pl-25">
                                                    {{orderDetails.shippingAddress.city + ', ' + orderDetails.shippingAddress.state + '-' + orderDetails.shippingAddress.postalCode}}
                                                </div>
                                            </ng-container>

                                            <div class="later-date  pl-25"
                                                *ngIf="item.resolutionCode == allResolutionCode.DELIVERY_DATE && resolutionValue == allResolutionCode.DELIVERY_DATE">
                                                <mat-radio-group formControlName="deferredDate" id="deferredDate"
                                                    name="deferredDate">
                                                    <ng-container *ngFor="let date of deferredDateArray">
                                                        <mat-radio-button matInput [value]="date"
                                                            (change)="deferredDateChange(date)"
                                                            [checked]="radioSelected === date ? 'true' : 'false'"
                                                            [disabled]="date < currentDate ? true :false">
                                                            <li [ngClass]="radioSelected === date ? 'active':''">
                                                                <span>{{ date | date:'dd'}}th</span>
                                                                <span>{{ date | date:'MMM y'}}</span></li>
                                                        </mat-radio-button>
                                                    </ng-container>
                                                    <mat-error
                                                        *ngIf="formControls.deferredDate.touched && formControls.deferredDate.invalid">
                                                        <mat-error class="error-message"
                                                            *ngIf="formControls.deferredDate.errors.required">This field
                                                            is mandatory</mat-error>
                                                    </mat-error>
                                                </mat-radio-group>
                                            </div>

                                            <mat-form-field class="example-full-width pl-25"
                                                *ngIf="item.resolutionCode == allResolutionCode.FALSE_REASON && resolutionValue == allResolutionCode.FALSE_REASON">
                                                <input matInput placeholder="Remarks" name="remarks" id="remarks"
                                                    formControlName="remarks" autocomplete="off">
                                                <mat-error
                                                    *ngIf="formControls.remarks.touched && formControls.remarks.invalid">
                                                    <mat-error class="error-message"
                                                        *ngIf="formControls.remarks.errors.required">This field is
                                                        mandatory</mat-error>
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field class="example-full-width pl-25"
                                                *ngIf="item.resolutionCode == allResolutionCode.CONTACT_NUMBER && resolutionValue == allResolutionCode.CONTACT_NUMBER">
                                                <input matInput placeholder="Alternate Contact Number" name="contactPhoneNumber" id="contactPhoneNumber"
                                                    formControlName="contactPhoneNumber" autocomplete="off" (keypress)="numberOnly($event)">
                                                <mat-error
                                                    *ngIf="formControls.contactPhoneNumber.touched && formControls.contactPhoneNumber.invalid">
                                                    <mat-error class="error-message"
                                                        *ngIf="formControls.contactPhoneNumber.errors.required">This
                                                        field is mandatory</mat-error>
                                                    <mat-error class="error-message"
                                                        *ngIf="formControls.contactPhoneNumber.errors.pattern">Please
                                                        Enter Valid Number</mat-error>
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field class="example-full-width pl-25"
                                                *ngIf="item.resolutionCode == allResolutionCode.LANDMARK && resolutionValue == allResolutionCode.LANDMARK">
                                                <input matInput placeholder="Landmark" name="landmark" id="landmark"
                                                    formControlName="landmark" autocomplete="off">
                                                <mat-error
                                                    *ngIf="formControls.landmark.touched && formControls.landmark.invalid">
                                                    <mat-error class="error-message"
                                                        *ngIf="formControls.landmark.errors.required">This field is
                                                        mandatory</mat-error>
                                                </mat-error>
                                            </mat-form-field>
                                        </ng-container>
                                        <mat-error
                                            *ngIf="formControls.resolutionCode.touched && formControls.resolutionCode.invalid">
                                            <mat-error class="error-message"
                                                *ngIf="formControls.resolutionCode.errors.required">This field is
                                                mandatory</mat-error>
                                        </mat-error>
                                    </mat-radio-group>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="btn-bottom">
                    <input type="button" value="Cancel" class="btn-cancel" (click)="setBackToOrder()">
                    <ng-container *ngIf="formLoader;else continueButton">
                        <figure><img class="btn-continue btn-loader" src="assets/images/loader.gif" width="100"
                                alt="Loader"></figure>
                    </ng-container>
                    <ng-template #continueButton>
                        <input class="btn-continue" type="button" (click)="onSubmit()"
                            [disabled]="!failedPickupForm.valid" value="Continue">
                    </ng-template>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="loader-layover" *ngIf="showLoader">
    <figure>
        <img [src]="(isTablet || isMobile) ? 'assets/images/mobile-loader.gif' :'assets/images/track-page-loader.gif'" alt="Loader">
    </figure>
</div>