"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var UserRoutes = [];
var SharedRoutingModule = /** @class */ (function () {
    function SharedRoutingModule() {
    }
    return SharedRoutingModule;
}());
exports.SharedRoutingModule = SharedRoutingModule;
