"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var OrderActions = require("../actions/order-return.actions");
exports.initialState = {
    returnReason: null
};
function OrderReturnReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        // case UserActions.UserActionTypes.GET_CURRENT_USER: {
        //   return state;
        //}
        case OrderActions.OrderReturnActionTypes.GET_RETURN_REASON: {
            return state;
        }
        case OrderActions.OrderReturnActionTypes.GET_RETURN_REASON_SUCCESS: {
            return tslib_1.__assign({}, state, { returnReason: action.payload });
        }
        case OrderActions.OrderReturnActionTypes.GET_RETURN_REASON_FAILED: {
            return state;
        }
        // case UserActions.UserActionTypes.SET_SELECTED_SECTION: {
        //   return {
        //     ...state,
        //     selectedSection: action.payload
        //   };
        // }
        default:
            return state;
    }
}
exports.OrderReturnReducer = OrderReturnReducer;
