"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var HelpComponent = /** @class */ (function () {
    function HelpComponent() {
        this.closeHelpModal = new core_1.EventEmitter();
    }
    HelpComponent.prototype.ngOnInit = function () { };
    /** @description Close help modal */
    HelpComponent.prototype.closeHelp = function () {
        this.closeHelpModal.emit('true');
    };
    HelpComponent.prototype.openSite = function (siteUrl) {
        window.open(siteUrl, '_blank');
    };
    return HelpComponent;
}());
exports.HelpComponent = HelpComponent;
