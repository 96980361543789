"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ReplaceStringPipe = /** @class */ (function () {
    function ReplaceStringPipe() {
    }
    ReplaceStringPipe.prototype.transform = function (value) {
        return value.replace(/_/g, ' ');
    };
    return ReplaceStringPipe;
}());
exports.ReplaceStringPipe = ReplaceStringPipe;
