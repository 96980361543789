import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
// import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { AlertComponent } from "./components/alert/alert.component";
import { SeeUpdateDataComponent } from "./components/see-update-data/see-update-data.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

import { AppMaterialModule } from "../app-material/app-material.module";

import { NgxBarcodeModule } from "ngx-barcode";
import { GetTruncatedName } from "../shared/pipes/string-operations.pipe";
import { Configuration } from "../shared/configuration/config";
import { SharedRoutingModule } from "./shared-routing.module";
import { OrderSidebarComponent } from "./components/order-sidebar/order-sidebar.component";
import { ChangeAddressComponent } from "./components/change-address/change-address.component";
import { ChangeDeliveryAddressComponent } from "./components/change-delivery-address/change-delivery-address.component";
import { ReplaceStringPipe } from "./pipes/replace-string.pipe";
import { Convert24hrsTo12hrsPipe } from "./pipes/convert24hrs-to12hrs.pipe";
import { SeeUpdateIconsComponent } from "./components/see-update-icons/see-update-icons.component";
import { HelpComponent } from "./components/help/help.component";
import { NegativeToastComponent } from "./components/negative-toast/negative-toast.component";
import { InformationPopoverComponent } from "./components/information-popover/information-popover.component";
// import { SignInModule } from "../sign-in/sign-in.module";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    // SidebarComponent,
    AlertComponent,
    GetTruncatedName,
    OrderSidebarComponent,
    ChangeAddressComponent,
    ChangeDeliveryAddressComponent,
    NegativeToastComponent,
    SeeUpdateDataComponent,
    HelpComponent, 
    SeeUpdateIconsComponent,
    ReplaceStringPipe,
    Convert24hrsTo12hrsPipe,
    GetTruncatedName,
    InformationPopoverComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    AppMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    SharedRoutingModule,
    NgxBarcodeModule,
    // SignInModule
  ],
  exports: [
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    NgxBarcodeModule,
    NgMultiSelectDropDownModule,
    HeaderComponent,
    FooterComponent,
    // SidebarComponent,
    OrderSidebarComponent,
    ChangeAddressComponent,
    ChangeDeliveryAddressComponent,
    NegativeToastComponent,
    AlertComponent,
    GetTruncatedName,
    SeeUpdateDataComponent,
    HelpComponent, 
    SeeUpdateIconsComponent,
    ReplaceStringPipe,
    Convert24hrsTo12hrsPipe,
    ReactiveFormsModule,
    FormsModule,
    InformationPopoverComponent
    
  ],
  providers: [Configuration],
  entryComponents: [InformationPopoverComponent]
})
export class SharedModule {}
