import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import {
  initialReducerMap,
  getInitialState,
  metaReducers
} from "./app.reducer";
import { HttpClientModule } from "@angular/common/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { SharedModule } from "./shared";
// import { fakeBackendProvider } from "@shared/interceptors/fake-backend.interceptor";
// import { responseProvider } from "@shared/interceptors/response.interceptor";
// import { tokenProvider } from "@shared/interceptors/token.interceptor";

import { AppMaterialModule } from "./app-material/app-material.module";

import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { LoginLayoutComponent } from "./components/_layouts/login-layout/login-layout.component";
import { OrderPlacedEffects } from "./order-placed/reducers/order-placed.effects";
import { OrderReturnEffects } from "./order-return/reducers/order-return.effects";
// import { AuthEffects } from "./auth/reducers/auth.effects";
import { OrderPlacedComponent } from "./order-placed/order-placed.component";
// import { OrderCancelComponent } from './order-cancel/order-cancel.component';
// import { ChangeAddressComponent } from "./shared/components/change-address/change-address.component";
import { OtpConfirmationComponent } from "./shared/components/otp-confirmation/otp-confirmation.component";
import { RequestSubmissionComponent } from "./shared/components/request-submission/request-submission.component";
import { OrderReturnComponent } from "./order-return/order-return.component";
// import { OrderSidebarComponent } from "./shared/components/order-sidebar/order-sidebar.component";
import { OrderListingComponent } from "./order-listing/order-listing.component";
import { DeviceDetectorModule } from "ngx-device-detector";
import { RedirectionComponent } from "./redirection/redirection.component";
import { OrderReturnCancelComponent } from "./order-return-cancel/order-return-cancel.component";
import { OrderFailedDeliveryComponent } from "./order-failed-delivery/order-failed-delivery.component";
import { OrderFailedPickupComponent } from "./order-failed-pickup/order-failed-pickup.component";
// import { LoginComponent } from "./auth/components/login/login.component";
import { NgxMaskModule } from "ngx-mask";
import { NgOtpInputModule } from "ng-otp-input";
import { MobHeaderModule } from "./shared/components/mob-header/mob-header.module";
import { NoticeMessageHeaderComponent } from "./shared/components/notice-message-header/notice-message-header.component";
import { ReverseValuePipe } from "./shared/pipes/reverse-value.pipe";
// import { ReplaceStringPipe } from "./shared/pipes/replace-string.pipe";
import { OutsideDirective } from "./order-placed/outside.directive";
// import { Convert24hrsTo12hrsPipe } from "./shared/pipes/convert24hrs-to12hrs.pipe";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OrderCancelComponent } from "./order-cancel-page/components/order-cancel/order-cancel.component";
import { CancelStepOneComponent } from "./order-cancel-page/components/cancel-step-one.component/cancel-step-one.component";
import { tokenProvider } from "./shared/interceptors/token.interceptor";
import { responseProvider } from "./shared/interceptors/response.interceptor";
import { fakeBackendProvider } from "./shared/interceptors/fake-backend.interceptor";
import { EDDComponent } from './shared/components/edd/edd.component';
import { ReadMoreComponent } from "./shared/components/edd/readmore/readmore.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginLayoutComponent,
    OrderPlacedComponent,
    OrderCancelComponent,
    // ChangeAddressComponent,
    OutsideDirective,
    OtpConfirmationComponent,
    RequestSubmissionComponent,
    OrderReturnComponent,
    // OrderSidebarComponent,
    OrderListingComponent,
    RedirectionComponent,
    OrderReturnCancelComponent,
    OrderFailedDeliveryComponent,
    OrderFailedPickupComponent,
    // LoginComponent,
    NoticeMessageHeaderComponent,
    ReverseValuePipe,
    // ReplaceStringPipe,
    // Convert24hrsTo12hrsPipe, 
    CancelStepOneComponent,
    EDDComponent,
    ReadMoreComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    NgOtpInputModule,
    AppMaterialModule,
    AppRoutingModule,
    SharedModule,
    StoreModule.forRoot(initialReducerMap, { metaReducers }),
    EffectsModule.forRoot([
      OrderPlacedEffects,
      OrderReturnEffects,
      // AuthEffects
    ]),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    DeviceDetectorModule.forRoot(),
    MobHeaderModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 2000,
      preventDuplicates: true
    }) // ToastrModule added
  ],

  providers: [tokenProvider, responseProvider, fakeBackendProvider, Title],
  bootstrap: [AppComponent]
})
export class AppModule {}
