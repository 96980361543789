import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-listing',
  templateUrl: './order-listing.component.html',
  styleUrls: ['./order-listing.component.sass']
})
export class OrderListingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
