import { MetaReducer,  ActionReducerMap } from "@ngrx/store";

import { ActionTypes } from "./actions";
import { AppState } from "./app.state";
// import * as fromAuth from "./auth/reducers/auth.reducer";
import * as fromOrderPlaced from "./order-placed/reducers/order-placed.reducer";
import * as fromOrderReturn from "./order-return/reducers/order-return.reducer";
// const reducers = {
//   auth: fromAuth.AuthReducer,
//   user: fromUser.UserReducer,
//   wholesale: fromWholesale.WholesaleReducer
// };

// export const developmentReducer: ActionReducer<AppState> = combineReducers(
//   reducers
// );

// export function AppReducer(state: any, action: any) {
//   return developmentReducer(state, action);
// }

export function defaultReducer<T>(state: T) {
  return state;
}
export const initialReducerMap = {
  // auth: fromAuth.AuthReducer,
  orderPlaced: fromOrderPlaced.OrderPlacedReducer,
  returnReason: fromOrderReturn.OrderReturnReducer
} as ActionReducerMap<AppState>;

export function getInitialState() {
  return {
    // auth: fromAuth.initialState,
    orderPlaced: fromOrderPlaced.initialState,
    returnReason: fromOrderReturn.initialState
  } as AppState;
}

export function clearState(reducer) {
  return function(state, action) {
    if (action.type === ActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<any, any>> = [clearState];
