"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var store_1 = require("@ngrx/store");
var effects_1 = require("@ngrx/effects");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var order_return_actions_1 = require("../actions/order-return.actions");
var services_1 = require("../../shared/services");
var OrderReturnEffects = /** @class */ (function () {
    function OrderReturnEffects(actions$, orderReturnService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.orderReturnService = orderReturnService;
        this.store = store;
        this.getReturnReason$ = this.actions$.pipe(effects_1.ofType(order_return_actions_1.OrderReturnActionTypes.GET_RETURN_REASON), operators_1.mergeMap(function (action) {
            return _this.orderReturnService.getReturnReason(action.payload).pipe(operators_1.map(function (data) { return ({
                type: order_return_actions_1.OrderReturnActionTypes.GET_RETURN_REASON_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function () { return rxjs_1.of({ type: order_return_actions_1.OrderReturnActionTypes.GET_RETURN_REASON_FAILED }); }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], OrderReturnEffects.prototype, "getReturnReason$", void 0);
    return OrderReturnEffects;
}());
exports.OrderReturnEffects = OrderReturnEffects;
