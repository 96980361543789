import { Injectable } from "@angular/core";
import { Action, select, Store } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  GetOrder,
  GetSelectedItem,
  OrderPlacedActionTypes
} from "../actions/order-placed.actions";
import { OrderService } from "../../shared/services";
import { getSelectedItem } from "../reducers/selectors";

@Injectable()
export class OrderPlacedEffects {
  @Effect()
  getOrder$: Observable<Action> = this.actions$.pipe(
    ofType<GetOrder>(OrderPlacedActionTypes.GET_ORDER),
    mergeMap(action => {
      return this.orderService.getOrder(action.payload).pipe(
        map(data => ({
          type: OrderPlacedActionTypes.GET_ORDER_SUCCESS,
          payload: data
        })),
        catchError(() => of({ type: OrderPlacedActionTypes.GET_ORDER_FAILED }))
      );
    })
  );

  @Effect()
  getUserSettings$: Observable<Action> = this.actions$.pipe(
    ofType<GetOrder>(OrderPlacedActionTypes.GET_USER_SETTINGS),
    mergeMap(action => {
      return this.orderService.getUserSettings().pipe(
        map(data => ({
          type: OrderPlacedActionTypes.GET_USER_SETTINGS_SUCCESS,
          payload: data
        })),
        catchError(() => of({ type: OrderPlacedActionTypes.GET_USER_SETTINGS_FAILED }))
      );
    })
  );

  @Effect()
  getSelectedItem$: Observable<Action> = this.actions$.pipe(
    ofType<GetSelectedItem>(OrderPlacedActionTypes.GET_SELECTED_ITEM),
    mergeMap(action => {
        return this.store.pipe(select(getSelectedItem),
          map(order => ({
            type: OrderPlacedActionTypes.SET_SELECTED_ITEM,
            payload: order
          }))
        );
    })
  );


  constructor(
    private actions$: Actions,
    private orderService: OrderService,
    private store: Store<{ orderPlaced }>
  ) {}
}
