import { Component, OnInit, OnDestroy, Renderer2 } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { SwUpdate } from "@angular/service-worker";
import { AuthService, LocalStorageService } from "./shared/services";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { Subscription } from "rxjs";
import { AppState } from "./app.state";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.sass"]
})
export class AppComponent implements OnInit, OnDestroy {
  title = "eshopbox";
  counter = 0;
  newTitle: string;
  encodedKey: any;
  subscriptions$ = new Subscription();
  userSettings: any;
  getColor: any;
  getToken: string = "";
  // currentRoute: string;
  currentRoute: string = window.location.href;
  currentOrigin: string = window.location.origin + "/";
  constructor(
    private swUpdate: SwUpdate,
    private auth: AuthService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private store: Store<AppState>
  ) {
    this.store
      .select(state => state.orderPlaced.userSettings)
      .subscribe(result => {
        if (result) {
          this.userSettings = result;
          this.renderer.setStyle(
            document.body,
            "background",
            this.userSettings.backgroundColor
          );
        }
      });
  }

  //code for service worker new version
  ngOnInit() {
    //set page titles
    this.setTitle();

    // this.auth.handleAuthenticationWithHash();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("New version available. Load new version?")) {
          window.location.reload();
        }
      });
    }
    this.getToken = LocalStorageService.get("token");
  }

  public setTitle() {
    const appTitle = this.titleService.getTitle();
    this.subscriptions$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          if (this.activatedRoute.firstChild) {
            let child = this.activatedRoute.firstChild;
            while (child.firstChild) {
              child = child.firstChild;
            }
            if (child.snapshot.data["title"]) {
              return child.snapshot.data["title"];
            }
            return appTitle;
          }
        })
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }
  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
