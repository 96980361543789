"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var actions_1 = require("./actions");
// import * as fromAuth from "./auth/reducers/auth.reducer";
var fromOrderPlaced = require("./order-placed/reducers/order-placed.reducer");
var fromOrderReturn = require("./order-return/reducers/order-return.reducer");
// const reducers = {
//   auth: fromAuth.AuthReducer,
//   user: fromUser.UserReducer,
//   wholesale: fromWholesale.WholesaleReducer
// };
// export const developmentReducer: ActionReducer<AppState> = combineReducers(
//   reducers
// );
// export function AppReducer(state: any, action: any) {
//   return developmentReducer(state, action);
// }
function defaultReducer(state) {
    return state;
}
exports.defaultReducer = defaultReducer;
exports.initialReducerMap = {
    // auth: fromAuth.AuthReducer,
    orderPlaced: fromOrderPlaced.OrderPlacedReducer,
    returnReason: fromOrderReturn.OrderReturnReducer
};
function getInitialState() {
    return {
        // auth: fromAuth.initialState,
        orderPlaced: fromOrderPlaced.initialState,
        returnReason: fromOrderReturn.initialState
    };
}
exports.getInitialState = getInitialState;
function clearState(reducer) {
    return function (state, action) {
        if (action.type === actions_1.ActionTypes.LOGOUT) {
            state = undefined;
        }
        return reducer(state, action);
    };
}
exports.clearState = clearState;
exports.metaReducers = [clearState];
