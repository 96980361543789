"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var InformationPopoverComponent = /** @class */ (function () {
    function InformationPopoverComponent() {
    }
    InformationPopoverComponent.prototype.ngOnInit = function () {
    };
    InformationPopoverComponent.prototype.showEditDetails = function () {
    };
    return InformationPopoverComponent;
}());
exports.InformationPopoverComponent = InformationPopoverComponent;
