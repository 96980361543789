"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var services_1 = require("../services");
var environment_1 = require("../../../environments/environment");
var constants_1 = require("../constants/constants");
var config_1 = require("../configuration/config");
var i0 = require("@angular/core");
var i1 = require("../services/auth.service");
var i2 = require("../services/alert.service");
var i3 = require("../configuration/config");
var TokenInterceptor = /** @class */ (function () {
    function TokenInterceptor(auth, alertService, config) {
        this.auth = auth;
        this.alertService = alertService;
        this.config = config;
        this.headers = new http_1.HttpHeaders();
    }
    TokenInterceptor.prototype.intercept = function (request, next) {
        var hostName = window.location.hostname === "localhost"
            ? environment_1.environment.LOCALHOST_API_URL
            : window.location.hostname;
        // const apiUrl = window.location.hostname === 'localhost' ? 'http://' : 'https://';
        if (!navigator.onLine) {
            this.alertService.showError(constants_1.CONSTANTS.ALERT_MESSAGES.NO_INTERNET_CONNECTION);
        }
        else {
            // todo hack to be removed
            if (request.responseType !== "blob") {
                var token = this.auth.getAuthToken();
                var proxyHost = window.location.hostname === "localhost"
                    ? environment_1.environment.LOCALHOST_API_URL
                    : window.location.origin;
                if (request.url.includes("update-status")) {
                    request = request.clone({
                        url: request.url,
                        setHeaders: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                            proxyHost: proxyHost,
                            "Access-Control-Allow-Origin": "*"
                        }
                    });
                }
                else if (token) {
                    //For LocalHost Env Only
                    if (hostName === environment_1.environment.LOCALHOST_API_URL) {
                        request = request.clone({
                            url: environment_1.environment.PROTOCOL + hostName + request.url,
                            setHeaders: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token,
                                proxyHost: proxyHost,
                                "Access-Control-Allow-Origin": "*"
                            }
                        });
                    }
                    else {
                        //For Staging And Poduction Env
                        // if (request.url.includes("/api/v1/tracking-url")) {
                        //   request = request.clone({
                        //     url: request.url,
                        //     setHeaders: {
                        //       "Content-Type": "application/json",
                        //       "Access-Control-Allow-Origin": "*"
                        //     }
                        //   });
                        // } else if (request.url.includes("/api/v1/poe-setting")) {
                        //   request = request.clone({
                        //     url: request.url
                        //   });
                        // } else {
                        request = request.clone({
                            url: environment_1.environment.PROTOCOL + hostName + request.url,
                            setHeaders: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token,
                                "Access-Control-Allow-Origin": "*"
                            }
                        });
                        // }
                    }
                }
                else {
                    // if (request.url.includes("/api/v1/tracking-url")) {
                    //   request = request.clone({
                    //     url: request.url,
                    //     setHeaders: {
                    //       "Content-Type": "application/json",
                    //       "Access-Control-Allow-Origin": "*"
                    //     }
                    //   });
                    // } else if (request.url.includes("/api/v1/poe-setting")) {
                    //   request = request.clone({
                    //     url: request.url
                    //   });
                    // } else {
                    request = request.clone({
                        url: environment_1.environment.PROTOCOL + hostName + request.url,
                        setHeaders: {
                            "Content-Type": "application/json",
                            proxyHost: proxyHost,
                            "Access-Control-Allow-Origin": "*"
                        }
                    });
                    // }
                }
            }
            // if (!request.url.includes(this.config.API_URL)) {
            //   // this.sharedService.showHideLoader(true);
            // }
            return next.handle(request);
        }
    };
    TokenInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function TokenInterceptor_Factory() { return new TokenInterceptor(i0.inject(i1.AuthService), i0.inject(i2.AlertService), i0.inject(i3.Configuration)); }, token: TokenInterceptor, providedIn: "root" });
    return TokenInterceptor;
}());
exports.TokenInterceptor = TokenInterceptor;
exports.tokenProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: http_1.HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
};
