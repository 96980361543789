<ng-container *ngIf="selectedItemDetails.latestStatus == 'failed_delivery' && selectedItemDetails.forwardTrackingTimeline.odaAddress == ''">
  <div class="banner-title oda-title">
      <img src="https://ik.imagekit.io/2gwij97w0o/alert-triangle-icon.svg" alt="" />
      <span >We are unable to deliver your order</span>
    </div>
</ng-container>

<ng-container *ngIf="selectedItemDetails.latestStatus == 'ndr_resolution_submitted' && selectedItemDetails.forwardTrackingTimeline.odaAddress">
  <div class="banner-title oda-title">
      <img src="https://ik.imagekit.io/2gwij97w0o/alert-triangle-icon.svg" alt="" />
      <span >We are unable to deliver your order. You are requested to pick the order from the below provided address.</span>
    </div>
</ng-container>

<ng-container *ngIf="(selectedItemDetails.latestStatus == 'intransit' || selectedItemDetails.latestStatus == 'out_for_delivery' || selectedItemDetails.latestStatus == 'picked_up')">
  <ng-container
    *ngIf="!(currentDate > updatedAt && (selectedItemDetails.latestStatus == 'out_for_delivery' || selectedItemDetails.latestStatus == 'picked_up'))">
    <div class="banner-title" *ngIf="(currentDate < selectedItemDetails.expectedDeliveryDate) && (selectedItemDetails.latestStatus == 'intransit' || selectedItemDetails.latestStatus == 'picked_up')">
      <img src="../../assets/images/delivery-vehicle.svg" alt="" />
      <span >Expected delivery by {{
        selectedItemDetails?.expectedDeliveryDate
            ? (selectedItemDetails.expectedDeliveryDate
              | date: "EEEE MMM d, y")
            : (selectedItemDetails.expectedDeliveryDate | date: "EEEE MMM d, y")
      
        }}
      </span>
    </div>

    
    <div class="banner-title oda-title"*ngIf="currentDate > selectedItemDetails.expectedDeliveryDate && (selectedItemDetails.latestStatus == 'intransit' || selectedItemDetails.latestStatus == 'picked_up' )">
      <img src="https://ik.imagekit.io/2gwij97w0o/POE/delivery-vehicle.png" alt="" />
      <span *ngIf="!userSettings?.delayedDeliveryMessage">Your order is delayed
      </span>
      <!-- {{userSettings?.delayedDeliveryMessage.length}} -->
        <span *ngIf="userSettings?.delayedDeliveryMessage" class="mobileview">
          <app-read-more   
            [content]="userSettings?.delayedDeliveryMessage"
            [limit]="67"
            [completeWords]="true"
          >
          </app-read-more>
        </span>
      
      <section [ngClass]="seemoretext ? 'delayfullmsg':''" class="desktopview">
      <span *ngIf="userSettings?.delayedDeliveryMessage"  [ngClass]="userSettings?.delayedDeliveryMessage.length > 210 ? 'seemore-text':'seeless-text'">
        {{userSettings?.delayedDeliveryMessage}}
        <ng-container *ngIf="!seemoretext">
          <span *ngIf="userSettings?.delayedDeliveryMessage.length > 210" class="seemore" (click)="seemore()">...see more</span>
        </ng-container>
        <ng-container *ngIf="seemoretext">
          <span *ngIf="userSettings?.delayedDeliveryMessage.length > 210" class="seeless" (click)="seeless()">...see less</span>
        </ng-container>
      </span>
    </section>
      <!-- <div class="icon-tooltip">
        <ng-container >
          <a>
            <img src="https://ik.imagekit.io/2gwij97w0o/POE/info-red.svg" />
            <span class="delivery-deley">
              {{userSettings?.delayedDeliveryMessage}}
            </span>
          </a>
        </ng-container>
      </div> -->
    </div>
  </ng-container>

  <ng-container   *ngIf="!(currentDate > updatedAt && selectedItemDetails.latestStatus == 'out_for_delivery')">
    <ng-container *ngIf=" (updatedAt < selectedItemDetails.expectedDeliveryDate  &&  selectedItemDetails.latestStatus == 'out_for_delivery');else other">
      <div class="banner-title">    
      <img src="../../assets/images/arriving_today.svg" alt="" />
      <span>
        Your order is arriving early
      </span>
    </div>
    </ng-container>
    <ng-template #other>
      <div class="banner-title" *ngIf="selectedItemDetails.latestStatus == 'out_for_delivery'" >    
        <img src="../../assets/images/arriving_today.svg" alt="" />
        <span>
          Your order is arriving today
        </span>
      </div>

    </ng-template>
  
  </ng-container>

  
</ng-container>
