import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { Injectable, Inject } from "@angular/core";
import { AuthService, AlertService, LocalStorageService } from "../services";
import { PARTIAL_STATUS_CODE, ROUTES } from "../constants/constants";

@Injectable({
  providedIn: "root"
})
export class ResponseInterceptor implements HttpInterceptor {
  partialStatusCode: any;
  orderID: any;
  orderItemID: any;

  constructor(
    private router: Router,
    private auth: AuthService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    LocalStorageService.flush('SuccessResponseData');
    LocalStorageService.flush('ErrorResponseData');
    // console.log(request.body);
    this.orderID =  (request.body && request.body.customerOrderNumber) ? request.body.customerOrderNumber : '';
    if (request.body && request.body.item){
      this.orderItemID =  (request.body.item.orderItemID) ? request.body.item.orderItemID : '';
    }else if (request.body && request.body.items && request.body.items[0]) {
      this.orderItemID = request.body.items[0].orderItemID;
    }else {
      // console.log(request.body.orderItemID);
      this.orderItemID =  (request.body && request.body.orderItemID) ? request.body.orderItemID : '';
    }
    
    return next.handle(request).pipe( 
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // this.sharedService.showHideLoader(false);
            // do stuff with response if you want
            
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // this.sharedService.showHideLoader(false);
            if (err.status === 401) {
              this.auth.logout();
            } else if (err.status === 500) {
              return err.error.error;
              // this.alertService.showError(err.error.error);
            } else if (err.status === 400) {
              this.partialStatusCode = err.error.error.message.split(':');
              const actionType = LocalStorageService.get('actionType');
              if (actionType && this.partialStatusCode[1] != "Invalid OTP.") 
              {
                LocalStorageService.flush('SuccessResponseData');
                LocalStorageService.flush('ErrorResponseData');
                LocalStorageService.set('ErrorResponseData', err.error.error.message);
                if (this.partialStatusCode[0] === PARTIAL_STATUS_CODE){
                  localStorage.removeItem("action");
                  localStorage.removeItem('shipmentId')
                 this.router.navigate([ROUTES.OTP_CONFIRMATION, this.orderID, this.orderItemID]); 
                }else{
                  return err.error.error;
                  // this.router.navigate([ROUTES.REQUEST_SUBMISSION]);
                }              
              }else {
                return err.error.error;
              } 
            } else {
              // this.alertService.showError(err.error.error.message);
              return err.error.error;
            }
          }
        }
      )
    );
  }
}

export let responseProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: ResponseInterceptor,
  multi: true
};
