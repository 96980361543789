import { Component, OnInit ,ViewChildren,ViewChild, QueryList ,ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription, Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import { GetReturnReason } from "./actions/order-return.actions";
import { ValidationService } from '../shared/services/validation.service';
import { OrderReturnService } from "../shared/services/order-return.service";
import * as global from "../shared/constants/constants";
import { LocalStorageService } from '../shared/services/index';
import { DatePipe, DecimalPipe } from '@angular/common';
import { AppState } from '../app.state';
import { getReturnReason } from "./reducers/selectors";
import * as productPaymentOption from "../shared/interceptors/mockdata/cancel-product-payment-option.json";
import { AlertService } from "../shared/services";
import { DeviceDetectorService } from 'ngx-device-detector';
import { SharedService } from '../shared/services/shared.service';
import { ScrollingService } from '../shared/services/scrolling.service';

@Component({
  selector: 'app-order-return',
  templateUrl: './order-return.component.html',
  styleUrls: ['./order-return.component.scss'],
  providers: [DecimalPipe]
})
export class OrderReturnComponent implements OnInit {
  @ViewChild('scroll') private scrollElement: ElementRef;
  // @ViewChildren('itemID') itemsElement:QueryList<ElementRef>
  isBoxshadow:boolean=false;
  scrollTopPosition:number;
  rupeeSymbol = global.RUPEE_SYMBOL;
  showLoader = true;
  ifscShowLoader = false;
  loading = false;
  exchangeItemDetails: any;
  showAddressFormHtml = false;
  addressFormValue : any ;
  public showAddressForm = false;
  pickupAddress: { "customerName": string; "addressLine1": any; "addressLine2": any; "city": any; "state": any; "postalCode": any; "countryCode": string; "countryName": string; "contactPhone": any; "email": string;};
  showBankDetailsForm: any = false;
  formType = 'return';
  showExchangeOptions = false;
  public params;
  returnReason: any;
  refundDetails: any;
  subReason: any = [];
  showSubReason = false;
  action;
  showAction = false;
  showBankDropdown = false;
  public paymentOptionList: any = productPaymentOption;
  subscriptions$ = new Subscription();
  public accountTypes: any = global.ACCOUNT_TYPES;
  public returnItemForm: FormGroup;
  public formControls: any;
  public returnItemRequest = LocalStorageService.get('returnItemRequest') ? LocalStorageService.get('returnItemRequest') : '';
  public orderDetails: any; //   = LocalStorageService.get('orderDetails') ? LocalStorageService.get('orderDetails') : '';
  public returnOrderItemDetails = LocalStorageService.get('processOrderItemDetails') ? LocalStorageService.get('processOrderItemDetails') : '';
  public bankDetailsControls = ['accountHolderName', 'accountNumber', 'confirmAccountNumber', 'ifscCode', 'bankName', 'branch', 'accountType'];
  leftPanelActive = false;
  exchangeOptionData = [];
  exchangeItemData: any;
  orderId: any;
  orderItemId: any;
  userSettings: any;
  orderType: any;
  radioSelected = LocalStorageService.get('exchangeOption') ? LocalStorageService.get('exchangeOption') : '';
  size = LocalStorageService.get('size') ? LocalStorageService.get('size') : '';

  isMobile: boolean;
  isTablet: boolean;
  public isHelpOpen = false;
  public isHelpOpendelay = false;
  footerKey: [string, unknown][];
  getColor: any;
  Helpcolor: boolean;
  isStockAvailable: boolean;
  orderTotal: any;
  selectedItem: any;
  productSize=LocalStorageService.get('productSize')?LocalStorageService.get('productSize'):''
  showToolTip: boolean;
  showToolTip1: boolean;

  constructor(private preventScroll:ScrollingService, private fb: FormBuilder, private router: Router, private store: Store<AppState>, private orderReturnService : OrderReturnService, private alertService: AlertService, private route: ActivatedRoute, private titleService: Title, private decimalPipe: DecimalPipe, private deviceService: DeviceDetectorService,private sharedService:SharedService) {}

  // ngAfterViewInit()
  // {
  //   this.itemsElement.changes.subscribe(res=>{
  //     console.log(res)
  //     setTimeout(()=>{
  //     res.last.nativeElement.focus();
  //     })
  //   })
  // }

  ngOnInit() {               
    this.sharedService.changeMessage("");
    this.orderTotal=LocalStorageService.get('orderTotal')
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    LocalStorageService.flush('returnItemRequest');
    this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
    this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';

    /*Get user settings data from state*/
    this.store.select(state => state.orderPlaced.userSettings).subscribe(result =>   {
      if (result) {
              this.userSettings = result;
              this.getColor=this.userSettings.actionColor;

              this.footerKey = Object.entries(this.userSettings.footerLinks);        

              // this.titleService.setTitle("Return Item");    
      }
    });

    this.initializeForm(); 
    /*Get Order Data*/
    this.store.select(state => state.orderPlaced.orderPlaced).subscribe(result =>   {
      if (result) {
          this.orderDetails = result;    
               
          this.orderType = this.orderDetails ? this.orderDetails.isCOD : '0';
          this.getReasonData();
          this.addressFormValue = this.orderDetails ? this.orderDetails.shippingAddress : '';
     
          
          //if order is cod then remove original payment mode option
          // if (this.orderType === 1) { 
          //   this.removeOriginalPaymentMode();
          //   // console.log(this.paymentOptionList.productPaymentOption);
          // }
      }
    });
  }

  public onSelectShowOrderItemDetail(item) {
    this.selectedItem=item;       
  }
  //this method for cod case 
  // removeOriginalPaymentMode(){
  //   const index = this.paymentOptionList.productPaymentOption.findIndex(option => option.value === 'originalPaymentMode');
  //   if (index !== -1) {
  //     this.paymentOptionList.productPaymentOption.splice(index, 1);
  //   }
  // }

  /* Get Reason Data*/
  getReasonData()
  { 
      this.params = { 
        'customerOrderNumber': this.orderDetails.customerOrderNumber,
        'orderItemId': this.returnOrderItemDetails.orderItemID
      }
      this.store.select(state => state.returnReason).subscribe(result =>   {
        if (result.returnReason) {
          
          this.showLoader = false;
          this.returnReason = result.returnReason.data;
          
          this.checkLocalData();
        }else {
          this.store.dispatch(new GetReturnReason(this.params));
          this.subscriptions$.add(
            this.store.pipe(select(getReturnReason)).subscribe(result1 => {
              if (result1) {
                this.showLoader = false;
                this.returnReason = result1.data;
                this.checkLocalData();
              }else {
                this.returnReason = [];
              } 
            })
          );
        }
      });  
  }
  public onChangeReturnReason(value, boolean = false) {
    this.showSubReason = true;  
    if (boolean === true)
    { 
      this.resetForm();
      this.formControls.returnReason.setValue(value);
    }

    if (1)
    { 
      let j = 0;
      this.subReason = [];
      this.action = [];
      // for (let i = 0; i < this.returnReason.length ; i++) {
      //   if (this.returnReason[i].reason.toLowerCase() === value.toLowerCase() && j === 0) {
      //       this.subReason.push(this.returnReason[i].subReason);
      //       this.action = this.returnReason[i].action;
      //       j++;
      //   }
      // }
      //subreason
      this.subReason = this.subReason[0];
      this.showSubReason = true;

      /* Hit Related Product API */
      // if (value.toLowerCase() === global.SIZE_FIT_ISSUE.toLowerCase()) {
        this.exchangeProductData();
      // }else 
      // if (value.toLowerCase() === global.ITEM_NO_LONGER_WANTED.toLowerCase()) { 
      //   this.action = this.action; 
      // }else {
        this.replacementProductData();
      // }
      
      // this.formControls.returnSubReason.setValidators([Validators.required]);
      /*End Here*/      
    }else{
      this.subReason = [];
      this.action = [];
      this.showAction = false;
      this.showSubReason = false;
      this.formControls.returnSubReason.setValidators(null);
      this.formControls.size.setValidators(null);

      this.formControls.returnSubReason.markAsUntouched();
      this.formControls.returnSubReason.patchValue('');
      this.formControls.returnSubReason.setErrors(null);
     
    }
  }

  public onChangeSubReason(value, onchange: boolean = false) {
    if (value.trim())
    {
      this.action = this.action;
      this.showAction = true;
      this.formControls.returnAction.setValidators([Validators.required]);
    }else{
      this.action = [];
      this.formControls.returnAction.setValidators(null);
      this.formControls.returnAction.markAsUntouched();
      this.formControls.returnAction.patchValue('');
      this.formControls.returnAction.setErrors(null);
    }
    
  }

  public exchangeProductData() {
    this.orderReturnService.getExchangeRelatedProducts(this.returnOrderItemDetails.sku?this.returnOrderItemDetails.sku:this.selectedItem.sku)
    .then(data => {
       if (data && data.data.length > 0) {
        this.exchangeOptionData = [];
        this.exchangeOptionData = data.data;
        

       }else {
        if (this.action.length > 1) {
          this.action.splice(this.action.indexOf(global.PROVIDE_EXCHANGE), 1);
        }
        this.exchangeOptionData = [];
       }
    }).catch(err => {
      throw err;
    });
  }

  public replacementProductData() {   
    this.orderReturnService.getStock(this.returnOrderItemDetails.itemID?this.returnOrderItemDetails.itemID:this.selectedItem.itemID).then(data => {
      if(data.stock>0){
        this.isStockAvailable=true;
      }
      if (data && data.stock === 0){
      if (this.action.includes(global.PROVIDE_REPLACEMENT)){
        this.action.splice(this.action.indexOf(global.PROVIDE_REPLACEMENT), 1);
      }
    }
  

    }).catch(err => {
       throw err;
    });
  }

  /**
   * Method to define returnItemForm info form group
   * @method initializeForm
   */
  private initializeForm() {
    this.returnItemForm = this.fb.group({
      returnReason: ['', [Validators.required]],
      size: ['', [Validators.required]],

      // returnReason: [''],
      returnAction: ['', [Validators.required]],

      returnSubReason: [''],
      // returnAction: [''],
      refundMode: [''],
      accountHolderName: [''], //, this.orderDetails.billingAddress.customerName],
      accountNumber: [''],
      confirmAccountNumber: [''],
      ifscCode: [''],
      bankName: [''],
      bankCity: [''],
      branch: [''],
      accountType: [''],
      exchangeOption: [''],
      addressInfo:['', [Validators.required]]
    }, {
      validator: ValidationService.MustMatch('accountNumber', 'confirmAccountNumber')
    });
    this.formControls = this.returnItemForm.controls;
  }

  formInitialized(name: string, form: FormGroup) {
    if(this.returnItemForm.controls['addressInfo'].status=='INVALID'){
      this.showToolTip=true;
    }
      else{
      this.showToolTip=false;

    }

    this.returnItemForm.setControl(name, form); 
  }

  /**
   * Method to define returnItemForm form group
   * @method checkLocalData
   */
  private checkLocalData() { 
    if (this.returnItemRequest) {
            //reason
      if (this.returnItemRequest.item && this.returnItemRequest.item.returnReason){
        this.onChangeReturnReason(this.returnItemRequest.item.returnReason, false);
      }

      //subreason
      if (this.returnItemRequest.item && this.returnItemRequest.item.returnSubReason){
        this.onChangeSubReason(this.returnItemRequest.item.returnSubReason);
      }if (this.returnItemRequest.item && this.returnItemRequest.item.size){
        this.onChangeSubReason(this.returnItemRequest.item.size);
      }
      

      //actions
      if (this.returnItemRequest.isExchange){
        const value = (this.returnItemRequest.isExchange === 0) ? global.REFUND : (this.returnItemRequest.exchangeItem ? global.PROVIDE_EXCHANGE : global.PROVIDE_REPLACEMENT);
        this.onActionChange(value);
      }

      this.returnItemForm.patchValue({
        returnReason: this.returnItemRequest.item ? this.returnItemRequest.item.returnReason : '',
        returnSubReason: this.returnItemRequest.item ? this.returnItemRequest.item.returnSubReason : '',
        // size: this.returnItemRequest.item ? this.returnItemRequest.item.size : '',

        // returnAction: (this.returnItemRequest.isExchange === 0) ? global.REFUND : (this.returnItemRequest.exchangeItem ? global.PROVIDE_EXCHANGE : global.PROVIDE_REPLACEMENT),
        refundMode: this.returnItemRequest.refundMode,
        accountHolderName:  this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryName : '',
        accountNumber: this.returnItemRequest.refundBankAccountDetails ?  this.returnItemRequest.refundBankAccountDetails.beneficiaryAccountNo : '',
        confirmAccountNumber: this.returnItemRequest.refundBankAccountDetails ?  this.returnItemRequest.refundBankAccountDetails.confirmAccountNumber : '',

        ifscCode: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryIfscCode : '',
        bankName: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryBankName : '',
        bankCity: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryBankCity : '',
        branch: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryBranch : '',
        accountType: this.returnItemRequest.refundBankAccountDetails ? this.returnItemRequest.refundBankAccountDetails.beneficiaryAccountType : '',
        exchangeOption: LocalStorageService.get('exchangeOption') ? LocalStorageService.get('exchangeOption') : '',
        returnAction: LocalStorageService.get('returnAction') ? LocalStorageService.get('returnAction') : '',
        // size: LocalStorageService.get('size') ? LocalStorageService.get('size') : '',
        size:' ',



      });
      
      // console.log(this.returnItemForm.value);
      //refund mode
      this.showBankDropdown = this.returnItemRequest.refundMode ? true : false;

      //bank details
      if (this.returnItemRequest.refundMode.toLowerCase() === global.REFUND_MODE.toLowerCase()){
         this.showBankDetailsForm = true;
      }
      
      this.addressFormValue = this.returnItemRequest.pickupAddress;
      this.showAddressForm  = true;
      this.showAddressFormHtml = true;

      if (this.returnItemRequest.exchangeItem) { 
      this.handleExchangeEvent(this.returnItemRequest.exchangeItem, false);
      // const ng = this.exchangeOptionData.filter((el) => el.itemID === event.itemID);
      // console.log("filter", ng);
    }
    }
  }

  /**
    * Refund Action 
    * @method onRefundActionChange
    */
   public onActionChange(value, onchange: boolean = false) {
    if (value.trim().toLowerCase() === global.REFUND.toLowerCase()) {
      this.showBankDropdown = true;
      this.showExchangeOptions = false;
      this.showAddressForm = false;
      this.formControls.refundMode.setValidators([Validators.required]);
      this.formControls.exchangeOption.setValidators(null);
      // this.formControls.size.setValue(0);
      this.formControls.size.setValidators(null);


    }else{
      if (value.trim().toLowerCase() === global.PROVIDE_EXCHANGE.toLowerCase()) { 
         this.showExchangeOptions = true;
         this.formControls.exchangeOption.setValidators([Validators.required]);
      }else {
        this.showExchangeOptions = false;
        this.formControls.exchangeOption.setValidators(null);
        this.formControls.size.setValidators(null);

      }
      this.showBankDetailsForm = false;
      this.showBankDropdown = false;
      this.showAddressForm = true;
      this.formControls.refundMode.setValidators(null);
      this.formControls.refundMode.markAsUntouched();
      this.formControls.refundMode.patchValue('');
      this.formControls.refundMode.setErrors(null);
      this.bankValidationSetNull();
    }  
  }

  public onPaymentModeChange (value, onchange: boolean = false) {
        if (value.trim().toLowerCase() === global.REFUND_MODE.toLowerCase()) {
      // this.returnItemForm.patchValue({ accountHolderName : this.orderDetails.billingAddress.customerName});
      this.showBankDetailsForm = true;
      this.showAddressForm = true;
      // this.returnItemForm.patchValue({ accountHolderName : this.orderDetails.billingAddress.customerName});
      this.formControls.accountHolderName.setValidators([Validators.required]);
      this.formControls.accountNumber.setValidators([Validators.required, Validators.pattern(global.NUMBERS_ONLY_REGEX), Validators.minLength(5), Validators.maxLength(25)]);
      this.formControls.confirmAccountNumber.setValidators([Validators.required, Validators.pattern(global.NUMBERS_ONLY_REGEX)]);
      this.formControls.ifscCode.setValidators([Validators.required, Validators.minLength(11), Validators.pattern(global.IFSC_CODE_REGEX)]);
      //  this.formControls.bankName.setValidators([Validators.required]); //, Validators.pattern(global.REGX_NAME)
      //  this.formControls.branch.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(40)]); //Validators.pattern(global.REGX_NAME)
      this.formControls.accountType.setValidators([Validators.required]);
    }else{
      this.showBankDetailsForm = false;  
      this.showAddressForm = true;
      this.bankValidationSetNull(); 
    }  
  }

  public bankValidationSetNull()
  {
    this.bankDetailsControls.forEach(element => {
      this.formControls[element].setValidators(null);
      this.formControls[element].markAsUntouched();
      this.formControls[element].patchValue('');
      this.formControls[element].setErrors(null);
    });
  }

  public handleExchangeEvent(event, condition = true){

    this.formControls.size.setValue(event.size);

    if (condition) {
      LocalStorageService.flush('exchangeOption');
    }
    this.exchangeItemData =  event;

  }

  /**
   * form submission
   * @method onSubmit
   */
  public onSubmit(): void {   
     
        if (this.returnItemForm.valid) { 
      this.loading = true;
      LocalStorageService.flush('returnItemRequest');
      LocalStorageService.flush('actionType');
      LocalStorageService.flush('returnFlowType');
      LocalStorageService.set('exchangeOption', this.formControls.exchangeOption.value);
      LocalStorageService.set('returnAction',this.formControls.returnAction.value)
      LocalStorageService.set('size',this.formControls.size.value)

      const itemDetails = {
        lineItemSequenceNumber: this.returnOrderItemDetails.lineItemSequenceNumber?this.returnOrderItemDetails.lineItemSequenceNumber:this.selectedItem.lineItemSequenceNumber,
        orderItemID: this.returnOrderItemDetails.orderItemID?this.returnOrderItemDetails.orderItemID:this.selectedItem.orderItemID,
        itemID: this.returnOrderItemDetails.itemID?this.returnOrderItemDetails.itemID:this.selectedItem.itemID,
        quantity: this.returnOrderItemDetails.quantity?this.returnOrderItemDetails.quantity.toString():this.selectedItem.quantity.toString(),
        productName: this.returnOrderItemDetails.productName?this.returnOrderItemDetails.productName:this.selectedItem.productName,
        returnReason: this.formControls.returnReason.value,
        returnSubReason: this.formControls.returnSubReason.value,
        exchangeSize : this.formControls.size.value,




        additionalInfo: {
          customerUploadImageUrl: "",
          comment: ""
        }
      };
      
    this.refundDetails = {
          beneficiaryName: this.formControls.accountHolderName.value, //this.orderDetails.billingAddress.customerName,
          beneficiaryAccountNo: this.formControls.accountNumber.value,
          confirmAccountNumber: this.formControls.confirmAccountNumber.value,
          beneficiaryIfscCode: this.formControls.ifscCode.value,
          beneficiaryBankName: this.formControls.bankName.value,
          beneficiaryBranch: this.formControls.branch.value,
          beneficiaryAccountType: this.formControls.accountType.value,
          beneficiaryBankCity: this.formControls.bankCity.value
    };

    //Exchange Item object
    if (this.formControls.returnAction.value.toLowerCase() === global.PROVIDE_EXCHANGE.toLowerCase()){
     if(this.orderDetails.isPoeOldFlow){
      this.exchangeItemDetails = {
        "sku" : this.exchangeItemData.sku,
        "itemID" : this.exchangeItemData.itemID,
        "exchangeSize" : this.formControls.size.value,

       }
     }
     else{
      this.exchangeItemDetails = {
        // "sku" : this.exchangeItemData.sku,
        // "itemID" : this.exchangeItemData.itemID,
        "exchangeSize" : this.formControls.size.value,

       }
     }

    }
    if(this.formControls.addressInfo.controls.notes.value && this.formControls.addressInfo.controls.notes.value!=undefined){
      var add2= this.formControls.addressInfo.controls.addressLine2.value+' , '+this.formControls.addressInfo.controls.notes.value
    }
    else{
      add2= this.formControls.addressInfo.controls.addressLine2.value;

    }
   
          const pickupAddress = {
        "customerName": this.formControls.addressInfo.controls.firstName.value + ' ' + this.formControls.addressInfo.controls.lastName.value,
        "addressLine1": this.formControls.addressInfo.controls.address.value,
        // "addressLine2": '', //this.orderDetails.shippingAddress.town.value,
       
        "addressLine2": add2?add2:' ', 

        "city": this.formControls.addressInfo.controls.city.value,
        "state": this.formControls.addressInfo.controls.state.value,
        "postalCode": this.formControls.addressInfo.controls.pinCode.value,
        "countryCode": this.orderDetails.shippingAddress.countryCode,
        "countryName": this.orderDetails.shippingAddress.countryName,
        "contactPhone": this.formControls.addressInfo.controls.mobile.value,
        "email": this.formControls.addressInfo.controls.email.value,
        // "landmark": this.formControls.addressInfo.controls.landmark.value

      };      
      this.returnItemRequest = {
        customerOrderNumber: this.orderDetails.customerOrderNumber,
        isCOD: this.orderDetails.isCOD,
        isExchange: (this.formControls.returnAction.value.toLowerCase() === global.REFUND.toLowerCase()) ? 0 : 1,
        refundMode: this.formControls.refundMode.value,
        refundBankAccountDetails: (this.formControls.refundMode.value.toLowerCase() === global.REFUND_MODE.toLowerCase()) ? this.refundDetails : '',
        exchangeItem: this.exchangeItemDetails ? this.exchangeItemDetails : null,
        item: itemDetails,
        pickupAddress: pickupAddress,
      }; 
      
       const returnFlowType = (this.formControls.returnAction.value.toLowerCase() === global.REFUND.toLowerCase()) ? 'refund' : (this.exchangeItemDetails ? 'exchange' : 'replacement');       
       LocalStorageService.set('returnFlowType', returnFlowType);
       if(this.orderDetails.isPoeOldFlow=='false'){
         const returnFlowType='Return'
        LocalStorageService.set('returnFlowType', returnFlowType);
       }
       LocalStorageService.set('actionType', 'returnProcess');
       LocalStorageService.set('returnItemRequest', this.returnItemRequest);
      //  console.log(this.returnItemRequest);
       if (this.returnItemRequest) {
        // this.router.navigate([global.ROUTES.OTP_CONFIRMATION, LocalStorageService.get('ORDER_ID'), LocalStorageService.get('ORDER_ITEM_ID')]);
        this.orderReturnService.returnItemApiRequest(this.returnItemRequest).then(data => {
          this.loading = false;
          LocalStorageService.set('SuccessResponseData', data);
          LocalStorageService.flush('returnItemRequest');
          this.router.navigate([global.ROUTES.REQUEST_SUBMISSION, this.orderId, this.orderItemId]);
        }).catch(err => {
          this.loading = false;
          // throw err;
        });
      }
      
    }
  }




  setBackToOrder(){
    this.sharedService.setOpen(true);
    LocalStorageService.flush('returnItemRequest');
    LocalStorageService.flush('exchangeOption');
    LocalStorageService.flush('size');
    this.router.navigate([global.ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
  }




  /*Left Panel Show Hide*/
  showLeftPanel(){
    this.leftPanelActive = !this.leftPanelActive;
  }

  displayLeftPanel(value){
    this.leftPanelActive = value;
  }

  callIfscCodeCheck(event){
    if (event.currentTarget.value.length === 11) {
      this.ifscShowLoader = true;
      this.orderReturnService.getIfscCodeDetails(event.currentTarget.value)
      .then(data => {
        if (data){
          this.ifscShowLoader = false;
          this.formControls.bankName.setValue(data.BANK);
          this.formControls.bankCity.setValue(data.CITY);
          this.formControls.branch.setValue(data.BRANCH);
        }else{
          this.formControls.ifscCode.markAsTouched();
          this.formControls.ifscCode.setErrors({invalid: true});
          this.formControls.bankName.setValue(null);
          this.formControls.bankCity.setValue(null);
          this.formControls.branch.setValue(null);
        }
      }).catch(err => {
        this.formControls.ifscCode.markAsTouched();
        this.formControls.ifscCode.setErrors({invalid: true});
        this.formControls.bankName.setValue(null);
        this.formControls.bankCity.setValue(null);
        this.formControls.branch.setValue(null);
        this.ifscShowLoader = false;
        throw err;
      });
    }
  }


// Help Modal
openHelpModal(){
  this.isHelpOpen = !this.isHelpOpen;
  this.preventScroll.disable();
  this.isHelpOpendelay = !this.isHelpOpendelay;
  document.querySelector("body").classList.add("stop-scrolling");
}

closeHelpModal() {
  this.isHelpOpen = false;  
  // this.preventScroll.enable();
  if(!this.deviceService.isDesktop()) {
    setTimeout(() => {
      this.isHelpOpendelay = false;
    }, 350);
  } else this.isHelpOpendelay = false;
  document.querySelector("body").classList.remove("stop-scrolling");
}


onScroll(){
  this.scrollTopPosition = this.scrollElement.nativeElement.scrollTop;
  if( this.scrollTopPosition > 0 ){
    this.isBoxshadow = true;
  }
  else{
    this.isBoxshadow = false;
  }
}


  resetForm(){
    this.returnItemForm.reset();
    this.showSubReason = false;
    this.showAction = false;
    this.showExchangeOptions = false;
    this.showBankDropdown = false;
    this.showBankDetailsForm = false;
    this.showAddressForm = false;
    this.showAddressFormHtml = false;
  }

  transformDecimal(num) {
    return this.decimalPipe.transform(num, '2.');
  }
  // ngOnDestory() {
  //   this.formControls();
  // }
  openSite(siteUrl) {
    window.open(siteUrl, '_blank');
  }
  open_ace_com(siteUrl) {
    window.open( siteUrl, '_blank');
  }
  mouseEnter(value){
    this.Helpcolor=true;
  }
  
  mouseLeave(value){
   this.Helpcolor=false;
  
  }
  getValue(value){
    
      this.formControls.exchangeOption.setValue(value);
      // this.exchangeItemDetails = {
      //   "sku" : this.exchangeItemData.sku,
      //   "itemID" : this.exchangeItemData.itemID,
      //   "exchangeSize" : this.formControls.size.value,

      //  }
    
  }
}

function typeOf(addressLine2: string): any {
  throw new Error('Function not implemented.');
}
