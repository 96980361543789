import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes,
  sequence
} from "@angular/animations";
import { Subscription } from "rxjs/Subscription";
import { AlertService } from "@app/shared/services/alert.service";
import { Router } from "@angular/router";

import { ActivatedRoute } from "@angular/router";
import { ROUTES } from "@app/shared/constants/constants";
@Component({
  selector: "app-web-alert",
  templateUrl: "./alert.component.html",
  animations: [
    trigger("slideAnimation", [
      transition("* => void", [
        style({
          height: "*",
          opacity: "1",
          transform: "translateX(0)",
          "box-shadow": "0 1px 4px 0 rgba(0, 0, 0, 0.3)"
        }),
        sequence([
          animate(
            ".25s ease",
            style({
              height: "*",
              opacity: ".2",
              transform: "translateX(20px)",
              "box-shadow": "none"
            })
          ),
          animate(
            ".1s ease",
            style({
              height: "0",
              opacity: 0,
              transform: "translateX(20px)",
              "box-shadow": "none"
            })
          )
        ])
      ]),
      transition("void => active", [
        style({
          height: "0",
          opacity: "0",
          transform: "translateX(20px)",
          "box-shadow": "none"
        }),
        sequence([
          animate(
            ".1s ease",
            style({
              height: "*",
              opacity: ".2",
              transform: "translateX(20px)",
              "box-shadow": "none"
            })
          ),
          animate(
            ".35s ease",
            style({
              height: "*",
              opacity: 1,
              transform: "translateX(0)",
              "box-shadow": "0 1px 4px 0 rgba(0, 0, 0, 0.3)"
            })
          )
        ])
      ])
    ])
  ]
})
export class AlertComponent implements OnInit, OnDestroy {
  alertMessageCollection: Array<any> = [];
  subscription: Subscription;
  showConfirmation: Boolean = false;

  confirmationContent = {
    title: "",
    subTitle: "",
    key: ""
  };
  picklistId;
  constructor(
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscription = this.alertService.alertMessageState.subscribe(
      newState => {
        this.addMessage(newState.show, newState.type, newState.content);
      }
    );

    this.subscription = this.alertService.confirmationListner.subscribe(
      data => {
        this.showConfirmationPopup(data.title, data.subtitle, data.key);
      }
    );

    this.route.queryParams.subscribe(params => {
      this.picklistId = params["id"];
    });

    this.runMessageClear();
  }

  trackByFn(index) {
    return index;
  }

  addMessage(status: boolean, type: string, message: string) {
    this.alertMessageCollection.push({
      status: status === true ? "active" : "inactive",
      type: type,
      message: message
    });
    this.runMessageClear();
  }

  removeMessage(index) {
    this.alertMessageCollection.splice(index, 1);
  }

  runMessageClear() {
    const timer = window.setInterval(() => {
      if (this.alertMessageCollection.length > 0)
        this.alertMessageCollection.shift();
      else window.clearInterval(timer);
    }, 7000);
  }

  showConfirmationPopup(title: string, subTitle: string, key: string) {
    this.showConfirmation = true;
    this.confirmationContent.title = title;
    this.confirmationContent.subTitle = subTitle;
    this.confirmationContent.key = key;
  }

  accept() {
    this.showConfirmation = false;
    this.alertService.acceptReject(true, this.confirmationContent.key);
  }

  reject() {
    this.showConfirmation = false;
    this.alertService.acceptReject(false, this.confirmationContent.key);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
