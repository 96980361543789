"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var store_1 = require("@ngrx/store");
var effects_1 = require("@ngrx/effects");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var order_placed_actions_1 = require("../actions/order-placed.actions");
var services_1 = require("../../shared/services");
var selectors_1 = require("../reducers/selectors");
var OrderPlacedEffects = /** @class */ (function () {
    function OrderPlacedEffects(actions$, orderService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.orderService = orderService;
        this.store = store;
        this.getOrder$ = this.actions$.pipe(effects_1.ofType(order_placed_actions_1.OrderPlacedActionTypes.GET_ORDER), operators_1.mergeMap(function (action) {
            return _this.orderService.getOrder(action.payload).pipe(operators_1.map(function (data) { return ({
                type: order_placed_actions_1.OrderPlacedActionTypes.GET_ORDER_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function () { return rxjs_1.of({ type: order_placed_actions_1.OrderPlacedActionTypes.GET_ORDER_FAILED }); }));
        }));
        this.getUserSettings$ = this.actions$.pipe(effects_1.ofType(order_placed_actions_1.OrderPlacedActionTypes.GET_USER_SETTINGS), operators_1.mergeMap(function (action) {
            return _this.orderService.getUserSettings().pipe(operators_1.map(function (data) { return ({
                type: order_placed_actions_1.OrderPlacedActionTypes.GET_USER_SETTINGS_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function () { return rxjs_1.of({ type: order_placed_actions_1.OrderPlacedActionTypes.GET_USER_SETTINGS_FAILED }); }));
        }));
        this.getSelectedItem$ = this.actions$.pipe(effects_1.ofType(order_placed_actions_1.OrderPlacedActionTypes.GET_SELECTED_ITEM), operators_1.mergeMap(function (action) {
            return _this.store.pipe(store_1.select(selectors_1.getSelectedItem), operators_1.map(function (order) { return ({
                type: order_placed_actions_1.OrderPlacedActionTypes.SET_SELECTED_ITEM,
                payload: order
            }); }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], OrderPlacedEffects.prototype, "getOrder$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], OrderPlacedEffects.prototype, "getUserSettings$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], OrderPlacedEffects.prototype, "getSelectedItem$", void 0);
    return OrderPlacedEffects;
}());
exports.OrderPlacedEffects = OrderPlacedEffects;
