"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".panel-right[_ngcontent-%COMP%]   .ordered-items[_ngcontent-%COMP%]{width:100%;display:flex;flex-direction:column;margin-bottom:0}.arrow-top[_ngcontent-%COMP%]{position:absolute;right:0;top:-25px;display:none}.btn-bottom[_ngcontent-%COMP%]{position:relative}  .mat-form-field.mat-focused.mat-primary .mat-select-arrow{-webkit-transform:rotate(-135deg);transform:rotate(-135deg)}@media only screen and (max-width:480px){.arrow-top[_ngcontent-%COMP%]{display:block}.mobile-panel[_ngcontent-%COMP%]{overflow:visible}.p-form-row[_ngcontent-%COMP%]{width:100%}.panel-right[_ngcontent-%COMP%]{overflow:visible}.custom-row[_ngcontent-%COMP%]{height:calc(100vh - 80px)!important;min-height:calc(100vh - 80px)!important;-webkit-animation:.3s ease-out slide;animation:.3s ease-out slide}.panel-right[_ngcontent-%COMP%]   .scroll-content[_ngcontent-%COMP%]{margin-bottom:0;padding-top:13px!important}.box-shadowcss[_ngcontent-%COMP%]{box-shadow:none}.panel-right[_ngcontent-%COMP%]   .btn-bottom[_ngcontent-%COMP%]{padding:10px 16px 16px}.p_panel-right[_ngcontent-%COMP%]   .ordered-items[_ngcontent-%COMP%]   .item-list[_ngcontent-%COMP%]   .item-info[_ngcontent-%COMP%]{margin:0;position:fixed;top:0;left:0;right:0;background:#fff;z-index:999999;box-shadow:2px 2px 2px #f9f9f9}.p_panel-right[_ngcontent-%COMP%]   .ordered-items[_ngcontent-%COMP%]   .item-list[_ngcontent-%COMP%]{height:60px}@-webkit-keyframes slide{0%{-webkit-transform:translateX(100%);transform:translateX(100%)}100%{-webkit-transform:translateX(0);transform:translateX(0)}}@keyframes slide{0%{-webkit-transform:translateX(100%);transform:translateX(100%)}100%{-webkit-transform:translateX(0);transform:translateX(0)}}}"];
exports.styles = styles;
