import { Action } from "@ngrx/store";

export enum OrderPlacedActionTypes {
  GET_ORDER = "[Order Page] Get Order",
  GET_ORDER_SUCCESS = "[Order API] Get Order Success",
  GET_ORDER_FAILED = "[Order API] Get Order Failed",
  GET_SELECTED_ITEM = "[Order Item] Get Selected Item",
  SET_SELECTED_ITEM = "[Order Item] Get Selected Item",
  GET_USER_SETTINGS = "[User Settings API] Get User Settings",
  GET_USER_SETTINGS_SUCCESS = "[User Settings API] Get User Settings Success",
  GET_USER_SETTINGS_FAILED = "[User Settings API] Get User Settings Failed",
  RESET_STATE = "[Order API] Reset state to initial values",
  MARK_AS_DELIVERED = "Mark as delivered"
}

export class GetOrder implements Action {
  readonly type = OrderPlacedActionTypes.GET_ORDER;

  constructor(public payload: string) {}
}

export class GetOrderSuccess implements Action {
  readonly type = OrderPlacedActionTypes.GET_ORDER_SUCCESS;

  constructor(public payload: { orderPlaced: any }) {}
}

export class GetOrderFailed implements Action {
  readonly type = OrderPlacedActionTypes.GET_ORDER_FAILED;
}

export class ResetGetOrderStateAction implements Action {
  readonly type = OrderPlacedActionTypes.RESET_STATE;
}
export class GetSelectedItem implements Action {
  readonly type = OrderPlacedActionTypes.GET_SELECTED_ITEM;

  constructor(public payload: string) {}
}

export class SetSelectedItem implements Action {
  readonly type = OrderPlacedActionTypes.SET_SELECTED_ITEM;

  constructor(public payload: string) {}
}
/*User Settings Actions */
export class GetUserSettings implements Action {
  readonly type = OrderPlacedActionTypes.GET_USER_SETTINGS;
  constructor() {}
}

export class GetUserSettingsSuccess implements Action {
  readonly type = OrderPlacedActionTypes.GET_USER_SETTINGS_SUCCESS;
  constructor(public payload: { userSettings: any }) {}
}

export class GetUserSettingsFailed implements Action {
  readonly type = OrderPlacedActionTypes.GET_USER_SETTINGS_FAILED;
}

export class MarkAsDelivered implements Action {
  readonly type = OrderPlacedActionTypes.MARK_AS_DELIVERED;
  constructor(public payload: { userSettings: any }) {}
}

export type OrderPlacedUnion =
  | GetOrder
  | GetOrderSuccess
  | GetOrderFailed
  | ResetGetOrderStateAction
  | GetSelectedItem
  | GetUserSettings
  | GetUserSettingsSuccess
  | GetUserSettingsFailed;
