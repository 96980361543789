import * as users from "./users.json";
import * as route_summary from "./route-summary.json";
import * as delivery_associates from "./delivery-associates.json";
import * as simulated_summary from "./simulated-routes.json";
import * as picklist_summary from "./picklist_summary.json";
import * as complete_consignment_list from "../mockdata/complete-consign-list.json";
import * as picklist_by_id from "./picklist_by_id.json";
import * as cancelProductReasonList from "../mockdata/cancel-product-reason.json";

export const usersResponse: any = users;
export const routeSummary: any = route_summary;
export const deliveryAssociates: any = delivery_associates;
export const simulatedSummary: any = simulated_summary;
export const picklistSummary: any = picklist_summary;
export const completeConsignmentList: any = complete_consignment_list;
export const picklistById: any = picklist_by_id;
export const cancelReasonList: any = cancelProductReasonList;
