"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["section.black-top-info[_ngcontent-%COMP%]{display:flex;align-items:center;justify-content:center;background:#343434;padding:6px 0}section.black-top-info[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{color:#fff;font-size:14px;line-height:18px;font-family:'Work Sans Light';display:flex}section.black-top-info[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{display:inline-flex;margin-left:5px}section.black-top-info[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{display:inline-flex;-o-object-fit:contain;object-fit:contain;margin:0 8px 0 0;padding:0;max-width:15px}"];
exports.styles = styles;
