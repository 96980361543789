"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("./api.service");
var environment_1 = require("../../../environments/environment");
var operators_1 = require("rxjs/operators");
var config_1 = require("../configuration/config");
var http_1 = require("@angular/common/http");
var i0 = require("@angular/core");
var i1 = require("./api.service");
var i2 = require("../configuration/config");
var OrderService = /** @class */ (function () {
    function OrderService(api, config) {
        this.api = api;
        this.config = config;
        this.API_URL = environment_1.environment.API_URL;
        this.USER_SETTING = environment_1.environment.USER_SETTING;
        this.headers = new http_1.HttpHeaders();
        this.env = environment_1.environment.prodEnv;
    }
    OrderService.prototype.getOrders = function (params) {
        if (params === void 0) { params = {}; }
        return this.api
            .get(this.API_URL + "orders", params)
            .pipe(operators_1.map(function (response) { return response["data"]; }));
    };
    OrderService.prototype.getOrder = function (id) {
        return this.api.get(this.API_URL + "order/" + encodeURIComponent(id));
    };
    OrderService.prototype.getUserSettings = function () {
        return this.api.get(this.USER_SETTING + "poe-setting");
    };
    OrderService.prototype.getOrdersCount = function () {
        return this.api
            .get(this.API_URL + "orders/count")
            .pipe(operators_1.map(function (response) { return response["data"]; }));
    };
    OrderService.prototype.markAsDelivered = function (data) {
        var url = this.env
            ? "https://wms.eshopbox.com"
            : "https://eshopbox-wms-api-dot-eshopbox-wms-staging.el.r.appspot.com";
        return this.api
            .post(url + "/api/update-status", data, this.headers)
            .toPromise()
            .then(function (res) {
            if (res) {
                return res;
            }
            throw res;
        })
            .catch(function (err) {
            console.log(err);
            throw err;
        });
    };
    OrderService.ngInjectableDef = i0.defineInjectable({ factory: function OrderService_Factory() { return new OrderService(i0.inject(i1.ApiService), i0.inject(i2.Configuration)); }, token: OrderService, providedIn: "root" });
    return OrderService;
}());
exports.OrderService = OrderService;
