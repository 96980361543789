import { createSelector } from "@ngrx/store";
import { OrderPlacedState } from "./order-placed.state";
import { AppState } from "../../app.state";

const getOrderState = (state: AppState) => state.orderPlaced;
const currentItem = (state: OrderPlacedState) => state.orderPlaced;
const userSetting = (state: OrderPlacedState) => state.userSettings;

export const getCurrentOrder = createSelector(
  getOrderState,
  currentItem
);

export const getSelectedItem = createSelector(
  getOrderState,
  currentItem
);

export const getUserSettings = createSelector(
  getOrderState,
  userSetting
);