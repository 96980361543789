import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const UserRoutes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild(UserRoutes)],
  exports: [RouterModule]
})
export class SharedRoutingModule {}
