"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./edd.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./readmore/readmore.component.ngfactory");
var i3 = require("./readmore/readmore.component");
var i4 = require("@angular/common");
var i5 = require("./edd.component");
var i6 = require("@ngrx/store");
var styles_EDDComponent = [i0.styles];
var RenderType_EDDComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EDDComponent, data: {} });
exports.RenderType_EDDComponent = RenderType_EDDComponent;
function View_EDDComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "banner-title oda-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["src", "https://ik.imagekit.io/2gwij97w0o/alert-triangle-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We are unable to deliver your order"]))], null, null); }
function View_EDDComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "banner-title oda-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["src", "https://ik.imagekit.io/2gwij97w0o/alert-triangle-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We are unable to deliver your order. You are requested to pick the order from the below provided address."]))], null, null); }
function View_EDDComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "banner-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["src", "../../assets/images/delivery-vehicle.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Expected delivery by ", " "])), i1.ɵppd(4, 2), i1.ɵppd(5, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.selectedItemDetails == null) ? null : _co.selectedItemDetails.expectedDeliveryDate) ? i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 0), _co.selectedItemDetails.expectedDeliveryDate, "EEEE MMM d, y")) : i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), _co.selectedItemDetails.expectedDeliveryDate, "EEEE MMM d, y"))); _ck(_v, 3, 0, currVal_0); }); }
function View_EDDComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your order is delayed "]))], null, null); }
function View_EDDComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "mobileview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-read-more", [], null, null, null, i2.View_ReadMoreComponent_0, i2.RenderType_ReadMoreComponent)), i1.ɵdid(2, 114688, null, 0, i3.ReadMoreComponent, [], { content: [0, "content"], limit: [1, "limit"], completeWords: [2, "completeWords"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.userSettings == null) ? null : _co.userSettings.delayedDeliveryMessage); var currVal_1 = 67; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_EDDComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "seemore"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.seemore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["...see more"]))], null, null); }
function View_EDDComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_11)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.userSettings == null) ? null : _co.userSettings.delayedDeliveryMessage.length) > 210); _ck(_v, 2, 0, currVal_0); }, null); }
function View_EDDComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "seeless"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.seeless() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["...see less"]))], null, null); }
function View_EDDComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_13)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.userSettings == null) ? null : _co.userSettings.delayedDeliveryMessage.length) > 210); _ck(_v, 2, 0, currVal_0); }, null); }
function View_EDDComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_10)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_12)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.userSettings == null) ? null : _co.userSettings.delayedDeliveryMessage.length) > 210) ? "seemore-text" : "seeless-text"); _ck(_v, 1, 0, currVal_0); var currVal_2 = !_co.seemoretext; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.seemoretext; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.userSettings == null) ? null : _co.userSettings.delayedDeliveryMessage); _ck(_v, 2, 0, currVal_1); }); }
function View_EDDComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "banner-title oda-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["src", "https://ik.imagekit.io/2gwij97w0o/POE/delivery-vehicle.png"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_7)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_8)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "section", [["class", "desktopview"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_9)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_co.userSettings == null) ? null : _co.userSettings.delayedDeliveryMessage); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.userSettings == null) ? null : _co.userSettings.delayedDeliveryMessage); _ck(_v, 5, 0, currVal_1); var currVal_2 = "desktopview"; var currVal_3 = (_co.seemoretext ? "delayfullmsg" : ""); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = ((_co.userSettings == null) ? null : _co.userSettings.delayedDeliveryMessage); _ck(_v, 9, 0, currVal_4); }, null); }
function View_EDDComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_5)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_6)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentDate < _co.selectedItemDetails.expectedDeliveryDate) && ((_co.selectedItemDetails.latestStatus == "intransit") || (_co.selectedItemDetails.latestStatus == "picked_up"))); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.currentDate > _co.selectedItemDetails.expectedDeliveryDate) && ((_co.selectedItemDetails.latestStatus == "intransit") || (_co.selectedItemDetails.latestStatus == "picked_up"))); _ck(_v, 4, 0, currVal_1); }, null); }
function View_EDDComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "banner-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["src", "../../assets/images/arriving_today.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your order is arriving early "]))], null, null); }
function View_EDDComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "banner-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["src", "../../assets/images/arriving_today.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your order is arriving today "]))], null, null); }
function View_EDDComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_17)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedItemDetails.latestStatus == "out_for_delivery"); _ck(_v, 1, 0, currVal_0); }, null); }
function View_EDDComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_15)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["other", 2]], null, 0, null, View_EDDComponent_16))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.updatedAt < _co.selectedItemDetails.expectedDeliveryDate) && (_co.selectedItemDetails.latestStatus == "out_for_delivery")); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_EDDComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_14)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_co.currentDate > _co.updatedAt) && ((_co.selectedItemDetails.latestStatus == "out_for_delivery") || (_co.selectedItemDetails.latestStatus == "picked_up"))); _ck(_v, 2, 0, currVal_0); var currVal_1 = !((_co.currentDate > _co.updatedAt) && (_co.selectedItemDetails.latestStatus == "out_for_delivery")); _ck(_v, 4, 0, currVal_1); }, null); }
function View_EDDComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EDDComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.selectedItemDetails.latestStatus == "failed_delivery") && (_co.selectedItemDetails.forwardTrackingTimeline.odaAddress == "")); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.selectedItemDetails.latestStatus == "ndr_resolution_submitted") && _co.selectedItemDetails.forwardTrackingTimeline.odaAddress); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.selectedItemDetails.latestStatus == "intransit") || (_co.selectedItemDetails.latestStatus == "out_for_delivery")) || (_co.selectedItemDetails.latestStatus == "picked_up")); _ck(_v, 6, 0, currVal_2); }, null); }
exports.View_EDDComponent_0 = View_EDDComponent_0;
function View_EDDComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edd", [], null, null, null, View_EDDComponent_0, RenderType_EDDComponent)), i1.ɵdid(1, 114688, null, 0, i5.EDDComponent, [i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_EDDComponent_Host_0 = View_EDDComponent_Host_0;
var EDDComponentNgFactory = i1.ɵccf("app-edd", i5.EDDComponent, View_EDDComponent_Host_0, {}, {}, []);
exports.EDDComponentNgFactory = EDDComponentNgFactory;
