import * as OrderActions from "../actions/order-placed.actions";
import { OrderPlacedState } from "./order-placed.state";

export const initialState: OrderPlacedState = {
  orderPlaced: null,
  selectedItem: null,
  userSettings: null,
};

export function OrderPlacedReducer( state = initialState, action: OrderActions.OrderPlacedUnion) {
  switch (action.type) {
    // case UserActions.UserActionTypes.GET_CURRENT_USER: {
    //   return state;
    //}

    case OrderActions.OrderPlacedActionTypes.GET_ORDER: {
      return state;
    }

    case OrderActions.OrderPlacedActionTypes.GET_ORDER_SUCCESS: {
      return {
        ...state,
        orderPlaced: action.payload
      };
    }

    case OrderActions.OrderPlacedActionTypes.GET_ORDER_FAILED: {
      return state;
    }

    case OrderActions.OrderPlacedActionTypes.RESET_STATE: {
      return Object.assign({}, initialState);
    }
    // case OrderActions.OrderPlacedActionTypes.GET_SELECTED_ITEM: {
    //   return state;
    // }

    // case OrderActions.OrderPlacedActionTypes.GET_SELECTED_ITEM: {
    //   return {
    //     ...state,
    //     selectedItem: 1
    //   };
    // }

    case OrderActions.OrderPlacedActionTypes.GET_USER_SETTINGS: {
      return state;
    }

    case OrderActions.OrderPlacedActionTypes.GET_USER_SETTINGS_SUCCESS: {
      return {
        ...state,
        userSettings: action.payload
      };
    }

    case OrderActions.OrderPlacedActionTypes.GET_USER_SETTINGS_FAILED: {
      return state;
    }

    default:
      return state;
  }
}
