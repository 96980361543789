<!-- {{reversePickuperror}}<br />
            {{ReversePickupPincode}} -->
            
<ng-container [formGroup]="addressInfoForm">
    <ng-container *ngIf="addressLoading">
    <div class="registered-address" *ngIf="!showPickupAddress">
        <mat-radio-group aria-label="Select an option" formControlName="oldAddress">
            <mat-radio-button id="saved-address" name="oldAddress" value="1" [checked]="true">
                <span class="address-line1" *ngIf="addressFormValue?.customerName">{{ addressFormValue.customerName ? addressFormValue.customerName :'' }}</span>    
                <span class="address-line2" *ngIf="addressFormValue?.contactPhone">{{ addressFormValue.contactPhone ? addressFormValue.contactPhone:'' }}  | {{addressFormValue.email ? addressFormValue.email:''}}</span>
                </mat-radio-button>
            <mat-error
                *ngIf="addressInfoForm.controls.oldAddress.touched && addressInfoForm.controls.oldAddress.invalid">
                <mat-error class="error-message" *ngIf="addressInfoForm.controls.oldAddress.errors.required">This field
                    is mandatory</mat-error>
            </mat-error>
        </mat-radio-group>
        <p>{{ (addressFormValue) ? addressFormValue.addressLine1 +', ':'' }}
            {{ (addressFormValue) ? addressFormValue.addressLine2+', ':'' }}
            {{ ((addressFormValue) ? addressFormValue.city +', ':'') | uppercase }} {{ ((addressFormValue) ? addressFormValue.state +' - '+addressFormValue.postalCode:'') | uppercase }}
        </p>
        <div class="text-right editaddressBtn">
            <ng-container *ngIf="!dynamicdata">
                <a (click)="showHideAddressSection(true)" class="add-address-btn" [ngStyle]="{color:getColor} " ><span>+</span>Edit Details</a>
            </ng-container>
            <ng-container *ngIf="dynamicdata">   
                <a (click)="showHideAddressSection(false)" class="add-address-btn"  [ngStyle]="{color:getColor} "><span>-</span>Edit Details</a>
            </ng-container>
            <!-- [ngStyle]="{color:getColor} " -->
        </div>
    </div>

    <div class="registered-address" *ngIf="showPickupAddress">
        <mat-radio-group aria-label="Select an option" formControlName="oldAddress">
            <mat-radio-button id="saved-address" name="oldAddress" value="1" [checked]="true">
                <span class="address-line1">{{ pickupAddress.customerName ? pickupAddress.customerName :'' }}</span>    
                <span class="address-line2">{{ pickupAddress.contactPhone ? pickupAddress.contactPhone:'' }} | {{addressFormValue.email ? addressFormValue.email:''}}</span>
                </mat-radio-button>
            <mat-error
                *ngIf="addressInfoForm.controls.oldAddress.touched && addressInfoForm.controls.oldAddress.invalid">
                <mat-error class="error-message" *ngIf="addressInfoForm.controls.oldAddress.errors.required">This field
                    is mandatory</mat-error>
            </mat-error>
        </mat-radio-group>
        <p>{{ (pickupAddress) ? pickupAddress.addressLine1 +', ':'' }} 
            {{ (pickupAddress) ? pickupAddress.addressLine2+', ':'' }}
            {{ ((pickupAddress) ? pickupAddress.city +', ':'') | uppercase }} {{ ((pickupAddress) ? pickupAddress.state +' - '+pickupAddress.postalCode:'') | uppercase }}
        </p>
        <div class="text-right editaddressBtn">
            <ng-container *ngIf="!dynamicdata">
                <a (click)="showHideAddressSection(true)" class="add-address-btn" [ngStyle]="{color:getColor} " ><span>+</span>Edit Details</a>
            </ng-container>
            <ng-container *ngIf="dynamicdata">
                <a (click)="showHideAddressSection(false)" class="add-address-btn"  [ngStyle]="{color:getColor} "><span>-</span>Edit Details</a>
            </ng-container>
    
        </div>
    </div>
    <div class="pincode-block" *ngIf="ReversePickupPincode == false">
        <img src="../../../../assets/images/alert-icon-new.svg" />This pin code is not serviceable. 
    </div>

</ng-container>
<div class="loader">
    <mat-spinner *ngIf="addressLoading == false" [diameter]="38" [strokeWidth]="3.5" class="centre-align"></mat-spinner>
</div>
 <div class="edit-address-popup" (click)="closeEditAddress()" [class.editAddressShow]="dynamicdata" >

    

 </div>   

 <div class="address-main-box" [class.editAddressShow]="dynamicdata">
    <div class="address-top-box">
        <h2 class="main-head" >Edit details</h2>
        <button (click)="closeEditAddress()"></button>
    </div>

    <div class="address-formbox" id="addrDivId">
        <div  class="form-row-box flex-parent-row mb-12 mobile_full_wd">
            <div class="flex-one-row">
                <label class="custom-label-2">First Name</label>
                <mat-form-field class="p-full-width" [ngStyle]="{border:(addressInfoForm.controls.firstName.touched && addressInfoForm.controls.firstName.invalid)?'1px solid red':''}">
                    <input matInput  id="firstName" name="firstName"
                        formControlName="firstName" mask="S*" placeholder="First Name">
                    <mat-error
                        *ngIf="addressInfoForm.controls.firstName.touched && addressInfoForm.controls.firstName.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.firstName.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.firstName.errors.pattern">
                            Please Enter Valid Name</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="flex-one-row">
                <label class="custom-label-2">Last Name</label>
                <mat-form-field class="p-full-width" [ngStyle]="{border:(addressInfoForm.controls.lastName.touched && addressInfoForm.controls.lastName.invalid)?'1px solid red':''}">
                    <input matInput placeholder="Last Name" id="lastName" name="lastName" formControlName="lastName" mask="S*">
                    <mat-error
                        *ngIf="addressInfoForm.controls.lastName.touched && addressInfoForm.controls.lastName.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.lastName.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.lastName.errors.pattern">Please
                            Enter Valid Name</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row-box flex-parent-row mb-12 mobile_full_wd">
            <div class="flex-one-row">
                <label class="custom-label-2">Phone Number</label>
                <mat-form-field class="p-full-width" [ngStyle]="{border:(addressInfoForm.controls.mobile.touched && addressInfoForm.controls.mobile.invalid)?'1px solid red':''}">
                    <input matInput id="mobile" placeholder="Phone Number" name="mobile" formControlName="mobile" mask="9999999999">
                    <mat-error *ngIf="addressInfoForm.controls.mobile.touched && addressInfoForm.controls.mobile.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.mobile.errors.required">This field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.mobile.errors.pattern">Please Enter Valid Number</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="flex-one-row blur_label">
                <label class="custom-label-2">Email ID</label>
                <mat-form-field class="p-full-width tooltip-exclusive" [ngStyle]="{border:(addressInfoForm.controls.email.touched && addressInfoForm.controls.email.invalid)?'1px solid red':''}">
                    <span class="tooltip">Email can’t be edited</span>
                    <input readonly matInput id="email" name="email" formControlName="email" class="input_field_blur">
                    <mat-error *ngIf="addressInfoForm.controls.email.touched && addressInfoForm.controls.email.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.email.errors.required">This field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.email.errors.pattern">Please Enter Valid Email</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    <div class="form-row-box flex-parent-row mb-12">
        <div class=" flex-two-row mobile-row-one " >
            <label class="custom-label-2">Pin code</label>
            
            <ng-container>
            <mat-form-field class="p-full-width tooltip-exclusive" [ngStyle]="{border:(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid)?'1px solid red':''}" *ngIf="showPincode">
                <!-- <span class="tooltip">Pin code can’t be edited 11</span> -->
                <input matInput id="pinCode" name="pinCode" formControlName="pinCode"
                    (input)="onPincodeChangeCityState($event)" mask="999999" placeholder="Pin Code" class="input_field_blur text">
                <mat-error
                    *ngIf="addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid">
                    <mat-error class="error-message" *ngIf="addressInfoForm.controls.pinCode.errors.required">This
                        field is mandatory</mat-error>
                    <!-- <mat-error class="error-message" *ngIf="addressInfoForm.controls.pinCode.errors.invalid">Invalid
                        Pincode</mat-error> -->
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.pinCode.errors.isReversePickup">
                            Service not available at this Pincode</mat-error>
    
                </mat-error>
               <img src="../../../../assets/images/correct-pin.svg" alt="" *ngIf="!(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid)" [ngClass]="ReversePickupPincode == false ? 'hide':''" class="error-pin">
                <img src="../../../../assets/images/wrong-pin.svg" alt="" *ngIf="(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid) || ReversePickupPincode == false" class="error-pin">
                <p class="error-message-pincode" *ngIf="ReversePickupPincode == false">
                    This pin code is not serviceable</p>   
            </mat-form-field>
             
            </ng-container>  

            <mat-form-field class="p-full-width tooltip-exclusive" [ngStyle]="{border:(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid)?'1px solid red':''}" *ngIf="!showPincode">
                <span class="tooltip">Pincode can’t be edited</span>
                <input matInput id="pinCode" name="pinCode" formControlName="pinCode" class="input_field_blur text"
                    (input)="onPincodeChangeCityState($event)" mask="999999" placeholder="Pin Code" readonly>
               
               <img src="../../../../assets/images/correct-pin.svg" alt="" *ngIf="!(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid)" class="error-pin">
                <img src="../../../../assets/images/wrong-pin.svg" alt="" *ngIf="addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid" class="error-pin">
            </mat-form-field>
        </div>

        <!-- <div class=" flex-two-row mobile-row-one blur_label" style="display: none;">
            <label class="custom-label-2">Pin code</label>            
            <mat-form-field class="p-full-width tooltip-exclusive" [ngStyle]="{border:(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid)?'1px solid red':''}" *ngIf="showPincode">
                <span class="tooltip">Pin code can’t be edited</span>
                <input matInput id="pinCode" name="pinCode" formControlName="pinCode"
                    (input)="onPincodeChangeCityState($event)" mask="999999" placeholder="Pin Code" class="input_field_blur text">
                <mat-error
                    *ngIf="addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid">
                    <mat-error class="error-message" *ngIf="addressInfoForm.controls.pinCode.errors.required">This
                        field is mandatory</mat-error>
                    <mat-error class="error-message" *ngIf="addressInfoForm.controls.pinCode.errors.invalid">Invalid
                        Pincode</mat-error>
                    <mat-error class="error-message" *ngIf="addressInfoForm.controls.pinCode.errors.isReversePickup">
                        Service not available at this Pincode</mat-error>
                </mat-error>
               <img src="../../../../assets/images/correct-pin.svg" alt="" *ngIf="!(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid)" class="error-pin">
                <img src="../../../../assets/images/wrong-pin.svg" alt="" *ngIf="addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid" class="error-pin">

            </mat-form-field>
           
            <mat-form-field class="p-full-width tooltip-exclusive" [ngStyle]="{border:(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid)?'1px solid red':''}" *ngIf="!showPincode">
                <span class="tooltip">Pincode can’t be edited 22</span>
                <input matInput id="pinCode" name="pinCode" formControlName="pinCode" class="input_field_blur text"
                    (input)="onPincodeChangeCityState($event)" mask="999999" placeholder="Pin Code" readonly>
               
               <img src="../../../../assets/images/correct-pin.svg" alt="" *ngIf="!(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid)" class="error-pin">
                <img src="../../../../assets/images/wrong-pin.svg" alt="" *ngIf="addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid" class="error-pin">

            </mat-form-field>
        </div> -->

        <div class=" flex-two-row mobile-row-two blur_label city_label">
            <label class="custom-label-2">City</label>
            <mat-form-field class="p-full-width tooltip-exclusive">
                <span class="tooltip">City can’t be edited</span>
                <input matInput id="city" name="city" readonly formControlName="city" class="input_field_blur text">
                <!-- <mat-error *ngIf="addressInfoForm.controls.city.touched && addressInfoForm.controls.city.invalid">
                    <mat-error class="error-message" *ngIf="addressInfoForm.controls.city.errors.required">This
                        field is mandatory</mat-error>
                </mat-error> -->
            </mat-form-field>
        </div>
        <div class="flex-two-row mobile-row-two blur_label state_label">
            <label class="custom-label-2 ">State</label>
            <mat-form-field class="p-full-width tooltip-exclusive">
                <span class="tooltip">State can’t be edited</span>
                <input matInput id="state" name="state" readonly formControlName="state" class="input_field_blur text">
                <!-- <mat-error *ngIf="addressInfoForm.controls.state.touched && addressInfoForm.controls.state.invalid">
                    <mat-error class="error-message" *ngIf="addressInfoForm.controls.state.errors.required">This
                        field is mandatory</mat-error>
                </mat-error> -->
            </mat-form-field>
        </div>
    </div>    
    
  
        <div class="form-row-box">
            <div class=" flex-three-row">
                <label class="custom-label-2">Address</label>
                <mat-form-field class="p-full-width" [ngStyle]="{border:(addressInfoForm.controls.address.touched && addressInfoForm.controls.address.invalid)?'1px solid red':''}">
                    <textarea matInput  placeholder="Address" id="address" name="address" formControlName="address"></textarea>
                    <mat-error
                        *ngIf="addressInfoForm.controls.address.touched && addressInfoForm.controls.address.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.address.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.address.errors.pattern">Invalid
                            Address</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
<br>
<div class="form-row-box">
<div class=" flex-three-row">
    <label class="custom-label-2">Landmark</label>
    <mat-form-field class="p-full-width" ><!--[ngStyle]="{border:(addressInfoForm.controls.addressLine2.touched && addressInfoForm.controls.addressLine2.invalid)?'1px solid red':''}"-->
        <input matInput  placeholder="Eg. Near IT park" id="addressLine2" name="addressLine2" formControlName="addressLine2">
        <mat-error
            *ngIf="addressInfoForm.controls.addressLine2.touched && addressInfoForm.controls.addressLine2.invalid">
            <mat-error class="error-message" *ngIf="addressInfoForm.controls.addressLine2.errors.required">This
                field is mandatory</mat-error>
            <mat-error class="error-message" *ngIf="addressInfoForm.controls.addressLine2.errors.pattern">Invalid
                Landmark</mat-error>
        </mat-error>
    </mat-form-field>
</div>
</div>
<br>
<div class="form-row-box">
<div class=" flex-three-row">
    <label class="custom-label-2">Additional Delivery Notes (Optional)</label>
    <mat-form-field class="p-full-width" ><!--[ngStyle]="{border:(addressInfoForm.controls.addressLine2.touched && addressInfoForm.controls.addressLine2.invalid)?'1px solid red':''}"-->
        
        <textarea  matInput placeholder="Enter special instructions or tips we need to know to locate your address" id="notes" name="notes" formControlName="notes" ></textarea>
        <!-- <input matInput  placeholder="Describe the exact location of your address, for eg: 'In front of City park'." id="notes" name="notes" formControlName="notes"> -->
        <!-- <mat-error
            *ngIf="addressInfoForm.controls.addressLine2.touched && addressInfoForm.controls.addressLine2.invalid">
            <mat-error class="error-message" *ngIf="addressInfoForm.controls.addressLine2.errors.required">This
                field is mandatory</mat-error>
            <mat-error class="error-message" *ngIf="addressInfoForm.controls.addressLine2.errors.pattern">Invalid
                Landmark</mat-error>
        </mat-error> -->
    </mat-form-field>
</div>
</div>



        
    </div>
    <div class="btn-bottom btn-bottom-custom btn-bottom-custom-1">
        <input type="button" value="Cancel" class="btn-cancel" (click)="closeEditAddress()" />       
     <ng-container *ngIf="loading;else continueButton">
          <figure class="d-flex btn-loader btn-continue" >
            <div class="loader-css" ></div>
          </figure>
        </ng-container>
        <!-- <ng-template #continueButton>
          <input class="btn-continue" type="button" (click)="onSubmit()" [ngStyle]="{background:!addressInfoForm.valid?'#E0E0E0':getColor} "  [disabled]='!addressInfoForm.valid'  value="Save" />
        </ng-template>    -->
        <ng-template #continueButton >
              <input
              class="btn-continue"
              type="button"
              (click)="onSubmit()"
                value="Save"
                [disabled]="!addressInfoForm.valid || ReversePickupPincode == false"
                [ngStyle]="{'background-color':addressInfoForm.valid || reversePickuperror == false ? getColor :'' }"
            />
          </ng-template>
        <!-- {{reversePickuperror}} -->
    </div>
</div>


<!-- 
    <ng-container *ngIf="dynamicdata">
        <div class="form-row-box mb-12">
            <div class="p-form-row mr-18">
                <mat-form-field class="p-full-width">
                    <input matInput placeholder="First Name*" id="firstName" name="firstName"
                        formControlName="firstName" mask="S*">
                    <mat-error
                        *ngIf="addressInfoForm.controls.firstName.touched && addressInfoForm.controls.firstName.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.firstName.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.firstName.errors.pattern">
                            Please Enter Valid Name</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="p-form-row">
                <mat-form-field class="p-full-width">
                    <input matInput placeholder="Last Name" id="lastName" name="lastName" formControlName="lastName" mask="S*">
                    <mat-error
                        *ngIf="addressInfoForm.controls.lastName.touched && addressInfoForm.controls.lastName.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.lastName.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.lastName.errors.pattern">Please
                            Enter Valid Name</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row-box mb-12">
            <div class="p-form-row mr-18">
                <mat-form-field class="p-full-width">
                    <input matInput placeholder="Pin Code*" id="pinCode" name="pinCode" formControlName="pinCode"
                        (input)="onPincodeChangeCityState($event)" mask="999999">
                    <mat-error
                        *ngIf="addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.pinCode.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.pinCode.errors.invalid">Invalid
                            Pincode</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.pinCode.errors.isServiceable">
                            Area Not Serviceable</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="p-form-row">
                <mat-form-field class="p-full-width">
                
                    <input matInput placeholder="State*" id="state" name="state" formControlName="state">
                    <mat-error *ngIf="addressInfoForm.controls.state.touched && addressInfoForm.controls.state.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.state.errors.required">This
                            field is mandatory</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row-box mb-12">
            <div class="p-form-row exclusive-full-width">
                <mat-form-field class="p-full-width">
                    <input matInput placeholder="Address*" id="address" name="address" formControlName="address">
                    <mat-error
                        *ngIf="addressInfoForm.controls.address.touched && addressInfoForm.controls.address.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.address.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.address.errors.pattern">Invalid
                            Address</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row-box mb-12">
        
            <div class="p-form-row mr-18">
                <mat-form-field class="p-full-width">
                 
                    <input matInput placeholder="City*" id="city" name="city" formControlName="city">
                    <mat-error *ngIf="addressInfoForm.controls.city.touched && addressInfoForm.controls.city.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.city.errors.required">This
                            field is mandatory</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        
        <div class="p-form-row">
            <mat-form-field class="p-full-width">
                <input matInput placeholder="Mobile No.*" id="mobile" name="mobile" formControlName="mobile" mask="9999999999">
                <mat-error *ngIf="addressInfoForm.controls.mobile.touched && addressInfoForm.controls.mobile.invalid">
                    <mat-error class="error-message" *ngIf="addressInfoForm.controls.mobile.errors.required">This field is mandatory</mat-error>
                    <mat-error class="error-message" *ngIf="addressInfoForm.controls.mobile.errors.pattern">Please Enter Valid Number</mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        </div>
        <div class="form-row">
          
            <div class="form-column">
                <div class="radio-placeholder">Type of Address *</div>
                <mat-radio-group aria-label="Select an option" formControlName="addressType">
                    <mat-radio-button matInput id="home-address" name="addressType" value="1">Home</mat-radio-button>
                    <mat-radio-button matInput id="office-address" name="addressType" value="2">Office
                    </mat-radio-button>
                    <mat-error
                        *ngIf="addressInfoForm.controls.addressType.touched && addressInfoForm.controls.addressType.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.addressType.errors.required">
                            This field is mandatory</mat-error>
                    </mat-error>
                </mat-radio-group>
            </div>
            
        </div>
    </ng-container> -->
</ng-container>