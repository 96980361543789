import { Component, OnInit, OnDestroy } from "@angular/core";
// import { Router } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
// import { AuthService } from "../../services"; //SharedService
import { AppState } from "../../../app.state";
import { GetUserSettings } from "../../../order-placed/actions/order-placed.actions";
import { getUserSettings } from "../../../order-placed/reducers/selectors";
import { environment } from "@env/environment";
import { LocalStorageService } from "../../services/index";
import { SharedService } from "../../services/shared.service";
import { LocationStrategy } from "@angular/common";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  showLoader = false;
  userSettings: any;
  subscriptions$ = new Subscription();
  showGoBackButton: boolean;
  channelSlug: any;
  orderData: any;
  currentDomain: string;
  finalPoeDataApi: string;
  poeDataApi: string = "/api/v1/poe-setting";
  hostName: string;
  poeData: any;
  actionColour: string;
  isDesktopDevice: boolean;
  showHeaderInMobile: boolean;
  currentRoute: string = window.location.href;
  currentOrigin: string = window.location.origin + "/";
  constructor(
    // public router: Router,
    // public auth: AuthService,
    private store: Store<AppState>,
    private sharedService: SharedService,
    private location: LocationStrategy,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.isDesktopDevice = this.deviceService.isDesktop();
    // this.currentDomain = this.location["_platformLocation"]["location"][
    //   "origin"
    // ];

    this.currentDomain =
      "http://" + this.location["_platformLocation"]["location"]["host"];
    this.hostName = this.location["_platformLocation"]["location"]["hostname"]; //Current running domain
    this.hostName = this.hostName.substring(0, this.hostName.indexOf("."));
    // this.finalPoeDataApi = "http://test_test_ajeet98.auperator.net" + this.poeDataApi;
    this.finalPoeDataApi = this.currentDomain + this.poeDataApi;
    // this.subscriptions$ = this.store
    //   .select(state => state.orderPlaced.orderPlaced)
    //   .subscribe(result => {
    //     if (result) {
    //       this.orderData = result;
    //       LocalStorageService.set("orderTotal", this.orderData.orderTotal);
    //     }
    //   });
    // this.sharedService.getPoeData(this.finalPoeDataApi).subscribe(response => {
    //   if (response) {
    //     this.poeData = response;
    //     console.log(response);
    //     if (
    //       this.poeData.faviconUrl == undefined ||
    //       this.poeData.faviconUrl == null ||
    //       this.poeData.faviconUrl == "null" ||
    //       this.poeData.faviconUrl == ""
    //     ) {
    //       this.setFavicon("../../../../assets/images/Eshopbox Icon.svg");
    //     } else {
    //       this.setFavicon(this.poeData.faviconUrl);
    //     }
    //   }
    // });

    // this.sharedService.loaderListner.subscribe( showLoader => {
    //   this.showLoader = showLoader.status;
    // });

    //user settings
    this.store.dispatch(new GetUserSettings());
    this.subscriptions$.add(
      this.store.pipe(select(getUserSettings)).subscribe(result => {
        if (result) {
          this.userSettings = result;
          this.actionColour = this.userSettings.actionColor;
          this.sharedService.actionColour.next(this.actionColour);
          if (
            this.userSettings.faviconUrl == undefined ||
            this.userSettings.faviconUrl == null ||
            this.userSettings.faviconUrl == "null" ||
            this.userSettings.faviconUrl == ""
          ) {
            this.change_favicon("../../../../assets/images/Eshopbox Icon.svg");
          } else {
            this.change_favicon(this.userSettings.faviconUrl);
          }
        } else {
          this.userSettings = [];
        }
        this.showHeader();
      })
    );
    // this.store
    //   .select(state => state.orderPlaced.userSettings)
    //   .subscribe(result => {
    //     if (result) {
    //       this.userSettings = result;
    //       if (
    //         this.userSettings.faviconUrl == undefined ||
    //         this.userSettings.faviconUrl == null ||
    //         this.userSettings.faviconUrl == "null" ||
    //         this.userSettings.faviconUrl == ""
    //       ) {
    //         this.change_favicon("../../../../assets/images/Eshopbox Icon.svg");
    //       } else {
    //         this.change_favicon(this.userSettings.faviconUrl);
    //       }
    //     }
    //   });

    // if (localStorage.getItem("clientPortalToken")) this.showGoBackButton = true;
  }
  showHeader() {
    if (
      this.location["_platformLocation"]["location"]["pathname"].includes(
        "/track"
      ) ||
      this.currentRoute == this.currentOrigin
    ) {
      this.showHeaderInMobile = true;
    }
  }
  change_favicon(img) {
    let favicon = document.querySelector('link[rel="shortcut icon"]');
    if (!favicon) {
      favicon = document.createElement("link");
      favicon.setAttribute("rel", "shortcut icon");
      let head = document.querySelector("head");
      head.appendChild(favicon);
    }
    favicon.setAttribute("type", "image/png");
    favicon.setAttribute("href", img);
  }

  // open_ace_com(siteUrl) {
  //   window.open(siteUrl, "_blank");
  // }

  // goBackToClientPortal() {
  //   let clientAccountSlug = localStorage.getItem("clientAccountSlug");
  //   let clientPortalFilters = localStorage.getItem("clientPortalFilters");
  //   let lastViewedItem = localStorage.getItem("lastViewedItem");
  //   let key = "redirect";

  //   if (environment.prodEnv) {
  //     window.open(
  //       `https://${clientAccountSlug}.myeshopbox.com/order/list?${clientPortalFilters}` +
  //         "&lastViewedItem=" +
  //         lastViewedItem +
  //         "&key=" +
  //         key,
  //       "_self"
  //     );
  //   } else {
  //     window.open(
  //       `https://${clientAccountSlug}.auperator.co/order/list?${clientPortalFilters}` +
  //         "&lastViewedItem=" +
  //         lastViewedItem +
  //         "&key=" +
  //         key,
  //       "_self"
  //     );
  //   }
  // }

  /**@description apply favicon*/
  setFavicon(img) {
    let favicon = document.querySelector('link[rel="shortcut icon"]');
    if (!favicon) {
      favicon = document.createElement("link");
      favicon.setAttribute("rel", "shortcut icon");
      let head = document.querySelector("head");
      head.appendChild(favicon);
    }
    favicon.setAttribute("type", "image/png");
    favicon.setAttribute("href", img);
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  open_ace_com(siteUrl) {
    window.open(siteUrl, "_blank");
  }

  goBackToClientPortal() {
    let clientAccountSlug = localStorage.getItem("clientAccountSlug");
    let clientPortalFilters = localStorage.getItem("clientPortalFilters");
    let lastViewedItem = localStorage.getItem("lastViewedItem");
    let key = "redirect";

    if (environment.prodEnv) {
      window.open(
        `https://${clientAccountSlug}.myeshopbox.com/order/list?${clientPortalFilters}` +
          "&lastViewedItem=" +
          lastViewedItem +
          "&key=" +
          key,
        "_self"
      );
    } else {
      window.open(
        `https://${clientAccountSlug}.auperator.co/order/list?${clientPortalFilters}` +
          "&lastViewedItem=" +
          lastViewedItem +
          "&key=" +
          key,
        "_self"
      );
    }
  }
}
