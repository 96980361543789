import { Component, OnInit, forwardRef, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef, ViewChild, AfterViewChecked  } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, ValidationErrors, AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
// import { ValidationService } from '../../services/validation.service';
import * as global from "../../constants/constants";
import * as cityOptionData from "../../interceptors/mockdata/city.json";
import * as stateOptionData from "../../interceptors/mockdata/state.json";
import { CommonFormService, LocalStorageService, OrderReturnService } from "../../services";
import { ScrollingService } from '../../services/scrolling.service';
import { SharedService } from '../../services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-change-delivery-address',
  templateUrl: './change-delivery-address.component.html',
  styleUrls: ['./change-delivery-address.component.scss']
})
export class ChangeDeliveryAddressComponent implements OnInit, OnChanges,AfterViewChecked{
  @ViewChild('goUp') contentPage: ElementRef;
  // public myGroup: FormGroup;
  public cityData: any = cityOptionData;
  public stateData: any = stateOptionData;
  public showAddAddressForm: any = false;
  public currentCheckedValue: any;
  @Input() parentFormType: any;
  @Input() isCod: any;
  @Input() dynamicdata: any;
  @Input() addressFormValue: any;
  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() getExistingAddress = new EventEmitter;
  @Output() closeEditAddresspopup: EventEmitter<any> = new EventEmitter();
  @Output() editdetailspaydataevent: EventEmitter<any> = new EventEmitter();
  @Output() editAddrError: EventEmitter<any> = new EventEmitter();
  @Input() customerOrderNumber;
  @Input() orderData;
  @Input() poeSetting;
  ismobile :boolean =false
  
  addressInfoForm: FormGroup;
  userSettings: any;
  getColor: any;
  pickupAddress: { contactPhone: any; customerName: string; addressLine1: any; addressLine2: any; state: any; postalCode: any; city: any; notes:any};
  showPickupAddress: boolean;
  loading:any;
  selectedMessage: any;
  showPincode=true;
  getAddress: boolean;
  formLoader = false;
  orderId: any;
  orderItemId: any;
  editAddrToster: boolean = true;
  editdetails: any;
  lastnamecontion: any;
  // errormsg:boolean = true;
  islandmarkfocused:boolean = false;
  isAddressLine2:boolean = false;
  isAddressLine1:boolean = false;
  loadingPincode:boolean;
  errorhighscorePopup:boolean = false;
  pinCodeChecked: boolean = false;

  constructor(private preventScroll:ScrollingService, private fb: FormBuilder, private commonFormService: CommonFormService,private store: Store<any>,private sharedService:SharedService,
    private toastr: ToastrService, private route: ActivatedRoute, private router: Router, private orderReturnService : OrderReturnService,
    private deviceService: DeviceDetectorService,) { }

  ngAfterViewChecked(): void {
    this.ismobile = !this.deviceService.isDesktop();
    if(this.orderData != undefined && this.orderData.isPincodeServiceable == false  && !this.pinCodeChecked) {
      this.setServiceableError();
    } else {
      this.pinCodeChecked = true;
    }
  }

  
ngOnChanges(){

/*
comment all onchange code update address issue  
date 29-11-2023
*/

  // console.log("addressFormValue",this.orderData);
  // this.addressInfoForm = this.fb.group({
  //   firstName:null,
  //   lastName:null,
  //   pinCode: null,
  //   state: null,
  //   address:null,
  //   // town: null,
  //   city: null,
  //   mobile:null,
  //   email: null,
  //   oldAddress: null,
  //   addressType: null,
  //   addressLine2:null,
  //   landmark:null,
  //   notes:null
  // });

  if(this.addressFormValue){
    // this.addressInfoForm = this.fb.group({
    //     firstName:null,
    //     lastName:null,
    //     pinCode: null,
    //     state: null,
    //     address:null,
    //     // town: null,
    //     city: null,
    //     mobile:null,
    //     email: null,
    //     oldAddress: null,
    //     addressType: null,
    //     addressLine2:null,
    //     landmark:null,
    //     notes:null
    //   });
    // this.addressInfoForm.patchValue({
    //   firstName: this.addressFormValue.customerName.split(' ')[0],
    //   lastName: this.addressFormValue.customerName.split(' ')[1],
    //   pinCode: this.addressFormValue.postalCode,
    //   state: this.addressFormValue.state,
    //   city: this.addressFormValue.city,
    //   address: this.addressFormValue.addressLine1,
    //   email: this.addressFormValue.email,
    //   addressLine2: this.addressFormValue.addressLine2,
    //   notes:this.addressFormValue.notes,
    //   landmark: this.addressFormValue.landmark,
    //   mobile: this.addressFormValue.contactPhone,
    //   addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
    // });
  }
  
  // this.ismobile = !this.deviceService.isDesktop();
  //   if(this.orderData != undefined && this.orderData.isPincodeServiceable == false ) {
  //     this.setServiceableError();
  //   }
}

  ngOnInit() {
    this.editdetails = LocalStorageService.get('editdetails');
    this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
    this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';

    
    this.store.select(state => state.orderPlaced.userSettings).subscribe(result =>   {
      if (result) {
              this.userSettings = result;
             this.getColor=this.userSettings.actionColor;
      }
    }); 
    this.sharedService.currentMessage.subscribe(message =>
       (this.selectedMessage= message)); //<= Always get current value!
    if(this.selectedMessage=='hidePincode'){
      this.showPincode=false
    }
    else{
      this.showPincode=true

    }

    this.pickupAddress= LocalStorageService.get('pickupAddress')
    this.dynamicdata=false;
    this.addressInfoForm = this.fb.group({
      firstName:null,
      lastName:null,
      pinCode: null,
      state: null,
      address:null,
      // town: null,
      city: null,
      mobile:null,
      email: null,
      oldAddress: null,
      addressType: null,
      addressLine2:null,
      landmark:null,
      notes:null
    });
    
    // Emit the form group to the father to do whatever it wishes
    
    this.formReady.emit(this.addressInfoForm);
    // if (this.dynamicdata) {
      
      // this.checkLocalData();
      this.showHideAddressSection();
    // }

    if (this.parentFormType === 'return'){
       this.checkLocalData();
    }
    // this.ismobile = !this.deviceService.isDesktop();
    // if(this.orderData != undefined && this.orderData.isPincodeServiceable == false ) {
    //   this.setServiceableError();
    // }
    // debugger;
    
  }


  checkLocalData() {
    if (this.addressFormValue && !this.showPickupAddress) {
      const lname = this.addressFormValue.customerName
      if(lname.split(' ')[1]){
        this.lastnamecontion = lname.split(' ')[1]
      }if(lname.split(' ')[2]){
        this.lastnamecontion = lname.split(' ')[1] + ' ' + lname.split(' ')[2]
      }if(lname.split(' ')[3]){
        this.lastnamecontion = lname.split(' ')[1] + ' ' + lname.split(' ')[2] + ' ' + lname.split(' ')[3]
      }
      this.addressInfoForm.patchValue({
        firstName: this.addressFormValue.customerName.split(' ')[0],
        lastName: this.lastnamecontion,
        pinCode: this.addressFormValue.postalCode,
        state: this.addressFormValue.state,
        city: this.addressFormValue.city,
        address: this.addressFormValue.addressLine1,
        email: this.addressFormValue.email,
        addressLine2: this.addressFormValue.addressLine2,
        notes:this.addressFormValue.notes,
        landmark: this.addressFormValue.landmark,
        mobile: this.addressFormValue.contactPhone,
        addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
      });
    }





    if (this.pickupAddress && this.showPickupAddress) {
      this.addressInfoForm.patchValue({
        firstName: this.pickupAddress.customerName.split(' ')[0],
        lastName: this.pickupAddress.customerName.split(' ')[1],
        pinCode: this.pickupAddress.postalCode,
        state: this.pickupAddress.state,
        city: this.pickupAddress.city,
        address: this.pickupAddress.addressLine1,
        // landmark:this.addressFormValue.landmark,
        addressLine2: this.pickupAddress.addressLine2,
        mobile: this.pickupAddress.contactPhone,
        addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
      });
    }
  }

  showHideAddressSection(cond = false, checked = false) {


    this.getAddress=true;
    this.getExistingAddress.emit(this.getAddress);

    // this.preventScroll.disable();
    this.dynamicdata = cond;
    // if (cond === true){ 
      // this.addressInfoForm.controls.oldAddress.setValue('');
      // this.addressInfoForm.controls.oldAddress.setValidators(null);
      
      this.checkLocalData();
      this.addressInfoForm.controls.firstName.setValidators([Validators.required]);
      // this.addressInfoForm.controls.lastName.setValidators([Validators.required, Validators.pattern(global.REGX_NAME)]);
      this.addressInfoForm.controls.pinCode.setValidators([Validators.required, Validators.pattern(global.REGX_PINCODE)]);
      this.addressInfoForm.controls.state.setValidators([Validators.required]);
      this.addressInfoForm.controls.address.setValidators([Validators.required,Validators.pattern(global.REGX_ADDRESS_With_NUM)]); //Validators.pattern(global.REGX_ADDRESS)
      // this.addressInfoForm.controls.landmark.setValidators([Validators.required]); //Validators.pattern(global.REGX_ADDRESS)
      this.addressInfoForm.controls.addressLine2.setValidators([Validators.required]); //Validators.pattern(global.REGX_ADDRESS)

      // this.addressInfoForm.controls.town.setValidators([Validators.required]);  //Validators.pattern(global.REGX_ADDRESS)
      this.addressInfoForm.controls.city.setValidators([Validators.required]);
      this.addressInfoForm.controls.email.setValidators([]);
      // this.addressInfoForm.controls.email.setValidators([Validators.required, Validators.pattern(global.REGX_EMAIL)]);

      this.addressInfoForm.controls.mobile.setValidators([Validators.required, Validators.pattern(global.REGX_MOBILE_NEW)]);
      // this.addressInfoForm.controls.addressType.setValidators([Validators.required]);
    // } else{

      // if (!checked)
      // {
      //   this.addressInfoForm.controls.oldAddress.setValidators([Validators.required]);
      // }

    //   this.addressInfoForm.controls.firstName.setValidators(null);
    //   this.addressInfoForm.controls.email.setValidators(null);

    //   this.addressInfoForm.controls.lastName.setValidators(null);
    //   this.addressInfoForm.controls.pinCode.setValidators(null);
    //   this.addressInfoForm.controls.state.setValidators(null);
    //   this.addressInfoForm.controls.address.setValidators(null);
    //   this.addressInfoForm.controls.landmark.setValidators(null);
    //   this.addressInfoForm.controls.addressLine2.setValidators(null);

    //   // this.addressInfoForm.controls.town.setValidators(null);
    //   this.addressInfoForm.controls.mobile.setValidators(null);
    //   this.addressInfoForm.controls.city.setValidators(null);
    //   // this.addressInfoForm.controls.oldAddress.setValidators(null);
    //   this.addressInfoForm.controls.addressType.setValidators(null);
    // }   
  }
  closeEditAddress(){
    this.dynamicdata=false;
    // this.preventScroll.enable();
    this.preventScroll.disablescroll();
    this.closeEditAddresspopup.emit();
    if(document.getElementById('addrDivId')) {
      var myDiv = document.getElementById('addrDivId');
      myDiv.scrollTop = 0;
    }
    document.querySelector("body").classList.remove("stop-scrolling");
    if(this.addressFormValue){
      if(!this.deviceService.isDesktop()) {
        setTimeout(() => {
          this.addressInfoForm.patchValue({
            firstName: this.addressFormValue.customerName.split(' ')[0],
            lastName: this.addressFormValue.customerName.split(' ')[1],
            pinCode: this.addressFormValue.postalCode,
            state: this.addressFormValue.state,
            city: this.addressFormValue.city,
            address: this.addressFormValue.addressLine1,
            email: this.addressFormValue.email,
            addressLine2: this.addressFormValue.addressLine2,
            notes:this.addressFormValue.notes,
            landmark: this.addressFormValue.landmark,
            mobile: this.addressFormValue.contactPhone,
            addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
          });   
        }, 350);
      } else {
        this.addressInfoForm.patchValue({
          firstName: this.addressFormValue.customerName.split(' ')[0],
          lastName: this.addressFormValue.customerName.split(' ')[1],
          pinCode: this.addressFormValue.postalCode,
          state: this.addressFormValue.state,
          city: this.addressFormValue.city,
          address: this.addressFormValue.addressLine1,
          email: this.addressFormValue.email,
          addressLine2: this.addressFormValue.addressLine2,
          notes:this.addressFormValue.notes,
          landmark: this.addressFormValue.landmark,
          mobile: this.addressFormValue.contactPhone,
          addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
        });
      }
      
    }
  }

/**
    * City State Data using pincode
    * @method onPincodeChangeCityState
*/
 onPincodeChangeCityState(event){
  if (event.currentTarget.value.length === 6) {
    this.loadingPincode = true;
    const params = {
      "deliveryType": "reverse",
      "pickup_pincode" : event.currentTarget.value
    }
    this.commonFormService.getCityState1(params)
    .then(data => {
       if (data && data.pincode && data.isServiceable){   
          this.loadingPincode = false;
          if(data.state && data.city) {
            this.pinCodeChecked = true;
            this.addressInfoForm.controls.pinCode.markAsTouched();
            this.addressInfoForm.controls.pinCode.setErrors(null);
            this.addressInfoForm.controls.state.setValue(data.state);
            this.addressInfoForm.controls.city.setValue(data.city);
          } else {
            this.setServiceableError();
          }
         

          //this condition will apply only in return process
          if (this.parentFormType === 'return')
          {
              if (this.isCod === 1) {
                  if ((!data.isReversePickup && data.isCod === 1) || (data.isReversePickup && data.isCod === 0)) {
                    this.setServiceableError();
                  }
              }else {
                if (!data.isReversePickup) {
                  this.setServiceableError();
                }
              }  
          }
       }else{
        this.loadingPincode = false;
        // if()
        this.setInvalidError(data);
        
       }
    }).catch(err => {
      this.loadingPincode = false;
        this.setInvalidError();
      //throw err;
    });
  }
  else{
    this.addressInfoForm.controls.city.setValue('');
  this.addressInfoForm.controls.state.setValue('');
  }

  // console.log(this.addressInfoForm,"address");
 }
 

 /*Pincode Invalid And Serviceable Error*/
 setInvalidError(data?){
  // if(data.isServiceable != undefined && data.isServiceable) { 
    this.addressInfoForm.controls.pinCode.setErrors({isServiceable: true}) 
  // } else {
    // this.addressInfoForm.controls.pinCode.setErrors({invalid: true});
  // }
    this.addressInfoForm.controls.pinCode.markAsTouched();
    this.addressInfoForm.controls.city.setValue('');
    this.addressInfoForm.controls.state.setValue('');
 }

 setServiceableError(){
    this.addressInfoForm.controls.pinCode.markAsTouched();
    this.addressInfoForm.controls.pinCode.setErrors({isServiceable: true});
 }


 onSubmit(){
  if(this.editdetails){
    const editdetails = { 
      "externalChannelID": this.orderData.externalChannelID,
      "customerOrderNumber": this.customerOrderNumber,
      "actor": localStorage.getItem("clientPortalemail"),
      'actorType': localStorage.getItem("clientPortalemail") ? 'seller': 'buyer',
      "externalShipmentIds": this.orderData.externalShipmentIds,
      "orderId": this.orderData.orderId,
      "accountSlug" : this.orderData.accountSlug,
      "isRiskScoreRecalculate":true,
      "shippingDetails": {
        customerName:this.addressInfoForm.controls.firstName.value + ' ' +(this.addressInfoForm.controls.lastName.value || ''),
        email:this.addressInfoForm.controls.email.value,
        postalCode:this.addressInfoForm.controls.pinCode.value,
        city: this.addressInfoForm.controls.city.value,
        state: this.addressInfoForm.controls.state.value,
        addressLine1: this.addressInfoForm.controls.address.value,
        addressLine2: this.addressInfoForm.controls.addressLine2.value,
        contactPhone: this.addressInfoForm.controls.mobile.value,
        landmark: this.addressInfoForm.controls.landmark.value?this.addressInfoForm.controls.landmark.value:'',      
      },
      "items":this.orderData.items
    }
    LocalStorageService.set('editdetailspayload', editdetails);
    if (editdetails) {
      LocalStorageService.set('actionType', 'editdetailsaction');
      this.formLoader = true;
      this.orderReturnService.deliveryDetailsApiRequest(editdetails).then(data => {
        if(data){
          LocalStorageService.set('editdetailspaydata', true);
          this.formLoader = false;
          if(data.riskScore == undefined) {
            this.closeEditAddresspopup.emit();
            this.editdetailspaydataevent.emit();
            this.router.navigate([global.ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
          } else {
            if(data.riskScore.toLowerCase() == 'high' && data.riskScoreReasons.includes('Address issue')) {
              this.errorhighscorePopup = true;
              this.contentPage.nativeElement.scrollIntoView();
            } else {
              this.orderData.riskScore = data.riskScore;
              this.orderData.riskScoreReasons = data.riskScoreReasons;
              this.closeEditAddresspopup.emit();
              this.editdetailspaydataevent.emit();
              this.router.navigate([global.ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
            }
          }
          // data['riskScore'] = 'high',
          // data['riskScoreReasons'] = ['Address issue'];
          
        }
      }).catch(err => {
        if(err && err.status != 400) {
          // LocalStorageService.set('isEditAddrErr', 'true');
          this.editAddrError.emit();
        }
        this.formLoader = false;
        // this.router.navigate([global.ROUTES.OTP_CONFIRMATION, this.orderId, this.orderItemId])
      });
    }
    return;
  }
 
  let getobj = LocalStorageService.get('failedDeliveryFormRequest');
  // console.log("getobj",getobj)
  const formReqObject = {
      actionSource: getobj.actionSource,
      actor:getobj.actor,
      actorType: getobj.actorType,
      customerOrderNumber: getobj.customerOrderNumber,
      resolutionCode: getobj.resolutionCode,
      orderItemID: this.orderItemId,
      trackingId: getobj.trackingId,
      deferredDate: getobj.deferredDate,
      shippingDetails : {},
    contactPhoneNumber:this.addressInfoForm.controls.mobile.value,
    timeline: getobj.timeline,
    remarks: getobj.remarks
  }
  const ReqObject = {
    customerOrderNumber: getobj.customerOrderNumber,
    resolutionCode: getobj.resolutionCode,
    orderItemID: this.orderItemId,
    trackingId: getobj.trackingId,
    deferredDate: getobj.deferredDate,
    shippingDetails: {
      name:this.addressInfoForm.controls.firstName.value + ' ' +(this.addressInfoForm.controls.lastName.value || ''),
      email:this.addressInfoForm.controls.email.value,
      currentAddress:getobj.shippingDetails.currentAddress,
      updatedAddress:  this.addressInfoForm.controls.address.value + 
      (this.addressInfoForm.controls.addressLine2.value? ', ' + this.addressInfoForm.controls.addressLine2.value:'')
      + ', ' +this.addressInfoForm.controls.city.value
                        + ', ' + this.addressInfoForm.controls.state.value+ ', ' + this.addressInfoForm.controls.pinCode.value ,                          
      landmark: this.addressInfoForm.controls.landmark.value?this.addressInfoForm.controls.landmark.value:'',
      notes:this.addressInfoForm.controls.notes.value?this.addressInfoForm.controls.notes.value:'',
  },
  contactPhoneNumber:this.addressInfoForm.controls.mobile.value,
  timeline: getobj.timeline,
  remarks: getobj.remarks
}
  const formValue = [];
    Object.keys(this.addressInfoForm.controls).map((key) => {
      const parsedValue = {
      [key]: this.addressInfoForm.get(key).value, // key is the actual form control name
      changed: this.addressInfoForm.get(key).dirty // added changed key to identify value change
      }
    formValue.push(parsedValue)
  })
  var changed = false
  formValue.forEach(d=>{ 
    if(d.changed){
      changed= true; 
    }
  })
  if(changed){
    formReqObject['shippingDetails'] = {}
    formReqObject.shippingDetails['name'] = this.addressInfoForm.controls.firstName.value + ' ' +(this.addressInfoForm.controls.lastName.value || '')
    formReqObject.shippingDetails['email'] = this.addressInfoForm.controls.email.value,
    formReqObject.shippingDetails['currentAddress'] = getobj.shippingDetails.currentAddress,
    formReqObject.shippingDetails['updatedAddress'] =   this.addressInfoForm.controls.address.value + 
    (this.addressInfoForm.controls.addressLine2.value? ', ' + this.addressInfoForm.controls.addressLine2.value:'')
    + ', ' +this.addressInfoForm.controls.city.value
                      + ', ' + this.addressInfoForm.controls.state.value+ '- ' + this.addressInfoForm.controls.pinCode.value ,                          
    formReqObject.shippingDetails['landmark']= this.addressInfoForm.controls.landmark.value?this.addressInfoForm.controls.landmark.value:'',
    formReqObject.shippingDetails['notes'] = this.addressInfoForm.controls.notes.value?this.addressInfoForm.controls.notes.value:''   
  } else {
    delete formReqObject.shippingDetails
    delete formReqObject.contactPhoneNumber
  }

  LocalStorageService.set('actionType', 'failedDeliveryProcess');
  LocalStorageService.set('failedDeliveryFormRequest', ReqObject);
  LocalStorageService.set('failedDeliveryFormReq', formReqObject);
  LocalStorageService.set('resolutionCode', getobj.resolutionCode);
  LocalStorageService.set('resolutionCodeContent', getobj.resolutionCode);
  LocalStorageService.flush("defaultSelected");
  // console.log("formReqObject",formReqObject)
if (formReqObject) {
  this.formLoader = true;
  this.orderReturnService.failedDeliveryApiRequest(formReqObject).then(data => {
    localStorage.removeItem("action")
    localStorage.removeItem("shipmentId")
    console.log("data---",data)
    this.formLoader = false;
    LocalStorageService.set('SuccessResponseData', data);
    LocalStorageService.flush('failedDeliveryFormRequest');
    LocalStorageService.flush('failedDeliveryFormReq');
    LocalStorageService.set('failedDeliverTostermsg', true);
    LocalStorageService.set('otpVerified', 'true');
    localStorage.removeItem("actionSource")
    this.router.navigate([global.ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
  }).catch(err => {
    if(err && err.status != 400) {
      LocalStorageService.set('isEditAddrErr', 'true');
      this.editAddrError.emit();
    }
    
    this.formLoader = false;
    // this.router.navigate([global.ROUTES.OTP_CONFIRMATION, this.orderId, this.orderItemId])
  });
}

// console.log("pickupAddress",formReqObject);

 
  }

  func(e,ev) {
    // console.log(e,ev,"ev");
  }
 
}
