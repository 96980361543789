import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { select, Store } from "@ngrx/store";
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ROUTES } from "../shared/constants/constants";
import { AlertService, LocalStorageService } from '../shared/services/index';
import { OrderReturnService } from "../shared/services/order-return.service";
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SharedService } from '../shared/services/shared.service';
import { DecimalPipe } from '@angular/common';
import { ScrollingService } from '../shared/services/scrolling.service';
@Component({
  selector: 'app-order-return-cancel',
  templateUrl: './order-return-cancel.component.html',
  styleUrls: ['./order-return-cancel.component.scss'],
  providers: [DecimalPipe]
})
export class OrderReturnCancelComponent implements OnInit {
  @ViewChild('scroll') private scrollElement: ElementRef;
  isBoxshadow:boolean=false;
  scrollTopPosition:number;
  leftPanelActive = false;
  public returnCancellationForm: FormGroup;
  public formControls: any;
  formLoader = false;
  orderId: any;
  orderItemId: any;
  userSettings: any;
  orderDetails: any;
  showLoader = true;
  returnCancelItemFormRequest = LocalStorageService.get('returnCancelItemFormRequest') ? LocalStorageService.get('returnCancelItemFormRequest') : '';
  selectedOrderItem = LocalStorageService.get('processOrderItemDetails') ? LocalStorageService.get('processOrderItemDetails') : '';
  isMobile: boolean;
  isTablet: boolean;
  public isHelpOpen = false;
  public isHelpOpendelay = false;
  footerKey: [string, unknown][];
  color: boolean;
  getColor: any;
  Helpcolor: boolean;
  selectedItemDetails: any;
  item: any;
  showFooter: boolean;
  ifarrowTop: boolean = true;
  ifarrowBottom: boolean;
  constructor(private preventScroll:ScrollingService,private fb: FormBuilder, private titleService: Title,
      private decimalPipe: DecimalPipe,private route: ActivatedRoute, private store: Store<any>, private router: Router,
       private orderReturnService : OrderReturnService, private deviceService: DeviceDetectorService,private sharedService:SharedService,
      private alertService: AlertService) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    // LocalStorageService.flush('returnCancelItemFormRequest');
    this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
    this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';

    /*Get user settings data from state*/
    this.store.select(state => state.orderPlaced.userSettings).subscribe(result =>   {
      if (result) {
              this.userSettings = result;
              this.footerKey = Object.entries(this.userSettings.footerLinks);        
             this.getColor=this.userSettings.actionColor;
              this.titleService.setTitle("Cancel Return");    
      }
    }); 

    /*Get Order Data*/
    this.store.select(state => state.orderPlaced.orderPlaced).subscribe(result =>   {
      if (result) {
              this.orderDetails = result; 
              this.showLoader = false;          
      }
    })

    this.initializeForm();
    this.returnCancellationForm.controls['returnCancelReason'].valueChanges
      .subscribe(() => {
        this.returnCancellationForm.controls['returnCancelReason'].markAsTouched();
      });
    
  }


  public onSelectShowOrderItemDetail(item) {
    this.item=item;   
  }


 
  /**
   * Method to define cancelItemForm info form group
   * @method initializeForm
   */
  private initializeForm() {
    this.returnCancellationForm = this.fb.group({
      returnCancelReason: ['', [Validators.required, Validators.maxLength(100)]]
    });
    this.formControls = this.returnCancellationForm.controls;
    // this.formControls.returnCancelReason= LocalStorageService.get('returnCancelReason')
    this.returnCancellationForm.controls.returnCancelReason.setValue(LocalStorageService.get('returnCancelReason'));

  }

  /**
   * form submission
   * @method onSubmit
   */
  public onSubmit(): void {
    if (this.returnCancellationForm.valid) { 
      this.formLoader = true;
      LocalStorageService.flush('returnCancelItemFormRequest');
      LocalStorageService.flush('actionType');
      LocalStorageService.set('returnCancelReason',this.formControls.returnCancelReason.value);


      // const formDataObject = {
      //       "returnTrackingID": this.selectedOrderItem.reverseTrackingTimeline.trackingID,
      //       "returnCancelReason": this.formControls.returnCancelReason.value,
      //       "customerOrderNumber": this.orderDetails.customerOrderNumber,
      //       "orderItemID": this.selectedOrderItem.orderItemID

      // };
      
      const formDataObject = {
        orderItemIdPk:this.selectedOrderItem.id,
        actor: localStorage.getItem("clientPortalemail") ? localStorage.getItem("clientPortalemail"): LocalStorageService.get('actorcutomername'),
        actorType: localStorage.getItem("clientPortalemail") ? 'seller': 'buyer',
        "returnTrackingID": this.selectedOrderItem.trackingID?this.selectedOrderItem.reverseTrackingTimeline.trackingID:this.item.reverseTrackingTimeline.trackingID,
        "remarks": this.formControls.returnCancelReason.value,
        "customerOrderNumber": this.orderDetails.customerOrderNumber,
        "orderItemID": this.selectedOrderItem.orderItemID?this.selectedOrderItem.orderItemID:this.item.orderItemID
  };  
      
      LocalStorageService.set('actionType', 'returnCancelProcess');
      LocalStorageService.set('returnCancelItemFormRequest', formDataObject);
       if (formDataObject) {
        this.orderReturnService.returnCancelItemApiRequest(formDataObject).then(data => {
          this.formLoader = false;
          LocalStorageService.set('SuccessResponseData', data);
          LocalStorageService.flush('returnCancelItemFormRequest');
          this.router.navigate([ROUTES.REQUEST_SUBMISSION, this.orderId, this.orderItemId]);
          this.returnCancellationForm.reset()
        }).catch(err => {
          this.formLoader = false;
          this.alertService.showError(err.error.message);
        });
      }
    }
  }



 


  setBackToOrder(){
    this.sharedService.setOpen(true);
    LocalStorageService.flush('returnCancelItemFormRequest');
    LocalStorageService.flush('returnCancelReason');
    localStorage.setItem('foo', 'no reload') 
    this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
  }

  showLeftPanel(){
    this.leftPanelActive = !this.leftPanelActive;
  }

  displayLeftPanel(value){
    this.leftPanelActive = value;
  }

  onScroll(){
    this.scrollTopPosition = this.scrollElement.nativeElement.scrollTop;
    if( this.scrollTopPosition > 0 ){
      this.isBoxshadow = true;
    }
    else{
      this.isBoxshadow = false;
    }
  }


// Help Modal
openHelpModal(){
  this.preventScroll.disable();
  this.isHelpOpen = !this.isHelpOpen;  
  this.isHelpOpendelay = !this.isHelpOpendelay;
  document.querySelector("body").classList.add("stop-scrolling");
}

closeHelpModal() {
  // this.preventScroll.enable();
  this.isHelpOpen = false;
  if(!this.deviceService.isDesktop()) {
    setTimeout(() => {
      this.isHelpOpendelay = false;
    }, 350);
  } else this.isHelpOpendelay = false;

  document.querySelector("body").classList.remove("stop-scrolling");
}

open_ace_com(siteUrl) {
  window.open(siteUrl, '_blank');
}
openSite(siteUrl){
  window.open(siteUrl, '_blank');

}
changeColor(value){
  if(value){
    this.color=true;
  }
  else{
    this.color=false;

  }
}
mouseEnter(value){
  this.Helpcolor=true;
}

mouseLeave(value){
 this.Helpcolor=false;

}

transformDecimal(num) {
  return this.decimalPipe.transform(num, '2.');
}
showFooterClick(){
  this.showFooter = true;
  this.ifarrowTop = false;
  this.ifarrowBottom = true;
}
hideFooterClick(){
  this.ifarrowTop = true;
  this.ifarrowBottom = false;
  this.showFooter = false;
}
}
