import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Observable } from "rxjs";
import { Subject } from "rxjs/Subject";

@Injectable({
  providedIn: "root"
})
export class AlertService {
  private alertMessageSubject = new Subject<{
    show: boolean;
    type: string;
    content: string;
  }>();
  alertMessageState = this.alertMessageSubject.asObservable();

  private confirmationSource = new Subject<{
    title: string;
    subtitle: string;
    key: string;
  }>();
  confirmationListner = this.confirmationSource.asObservable();

  confirmationAcceptReject = new Subject<{ status: boolean; key: string }>();
  confirmationAcceptRejectListner = this.confirmationAcceptReject.asObservable();
  openPopup$: Observable<any>;
  private openPopupSubject = new Subject<any>();
  constructor() {
    this.openPopup$ = this.openPopupSubject.asObservable();
  }

  showError(content: string) {
    this.alertMessageSubject.next({
      show: true,
      type: "error",
      content: content
    });
  }

  showSuccess(content: string) {
    this.alertMessageSubject.next({
      show: true,
      type: "success",
      content: content
    });
  }

  showInfo(content: string) {
    this.alertMessageSubject.next({
      show: true,
      type: "info",
      content: content
    });
  }

  showConfirmation(title: string, subtitle: string, key: string) {
    this.confirmationSource.next({
      title: title,
      subtitle: subtitle,
      key: key
    });
  }

  acceptReject(status: boolean, key: string) {
    this.confirmationAcceptReject.next({ status: status, key: key });
  }

  findShowMessage(data: any) {
    if (data.messages.length > 0) {
      for (const error in data.messages[0]) {
        if (data.messages[0].hasOwnProperty(error)) {
          this.showError(data.messages[0][error]);
          break;
        }
      }
    } else {
      this.showError(data.message);
    }
  }

  hideMessage() {
    this.alertMessageSubject.next({ show: false, type: "", content: "" });
  }

  openAddressPopup(data) {
    this.openPopupSubject.next(data);
  } 
}
