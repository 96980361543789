"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Subject_1 = require("rxjs/Subject");
var BehaviorSubject_1 = require("rxjs/internal/BehaviorSubject");
var http_1 = require("@angular/common/http");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
// const client = filestack.init(environment.FILESTACK_APIKEY);
var SharedService = /** @class */ (function () {
    function SharedService(httpClient) {
        this.httpClient = httpClient;
        this.actionColour = new Subject_1.Subject();
        this.loaderSource = new Subject_1.Subject();
        this.loaderListner = this.loaderSource.asObservable();
        this.subject = new Subject_1.Subject();
        this.messageSource = new BehaviorSubject_1.BehaviorSubject("test");
        this.currentMessage = this.messageSource.asObservable();
    }
    SharedService.prototype.showHideLoader = function (status) {
        this.loaderSource.next({ status: status });
    };
    SharedService.prototype.setOpen = function (data) {
        this.isOpen = data;
    };
    SharedService.prototype.getData = function () {
        return this.isOpen;
    };
    SharedService.prototype.changeMessage = function (message) {
        this.messageSource.next(message);
    };
    // uploadDocument()
    // {
    //   return client
    //   .pick({
    //     maxFiles: 10,
    //     accept: [".pdf", ".jpg", ".xls", ".csv", ".xlsx", ".png", ".jpeg"],
    //     uploadInBackground: false,
    //     startUploadingWhenMaxFilesReached: true,
    //     fromSources: [
    //       "local_file_system",
    //       "imagesearch",
    //       "googledrive",
    //       "dropbox"
    //     ],
    //     onOpen: () => {},
    //     rejectOnCancel: true
    //     // storeTo: {
    //     //   location: "gcs",
    //     //   path: "/import_jobs/"
    //     // }
    //   })
    // }
    SharedService.prototype.getPoeData = function (apiUrl) {
        // const apiUrl = "http://test_test_ajeet98.auperator.net/api/v1/poe-settingV2";
        return this.httpClient.get(apiUrl);
    };
    SharedService.ngInjectableDef = i0.defineInjectable({ factory: function SharedService_Factory() { return new SharedService(i0.inject(i1.HttpClient)); }, token: SharedService, providedIn: "root" });
    return SharedService;
}());
exports.SharedService = SharedService;
