import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDialogModule, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMenuModule } from "@angular/material/menu";
import {
  MatInputModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatRadioModule
} from "@angular/material";
import { MatTabsModule } from "@angular/material/tabs";
import { MatPaginatorModule } from "@angular/material/paginator";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material";
import { MatSortModule } from "@angular/material/sort";
import { MatSelectModule, MatSelect } from "@angular/material/select";
import { MatChipsModule } from "@angular/material/chips";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSortModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatPaginatorModule,
    MatTabsModule,
    MatDialogModule,
    MatRadioModule,
    MatProgressSpinnerModule
  ],
  exports: [
    MatDatepickerModule,
    MatChipsModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatProgressSpinnerModule
  ],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: [] }]
})
export class AppMaterialModule {}
