"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var router_2 = require("@angular/router");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var ngx_device_detector_1 = require("ngx-device-detector");
var services_1 = require("../../services");
// import { AppState } from 'src/app/app.state';
// import { AppState } from '../app.state';
var NoticeMessageHeaderComponent = /** @class */ (function () {
    function NoticeMessageHeaderComponent(router, store, alertService, route, titleService, deviceService) {
        this.router = router;
        this.store = store;
        this.alertService = alertService;
        this.route = route;
        this.titleService = titleService;
        this.deviceService = deviceService;
        this.userSettingssubscriptions$ = new rxjs_1.Subscription();
    }
    NoticeMessageHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        var urlParams = new URLSearchParams(window.location.search);
        var queryParams = {
            'action': urlParams.get('action'),
            'shipmentId': urlParams.get('s'),
            'orderIdURL': urlParams.get('o'),
            'authToken': urlParams.get('authToken'),
            'email': urlParams.get('email'),
            'proxyHost': urlParams.get('proxyHost'),
            'key': urlParams.get('key'),
            'orderItemId': urlParams.get('orderItemId'),
            'filters': urlParams.get('filters'),
            'lastViewedItem': urlParams.get('lastViewedItem'),
            'isDeliveryFailed': urlParams.get('isDeliveryFailed'),
            'isCancelPickup': urlParams.get('isCancelPickup'),
            'isCancelItem': urlParams.get('isCancelItem'),
            'isReturnInitiated': urlParams.get('isReturnInitiated'),
            'actionSource': urlParams.get('m'),
        };
        console.log("email", urlParams.get('email'));
        console.log("urlParams", queryParams);
        if (queryParams.key === 'redirect') {
            localStorage.setItem("clientPortalemail", queryParams.email);
            localStorage.setItem("clientPortalToken", queryParams.authToken);
            localStorage.setItem("clientAccountSlug", queryParams.proxyHost);
            localStorage.setItem("clientPortalFilters", decodeURIComponent(queryParams.filters));
            localStorage.setItem("lastViewedItem", queryParams.lastViewedItem);
        }
        if (queryParams.action == 'ua') {
            localStorage.setItem("action", queryParams.action);
            localStorage.setItem('shipmentId', queryParams.shipmentId);
            localStorage.setItem('orderIdURL', queryParams.orderIdURL);
        }
        else {
            localStorage.removeItem("action");
            localStorage.removeItem('shipmentId');
            localStorage.removeItem('orderIdURL');
        }
        if (queryParams.actionSource) {
            localStorage.setItem("actionSource", queryParams.actionSource);
        }
        /*Get user settings data from state*/
        this.userSettingssubscriptions$ = this.store.select(function (state) { return state.orderPlaced.userSettings; }).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                _this.color = _this.userSettings.noticeBackgroundColor;
                // this.titleService.setTitle(this.userSettings.pageTitle.track_order);    
                if (_this.userSettings.noticeMessage != 'null') {
                    var body = document.getElementsByTagName('body')[0];
                    body.classList.add('noticeMessage');
                }
            }
        });
        if (localStorage.getItem("clientPortalToken"))
            this.showStrip = true;
        if (this.showStrip) {
            var body = document.getElementsByTagName('body')[0];
            body.classList.add('showstrip-black');
        }
    };
    return NoticeMessageHeaderComponent;
}());
exports.NoticeMessageHeaderComponent = NoticeMessageHeaderComponent;
