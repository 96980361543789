import { Injectable } from "@angular/core";
import { Action, select, Store } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  GetReturnReason,
  OrderReturnActionTypes
} from "../actions/order-return.actions";
import { OrderReturnService } from "../../shared/services";

@Injectable()
export class OrderReturnEffects {
  @Effect()
  getReturnReason$: Observable<Action> = this.actions$.pipe(
    ofType<GetReturnReason>(OrderReturnActionTypes.GET_RETURN_REASON),
    mergeMap(action => {
      return this.orderReturnService.getReturnReason(action.payload).pipe(
        map(data => ({
          type: OrderReturnActionTypes.GET_RETURN_REASON_SUCCESS,
          payload: data
        })),
        catchError(() => of({ type: OrderReturnActionTypes.GET_RETURN_REASON_FAILED }))
      );
    })
  );

  constructor(
    private actions$: Actions,
    private orderReturnService: OrderReturnService,
    private store: Store<{ returnReason }>
  ) {}
}
