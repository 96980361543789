"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var alert_service_1 = require("@app/shared/services/alert.service");
var router_1 = require("@angular/router");
var router_2 = require("@angular/router");
var AlertComponent = /** @class */ (function () {
    function AlertComponent(alertService, route, router) {
        this.alertService = alertService;
        this.route = route;
        this.router = router;
        this.alertMessageCollection = [];
        this.showConfirmation = false;
        this.confirmationContent = {
            title: "",
            subTitle: "",
            key: ""
        };
    }
    AlertComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.alertService.alertMessageState.subscribe(function (newState) {
            _this.addMessage(newState.show, newState.type, newState.content);
        });
        this.subscription = this.alertService.confirmationListner.subscribe(function (data) {
            _this.showConfirmationPopup(data.title, data.subtitle, data.key);
        });
        this.route.queryParams.subscribe(function (params) {
            _this.picklistId = params["id"];
        });
        this.runMessageClear();
    };
    AlertComponent.prototype.trackByFn = function (index) {
        return index;
    };
    AlertComponent.prototype.addMessage = function (status, type, message) {
        this.alertMessageCollection.push({
            status: status === true ? "active" : "inactive",
            type: type,
            message: message
        });
        this.runMessageClear();
    };
    AlertComponent.prototype.removeMessage = function (index) {
        this.alertMessageCollection.splice(index, 1);
    };
    AlertComponent.prototype.runMessageClear = function () {
        var _this = this;
        var timer = window.setInterval(function () {
            if (_this.alertMessageCollection.length > 0)
                _this.alertMessageCollection.shift();
            else
                window.clearInterval(timer);
        }, 7000);
    };
    AlertComponent.prototype.showConfirmationPopup = function (title, subTitle, key) {
        this.showConfirmation = true;
        this.confirmationContent.title = title;
        this.confirmationContent.subTitle = subTitle;
        this.confirmationContent.key = key;
    };
    AlertComponent.prototype.accept = function () {
        this.showConfirmation = false;
        this.alertService.acceptReject(true, this.confirmationContent.key);
    };
    AlertComponent.prototype.reject = function () {
        this.showConfirmation = false;
        this.alertService.acceptReject(false, this.confirmationContent.key);
    };
    AlertComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return AlertComponent;
}());
exports.AlertComponent = AlertComponent;
