<div class="container" [class.left-panel-active]="leftPanelActive" [ngClass]="!showFooter ? '' : 'fix-page'">
    <div class="row">
        <div class="panel-left p_panel-left display-none">
            <app-order-sidebar (changeleftPanelValue)='displayLeftPanel($event)'  (selectedItemData)="onSelectShowOrderItemDetail($event)"></app-order-sidebar>
        </div>
        <div class="panel-right p_panel-right mobile-move">
            <form [formGroup]="returnCancellationForm">

                <div class="ordered-items">


                    <div class="item-list">
                  <div class="item-info">
                    <div class="item-name">
            
            <button class="close-track"  (click)=" setBackToOrder()"></button>
            
                     <span class="order-heading">Cancel Return</span>
                    <ng-container *ngIf="(userSettings?.supportPhone && userSettings?.supportPhone!='null') || (userSettings?.supportEmail && userSettings?.supportEmail!='null') || (userSettings?.supportUrl && userSettings?.supportUrl!='null')">
                        <a  (click)="openHelpModal()" (mouseover) ="mouseEnter('addColor') "  (mouseout) ="mouseLeave('removeColor')"  [ngStyle]="{color:Helpcolor?getColor:'#828282'} ">Help ?</a>
                    </ng-container>
                    
              
                    </div>
                    </div>
                    </div>
                    </div>
<!-- 
                <div class="left-panel-show" (click)="showLeftPanel()">
                    <figure><img src="assets/images/arrow-left.png" alt=""></figure>
                    <span> Order </span>
                </div> -->
                <div class="return-mob-wrp scroll-content" #scroll (scroll)="onScroll()" [class.box-shadowcss]="isBoxshadow">
                    <!-- <div class="courier-details">
                        <div class="courier-name"
                            *ngIf="selectedOrderItem && selectedOrderItem.reverseTrackingTimeline.courierName">
                            Courier Name: <span>{{ selectedOrderItem.reverseTrackingTimeline.courierName }}</span>
                        </div>
                        <div class="track-id"
                            *ngIf="selectedOrderItem && selectedOrderItem.reverseTrackingTimeline.trackingID">
                            Tracking ID: <span>{{ selectedOrderItem.reverseTrackingTimeline.trackingID}} </span>
                        </div>
                    </div> -->

                    <div class="product-detail-mob" *ngIf="selectedOrderItem">
                        <div class="prod-img">
                            <img src="{{ selectedOrderItem && selectedOrderItem.productImageUrl ? selectedOrderItem.productImageUrl:'assets/images/item-image.jpg' }}" alt="product image">
                          </div>
                          <div class="prod-info">
                              <h4>{{ selectedOrderItem ? selectedOrderItem.productName:''}}</h4>
                              <p>{{rupeeSymbol}} <span> {{ selectedOrderItem ? transformDecimal(selectedOrderItem.customerPrice):''}}</span>
                                <!-- <span class="size-class" *ngIf="selectedOrderItem.productSize">{{selectedOrderItem ? selectedOrderItem.productSize:''}}</span> -->
                                <span  *ngIf="selectedOrderItem.productSize">• Size {{selectedOrderItem ? selectedOrderItem.productSize:''}}</span>

                            </p>
                          </div>
                      </div>
                    <div>

                        <!-- Bank Details Start -->
                        <h5 class="reason-heading">Please let us know why do you wish to cancel?</h5>
                        <!-- <div class="form-row">
                        <div class="form-column form-column-third">
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="Reason for cancellation*" name="returnCancelReason" id="returnCancelReason" formControlName="returnCancelReason" autocomplete="off">
                                <mat-error *ngIf="formControls.returnCancelReason.touched && formControls.returnCancelReason.invalid">
                                        <mat-error class="error-message" *ngIf="formControls.returnCancelReason.errors.required">This field is mandatory</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div> -->
                        <div class="form-row-box mb-30">
                            
                                <div class="p-form-row left-form-field">
                                    <mat-form-field class="p-full-width" [ngStyle]="{border:(formControls.returnCancelReason.touched && formControls.returnCancelReason.invalid)?'1px solid #EB5757':''}">
                                        <textarea matInput placeholder="Tell us the reason" name="returnCancelReason"
                                            id="returnCancelReason" formControlName="returnCancelReason" (input)='changeColor(val.value)'
                                            autocomplete="off" [class.input-error-css]="formControls.returnCancelReason.touched && formControls.returnCancelReason.invalid"  #val ></textarea>
                                        <mat-error
                                            *ngIf="formControls.returnCancelReason.touched && formControls.returnCancelReason.invalid">
                                            <mat-error class="error-message"
                                                *ngIf="formControls.returnCancelReason.errors.required">This field is
                                                mandatory
                                            </mat-error>
                                            <mat-error class="error-message"
                                                *ngIf="formControls.returnCancelReason.errors.maxlength">
                                                Remarks can be at most 100 characters
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                           
                            <!-- <div class="p-form-row mt-20" >
                                <div>
                                  <mat-form-field class="p-full-width" style="line-height: 19px;">
                                    <label class="p-custom-label">Additional Remarks </label>
                                    <mat-select disableOptionCentering  matInput name="returnSubReason" id="returnSubReason" formControlName="returnSubReason"
                                      (selectionChange)="onChangeSubReason($event.value, true)">
                                      <mat-option *ngFor="let subreason of subReason" value="{{ subreason }}">
                                        {{ subreason }}
                                      </mat-option>
                                    </mat-select>
                                    <input matInput>
                            
                                    <mat-error *ngIf="
                                        formControls.returnSubReason.touched &&
                                        formControls.returnSubReason.invalid
                                      ">
                                      <mat-error class="error-message" *ngIf="formControls.returnSubReason.errors.required">This field is
                                        mandatory</mat-error>
                                    </mat-error>
                                  </mat-form-field>
                                </div>
                              </div> -->
                        </div>
                    </div>

                
                </div>
                <!-- <div class="mob-footer">
                    <p> Need further assistance? <a href="mailto:{{ (userSettings && userSettings.contactMailId) ? userSettings.contactMailId:'' }}"> Contact us </a> </p>
                    <app-mob-header></app-mob-header>
                </div> -->
                <div class="btn-bottom" [ngClass]="!showFooter ? '' : 'fix-bottom'">
                    <div class="btn-bottom-arrow">
                      <a class="arrow-top" (click)="showFooterClick()" *ngIf="ifarrowTop"><img src="https://ik.imagekit.io/2gwij97w0o/POE/arrow-top.svg" /></a>  
                      <a class="arrow-top" (click)="hideFooterClick()" *ngIf="ifarrowBottom"><img src="https://ik.imagekit.io/2gwij97w0o/POE/arrow-bottom.svg" /></a>
                    </div>
                    <input type="button" value="Cancel" class="btn-cancel" (click)="setBackToOrder()">
                    <ng-container *ngIf="formLoader;else continueButton">
                        <figure class="d-flex btn-loader btn-continue" [ngStyle]="{'background-color':getColor}">
                            <div class="loader-css"></div>
                        </figure>
                    </ng-container>
                    <ng-template #continueButton>
                        <input class="btn-continue " [ngStyle]="{background:returnCancellationForm.valid ? getColor:''} "    type="button" (click)="onSubmit()"
                            [disabled]="!returnCancellationForm.valid" value="Continue">
                    </ng-template>
                    
                </div>
            </form>
        </div>
    </div>
</div>


<div class="mob-footer" *ngIf="showFooter">
    <div class="footer-underline">
        <p>
            <ng-container *ngFor="let keys of footerKey;let i=index" >
                <a target="_blank"    (click)="open_ace_com(keys[1])">
                    {{keys[0]}}
                  </a>
                </ng-container>
         
        </p>
      
  
    </div>
  
    <div *ngIf="userSettings?.websiteUrl">
        <p class="second-para" >
            <a (click)="open_ace_com(userSettings?.websiteUrl)">
             <span class="mobile-store-link"> Go to store </span> </a>
  
        </p>
  </div>
  </div>


<div class="loader-layover" *ngIf="showLoader">
    <figure>
        <img [src]="(isTablet || isMobile) ? 'assets/images/mobile-loader.gif' :'assets/images/track-page-loader.gif'"
            alt="Loader">
    </figure>
</div>
<div class="poweredby-mobile" *ngIf="showFooter">
    <a href="https://www.eshopbox.com/" target="_blank">
        <p style="word-wrap: no-wrap;"><a href="https://www.eshopbox.com/" target="_blank">   <img src="https://ik.imagekit.io/2gwij97w0o/POE/eshopbox-monogram.svg" alt=""></a>Powered by Eshopbox</p>
    
    </a>     

</div>

<div class="help-box" (click)="closeHelpModal()" [class.helpShow]="isHelpOpendelay">
    </div>
    <div class="main-helpbox" [ngStyle]="{display:isHelpOpendelay ? 'block': ''} " [class.helpShow]="isHelpOpen">
        <div class="help-top-box">
            <h2>Help ?</h2>
            <button (click)="closeHelpModal()"></button>
                </div>
  
                <div class="help-bottom-box">
                    <div class="text-block" *ngIf="userSettings?.supportUrl && userSettings?.supportUrl!='null'">
                        <h1>Support URL</h1>
                        <a (click)="openSite(userSettings?.supportUrl)">{{userSettings?.supportUrl}}</a>
                    </div>
                    <div class="text-block" *ngIf="userSettings?.supportEmail && userSettings?.supportEmail!='null'">
                        <h1>Support email</h1>
                        <a href="mailto:{{ (userSettings && userSettings.supportEmail) ? userSettings.supportEmail:'' }}">{{userSettings?.supportEmail}}</a>
                    </div>
                    <div class="text-block" *ngIf="userSettings?.supportPhone && userSettings?.supportPhone!='null'">
                        <h1>Support phone number</h1>
                        <a  href="tel:{{userSettings?.supportPhone}}">{{userSettings?.supportPhone}}</a>
                    </div>
                </div>
    </div>
    
  