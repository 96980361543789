import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../app.state";
import * as moment from "moment";
import { NavigationEnd, Router } from "@angular/router";
import { LocalStorageService } from "../../services/local-storage.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  userSettings: any;
  showLoader = true;
  footerKey: [string, unknown][];
  getColor: any;
  Helpcolor: boolean;
  contentText: any;
  contentText1: any;
  value: any;
  url_color: boolean;
  currentRoute: string = window.location.href;
  currentOrigin:string= window.location.origin+'/';

  constructor(private store: Store<AppState>, private router: Router) {}

  currentYear: number = new Date().getFullYear();
  ngOnInit() {
    /*Get user settings data from state*/
    this.store
      .select(state => state.orderPlaced.userSettings)
      .subscribe(result => {
        if (result) {
          this.userSettings = result;
          if (this.userSettings.footerLinks) {
            this.footerKey = Object.entries(this.userSettings.footerLinks);
          }
          this.getColor = this.userSettings.actionColor;
        }
      });
  }
  open_ace_com(siteUrl) {
    siteUrl = siteUrl.replace('www.', 'https://')
    window.open(siteUrl, "_blank");
  }
  mouseEnter(value) {
    this.value = value;
  }

  mouseLeave(value) {
    this.Helpcolor = false;
    this.value = null;

    //  console.log(value);
  }
  imgSrc: string = "https://ik.imagekit.io/2gwij97w0o/POE/link-icon.svg";

  onMouseOver(): void {
    this.imgSrc = "https://ik.imagekit.io/2gwij97w0o/POE/link-icon-hover.svg";
  }

  onMouseOut(): void {
    this.imgSrc = "https://ik.imagekit.io/2gwij97w0o/POE/link-icon.svg";
  }
}
