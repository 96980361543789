"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var index_1 = require("../../services/index");
var store_1 = require("@ngrx/store");
var EDDComponent = /** @class */ (function () {
    function EDDComponent(store) {
        this.store = store;
        this.selectedItemDetails = index_1.LocalStorageService.get("processOrderItemDetails");
    }
    EDDComponent.prototype.ngOnInit = function () {
        var _this = this;
        for (var index = 0; index < this.selectedItemDetails.forwardTrackingTimeline.statusLog.length; index++) {
            if (this.selectedItemDetails.forwardTrackingTimeline.statusLog[index].status == 'out_for_delivery') {
                this.updatedAt = this.selectedItemDetails.forwardTrackingTimeline.statusLog[index].updatedAt;
            }
        }
        this.today = new Date();
        var dd = String(this.today.getDate()).padStart(2, '0');
        var mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = this.today.getFullYear();
        // this.currentDate = mm + '-' + dd + '-' + yyyy;
        this.currentDate = yyyy + '-' + mm + '-' + dd;
        this.store
            .select(function (state) { return state.orderPlaced.userSettings; })
            .subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
            }
        });
    };
    EDDComponent.prototype.seemore = function () {
        this.seemoretext = true;
    };
    EDDComponent.prototype.seeless = function () {
        this.seemoretext = false;
    };
    return EDDComponent;
}());
exports.EDDComponent = EDDComponent;
