"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var http_1 = require("@angular/common/http");
var api_service_1 = require("./api.service");
var store_1 = require("@ngrx/store");
var environment_1 = require("../../../environments/environment");
var router_1 = require("@angular/router");
var constants_1 = require("../constants/constants");
var actions_1 = require("../../actions");
var local_storage_service_1 = require("./local-storage.service");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var i1 = require("./api.service");
var i2 = require("@ngrx/store");
var i3 = require("@angular/router");
var AuthService = /** @class */ (function () {
    /**
     * Creates an instance of AuthService
     * @param platformId - platformId
     * @param api - HTTP service to call the APIS
     * @param store - Store
     * */
    function AuthService(platformId, api, store, router) {
        this.platformId = platformId;
        this.api = api;
        this.store = store;
        this.router = router;
        // private _Auth0 = new auth0.WebAuth({
        //   clientID: environment.auth.clientID,
        //   domain: environment.auth.domain,
        //   responseType: "token",
        //   redirectUri: environment.auth.redirect,
        //   scope: environment.auth.scope
        // });
        // private auth0 = new Auth0Lock(
        //   environment.auth.clientID,
        //   environment.auth.domain,
        //   {
        //     oidcConformant: true,
        //     autoclose: true,
        //     rememberLastLogin: false,
        //     allowSignUp: false,
        //     allowedConnections: ["Username-Password-Authentication"],
        //     auth: {
        //       autoParseHash: false,
        //       // redirectUrl: environment.production
        //       //   ? "https://" + window.location.hostname + "/callback"
        //       //   : "http://" + window.location.hostname + "4200/callback",
        //       redirectUrl: environment.auth.redirect,
        //       responseType: "token id_token",
        //       audience: environment.auth.audience,
        //       params: {
        //         scope: "openid profile email app_metadata"
        //       }
        //     },
        //     theme: {
        //       logo: "assets/images/logo2.png",
        //       primaryColor: "#31324F",
        //       displayName: "Sign in"
        //     },
        //     languageDictionary: {
        //       emailInputPlaceholder: "Email",
        //       title: "WMS",
        //       passwordInputPlaceholder: "Password"
        //     }
        //   }
        // );
        // Track whether or not to renew token
        this._authFlag = "isLoggedIn";
        this._onAuthFailureUrl = "";
        this._logoutUrl = "http://localhost:4200";
        this.headers = new http_1.HttpHeaders();
        // this._onAuthSuccessUrl =
        //   (window.location.hostname !== "warehouse.auperator.co" &&
        //     window.location.hostname !== "warehouse.eshopbox.com")
        //     ? ROUTES.WHOLESALE
        //     : ROUTES.INBOUND;
        //     console.log(this._onAuthSuccessUrl);
    }
    AuthService.prototype.handleAuthenticationWithHash = function () {
        //: Promise<any>
    };
    /*For generating token on getting encoded key in url*/
    AuthService.prototype.generateTokenWithEncodedKey = function (encodedKey, queryParams) {
        var _this = this;
        if (queryParams.action === 'ua') {
            localStorage.setItem("action", queryParams.action);
            localStorage.setItem('shipmentId', queryParams.shipmentId);
        }
        else {
            localStorage.removeItem("action");
            localStorage.removeItem("shipmentId");
        }
        setTimeout(function () {
            var decodedString = encodedKey.split("##");
            if (queryParams.isaction == "true") {
                localStorage.setItem("isReAttemptAction", queryParams.isReAttemptAction),
                    _this.router.navigate([
                        constants_1.ROUTES.ORDER_FAILED_DELIVERY,
                        decodedString[0],
                        queryParams.orderItemId
                    ]);
            }
        }, 2000);
        if (!local_storage_service_1.LocalStorageService.get("token")) {
            var decodedString_1 = encodedKey.split("##");
            if (queryParams.key === "redirect") {
                var params = { mobile: decodedString_1[2] };
                var AuthorizationToken = "Bearer " + queryParams.authToken;
                var headers = {
                    Authorization: AuthorizationToken,
                    accountSlug: queryParams.proxyHost,
                    ProxyHost: "http://test_test_ajeet98.com"
                };
                localStorage.setItem("customerOrderNumber", decodedString_1[0]);
                return this.api
                    .post(environment_1.environment.TOKEN_GENERATE_URL, params, headers)
                    .subscribe(function (res) {
                    if (res) {
                        console.log("queryParams savan", queryParams, res);
                        _this.setAuthToken(res);
                        _this.setTokenType(res);
                        if (queryParams.isDeliveryFailed == "true")
                            localStorage.setItem("isReAttemptAction", queryParams.isReAttemptAction),
                                _this.router.navigate([
                                    constants_1.ROUTES.ORDER_FAILED_DELIVERY,
                                    decodedString_1[0],
                                    queryParams.orderItemId
                                ]);
                        else if (queryParams.isCancelPickup == "true")
                            _this.router.navigate([
                                constants_1.ROUTES.ORDER_RETURN_CANCELLATION,
                                decodedString_1[0],
                                queryParams.orderItemId
                            ]);
                        else if (queryParams.isCancelItem == "true")
                            _this.router.navigate([
                                constants_1.ROUTES.ORDER_CANCEL,
                                decodedString_1[0],
                                queryParams.orderItemId
                            ]);
                        else if (queryParams.isReturnInitiated == "true")
                            _this.router.navigate([
                                constants_1.ROUTES.ORDER_REFUND_EXCHANGE,
                                decodedString_1[0],
                                queryParams.orderItemId
                            ]);
                        // this.router.navigate([ROUTES.ORDER_RETURN, decodedString[0], queryParams.orderItemId]);
                        else
                            _this.router.navigate([
                                constants_1.ROUTES.ORDER_PLACED,
                                decodedString_1[0],
                                queryParams.orderItemId
                            ]);
                    }
                });
            }
            else {
                if (decodedString_1[2]) {
                    var params = { mobile: decodedString_1[2] };
                    return this.api
                        .post(environment_1.environment.TOKEN_GENERATE_URL, params, "")
                        .subscribe(function (res) {
                        if (res) {
                            // console.log("savan surani",res)
                            _this.setAuthToken(res);
                            _this.setTokenType(res);
                            _this.router.navigate([
                                constants_1.ROUTES.ORDER_PLACED,
                                decodedString_1[0],
                                decodedString_1[1]
                            ]);
                            // return res;
                        }
                    });
                }
            }
        }
    };
    /*Send Otp*/
    AuthService.prototype.onSendOtp = function (mobile) {
        var _this = this;
        var params = { mobile: mobile };
        return this.api.post(environment_1.environment.LOGIN_URL, params, "").pipe(operators_1.map(function (res) {
            _this.setAuthToken(res);
            return res;
        }));
    };
    /*Login With Mobile*/
    AuthService.prototype.onLogin = function (otp) {
        var _this = this;
        var params = { otp: otp };
        return this.api
            .post(environment_1.environment.API_URL + "auth/otp/validate", params, "")
            .pipe(operators_1.map(function (res) {
            _this.setAuthToken(res);
            return res;
        }));
    };
    AuthService.prototype.getAuthToken = function () {
        if (common_1.isPlatformBrowser(this.platformId)) {
            return local_storage_service_1.LocalStorageService.get("token");
        }
        else {
            return null;
        }
    };
    AuthService.prototype.setAuthToken = function (tokenData) {
        return local_storage_service_1.LocalStorageService.set("token", tokenData.accessToken);
    };
    AuthService.prototype.isLoggedIn = function () {
        if (this.getAuthToken() == null) {
            return false;
        }
        return true;
    };
    Object.defineProperty(AuthService.prototype, "authSuccessUrl", {
        /**
         * Call the Login API and store the user in localStorage.
         * @param email - email of the user;
         * @param password - password of the user;
         * @returns user - User from the response of the API;
         */
        // login({ username, password }) {
        //   const params = { data: { username: username, password: password } };
        //   return this.api.post(apiUrl.login, params).pipe(
        //     map(user => {
        //       this.setAuthToken(JSON.stringify(user), "token");
        //       return user;
        //     })
        //   );
        // }
        get: function () {
            return this._onAuthSuccessUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "authFailureUrl", {
        get: function () {
            return this._onAuthFailureUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "authenticated", {
        get: function () {
            return JSON.parse(localStorage.getItem(this._authFlag));
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.resetAuthFlag = function () {
        localStorage.removeItem(this._authFlag);
    };
    AuthService.prototype.login = function () {
        // this.auth0.show();
    };
    AuthService.prototype.setAuth = function (authResult) {
        this._expiresAt = authResult.expiresIn * 1000 + Date.now();
        // Set flag in local storage stating this app is logged in
        localStorage.setItem(this._authFlag, JSON.stringify(true));
        localStorage.setItem("token", authResult.accessToken);
    };
    AuthService.prototype.logout = function () {
        // Set authentication status flag in local storage to false
        localStorage.clear();
        this.store.dispatch(new actions_1.Logout());
        // This does a refresh and redirects back to homepage
        // Make sure you have the logout URL in your Auth0
        // Dashboard Application settings in Allowed Logout URLs
        // this.router.navigate([ROUTES.LOGIN]);
    };
    AuthService.prototype.setTokenType = function (tokenData) {
        return local_storage_service_1.LocalStorageService.set("tokenType", tokenData.type);
    };
    AuthService.prototype.getTokenType = function () {
        if (common_1.isPlatformBrowser(this.platformId)) {
            return local_storage_service_1.LocalStorageService.get("tokenType");
        }
        else {
            return null;
        }
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i0.PLATFORM_ID), i0.inject(i1.ApiService), i0.inject(i2.Store), i0.inject(i3.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
exports.AuthService = AuthService;
