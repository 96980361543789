"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var store_1 = require("@ngrx/store");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var constants_1 = require("../shared/constants/constants");
var index_1 = require("../shared/services/index");
var order_return_service_1 = require("../shared/services/order-return.service");
var router_2 = require("@angular/router");
var common_1 = require("@angular/common");
var ngx_device_detector_1 = require("ngx-device-detector");
var OrderFailedPickupComponent = /** @class */ (function () {
    function OrderFailedPickupComponent(fb, titleService, route, store, router, orderReturnService, deviceService) {
        this.fb = fb;
        this.titleService = titleService;
        this.route = route;
        this.store = store;
        this.router = router;
        this.orderReturnService = orderReturnService;
        this.deviceService = deviceService;
        this.leftPanelActive = false;
        this.allResolutionCode = constants_1.RESOLUTION_CODE;
        this.formLoader = false;
        this.showLoader = true;
        this.resolutionFields = ['updatedAddress', 'deferredDate', 'remarks', 'contactPhoneNumber', 'landmark'];
        this.selectedOrderItem = index_1.LocalStorageService.get('processOrderItemDetails') ? index_1.LocalStorageService.get('processOrderItemDetails') : '';
        this.failedPickupFormRequest = index_1.LocalStorageService.get('failedPickupFormRequest') ? index_1.LocalStorageService.get('failedPickupFormRequest') : '';
        this.deferredDateArray = [];
    }
    OrderFailedPickupComponent.prototype.numberOnly = function (event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    };
    ;
    OrderFailedPickupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        index_1.LocalStorageService.flush('failedPickupFormRequest');
        this.currentDate = common_1.formatDate(new Date(), 'yyyy-MM-dd', 'en');
        this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
        this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';
        /*Get user settings data from state*/
        this.store.select(function (state) { return state.orderPlaced.userSettings; }).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                _this.titleService.setTitle(_this.userSettings.pageTitle.failed_pickup);
            }
        });
        /*Get Order Data*/
        this.store.select(function (state) { return state.orderPlaced.orderPlaced; }).subscribe(function (result) {
            if (result) {
                _this.orderDetails = result;
                _this.showLoader = false;
            }
        });
        this.initializeForm();
        this.checkLocalData();
    };
    /**
     * Method to define failedPickupForm info form group
     * @method initializeForm
     */
    OrderFailedPickupComponent.prototype.initializeForm = function () {
        this.failedPickupForm = this.fb.group({
            resolutionCode: ['', [forms_1.Validators.required]],
            updatedAddress: '',
            remarks: '',
            deferredDate: '',
            contactPhoneNumber: '',
            landmark: ''
        });
        this.formControls = this.failedPickupForm.controls;
    };
    OrderFailedPickupComponent.prototype.checkLocalData = function () {
        if (this.failedPickupFormRequest) {
            this.radioChange(this.failedPickupFormRequest.resolutionCode);
            this.failedPickupForm.patchValue({
                resolutionCode: this.failedPickupFormRequest.resolutionCode ? this.failedPickupFormRequest.resolutionCode : '',
                updatedAddress: this.failedPickupFormRequest.shippingDetails.updatedAddress ? this.failedPickupFormRequest.shippingDetails.updatedAddress : '',
                deferredDate: this.failedPickupFormRequest.deferredDate ? this.failedPickupFormRequest.deferredDate : '',
                remarks: this.failedPickupFormRequest.remarks ? this.failedPickupFormRequest.remarks : '',
                contactPhoneNumber: this.failedPickupFormRequest.contactPhoneNumber ? this.failedPickupFormRequest.contactPhoneNumber : '',
                landmark: this.failedPickupFormRequest.shippingDetails.landmark ? this.failedPickupFormRequest.shippingDetails.landmark : '',
            });
            if (this.failedPickupFormRequest.deferredDate) {
                this.radioSelected = this.failedPickupFormRequest.deferredDate;
            }
        }
    };
    OrderFailedPickupComponent.prototype.radioChange = function (resolutionCode) {
        this.resolutionValue = resolutionCode;
        if (resolutionCode === this.allResolutionCode.DIFFERENT_ADDRESS) {
            this.formControls.updatedAddress.setValidators([forms_1.Validators.required]);
            this.fieldsValidationSetNull('updatedAddress');
            this.radioSelected = '';
        }
        else if (resolutionCode === this.allResolutionCode.CONTACT_NUMBER) {
            this.formControls.contactPhoneNumber.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(constants_1.REGX_MOBILE)]);
            this.fieldsValidationSetNull('contactPhoneNumber');
            this.radioSelected = '';
        }
        else if (resolutionCode === this.allResolutionCode.FALSE_REASON) {
            this.formControls.remarks.setValidators([forms_1.Validators.required]);
            this.fieldsValidationSetNull('remarks');
            this.radioSelected = '';
        }
        else if (resolutionCode === this.allResolutionCode.LANDMARK) {
            this.formControls.landmark.setValidators([forms_1.Validators.required]);
            this.fieldsValidationSetNull('landmark');
            this.radioSelected = '';
        }
        else if (resolutionCode === this.allResolutionCode.DELIVERY_DATE) {
            var startDate = new Date(this.selectedOrderItem.statusUpdatedAt.replace(' ', 'T'));
            var newDate = startDate.getDate();
            if (startDate) {
                this.deferredDateArray = [];
                for (var i = 1; i <= 3; i++) {
                    var nxtDate = common_1.formatDate(startDate.setDate(newDate + i), 'yyyy-MM-dd', 'en');
                    this.deferredDateArray.push(nxtDate);
                }
            }
            this.formControls.deferredDate.setValidators([forms_1.Validators.required]);
            this.fieldsValidationSetNull('deferredDate');
        }
        else {
            this.fieldsValidationSetNull();
            this.radioSelected = '';
        }
    };
    OrderFailedPickupComponent.prototype.deferredDateChange = function (value) {
        this.radioSelected = value;
    };
    OrderFailedPickupComponent.prototype.fieldsValidationSetNull = function (currentElement) {
        var _this = this;
        if (currentElement === void 0) { currentElement = null; }
        this.resolutionFields.forEach(function (element) {
            if (element !== currentElement) {
                _this.formControls[element].setValidators(null);
                _this.formControls[element].markAsUntouched();
                _this.formControls[element].patchValue('');
                _this.formControls[element].setErrors(null);
            }
        });
    };
    /**
     * form submission
     * @method onSubmit
     */
    OrderFailedPickupComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.failedPickupForm.valid) {
            this.formLoader = true;
            index_1.LocalStorageService.flush('failedPickupFormRequest');
            index_1.LocalStorageService.flush('actionType');
            if (this.formControls.landmark.value) {
                this.completeAddress = this.orderDetails.shippingAddress.addressLine1 + ', ' + this.orderDetails.shippingAddress.addressLine2 + ', ' + this.orderDetails.shippingAddress.city + ', ' + this.orderDetails.shippingAddress.state + ', ' + this.orderDetails.shippingAddress.postalCode;
            }
            if (this.formControls.updatedAddress.value) {
                this.updatedAddress = this.formControls.updatedAddress.value + ', ' + this.orderDetails.shippingAddress.city + ', ' + this.orderDetails.shippingAddress.state + ', ' + this.orderDetails.shippingAddress.postalCode;
            }
            var formDataObject = {
                customerOrderNumber: this.orderDetails.customerOrderNumber,
                orderItemID: this.selectedOrderItem.orderItemID,
                resolutionCode: this.formControls.resolutionCode.value,
                trackingId: this.selectedOrderItem.reverseTrackingTimeline.trackingID,
                deferredDate: this.formControls.deferredDate.value ? this.formControls.deferredDate.value : '',
                shippingDetails: {
                    currentAddress: this.formControls.landmark.value ? this.completeAddress : '',
                    updatedAddress: this.formControls.updatedAddress.value ? this.updatedAddress : '',
                    landmark: this.formControls.landmark.value ? this.formControls.landmark.value : ''
                },
                contactPhoneNumber: this.formControls.contactPhoneNumber.value ? this.formControls.contactPhoneNumber.value : '',
                remarks: this.formControls.remarks.value ? this.formControls.remarks.value : '',
                timeline: this.selectedOrderItem.timeLine
            };
            index_1.LocalStorageService.set('actionType', 'failedPickupProcess');
            index_1.LocalStorageService.set('failedPickupFormRequest', formDataObject);
            if (formDataObject) {
                this.orderReturnService.failedPickupApiRequest(formDataObject).then(function (data) {
                    _this.formLoader = false;
                    index_1.LocalStorageService.set('SuccessResponseData', data);
                    index_1.LocalStorageService.flush('failedDeliveryFormRequest');
                    _this.router.navigate([constants_1.ROUTES.REQUEST_SUBMISSION, _this.orderId, _this.orderItemId]);
                }).catch(function (err) {
                    _this.formLoader = false;
                });
            }
        }
    };
    OrderFailedPickupComponent.prototype.setBackToOrder = function () {
        index_1.LocalStorageService.flush('failedPickupFormRequest');
        this.router.navigate([constants_1.ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
    };
    /*Left Panel Show Hide*/
    OrderFailedPickupComponent.prototype.showLeftPanel = function () {
        this.leftPanelActive = !this.leftPanelActive;
    };
    OrderFailedPickupComponent.prototype.displayLeftPanel = function (value) {
        this.leftPanelActive = value;
    };
    return OrderFailedPickupComponent;
}());
exports.OrderFailedPickupComponent = OrderFailedPickupComponent;
