// export const environment = {
//   production: true,
//   API_URL:
//     "https://eshopbox-wholesale-service-dot-eshopbox-2018.appspot.com/api/",
//   DASHBOARD_API_URL: "https://amazon.eshopbox.com/api/",
//   INBOUND_URL:
//     "https://backend-dot-eshopbox-portal-dev.appspot.com/_ah/api/esb/v1/wh/",
//   CDN_URL: "https://cdn.eshopbox.com/",
//   auth: {
//     cookie_domain: "https://wms-frontend-staging-231507.firebaseapp.com",
//     clientID: "oNO2EvstgenqKn69qR6wyDzbjeVgYOsE",
//     domain: "eshopbox.auth0.com", // e.g., you.auth0.com
//     redirect: "http://amzb2b.eshopbox.com/callback",
//     scope: "openid profile email",
//     audience: "https://wms.myeshopbox.com"
//   },
//   FILESTACK_APIKEY: "AQ2ZyOPLSRSmn5ETQx29dz"
// };

export const environment = {
  production: false,
  prodEnv: true,
  // API_URL: "https://poe-backend-service-staging-dot-poe-backend-staging.appspot.com/_ah/api/esb/v2/", //"https://warehouse.auperator.co/api/",
  //https://backend-dot-poe-backend-staging.appspot.com/_ah/api/esb/v2/userSetting
  //API_URL: "/api/v2/", //http://clarkspreprod.auperator.net
  //TOKEN_GENERATE_URL: "http://clarkspreprod.auperator.net/api/v1/auth/token/",
  //for localhost only
  //LOCALHOST_API_URL: "test-dot-poe-backend-staging.appspot.com/_ah/" //https://test-dot-poe-backend-staging.appspot.com/_ah/api/esb/v2/userSetting

  API_URL: "/api/v2/", //http://clarkspreprod.auperator.net
  USER_SETTING: "/api/v2/",
  // TOKEN_GENERATE_URL: "/api/v1/auth/token/",
  TOKEN_GENERATE_URL: "/api/v2/auth/token/",
  LOGIN_URL: "/api/v1/auth/token/login/",
  LOCALHOST_API_URL: "",
  PROTOCOL: "https://",
  PINCODE: "/api/v1/",
  MarkAsDelivered:
    "https://eshopbox-wms-api-dot-eshopbox-wms-staging.el.r.appspot.com"
};
