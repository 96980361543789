"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var SeeUpdateIconsComponent = /** @class */ (function () {
    function SeeUpdateIconsComponent() {
    }
    SeeUpdateIconsComponent.prototype.ngOnInit = function () { };
    /** @description Method used for return or reverse tracking */
    SeeUpdateIconsComponent.prototype.getStatus = function (status, timeline) {
        status = status.replace(/[0-9]/g, '');
        if (status == "created") {
            if (timeline == "forward") {
                return "Order placed";
            }
            else if (timeline == "reverse") {
                return "Return created";
            }
        }
        else if (status == "order_placed" && timeline == "forward") {
            return "Order placed";
        }
        else if (status == "accepted") {
            return "Order accepted";
        }
        else if (status == "manifested") {
            return "Order manifested";
        }
        else if (status == "packed") {
            return "Order packed";
        }
        else if ((status == "picked" || status == "picked_up") &&
            timeline == "forward") {
            return "Picked up";
        }
        else if (status == "shipped") {
            return "Order shipped";
        }
        else if (status == "ready_to_ship") {
            return "Order is ready to ship";
        }
        else if (status == "dispatched") {
            return "Order dispatched";
        }
        else if (status == "intransit") {
            return "Order intransit";
        }
        else if (status == "shipment_delayed") {
            return "Shipment has been delayed";
        }
        else if (status == "delivered") {
            return "Order delivered";
        }
        else if (status == "out_for_delivery") {
            return "Order is out for delivery";
        }
        else if (status == "rto" || status == "cancelled") {
            return "Order cancelled";
        }
        else if (status == "rto_intransit") {
            return "Return In-transit";
        }
        else if (status == "rto_created") {
            return "Return created";
        }
        else if (status == "rto_delivered_warehouse") {
            return "Return delivered to warehouse";
        }
        else if (status == "rto_out_for_delivery") {
            return "Return is out for delivery";
        }
        else if (status == "rto_shipment_delay") {
            return "Return shipment is delayed";
        }
        else if (status == "rto_requested") {
            return "Return requested";
        }
        else if (status == "not_serviceable") {
            return "Delivery not serviceable";
        }
        else if (status == "failed_delivery" || status == "delivery_failed") {
            if (status == "failed_delivery" && timeline == "forward") {
                return "Failed delivery attempt";
            }
            else if (status == "failed_delivery" && timeline == "reverse") {
                return "Failed pickup attempt";
            }
        }
        else if (status == "pickup_pending") {
            return "Pickup pending";
        }
        else if (status == "out_for_pickup") {
            return "Out for pickup";
        }
        else if (status == "order_placed" && timeline == "reverse") {
            return " Return Order placed";
        }
        else if (status == "approved") {
            return "Return approved";
        }
        else if ((status == "picked" || status == "picked_up") &&
            timeline == "reverse") {
            return "Return picked up";
        }
        else if (status == "pickup_cancelled") {
            return "Pickup failed";
        }
        else if (status == "return_cancelled") {
            return "Return cancelled";
        }
        else if (status == "delivered_warehouse" ||
            status == "warehouse_delivered") {
            return "Delivered warehouse";
        }
        else if (status == "cancelled_order") {
            return "Cancelled order";
        }
        else if (status == "ndr_resolution_submitted") {
            return "Delivery requested by customer";
        }
        else if (status == "rto_failed") {
            return "Return attempt failed";
        }
        else {
            return status;
        }
    };
    /** @description Method to remove numbers from status*/
    SeeUpdateIconsComponent.prototype.formatStatus = function (status) {
        return status.replace(/[0-9]/g, '');
    };
    return SeeUpdateIconsComponent;
}());
exports.SeeUpdateIconsComponent = SeeUpdateIconsComponent;
