"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var OrderReturnActionTypes;
(function (OrderReturnActionTypes) {
    OrderReturnActionTypes["GET_RETURN_REASON"] = "[Return Reason API] Get Return Reason";
    OrderReturnActionTypes["GET_RETURN_REASON_SUCCESS"] = "[Return Reason API] Get Return Reason Success";
    OrderReturnActionTypes["GET_RETURN_REASON_FAILED"] = "[Return Reason API] Get Return Reason Failed";
})(OrderReturnActionTypes = exports.OrderReturnActionTypes || (exports.OrderReturnActionTypes = {}));
var GetReturnReason = /** @class */ (function () {
    function GetReturnReason(payload) {
        this.payload = payload;
        this.type = OrderReturnActionTypes.GET_RETURN_REASON;
    }
    return GetReturnReason;
}());
exports.GetReturnReason = GetReturnReason;
var GetReturnReasonSuccess = /** @class */ (function () {
    function GetReturnReasonSuccess(payload) {
        this.payload = payload;
        this.type = OrderReturnActionTypes.GET_RETURN_REASON_SUCCESS;
    }
    return GetReturnReasonSuccess;
}());
exports.GetReturnReasonSuccess = GetReturnReasonSuccess;
var GetReturnReasonFailed = /** @class */ (function () {
    function GetReturnReasonFailed() {
        this.type = OrderReturnActionTypes.GET_RETURN_REASON_FAILED;
    }
    return GetReturnReasonFailed;
}());
exports.GetReturnReasonFailed = GetReturnReasonFailed;
