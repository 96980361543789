<div class="help-top-box">
  <h2>Help?</h2>
  <button (click)="closeHelp()"></button>
</div>

<div class="help-bottom-box">
  <div class="text-block" *ngIf="userSettings?.supportUrl && userSettings?.supportUrl!='null'">
      <h1>Support URL</h1>
      <a href="{{userSettings?.supportUrl}}" target="_blank">{{userSettings?.supportUrl}}</a>
  </div>
  <div class="text-block" *ngIf="userSettings?.supportEmail && userSettings?.supportEmail!='null'">
      <h1>Support email</h1>
      <a href="mailto:{{ (userSettings && userSettings.supportEmail) ? userSettings.supportEmail:'' }}">{{userSettings?.supportEmail}}</a>
  </div>
  <div class="text-block" *ngIf="userSettings?.supportPhone && userSettings?.supportPhone!='null'">
      <h1>Support phone number</h1>
      <a href="tel:{{userSettings?.supportPhone}}">{{userSettings?.supportPhone}}</a>
  </div>
</div>
