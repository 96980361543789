"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ReadMoreComponent = /** @class */ (function () {
    function ReadMoreComponent() {
    }
    ReadMoreComponent.prototype.ngOnInit = function () {
        // console.log(this.content);
        this.nonEditedContent = this.content;
        this.content = this.formatContent(this.content);
    };
    ReadMoreComponent.prototype.toggleContent = function () {
        this.isContentToggled = !this.isContentToggled;
        this.content = this.isContentToggled ? this.nonEditedContent : this.formatContent(this.content);
    };
    ReadMoreComponent.prototype.formatContent = function (content) {
        if (this.completeWords) {
            this.limit = content.substr(0, this.limit).lastIndexOf('');
        }
        return content.substr(0, this.limit) + "...";
    };
    return ReadMoreComponent;
}());
exports.ReadMoreComponent = ReadMoreComponent;
