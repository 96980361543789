import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from "../../services/index";
import { select, Store } from "@ngrx/store";


@Component({
  selector: 'app-edd',
  templateUrl: './edd.component.html',
  styleUrls: ['./edd.component.scss']
})
export class EDDComponent implements OnInit {
  selectedItemDetails=LocalStorageService.get("processOrderItemDetails");
  today: Date;
  currentDate: string;
  updatedAt: any;
  userSettings: any;
  seemoretext: boolean;

  constructor(private store: Store<any>) { }

  ngOnInit() {
    for (let index = 0; index < this.selectedItemDetails.forwardTrackingTimeline.statusLog.length; index++) {
      if(this.selectedItemDetails.forwardTrackingTimeline.statusLog[index].status=='out_for_delivery'){
        this.updatedAt = this.selectedItemDetails.forwardTrackingTimeline.statusLog[index].updatedAt;
      }
      
    }
    this.today = new Date();
    var dd = String(this.today. getDate()). padStart(2, '0');
    var mm = String(this.today. getMonth() + 1). padStart(2, '0'); //January is 0!
    var yyyy = this.today. getFullYear();
​
// this.currentDate = mm + '-' + dd + '-' + yyyy;
this.currentDate = yyyy + '-' + mm + '-' + dd;

this.store
.select(state => state.orderPlaced.userSettings)
.subscribe(result => {
  if (result) {
    this.userSettings = result;
  }
});    
  }
 

seemore(){
  this.seemoretext = true;
}
seeless(){
  this.seemoretext = false;
}

}
