import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-information-popover',
  templateUrl: './information-popover.component.html',
  styleUrls: ['./information-popover.component.scss']
})
export class InformationPopoverComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  showEditDetails(){
    
  }

}
