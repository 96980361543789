import { Component, OnInit, OnDestroy} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import { AuthService } from "../../services"; //SharedService
import { AppState } from '../../../app.state';
import { GetUserSettings } from "../../../order-placed/actions/order-placed.actions";
import { getUserSettings } from "../../../order-placed/reducers/selectors";

@Component({
  selector: "app-mob-header",
  templateUrl: "./mob-header.component.html",
  styleUrls: ["./mob-header.component.sass"]
})
export class MobHeaderComponent implements OnInit, OnDestroy {
  showLoader = false;
  userSettings: any;
  subscriptions$ = new Subscription();
  constructor(
    public router: Router,
    public auth: AuthService,
    private store: Store<AppState>,
    // private sharedService: SharedService
  ) {}

  ngOnInit() {
    // this.sharedService.loaderListner.subscribe( showLoader => {
    //   this.showLoader = showLoader.status;
    // });

    //user settings
    // this.store.dispatch(new GetUserSettings());
    //   this.subscriptions$.add(
    //     this.store.pipe(select(getUserSettings)).subscribe(result => {
    //       if (result) {
    //         this.userSettings = result;
    //         console.log("this.userSettings",this.userSettings);

    //        this.change_favicon(this.userSettings.faviconUrl);
    //       }else {
    //         this.userSettings = [];
    //       }
    //     })
    // );
    this.store.select(state => state.orderPlaced.userSettings).subscribe(result => {
      if (result) {
          this.userSettings = result;
      }
    });

    
  }

  change_favicon(img) {
    let favicon = document.querySelector('link[rel="shortcut icon"]');
    if (!favicon) {
        favicon = document.createElement('link');
        favicon.setAttribute('rel', 'shortcut icon');
        let head = document.querySelector('head');
        head.appendChild(favicon);
    }
    favicon.setAttribute('type', 'image/png');
    favicon.setAttribute('href', img);
  }
  ngOnDestroy()
  {
    this.subscriptions$.unsubscribe();
  }
}
