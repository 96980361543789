import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.sass']
})
export class HelpComponent implements OnInit {
  @Input() userSettings: any;
  @Output() closeHelpModal = new EventEmitter();
  
  constructor() { }

  ngOnInit() {}

  /** @description Close help modal */
  closeHelp() {
    this.closeHelpModal.emit('true');
  }

  openSite(siteUrl) {
    window.open(siteUrl, '_blank');
  }
}
