import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root"
})
export class CommonFormService {
  private API_URL = environment.API_URL;
  private PINCODE = environment.PINCODE;

  constructor(private api: ApiService) {}

  getCityState(params= {}): Promise<any> {
    return this.api
      .get(`${this.PINCODE}pincode/${params}`)
      .toPromise().then(res => {
        if (res) {
          return res;
        }
        throw res;
      }).catch(err => { //throw err; 
      });
  } 

  getIfscCodeDetails(params= {}) : Promise<any>{
    return this.api
      .get(`${this.API_URL}ifscCode/${params}`)
      .toPromise().then(res => {
        if (res) { 
          return res;
        }
        throw res;
      }).catch(err => { //throw err; 
      });
  }

  public getCityState1(body): Promise<any>{
    return this.api.post(`${this.PINCODE}pincode-serviceability`, body, '')
    .toPromise().then(res => {
        if (res) {
          return res;
        }
        throw res;
      }).catch(err => { throw err; });
  }
}
