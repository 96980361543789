"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".d-flex[_ngcontent-%COMP%]{align-items:center;justify-content:center;position:relative}.back-btn[_ngcontent-%COMP%]{cursor:pointer;position:absolute;top:2px;left:1px;display:flex;align-items:center;font-family:'Work Sans Light';font-weight:400;font-style:normal;color:#828282;font-size:14px}.back-btn[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{max-width:14px;margin-right:8px}header[_ngcontent-%COMP%]   head[_ngcontent-%COMP%]{z-index:9}"];
exports.styles = styles;
