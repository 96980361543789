<ng-container
  *ngFor="
    let message of alertMessageCollection;
    let i = index;
    trackBy: trackByFn
  "
>
  <div
    class="notify notify-{{
      message.type
    }} notify-with-icon notify-theme-default"
    [@slideAnimation]="message.status"
  >
    <div class="notify-body-wrapper">
      <div class="notify-body">
        <div class="notification-body-icon">
          <i *ngIf="message.type == 'error'" class="fa fa-warning"></i>
          <i *ngIf="message.type == 'success'" class="fa fa-check"></i>
          <i *ngIf="message.type == 'info'" class="fa fa-info"></i>
        </div>
        <div class="notify-main-content">
          <!-- <div class="notify-body-title">{{ message.type }}</div> -->
          <div class="notify-body-text">{{ message.message }}</div>
        </div>
      </div>
      <div class="notify-action-buttons">
        <a
          class="notify-square-button notify-close-button"
          (click)="removeMessage(i)"
        ></a>
      </div>
    </div>
  </div>
</ng-container>

<div class="overlay" *ngIf="showConfirmation">
  <div class="overlay-inner">
    <div class="popup-container">
      <h3 class="title">{{ confirmationContent?.title }}</h3>
      <p>{{ confirmationContent?.subTitle }}</p>
      <div class="action-controller confirmation-buttons">
        <button class="cancel-btn" type="button" (click)="reject()">
          Cancel
        </button>
        <button class="ok-btn" type="button" (click)="accept()">OK</button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="dialog-box" *ngIf="showConfirmation">
        <button class="close" mat-dialog-close>
          <img src="assets/images/x.png" alt="" />
          <small>ESC</small>
        </button>
        <div class="drag-area">
          <div class="db-header">
            <div class="left">
              <img src="assets/images/export.png" alt="" />
              <span>Add File</span>
            </div>
            <div class="right">
              <span>Upload POH Copy </span>
            </div>
          </div>
          <div class="db-body">
            <span>or drag and drop file here</span>
            <span class="path-name"
              >scance.jpge<a class="remove">
                <img src="assets/images/x.png" alt=""/></a
            ></span>
          </div>
        </div>
        <div class="text-center">
          <button class="btn btn-transparent">Save</button>
          <button type="button" class="btn-default blue-btn" (click)="reject()" >Cancel</button>
          <button type="button" class="btn-default blue-btn" (click)="accept()">OK</button>
        </div>
</div> -->
