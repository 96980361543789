"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var OrderActions = require("../actions/order-placed.actions");
exports.initialState = {
    orderPlaced: null,
    selectedItem: null,
    userSettings: null,
};
function OrderPlacedReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        // case UserActions.UserActionTypes.GET_CURRENT_USER: {
        //   return state;
        //}
        case OrderActions.OrderPlacedActionTypes.GET_ORDER: {
            return state;
        }
        case OrderActions.OrderPlacedActionTypes.GET_ORDER_SUCCESS: {
            return tslib_1.__assign({}, state, { orderPlaced: action.payload });
        }
        case OrderActions.OrderPlacedActionTypes.GET_ORDER_FAILED: {
            return state;
        }
        case OrderActions.OrderPlacedActionTypes.RESET_STATE: {
            return Object.assign({}, exports.initialState);
        }
        // case OrderActions.OrderPlacedActionTypes.GET_SELECTED_ITEM: {
        //   return state;
        // }
        // case OrderActions.OrderPlacedActionTypes.GET_SELECTED_ITEM: {
        //   return {
        //     ...state,
        //     selectedItem: 1
        //   };
        // }
        case OrderActions.OrderPlacedActionTypes.GET_USER_SETTINGS: {
            return state;
        }
        case OrderActions.OrderPlacedActionTypes.GET_USER_SETTINGS_SUCCESS: {
            return tslib_1.__assign({}, state, { userSettings: action.payload });
        }
        case OrderActions.OrderPlacedActionTypes.GET_USER_SETTINGS_FAILED: {
            return state;
        }
        default:
            return state;
    }
}
exports.OrderPlacedReducer = OrderPlacedReducer;
