"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
// import { LoginComponent } from "./auth/components/login/login.component";
// import { HomeLayoutComponent } from "./components/_layouts/home-layout/home-layout.component";
var order_placed_component_1 = require("./order-placed/order-placed.component");
// import { OrderCancelComponent } from "./order-cancel/order-cancel.component";
var order_listing_component_1 = require("./order-listing/order-listing.component");
var order_return_component_1 = require("./order-return/order-return.component");
var otp_confirmation_component_1 = require("./shared/components/otp-confirmation/otp-confirmation.component");
var request_submission_component_1 = require("./shared/components/request-submission/request-submission.component");
var redirection_component_1 = require("./redirection/redirection.component");
var order_return_cancel_component_1 = require("./order-return-cancel/order-return-cancel.component");
var order_failed_pickup_component_1 = require("./order-failed-pickup/order-failed-pickup.component");
var order_cancel_component_1 = require("./order-cancel-page/components/order-cancel/order-cancel.component");
var cancel_step_one_component_1 = require("./order-cancel-page/components/cancel-step-one.component/cancel-step-one.component");
var ɵ0 = { title: 'Order Listing' }, ɵ1 = { title: 'Eshopbox' }, ɵ2 = { title: 'Track Order' }, ɵ3 = { title: 'Track Order' }, ɵ4 = { title: 'Return Item' }, ɵ5 = { title: 'Enter OTP' }, ɵ6 = { title: 'Enter OTP' };
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.ɵ4 = ɵ4;
exports.ɵ5 = ɵ5;
exports.ɵ6 = ɵ6;
// import { SidebarComponent } from "./shared/components/sidebar/sidebar.component";
var routes = [
    // {
    //   path: "",
    //   component: LoginLayoutComponent,
    //   children: [
    //     {
    //       path: "",
    //       loadChildren: "./auth/auth.module#AuthModule"
    //     }
    //   ]
    // },
    // {
    //   path: "",
    //   component: HomeLayoutComponent,
    //   children: [
    //     {
    //       path: "dashboard",
    //       loadChildren: "./dashboard/index#DashboardModule"
    //     },
    //     {
    //       path: "order-placed",
    //       component: OrderPlacedComponent,
    //     }
    //   ]
    // },
    // {
    //   path: "login",
    //   component: LoginComponent,
    //   data: {title: 'Login'},
    //   pathMatch: 'full'
    // },
    {
        path: "order-list",
        component: order_listing_component_1.OrderListingComponent,
        data: ɵ0,
        pathMatch: 'full'
    },
    {
        path: "",
        loadChildren: './search-order/search-order.module#SearchOrderModule'
    },
    {
        path: ":encodedKey",
        component: redirection_component_1.RedirectionComponent,
        data: ɵ1
    },
    {
        path: "track/:orderId/:orderItemId",
        component: order_placed_component_1.OrderPlacedComponent,
        data: ɵ2
    },
    {
        path: "track/:orderId",
        component: order_placed_component_1.OrderPlacedComponent,
        data: ɵ3
    },
    {
        path: "cancel/:orderId/:orderItemId",
        component: order_cancel_component_1.OrderCancelComponent,
    },
    {
        path: "cancel-step-one/:orderId/:orderItemId",
        component: cancel_step_one_component_1.CancelStepOneComponent,
    },
    {
        path: "return/:orderId/:orderItemId",
        component: order_return_component_1.OrderReturnComponent,
        data: ɵ4
    },
    {
        path: "otp/:orderId/:orderItemId",
        component: otp_confirmation_component_1.OtpConfirmationComponent,
        data: ɵ5
    },
    {
        path: "success/:orderId/:orderItemId",
        component: request_submission_component_1.RequestSubmissionComponent,
    },
    {
        path: "return-cancel/:orderId/:orderItemId",
        component: order_return_cancel_component_1.OrderReturnCancelComponent,
    },
    // {
    //   path: "failed-delivery/:orderId/:orderItemId",
    //   component: OrderFailedDeliveryComponent
    // },
    {
        path: "failed-pickup/:orderId/:orderItemId",
        component: order_failed_pickup_component_1.OrderFailedPickupComponent
    },
    {
        path: 'refund-exchange/:orderId/:orderItemId',
        loadChildren: './refund-and-exchange/refund-exchange.module#RefundExchangeModule'
    },
    {
        path: 'failed-delivery/:orderId/:orderItemId',
        loadChildren: './failed-delivery/failed-delivery.module#FailedDeliveryModule'
    },
    {
        path: "verifyotp/:orderId",
        component: otp_confirmation_component_1.OtpConfirmationComponent,
        data: ɵ6
    },
    { path: "**", redirectTo: "" }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
exports.AppRoutingModule = AppRoutingModule;
