"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var OrderListingComponent = /** @class */ (function () {
    function OrderListingComponent() {
    }
    OrderListingComponent.prototype.ngOnInit = function () {
    };
    return OrderListingComponent;
}());
exports.OrderListingComponent = OrderListingComponent;
