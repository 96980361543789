import { Component, EventEmitter, OnInit, Output,ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { select, Store } from "@ngrx/store";
import { ActivatedRoute } from '@angular/router';
import { Subscription } from "rxjs";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as global from "../../../shared/constants/constants";
import * as cancelProductReasonList from "../../../shared/interceptors/mockdata/index";
import * as productPaymentOption from "../../../shared/interceptors/mockdata/index";
import { LocalStorageService } from '../../../shared/services/index';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ValidationService } from '../../../shared/services/validation.service';
import { OrderReturnService } from "../../../shared/services/order-return.service";
import { AlertService } from "../../../shared/services";
import { SharedService } from '../../../shared/services/shared.service';
import { ScrollingService } from '../../../shared/services/scrolling.service';
import { GetOrder } from '../../../order-placed/actions/order-placed.actions';
import { getCurrentOrder } from '../../../order-placed/reducers/selectors';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-order-cancel',
  templateUrl: './order-cancel.component.html',
  styleUrls: ['./order-cancel.component.scss'],
  providers: [DatePipe, DecimalPipe]
})

export class OrderCancelComponent implements OnInit {
  @ViewChild('scroll') private scrollElement: ElementRef;
  isBoxshadow:boolean=false;
  scrollTopPosition:number;

  rupeeSymbol = global.RUPEE_SYMBOL;
  leftPanelActive = false;
  public cancelReasonList: any = cancelProductReasonList;
  public paymentOptionList: any = productPaymentOption;
  public accountTypes: any = global.ACCOUNT_TYPES;
  refundDetails: any;
  itemsArray = [];
  public cancelItemForm: FormGroup;
  public cancellationForm:FormGroup;
  public formControls: any;
  public orderDetails: any;
  public cancelOrderItemDetails = LocalStorageService.get('processOrderItemDetails') ? LocalStorageService.get('processOrderItemDetails') : '';
  public showBankDetailsForm = false;
  public currentDate = new Date();
  public cancelItemRequest = LocalStorageService.get('cancelItemRequest') ? LocalStorageService.get('cancelItemRequest') : '';
  public showAddressForm = false;
  public showRefundMode = false;
  // public showOtpForm = false;
  public bankDetailsControls = ['accountHolderName', 'accountNumber', 'confirmAccountNumber', 'ifscCode', 'bankName', 'branch', 'accountType'];
  showAddressFormHtml = false;
  addressFormValue : any ;
  pickupAddress: { "customerName": string; "addressLine1": any; "addressLine2": any; "city": any; "state": any; "postalCode": any; "countryCode": string; "countryName": string; "contactPhone": any; "email": string; };
  showLoader = false;
  loading = false;
  formType: any;
  orderId: any;
  orderItemId: any;
  userSettings: any;
  public isHelpOpen = false;
  public isHelpOpendelay = false;
  footerKey: [string, unknown][];
  Helpcolor: boolean;
  getColor: any;
  item: any;
  selectedItem: any;
  originalOrderItemId: any;
  showFooter: boolean;
  ifarrowTop: boolean = true;
  ifarrowBottom: boolean;
  private defaultSelected = 0;
  
  constructor(
    private preventScroll:ScrollingService,
    private fb: FormBuilder, 
    private router: Router, 
    private datePipe: DatePipe, 
    private store: Store<any>, 
    private orderReturnService: OrderReturnService, 
    private alertService: AlertService, 
    private route: ActivatedRoute, 
    private titleService: Title, 
    private decimalPipe: DecimalPipe,
    private sharedService:SharedService,
    private deviceService: DeviceDetectorService
    ) { }
  @Output() changeleftPanelValue = new EventEmitter();
  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('top-header');
    this.sharedService.changeMessage("");
    LocalStorageService.flush('cancelItemRequest');
    this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
    this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';

    /*Get user settings data from state*/
    this.store.select(state => state.orderPlaced.userSettings).subscribe(result =>   {
      if (result) {
              this.userSettings = result;
              // console.log("this.",this.userSettings.cancellationReasons[0])
              if(this.userSettings.footerLinks){
                this.footerKey = Object.entries(this.userSettings.footerLinks);        
              }       
              this.getColor = this.userSettings.actionColor;

              this.titleService.setTitle("Cancel Item");    
              this.initializeForm();
      }
    }); 
    /*Get Order Data*/
    this.store.select(state => state.orderPlaced.orderPlaced).subscribe(result =>   {
      if (result) {
              this.orderDetails = result;
              // console.log("this.orderDetails ",this.orderDetails.isCOD )
              this.addressFormValue = this.orderDetails ? this.orderDetails.shippingAddress : '';   
                      
      }
    })
   
    this.checkLocalData();
  }

  public onSelectShowOrderItemDetail(item) {
    this.selectedItem=item;   
    if(this.selectedItem.originalOrderItemId && this.selectedItem.originalOrderItemId>0){
      this.originalOrderItemId=this.selectedItem.originalOrderItemId;
    }
  }


  /**
   * Method to define cancelItemForm info form group
   * @method initializeForm
   */
  private initializeForm() {
    this.cancellationForm = this.fb.group({
      AdditionalComments: ['',[Validators.maxLength(100)]],
      cancellationReasons: [this.userSettings.cancellationReasons[0], [Validators.required]],
    })
    this.cancelItemForm = this.fb.group({
      cancelReason: ['', [Validators.required]],
      additionalReason:[''],
      refundMode: [''],
      accountHolderName: [''],
      accountNumber: [''],
      confirmAccountNumber: [''],
      ifscCode: [''],
      bankName: [''],
      bankCity: [''],
      branch: [''],
      accountType: [''],
      addressInfo: [''],
      
    }, {
      validator: ValidationService.MustMatch('accountNumber', 'confirmAccountNumber')
    });
    this.formControls = this.cancellationForm.controls;
    // console.log("this.cancellationForm",this.cancellationForm.valid);
    this.formControls['AdditionalComments'].valueChanges.subscribe((check) => {
      this.cancellationForm.controls['AdditionalComments'].markAsTouched();
    });
  }

  formInitialized(name: string, form: FormGroup) {
    this.cancellationForm.setControl(name, form);
    // console.log("this.cancellationForm",this.cancellationForm);
  }

  onSubmitReasons(){
    if(this.orderDetails.isCOD){
    }
    LocalStorageService.set('AdditionalComments', this.formControls.AdditionalComments.value);
    LocalStorageService.set('cancellationReasons', this.formControls.cancellationReasons.value);
    
    
    // this.router.navigate([global.ROUTES.ORDER_CANCEL_STEP_ONE, this.orderId, this.orderItemId]);
  }

  radioChange(item){
// console.log("item",item)
  }

  // (COD  = 1 && items.previousOrderItemId)
  onreasonChange(value, onchange: boolean = false){
    if (value){
      if (value === "Need to change shipping address"){
        this.showAddressForm = true;
        this.showBankDetailsForm = false;
        this.formControls.refundMode.setValidators(null);
        this.showRefundMode = false;
        this.onPaymentModeChange(false);
      }else
      {
        this.showAddressForm = false;
        let currentOrderItem;
        this.orderDetails.items.forEach((element, index) => {
          if (element.orderItemID === this.orderItemId) {
            currentOrderItem = this.orderDetails.items[index];
          }
        });
        if ((this.orderDetails.isCOD === 0 && !this.showAddressForm) || 
        (this.orderDetails.isCOD === 1 && !!currentOrderItem.previousOrderItemId))
        {
          this.formControls.refundMode.setValidators([Validators.required]); 
          this.showRefundMode = true;
          this.onPaymentModeChange(this.formControls.refundMode.value);
        }
      }
    }else{
      this.formControls.refundMode.setValidators(null);
      this.showRefundMode = false;
    }
  }
  /**
   * Method to define cancelItemForm form group
   * @method checkLocalData
   */
  private checkLocalData() {    
    if (this.cancelItemRequest) {
      this.cancelItemForm.patchValue({
        cancelReason: this.cancelItemRequest.reason,
        additionalReason: this.cancelItemRequest.additionalReason,
        refundMode: this.cancelItemRequest.refundMode,
        accountHolderName: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryName : '',
        accountNumber: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryAccountNo : '',
        ifscCode: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryIfscCode : '',
        bankName: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryBankName : '',
        bankCity: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryBankCity : '',
        branch: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryBranch : '',
        accountType: this.cancelItemRequest.refundBankAccountDetails ? this.cancelItemRequest.refundBankAccountDetails.beneficiaryAccountType : '',

      });


      if (this.cancelItemRequest.refundMode)
      {
        this.showRefundMode = true;
        this.showBankDetailsForm = (this.cancelItemRequest.refundMode === 'bank') ? true : false;
      }
      
      if (this.cancelItemRequest.reason === "Need to change shipping address"){
        this.addressFormValue = this.cancelItemRequest.pickupAddress;
        this.showAddressForm = true;
        this.showAddressFormHtml = true;
        this.showBankDetailsForm = false;
        this.formControls.refundMode.setValidators(null);
        this.showRefundMode = false;
        this.onPaymentModeChange(false);
      }else{
        this.addressFormValue = '';
      }
    }
  }

  /**
    * Refund Mode 
    * @method onPaymentModeChange
  */
  public onCancelReasonChange(value, onchange: boolean = false) {
    if (value === "Need to change shipping address"){
      this.showAddressForm = true;
      this.showBankDetailsForm = false;
      this.formControls.refundMode.setValidators(null);
    }else{
      this.showAddressForm = false;
    }
  }



  /**
    * Refund Mode 
    * @method onPaymentModeChange
    */
  public onPaymentModeChange(value, onchange: boolean = false) {
    if (value === "bank") {
      this.showBankDetailsForm = true;
      
      // this.cancelItemForm.patchValue({ accountHolderName : this.orderDetails.billingAddress.customerName});
      this.formControls.accountHolderName.setValidators([Validators.required]);
      this.formControls.accountNumber.setValidators([Validators.required, Validators.pattern(global.NUMBERS_ONLY_REGEX), Validators.minLength(5), Validators.maxLength(25)]);
      this.formControls.confirmAccountNumber.setValidators([Validators.required, Validators.pattern(global.NUMBERS_ONLY_REGEX)]);
      this.formControls.ifscCode.setValidators([Validators.required, Validators.minLength(11), Validators.pattern(global.IFSC_CODE_REGEX)]);
      // this.formControls.bankName.setValidators([Validators.required]); //, Validators.pattern(global.REGX_NAME)
      // this.formControls.branch.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(40)]);
      this.formControls.accountType.setValidators([Validators.required]);  
    }else{
      this.showBankDetailsForm = false;  
      this.bankDetailsControls.forEach(element => {
        this.formControls[element].setValidators(null);
        this.formControls[element].markAsUntouched();
        this.formControls[element].patchValue('');
        this.formControls[element].setErrors(null);
      });
    }  
  }

  /**
   * form submission
   * @method onSubmit
   */
  public onSubmitReason(): void {
    LocalStorageService.set('AdditionalComments', this.formControls.AdditionalComments.value);
    LocalStorageService.set('cancellationReasons', this.formControls.cancellationReasons.value);    
    if(this.orderDetails.isCOD){
      // console.log("item",this.orderDetails.isCOD)          
    // console.log("this.cancelItemForm",this.cancelItemForm)
    // if (this.cancelItemForm.valid) { 
      this.loading = true;
      LocalStorageService.flush('cancelItemRequest');
      LocalStorageService.flush('actionType');
      const itemDetails = {
        lineItemSequenceNumber: this.cancelOrderItemDetails.lineItemSequenceNumber?this.cancelOrderItemDetails.lineItemSequenceNumber:this.selectedItem.lineItemSequenceNumber,
        orderItemID: this.cancelOrderItemDetails.orderItemID?this.cancelOrderItemDetails.orderItemID:this.selectedItem.orderItemID,
        itemID: this.cancelOrderItemDetails.itemID?this.cancelOrderItemDetails.itemID:this.selectedItem.itemID,
        quantity: this.cancelOrderItemDetails.quantity?this.cancelOrderItemDetails.quantity.toString():this.selectedItem.quantity.toString(),
        productName: this.cancelOrderItemDetails.productName?this.cancelOrderItemDetails.productName:this.selectedItem.productName,
        remark: 'cancelled by customer',
        additionalInfo: {
          customerUploadImageUrl: "",
          comment: ""
        }
      };
      // console.log("item",itemDetails)
      
      this.itemsArray.push(itemDetails);
      this.cancelItemRequest = {
        actor: localStorage.getItem("clientPortalemail") ? localStorage.getItem("clientPortalemail"): LocalStorageService.get('actorcutomername'),
        actorType: localStorage.getItem("clientPortalemail") ? 'seller': 'buyer',
        additionalReason: LocalStorageService.get('AdditionalComments'),
        customerOrderNumber: this.orderDetails.customerOrderNumber,
        reason: LocalStorageService.get('cancellationReasons'),
        isCOD: this.orderDetails.isCOD.toString(),
        items: this.itemsArray,
        pickupAddress: this.pickupAddress ? this.pickupAddress : null,
        // refundBankAccountDetails: this.refundDetails ? this.refundDetails : null,
        // refundMode: this.formControls.refundMode.value,
      };
      // console.log("cancelItemRequest",this.cancelItemRequest)
      LocalStorageService.set('actionType', 'cancelProcess');
      LocalStorageService.set('cancelItemRequest', this.cancelItemRequest);
      if (this.cancelItemRequest) {
        this.orderReturnService.cancelItemsApiRequest(this.cancelItemRequest)
        .then(data => {
          // this.loading = false;
          LocalStorageService.set('SuccessResponseData', data);
          LocalStorageService.flush('cancelItemRequest'); 
          if (this.orderId && data){
            this.store.dispatch(new GetOrder(this.orderId));  
            // this.showLoader = false;
          }
          this.store.pipe(select(getCurrentOrder)).subscribe(result1 => {
            if (result1) {   
              // console.log("result1",result1)
              LocalStorageService.set('cancelItemRequestTrue', true);    
              this.router.navigate([global.ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
            }
          })
          
          // this.router.navigate([global.ROUTES.REQUEST_SUBMISSION, this.orderId, this.orderItemId]);
        }).catch(err => {
          this.loading = false;
          this.alertService.showInfo(err.error.error.message);
          // throw err;
        });
      }
    // }
    }else{
      this.router.navigate([global.ROUTES.ORDER_CANCEL_STEP_ONE, this.orderId, this.orderItemId]);
    }
  }


 
  setBackToOrder(){
    this.sharedService.setOpen(true);
    LocalStorageService.flush('cancelItemRequest');
    localStorage.setItem('foo', 'no reload') 
    this.router.navigate([global.ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
  }

  callIfscCodeCheck(event){
    if (event.currentTarget.value.length === 11) {
      this.showLoader = true;
      this.orderReturnService.getIfscCodeDetails(event.currentTarget.value)
      .then(data => {
        if (data){
          this.showLoader = false;
          this.formControls.bankName.setValue(data.BANK);
          this.formControls.bankCity.setValue(data.CITY);
          this.formControls.branch.setValue(data.BRANCH);
        }else{
          this.formControls.ifscCode.setErrors({invalid: true});
          this.formControls.bankName.setValue(null);
          this.formControls.bankCity.setValue(null);
          this.formControls.branch.setValue(null);
        }
      }).catch(err => {
        this.formControls.ifscCode.setErrors({invalid: true});
        this.formControls.bankName.setValue(null);
        this.formControls.bankCity.setValue(null);
        this.formControls.branch.setValue(null);
        this.showLoader = false;
        throw err;
      });
    }
  }



  onScroll(){
    this.scrollTopPosition = this.scrollElement.nativeElement.scrollTop;
    if( this.scrollTopPosition > 0 ){
      this.isBoxshadow = true;
    }
    else{
      this.isBoxshadow = false;
    }
  }


// Help Modal
openHelpModal(){
  this.preventScroll.disable();
  this.isHelpOpen = !this.isHelpOpen;
  this.isHelpOpendelay = !this.isHelpOpendelay;
    document.querySelector("body").classList.add("stop-scrolling");
}

closeHelpModal() {
  this.isHelpOpen = false;
  // this.preventScroll.enable();
  if(!this.deviceService.isDesktop()) {
    setTimeout(() => {
      this.isHelpOpendelay = false;
    }, 350);
  }
  else this.isHelpOpendelay = false;
  document.querySelector("body").classList.remove("stop-scrolling");
}




  // ngDoCheck()
  // {
  //   this.cancelItemForm.controls;
  // }
  

  /*Left Panel Show Hide*/
  showLeftPanel(){
    this.leftPanelActive = !this.leftPanelActive;
  }

  displayLeftPanel(value){
    this.leftPanelActive = value;
  }

  transformDecimal(num) {
    return this.decimalPipe.transform(num, '2.');
  }
  openSite(siteUrl) {
    window.open(siteUrl, '_blank');
  }
  open_ace_com(siteUrl){
    window.open(siteUrl, '_blank');

  }
  mouseEnter(value){
    this.Helpcolor=true;
  }
  
  mouseLeave(value){
   this.Helpcolor=false;
  
  }

  showFooterClick(){
    this.showFooter = true;
    this.ifarrowTop = false;
    this.ifarrowBottom = true;
  }
  hideFooterClick(){
    this.ifarrowTop = true;
    this.ifarrowBottom = false;
    this.showFooter = false;
  }
}
