"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var store_1 = require("@ngrx/store");
// import { ValidationService } from '../../services/validation.service';
var global = require("../../constants/constants");
var cityOptionData = require("../../interceptors/mockdata/city.json");
var stateOptionData = require("../../interceptors/mockdata/state.json");
var services_1 = require("../../services");
var scrolling_service_1 = require("../../services/scrolling.service");
var shared_service_1 = require("../../services/shared.service");
var ngx_toastr_1 = require("ngx-toastr");
var router_1 = require("@angular/router");
var ngx_device_detector_1 = require("ngx-device-detector");
var ChangeDeliveryAddressComponent = /** @class */ (function () {
    function ChangeDeliveryAddressComponent(preventScroll, fb, commonFormService, store, sharedService, toastr, route, router, orderReturnService, deviceService) {
        this.preventScroll = preventScroll;
        this.fb = fb;
        this.commonFormService = commonFormService;
        this.store = store;
        this.sharedService = sharedService;
        this.toastr = toastr;
        this.route = route;
        this.router = router;
        this.orderReturnService = orderReturnService;
        this.deviceService = deviceService;
        // public myGroup: FormGroup;
        this.cityData = cityOptionData;
        this.stateData = stateOptionData;
        this.showAddAddressForm = false;
        this.formReady = new core_1.EventEmitter();
        this.getExistingAddress = new core_1.EventEmitter;
        this.closeEditAddresspopup = new core_1.EventEmitter();
        this.editdetailspaydataevent = new core_1.EventEmitter();
        this.editAddrError = new core_1.EventEmitter();
        this.ismobile = false;
        this.showPincode = true;
        this.formLoader = false;
        this.editAddrToster = true;
        // errormsg:boolean = true;
        this.islandmarkfocused = false;
        this.isAddressLine2 = false;
        this.isAddressLine1 = false;
        this.errorhighscorePopup = false;
        this.pinCodeChecked = false;
    }
    ChangeDeliveryAddressComponent.prototype.ngAfterViewChecked = function () {
        this.ismobile = !this.deviceService.isDesktop();
        if (this.orderData != undefined && this.orderData.isPincodeServiceable == false && !this.pinCodeChecked) {
            this.setServiceableError();
        }
        else {
            this.pinCodeChecked = true;
        }
    };
    ChangeDeliveryAddressComponent.prototype.ngOnChanges = function () {
        /*
        comment all onchange code update address issue
        date 29-11-2023
        */
        // console.log("addressFormValue",this.orderData);
        // this.addressInfoForm = this.fb.group({
        //   firstName:null,
        //   lastName:null,
        //   pinCode: null,
        //   state: null,
        //   address:null,
        //   // town: null,
        //   city: null,
        //   mobile:null,
        //   email: null,
        //   oldAddress: null,
        //   addressType: null,
        //   addressLine2:null,
        //   landmark:null,
        //   notes:null
        // });
        if (this.addressFormValue) {
            // this.addressInfoForm = this.fb.group({
            //     firstName:null,
            //     lastName:null,
            //     pinCode: null,
            //     state: null,
            //     address:null,
            //     // town: null,
            //     city: null,
            //     mobile:null,
            //     email: null,
            //     oldAddress: null,
            //     addressType: null,
            //     addressLine2:null,
            //     landmark:null,
            //     notes:null
            //   });
            // this.addressInfoForm.patchValue({
            //   firstName: this.addressFormValue.customerName.split(' ')[0],
            //   lastName: this.addressFormValue.customerName.split(' ')[1],
            //   pinCode: this.addressFormValue.postalCode,
            //   state: this.addressFormValue.state,
            //   city: this.addressFormValue.city,
            //   address: this.addressFormValue.addressLine1,
            //   email: this.addressFormValue.email,
            //   addressLine2: this.addressFormValue.addressLine2,
            //   notes:this.addressFormValue.notes,
            //   landmark: this.addressFormValue.landmark,
            //   mobile: this.addressFormValue.contactPhone,
            //   addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
            // });
        }
        // this.ismobile = !this.deviceService.isDesktop();
        //   if(this.orderData != undefined && this.orderData.isPincodeServiceable == false ) {
        //     this.setServiceableError();
        //   }
    };
    ChangeDeliveryAddressComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.editdetails = services_1.LocalStorageService.get('editdetails');
        this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
        this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';
        this.store.select(function (state) { return state.orderPlaced.userSettings; }).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                _this.getColor = _this.userSettings.actionColor;
            }
        });
        this.sharedService.currentMessage.subscribe(function (message) {
            return (_this.selectedMessage = message);
        }); //<= Always get current value!
        if (this.selectedMessage == 'hidePincode') {
            this.showPincode = false;
        }
        else {
            this.showPincode = true;
        }
        this.pickupAddress = services_1.LocalStorageService.get('pickupAddress');
        this.dynamicdata = false;
        this.addressInfoForm = this.fb.group({
            firstName: null,
            lastName: null,
            pinCode: null,
            state: null,
            address: null,
            // town: null,
            city: null,
            mobile: null,
            email: null,
            oldAddress: null,
            addressType: null,
            addressLine2: null,
            landmark: null,
            notes: null
        });
        // Emit the form group to the father to do whatever it wishes
        this.formReady.emit(this.addressInfoForm);
        // if (this.dynamicdata) {
        // this.checkLocalData();
        this.showHideAddressSection();
        // }
        if (this.parentFormType === 'return') {
            this.checkLocalData();
        }
        // this.ismobile = !this.deviceService.isDesktop();
        // if(this.orderData != undefined && this.orderData.isPincodeServiceable == false ) {
        //   this.setServiceableError();
        // }
        // debugger;
    };
    ChangeDeliveryAddressComponent.prototype.checkLocalData = function () {
        if (this.addressFormValue && !this.showPickupAddress) {
            var lname = this.addressFormValue.customerName;
            if (lname.split(' ')[1]) {
                this.lastnamecontion = lname.split(' ')[1];
            }
            if (lname.split(' ')[2]) {
                this.lastnamecontion = lname.split(' ')[1] + ' ' + lname.split(' ')[2];
            }
            if (lname.split(' ')[3]) {
                this.lastnamecontion = lname.split(' ')[1] + ' ' + lname.split(' ')[2] + ' ' + lname.split(' ')[3];
            }
            this.addressInfoForm.patchValue({
                firstName: this.addressFormValue.customerName.split(' ')[0],
                lastName: this.lastnamecontion,
                pinCode: this.addressFormValue.postalCode,
                state: this.addressFormValue.state,
                city: this.addressFormValue.city,
                address: this.addressFormValue.addressLine1,
                email: this.addressFormValue.email,
                addressLine2: this.addressFormValue.addressLine2,
                notes: this.addressFormValue.notes,
                landmark: this.addressFormValue.landmark,
                mobile: this.addressFormValue.contactPhone,
                addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
            });
        }
        if (this.pickupAddress && this.showPickupAddress) {
            this.addressInfoForm.patchValue({
                firstName: this.pickupAddress.customerName.split(' ')[0],
                lastName: this.pickupAddress.customerName.split(' ')[1],
                pinCode: this.pickupAddress.postalCode,
                state: this.pickupAddress.state,
                city: this.pickupAddress.city,
                address: this.pickupAddress.addressLine1,
                // landmark:this.addressFormValue.landmark,
                addressLine2: this.pickupAddress.addressLine2,
                mobile: this.pickupAddress.contactPhone,
                addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
            });
        }
    };
    ChangeDeliveryAddressComponent.prototype.showHideAddressSection = function (cond, checked) {
        if (cond === void 0) { cond = false; }
        if (checked === void 0) { checked = false; }
        this.getAddress = true;
        this.getExistingAddress.emit(this.getAddress);
        // this.preventScroll.disable();
        this.dynamicdata = cond;
        // if (cond === true){ 
        // this.addressInfoForm.controls.oldAddress.setValue('');
        // this.addressInfoForm.controls.oldAddress.setValidators(null);
        this.checkLocalData();
        this.addressInfoForm.controls.firstName.setValidators([forms_1.Validators.required]);
        // this.addressInfoForm.controls.lastName.setValidators([Validators.required, Validators.pattern(global.REGX_NAME)]);
        this.addressInfoForm.controls.pinCode.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.REGX_PINCODE)]);
        this.addressInfoForm.controls.state.setValidators([forms_1.Validators.required]);
        this.addressInfoForm.controls.address.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.REGX_ADDRESS_With_NUM)]); //Validators.pattern(global.REGX_ADDRESS)
        // this.addressInfoForm.controls.landmark.setValidators([Validators.required]); //Validators.pattern(global.REGX_ADDRESS)
        this.addressInfoForm.controls.addressLine2.setValidators([forms_1.Validators.required]); //Validators.pattern(global.REGX_ADDRESS)
        // this.addressInfoForm.controls.town.setValidators([Validators.required]);  //Validators.pattern(global.REGX_ADDRESS)
        this.addressInfoForm.controls.city.setValidators([forms_1.Validators.required]);
        this.addressInfoForm.controls.email.setValidators([]);
        // this.addressInfoForm.controls.email.setValidators([Validators.required, Validators.pattern(global.REGX_EMAIL)]);
        this.addressInfoForm.controls.mobile.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.REGX_MOBILE_NEW)]);
        // this.addressInfoForm.controls.addressType.setValidators([Validators.required]);
        // } else{
        // if (!checked)
        // {
        //   this.addressInfoForm.controls.oldAddress.setValidators([Validators.required]);
        // }
        //   this.addressInfoForm.controls.firstName.setValidators(null);
        //   this.addressInfoForm.controls.email.setValidators(null);
        //   this.addressInfoForm.controls.lastName.setValidators(null);
        //   this.addressInfoForm.controls.pinCode.setValidators(null);
        //   this.addressInfoForm.controls.state.setValidators(null);
        //   this.addressInfoForm.controls.address.setValidators(null);
        //   this.addressInfoForm.controls.landmark.setValidators(null);
        //   this.addressInfoForm.controls.addressLine2.setValidators(null);
        //   // this.addressInfoForm.controls.town.setValidators(null);
        //   this.addressInfoForm.controls.mobile.setValidators(null);
        //   this.addressInfoForm.controls.city.setValidators(null);
        //   // this.addressInfoForm.controls.oldAddress.setValidators(null);
        //   this.addressInfoForm.controls.addressType.setValidators(null);
        // }   
    };
    ChangeDeliveryAddressComponent.prototype.closeEditAddress = function () {
        var _this = this;
        this.dynamicdata = false;
        // this.preventScroll.enable();
        this.preventScroll.disablescroll();
        this.closeEditAddresspopup.emit();
        if (document.getElementById('addrDivId')) {
            var myDiv = document.getElementById('addrDivId');
            myDiv.scrollTop = 0;
        }
        document.querySelector("body").classList.remove("stop-scrolling");
        if (this.addressFormValue) {
            if (!this.deviceService.isDesktop()) {
                setTimeout(function () {
                    _this.addressInfoForm.patchValue({
                        firstName: _this.addressFormValue.customerName.split(' ')[0],
                        lastName: _this.addressFormValue.customerName.split(' ')[1],
                        pinCode: _this.addressFormValue.postalCode,
                        state: _this.addressFormValue.state,
                        city: _this.addressFormValue.city,
                        address: _this.addressFormValue.addressLine1,
                        email: _this.addressFormValue.email,
                        addressLine2: _this.addressFormValue.addressLine2,
                        notes: _this.addressFormValue.notes,
                        landmark: _this.addressFormValue.landmark,
                        mobile: _this.addressFormValue.contactPhone,
                        addressType: _this.addressFormValue.addressType ? _this.addressFormValue.addressType : '1',
                    });
                }, 350);
            }
            else {
                this.addressInfoForm.patchValue({
                    firstName: this.addressFormValue.customerName.split(' ')[0],
                    lastName: this.addressFormValue.customerName.split(' ')[1],
                    pinCode: this.addressFormValue.postalCode,
                    state: this.addressFormValue.state,
                    city: this.addressFormValue.city,
                    address: this.addressFormValue.addressLine1,
                    email: this.addressFormValue.email,
                    addressLine2: this.addressFormValue.addressLine2,
                    notes: this.addressFormValue.notes,
                    landmark: this.addressFormValue.landmark,
                    mobile: this.addressFormValue.contactPhone,
                    addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
                });
            }
        }
    };
    /**
        * City State Data using pincode
        * @method onPincodeChangeCityState
    */
    ChangeDeliveryAddressComponent.prototype.onPincodeChangeCityState = function (event) {
        var _this = this;
        if (event.currentTarget.value.length === 6) {
            this.loadingPincode = true;
            var params = {
                "deliveryType": "reverse",
                "pickup_pincode": event.currentTarget.value
            };
            this.commonFormService.getCityState1(params)
                .then(function (data) {
                if (data && data.pincode && data.isServiceable) {
                    _this.loadingPincode = false;
                    if (data.state && data.city) {
                        _this.pinCodeChecked = true;
                        _this.addressInfoForm.controls.pinCode.markAsTouched();
                        _this.addressInfoForm.controls.pinCode.setErrors(null);
                        _this.addressInfoForm.controls.state.setValue(data.state);
                        _this.addressInfoForm.controls.city.setValue(data.city);
                    }
                    else {
                        _this.setServiceableError();
                    }
                    //this condition will apply only in return process
                    if (_this.parentFormType === 'return') {
                        if (_this.isCod === 1) {
                            if ((!data.isReversePickup && data.isCod === 1) || (data.isReversePickup && data.isCod === 0)) {
                                _this.setServiceableError();
                            }
                        }
                        else {
                            if (!data.isReversePickup) {
                                _this.setServiceableError();
                            }
                        }
                    }
                }
                else {
                    _this.loadingPincode = false;
                    // if()
                    _this.setInvalidError(data);
                }
            }).catch(function (err) {
                _this.loadingPincode = false;
                _this.setInvalidError();
                //throw err;
            });
        }
        else {
            this.addressInfoForm.controls.city.setValue('');
            this.addressInfoForm.controls.state.setValue('');
        }
        // console.log(this.addressInfoForm,"address");
    };
    /*Pincode Invalid And Serviceable Error*/
    ChangeDeliveryAddressComponent.prototype.setInvalidError = function (data) {
        // if(data.isServiceable != undefined && data.isServiceable) { 
        this.addressInfoForm.controls.pinCode.setErrors({ isServiceable: true });
        // } else {
        // this.addressInfoForm.controls.pinCode.setErrors({invalid: true});
        // }
        this.addressInfoForm.controls.pinCode.markAsTouched();
        this.addressInfoForm.controls.city.setValue('');
        this.addressInfoForm.controls.state.setValue('');
    };
    ChangeDeliveryAddressComponent.prototype.setServiceableError = function () {
        this.addressInfoForm.controls.pinCode.markAsTouched();
        this.addressInfoForm.controls.pinCode.setErrors({ isServiceable: true });
    };
    ChangeDeliveryAddressComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.editdetails) {
            var editdetails = {
                "externalChannelID": this.orderData.externalChannelID,
                "customerOrderNumber": this.customerOrderNumber,
                "actor": localStorage.getItem("clientPortalemail"),
                'actorType': localStorage.getItem("clientPortalemail") ? 'seller' : 'buyer',
                "externalShipmentIds": this.orderData.externalShipmentIds,
                "orderId": this.orderData.orderId,
                "accountSlug": this.orderData.accountSlug,
                "isRiskScoreRecalculate": true,
                "shippingDetails": {
                    customerName: this.addressInfoForm.controls.firstName.value + ' ' + (this.addressInfoForm.controls.lastName.value || ''),
                    email: this.addressInfoForm.controls.email.value,
                    postalCode: this.addressInfoForm.controls.pinCode.value,
                    city: this.addressInfoForm.controls.city.value,
                    state: this.addressInfoForm.controls.state.value,
                    addressLine1: this.addressInfoForm.controls.address.value,
                    addressLine2: this.addressInfoForm.controls.addressLine2.value,
                    contactPhone: this.addressInfoForm.controls.mobile.value,
                    landmark: this.addressInfoForm.controls.landmark.value ? this.addressInfoForm.controls.landmark.value : '',
                },
                "items": this.orderData.items
            };
            services_1.LocalStorageService.set('editdetailspayload', editdetails);
            if (editdetails) {
                services_1.LocalStorageService.set('actionType', 'editdetailsaction');
                this.formLoader = true;
                this.orderReturnService.deliveryDetailsApiRequest(editdetails).then(function (data) {
                    if (data) {
                        services_1.LocalStorageService.set('editdetailspaydata', true);
                        _this.formLoader = false;
                        if (data.riskScore == undefined) {
                            _this.closeEditAddresspopup.emit();
                            _this.editdetailspaydataevent.emit();
                            _this.router.navigate([global.ROUTES.ORDER_PLACED, _this.orderId, _this.orderItemId]);
                        }
                        else {
                            if (data.riskScore.toLowerCase() == 'high' && data.riskScoreReasons.includes('Address issue')) {
                                _this.errorhighscorePopup = true;
                                _this.contentPage.nativeElement.scrollIntoView();
                            }
                            else {
                                _this.orderData.riskScore = data.riskScore;
                                _this.orderData.riskScoreReasons = data.riskScoreReasons;
                                _this.closeEditAddresspopup.emit();
                                _this.editdetailspaydataevent.emit();
                                _this.router.navigate([global.ROUTES.ORDER_PLACED, _this.orderId, _this.orderItemId]);
                            }
                        }
                        // data['riskScore'] = 'high',
                        // data['riskScoreReasons'] = ['Address issue'];
                    }
                }).catch(function (err) {
                    if (err && err.status != 400) {
                        // LocalStorageService.set('isEditAddrErr', 'true');
                        _this.editAddrError.emit();
                    }
                    _this.formLoader = false;
                    // this.router.navigate([global.ROUTES.OTP_CONFIRMATION, this.orderId, this.orderItemId])
                });
            }
            return;
        }
        var getobj = services_1.LocalStorageService.get('failedDeliveryFormRequest');
        // console.log("getobj",getobj)
        var formReqObject = {
            actionSource: getobj.actionSource,
            actor: getobj.actor,
            actorType: getobj.actorType,
            customerOrderNumber: getobj.customerOrderNumber,
            resolutionCode: getobj.resolutionCode,
            orderItemID: this.orderItemId,
            trackingId: getobj.trackingId,
            deferredDate: getobj.deferredDate,
            shippingDetails: {},
            contactPhoneNumber: this.addressInfoForm.controls.mobile.value,
            timeline: getobj.timeline,
            remarks: getobj.remarks
        };
        var ReqObject = {
            customerOrderNumber: getobj.customerOrderNumber,
            resolutionCode: getobj.resolutionCode,
            orderItemID: this.orderItemId,
            trackingId: getobj.trackingId,
            deferredDate: getobj.deferredDate,
            shippingDetails: {
                name: this.addressInfoForm.controls.firstName.value + ' ' + (this.addressInfoForm.controls.lastName.value || ''),
                email: this.addressInfoForm.controls.email.value,
                currentAddress: getobj.shippingDetails.currentAddress,
                updatedAddress: this.addressInfoForm.controls.address.value +
                    (this.addressInfoForm.controls.addressLine2.value ? ', ' + this.addressInfoForm.controls.addressLine2.value : '')
                    + ', ' + this.addressInfoForm.controls.city.value
                    + ', ' + this.addressInfoForm.controls.state.value + ', ' + this.addressInfoForm.controls.pinCode.value,
                landmark: this.addressInfoForm.controls.landmark.value ? this.addressInfoForm.controls.landmark.value : '',
                notes: this.addressInfoForm.controls.notes.value ? this.addressInfoForm.controls.notes.value : '',
            },
            contactPhoneNumber: this.addressInfoForm.controls.mobile.value,
            timeline: getobj.timeline,
            remarks: getobj.remarks
        };
        var formValue = [];
        Object.keys(this.addressInfoForm.controls).map(function (key) {
            var _a;
            var parsedValue = (_a = {},
                _a[key] = _this.addressInfoForm.get(key).value,
                _a.changed = _this.addressInfoForm.get(key).dirty // added changed key to identify value change
            ,
                _a);
            formValue.push(parsedValue);
        });
        var changed = false;
        formValue.forEach(function (d) {
            if (d.changed) {
                changed = true;
            }
        });
        if (changed) {
            formReqObject['shippingDetails'] = {};
            formReqObject.shippingDetails['name'] = this.addressInfoForm.controls.firstName.value + ' ' + (this.addressInfoForm.controls.lastName.value || '');
            formReqObject.shippingDetails['email'] = this.addressInfoForm.controls.email.value,
                formReqObject.shippingDetails['currentAddress'] = getobj.shippingDetails.currentAddress,
                formReqObject.shippingDetails['updatedAddress'] = this.addressInfoForm.controls.address.value +
                    (this.addressInfoForm.controls.addressLine2.value ? ', ' + this.addressInfoForm.controls.addressLine2.value : '')
                    + ', ' + this.addressInfoForm.controls.city.value
                    + ', ' + this.addressInfoForm.controls.state.value + '- ' + this.addressInfoForm.controls.pinCode.value,
                formReqObject.shippingDetails['landmark'] = this.addressInfoForm.controls.landmark.value ? this.addressInfoForm.controls.landmark.value : '',
                formReqObject.shippingDetails['notes'] = this.addressInfoForm.controls.notes.value ? this.addressInfoForm.controls.notes.value : '';
        }
        else {
            delete formReqObject.shippingDetails;
            delete formReqObject.contactPhoneNumber;
        }
        services_1.LocalStorageService.set('actionType', 'failedDeliveryProcess');
        services_1.LocalStorageService.set('failedDeliveryFormRequest', ReqObject);
        services_1.LocalStorageService.set('failedDeliveryFormReq', formReqObject);
        services_1.LocalStorageService.set('resolutionCode', getobj.resolutionCode);
        services_1.LocalStorageService.set('resolutionCodeContent', getobj.resolutionCode);
        services_1.LocalStorageService.flush("defaultSelected");
        // console.log("formReqObject",formReqObject)
        if (formReqObject) {
            this.formLoader = true;
            this.orderReturnService.failedDeliveryApiRequest(formReqObject).then(function (data) {
                localStorage.removeItem("action");
                localStorage.removeItem("shipmentId");
                console.log("data---", data);
                _this.formLoader = false;
                services_1.LocalStorageService.set('SuccessResponseData', data);
                services_1.LocalStorageService.flush('failedDeliveryFormRequest');
                services_1.LocalStorageService.flush('failedDeliveryFormReq');
                services_1.LocalStorageService.set('failedDeliverTostermsg', true);
                services_1.LocalStorageService.set('otpVerified', 'true');
                localStorage.removeItem("actionSource");
                _this.router.navigate([global.ROUTES.ORDER_PLACED, _this.orderId, _this.orderItemId]);
            }).catch(function (err) {
                if (err && err.status != 400) {
                    services_1.LocalStorageService.set('isEditAddrErr', 'true');
                    _this.editAddrError.emit();
                }
                _this.formLoader = false;
                // this.router.navigate([global.ROUTES.OTP_CONFIRMATION, this.orderId, this.orderItemId])
            });
        }
        // console.log("pickupAddress",formReqObject);
    };
    ChangeDeliveryAddressComponent.prototype.func = function (e, ev) {
        // console.log(e,ev,"ev");
    };
    return ChangeDeliveryAddressComponent;
}());
exports.ChangeDeliveryAddressComponent = ChangeDeliveryAddressComponent;
