"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var getOrderState = function (state) { return state.orderPlaced; };
var ɵ0 = getOrderState;
exports.ɵ0 = ɵ0;
var currentItem = function (state) { return state.orderPlaced; };
var ɵ1 = currentItem;
exports.ɵ1 = ɵ1;
var userSetting = function (state) { return state.userSettings; };
var ɵ2 = userSetting;
exports.ɵ2 = ɵ2;
exports.getCurrentOrder = store_1.createSelector(getOrderState, currentItem);
exports.getSelectedItem = store_1.createSelector(getOrderState, currentItem);
exports.getUserSettings = store_1.createSelector(getOrderState, userSetting);
