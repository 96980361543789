"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var router_1 = require("@angular/router");
var router_2 = require("@angular/router");
var services_1 = require("../services");
var constants_1 = require("../constants/constants");
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var i2 = require("../services/auth.service");
var i3 = require("../services/alert.service");
var ResponseInterceptor = /** @class */ (function () {
    function ResponseInterceptor(router, auth, alertService, route) {
        this.router = router;
        this.auth = auth;
        this.alertService = alertService;
        this.route = route;
    }
    ResponseInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        services_1.LocalStorageService.flush('SuccessResponseData');
        services_1.LocalStorageService.flush('ErrorResponseData');
        // console.log(request.body);
        this.orderID = (request.body && request.body.customerOrderNumber) ? request.body.customerOrderNumber : '';
        if (request.body && request.body.item) {
            this.orderItemID = (request.body.item.orderItemID) ? request.body.item.orderItemID : '';
        }
        else if (request.body && request.body.items && request.body.items[0]) {
            this.orderItemID = request.body.items[0].orderItemID;
        }
        else {
            // console.log(request.body.orderItemID);
            this.orderItemID = (request.body && request.body.orderItemID) ? request.body.orderItemID : '';
        }
        return next.handle(request).pipe(operators_1.tap(function (event) {
            if (event instanceof http_1.HttpResponse) {
                // this.sharedService.showHideLoader(false);
                // do stuff with response if you want
            }
        }, function (err) {
            if (err instanceof http_1.HttpErrorResponse) {
                // this.sharedService.showHideLoader(false);
                if (err.status === 401) {
                    _this.auth.logout();
                }
                else if (err.status === 500) {
                    return err.error.error;
                    // this.alertService.showError(err.error.error);
                }
                else if (err.status === 400) {
                    _this.partialStatusCode = err.error.error.message.split(':');
                    var actionType = services_1.LocalStorageService.get('actionType');
                    if (actionType && _this.partialStatusCode[1] != "Invalid OTP.") {
                        services_1.LocalStorageService.flush('SuccessResponseData');
                        services_1.LocalStorageService.flush('ErrorResponseData');
                        services_1.LocalStorageService.set('ErrorResponseData', err.error.error.message);
                        if (_this.partialStatusCode[0] === constants_1.PARTIAL_STATUS_CODE) {
                            localStorage.removeItem("action");
                            localStorage.removeItem('shipmentId');
                            _this.router.navigate([constants_1.ROUTES.OTP_CONFIRMATION, _this.orderID, _this.orderItemID]);
                        }
                        else {
                            return err.error.error;
                            // this.router.navigate([ROUTES.REQUEST_SUBMISSION]);
                        }
                    }
                    else {
                        return err.error.error;
                    }
                }
                else {
                    // this.alertService.showError(err.error.error.message);
                    return err.error.error;
                }
            }
        }));
    };
    ResponseInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function ResponseInterceptor_Factory() { return new ResponseInterceptor(i0.inject(i1.Router), i0.inject(i2.AuthService), i0.inject(i3.AlertService), i0.inject(i1.ActivatedRoute)); }, token: ResponseInterceptor, providedIn: "root" });
    return ResponseInterceptor;
}());
exports.ResponseInterceptor = ResponseInterceptor;
exports.responseProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: http_1.HTTP_INTERCEPTORS,
    useClass: ResponseInterceptor,
    multi: true
};
