"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./help.component.sass.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./help.component");
var styles_HelpComponent = [i0.styles];
var RenderType_HelpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpComponent, data: {} });
exports.RenderType_HelpComponent = RenderType_HelpComponent;
function View_HelpComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Support URL"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_co.userSettings == null) ? null : _co.userSettings.supportUrl), ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.userSettings == null) ? null : _co.userSettings.supportUrl); _ck(_v, 4, 0, currVal_1); }); }
function View_HelpComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Support email"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "mailto:", ((_co.userSettings && _co.userSettings.supportEmail) ? _co.userSettings.supportEmail : ""), ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.userSettings == null) ? null : _co.userSettings.supportEmail); _ck(_v, 4, 0, currVal_1); }); }
function View_HelpComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Support phone number"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "tel:", ((_co.userSettings == null) ? null : _co.userSettings.supportPhone), ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.userSettings == null) ? null : _co.userSettings.supportPhone); _ck(_v, 4, 0, currVal_1); }); }
function View_HelpComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "help-top-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Help?"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeHelp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "help-bottom-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.userSettings == null) ? null : _co.userSettings.supportUrl) && (((_co.userSettings == null) ? null : _co.userSettings.supportUrl) != "null")); _ck(_v, 6, 0, currVal_0); var currVal_1 = (((_co.userSettings == null) ? null : _co.userSettings.supportEmail) && (((_co.userSettings == null) ? null : _co.userSettings.supportEmail) != "null")); _ck(_v, 8, 0, currVal_1); var currVal_2 = (((_co.userSettings == null) ? null : _co.userSettings.supportPhone) && (((_co.userSettings == null) ? null : _co.userSettings.supportPhone) != "null")); _ck(_v, 10, 0, currVal_2); }, null); }
exports.View_HelpComponent_0 = View_HelpComponent_0;
function View_HelpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-help", [], null, null, null, View_HelpComponent_0, RenderType_HelpComponent)), i1.ɵdid(1, 114688, null, 0, i3.HelpComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_HelpComponent_Host_0 = View_HelpComponent_Host_0;
var HelpComponentNgFactory = i1.ɵccf("app-help", i3.HelpComponent, View_HelpComponent_Host_0, { userSettings: "userSettings" }, { closeHelpModal: "closeHelpModal" }, []);
exports.HelpComponentNgFactory = HelpComponentNgFactory;
