"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./redirection.component.sass.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./redirection.component");
var i4 = require("@angular/router");
var i5 = require("../shared/services/auth.service");
var i6 = require("ngx-device-detector");
var styles_RedirectionComponent = [i0.styles];
var RenderType_RedirectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RedirectionComponent, data: {} });
exports.RenderType_RedirectionComponent = RenderType_RedirectionComponent;
function View_RedirectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loader-layover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "figure", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Loader"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isMobile || _co.isTablet) ? "assets/images/mobile-loader.gif" : "assets/images/track-page-loader.gif"); _ck(_v, 2, 0, currVal_0); }); }
function View_RedirectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RedirectionComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLoader; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_RedirectionComponent_0 = View_RedirectionComponent_0;
function View_RedirectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-redirection", [], null, null, null, View_RedirectionComponent_0, RenderType_RedirectionComponent)), i1.ɵdid(1, 114688, null, 0, i3.RedirectionComponent, [i4.ActivatedRoute, i5.AuthService, i6.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_RedirectionComponent_Host_0 = View_RedirectionComponent_Host_0;
var RedirectionComponentNgFactory = i1.ɵccf("app-redirection", i3.RedirectionComponent, View_RedirectionComponent_Host_0, {}, {}, []);
exports.RedirectionComponentNgFactory = RedirectionComponentNgFactory;
