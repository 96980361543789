"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var OrderPlacedActionTypes;
(function (OrderPlacedActionTypes) {
    OrderPlacedActionTypes["GET_ORDER"] = "[Order Page] Get Order";
    OrderPlacedActionTypes["GET_ORDER_SUCCESS"] = "[Order API] Get Order Success";
    OrderPlacedActionTypes["GET_ORDER_FAILED"] = "[Order API] Get Order Failed";
    OrderPlacedActionTypes["GET_SELECTED_ITEM"] = "[Order Item] Get Selected Item";
    OrderPlacedActionTypes["SET_SELECTED_ITEM"] = "[Order Item] Get Selected Item";
    OrderPlacedActionTypes["GET_USER_SETTINGS"] = "[User Settings API] Get User Settings";
    OrderPlacedActionTypes["GET_USER_SETTINGS_SUCCESS"] = "[User Settings API] Get User Settings Success";
    OrderPlacedActionTypes["GET_USER_SETTINGS_FAILED"] = "[User Settings API] Get User Settings Failed";
    OrderPlacedActionTypes["RESET_STATE"] = "[Order API] Reset state to initial values";
    OrderPlacedActionTypes["MARK_AS_DELIVERED"] = "Mark as delivered";
})(OrderPlacedActionTypes = exports.OrderPlacedActionTypes || (exports.OrderPlacedActionTypes = {}));
var GetOrder = /** @class */ (function () {
    function GetOrder(payload) {
        this.payload = payload;
        this.type = OrderPlacedActionTypes.GET_ORDER;
    }
    return GetOrder;
}());
exports.GetOrder = GetOrder;
var GetOrderSuccess = /** @class */ (function () {
    function GetOrderSuccess(payload) {
        this.payload = payload;
        this.type = OrderPlacedActionTypes.GET_ORDER_SUCCESS;
    }
    return GetOrderSuccess;
}());
exports.GetOrderSuccess = GetOrderSuccess;
var GetOrderFailed = /** @class */ (function () {
    function GetOrderFailed() {
        this.type = OrderPlacedActionTypes.GET_ORDER_FAILED;
    }
    return GetOrderFailed;
}());
exports.GetOrderFailed = GetOrderFailed;
var ResetGetOrderStateAction = /** @class */ (function () {
    function ResetGetOrderStateAction() {
        this.type = OrderPlacedActionTypes.RESET_STATE;
    }
    return ResetGetOrderStateAction;
}());
exports.ResetGetOrderStateAction = ResetGetOrderStateAction;
var GetSelectedItem = /** @class */ (function () {
    function GetSelectedItem(payload) {
        this.payload = payload;
        this.type = OrderPlacedActionTypes.GET_SELECTED_ITEM;
    }
    return GetSelectedItem;
}());
exports.GetSelectedItem = GetSelectedItem;
var SetSelectedItem = /** @class */ (function () {
    function SetSelectedItem(payload) {
        this.payload = payload;
        this.type = OrderPlacedActionTypes.SET_SELECTED_ITEM;
    }
    return SetSelectedItem;
}());
exports.SetSelectedItem = SetSelectedItem;
/*User Settings Actions */
var GetUserSettings = /** @class */ (function () {
    function GetUserSettings() {
        this.type = OrderPlacedActionTypes.GET_USER_SETTINGS;
    }
    return GetUserSettings;
}());
exports.GetUserSettings = GetUserSettings;
var GetUserSettingsSuccess = /** @class */ (function () {
    function GetUserSettingsSuccess(payload) {
        this.payload = payload;
        this.type = OrderPlacedActionTypes.GET_USER_SETTINGS_SUCCESS;
    }
    return GetUserSettingsSuccess;
}());
exports.GetUserSettingsSuccess = GetUserSettingsSuccess;
var GetUserSettingsFailed = /** @class */ (function () {
    function GetUserSettingsFailed() {
        this.type = OrderPlacedActionTypes.GET_USER_SETTINGS_FAILED;
    }
    return GetUserSettingsFailed;
}());
exports.GetUserSettingsFailed = GetUserSettingsFailed;
var MarkAsDelivered = /** @class */ (function () {
    function MarkAsDelivered(payload) {
        this.payload = payload;
        this.type = OrderPlacedActionTypes.MARK_AS_DELIVERED;
    }
    return MarkAsDelivered;
}());
exports.MarkAsDelivered = MarkAsDelivered;
