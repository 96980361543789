<!-- Forward tracking icons -->
<ng-container *ngIf="isForward;else returnTracking">
  <div class="left-circle {{ formatStatus(status.key) }}">
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/intransit-icon.svg"
      *ngIf="getStatus(status.key) == 'Return In-transit'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/order-cancel.svg"
      *ngIf="['Order cancelled', 'Pickup failed', 'Cancelled order', 'Return attempt failed'].includes(getStatus(status.key))"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/icon-dispatch.svg"
      *ngIf="getStatus(status.key) == 'Order dispatched'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/order-packed-icon.svg"
      *ngIf="getStatus(status.key) == 'Order packed'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/order-cancel.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Failed delivery attempt'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/order-placed-icon.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Order placed' ||
      getStatus(status.key) == 'Return created'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/order-delivered-icon.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Order delivered'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/intransit-icon.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Order intransit'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/intransit-icon.svg"
      *ngIf="getStatus(status.key, 'forward') == 'shipment_held'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/order-placed-icon.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Order manifested'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/picked-up-icon.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Picked up'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/order-placed-icon.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Order accepted'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/icon-out-for-delivery.svg"
      *ngIf="
        getStatus(status.key, 'forward') == 'Order is out for delivery'
      "
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/icon-ready-to-ship.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Order is ready to ship'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/icon-deley.svg"
      *ngIf="
        getStatus(status.key, 'forward') == 'Shipment has been delayed'
      "
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/icon-out-for-delivery.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Out for pickup'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/intransit-icon.svg"
      *ngIf="
        getStatus(status.key, 'forward') == 'Return is out for delivery'
      "
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/intransit-icon.svg"
      *ngIf="formatStatus(status.key) == 'rto_delivered'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/intransit-icon.svg"
      *ngIf="formatStatus(status.key) == 'rto_delivered_warehouse'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/order-delivered-icon.svg"
      *ngIf="formatStatus(status.key) == 'received'"
      alt=""
      class="order-placed-img"
    />
    <img
    src="https://ik.imagekit.io/2gwij97w0o/POE/pickup-pending.svg"
    *ngIf="getStatus(status.key) == 'Pickup pending'"
    alt=""
    class="order-placed-img"
  />
  <img
    src="https://ik.imagekit.io/2gwij97w0o/POE/ndr.svg"
    *ngIf="getStatus(status.key) == 'Delivery requested by customer'"
    alt=""
    class="order-placed-img"
  />
  <img
  src="https://ik.imagekit.io/2gwij97w0o/POE/phone.svg"
  *ngIf="getStatus(status.key) == 'contact_customer_care'"
  alt="Contact customer care"
  class="order-placed-img"
/> 
  </div>
</ng-container>
<!-- Reverse and return tracking icons -->
<ng-template #returnTracking>
  <div class="left-circle {{ formatStatus(status.key) }}">
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/intransit-icon.svg"
      *ngIf="
        getStatus(status.key) == 'Return In-transit' ||
        getStatus(status.key, 'forward') == 'Order intransit'
      "
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/order-cancel.svg"
      *ngIf="
        ['Order cancelled', 'Return cancelled', 'Pickup cancelled', 'Pickup failed', 'Cancelled order', 'Return attempt failed'].includes(getStatus(status.key)) ||
        getStatus(status.key, 'forward') == 'Failed delivery attempt'
      "
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/icon-dispatch.svg"
      *ngIf="getStatus(status.key) == 'Order dispatched'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/order-packed-icon.svg"
      *ngIf="getStatus(status.key) == 'Order packed'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/order-placed-icon.svg"
      *ngIf="
        ['Order placed', 'Order manifested', 'Order accepted'].includes(
          getStatus(status.key, 'forward')
        ) || getStatus(status.key) == 'Return approved'
      "
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/order-delivered-icon.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Order delivered'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/picked-up-icon.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Picked up'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/icon-out-for-delivery.svg"
      *ngIf="
        ['Order is out for delivery', 'Out for pickup'].includes(
          getStatus(status.key, 'forward')
        )
      "
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/icon-ready-to-ship.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Order is ready to ship'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/icon-deley.svg"
      *ngIf="getStatus(status.key, 'forward') == 'Shipment has been delayed'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/delivered-warehouse.svg"
      *ngIf="getStatus(status.key) == 'Delivered warehouse'"
      alt=""
      class="order-placed-img"
    />
    <img
      src="https://ik.imagekit.io/2gwij97w0o/POE/pickup-pending.svg"
      *ngIf="getStatus(status.key) == 'Pickup pending'"
      alt=""
      class="order-placed-img"
    />     
  </div>
</ng-template>

