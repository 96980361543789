import { Component, OnInit, forwardRef, Input, Output, EventEmitter, OnChanges, SimpleChanges  } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, ValidationErrors, AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
// import { ValidationService } from '../../services/validation.service';
import * as global from "../../../shared/constants/constants";
import * as cityOptionData from "../../interceptors/mockdata/city.json";
import * as stateOptionData from "../../interceptors/mockdata/state.json";
import { CommonFormService, LocalStorageService } from "../../services";
import { ScrollingService } from '../../services/scrolling.service';
import { SharedService } from '../../services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-change-address',
  templateUrl: './change-address.component.html',
  styleUrls: ['./change-address.component.scss']
})
export class ChangeAddressComponent implements OnInit {
  // public myGroup: FormGroup;
  public cityData: any = cityOptionData;
  public stateData: any = stateOptionData;
  public showAddAddressForm: any = false;
  public currentCheckedValue: any;
  @Input() parentFormType: any;
  @Input() isCod: any;
  @Input() dynamicdata: any;
  // @Input() addressFormValue: any;
  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() getExistingAddress = new EventEmitter;
  @Output() pincodeevent = new EventEmitter;
  
@Input() ReversePickupPincode;
  addressInfoForm: FormGroup;
  userSettings: any;
  getColor: any;
  pickupAddress: { contactPhone: any; customerName: string; addressLine1: any; addressLine2: any; state: any; postalCode: any; city: any; notes:any};
  showPickupAddress: boolean;
  loading:any;
  selectedMessage: any;
  showPincode=true;
  getAddress: boolean;
  // ReversePickupPincode = true;
  private _addressFormValue = new BehaviorSubject<any>({});
  addressFormData: any;
  reversePickuperror: any;
  pickupAddresspostalCode: any;
  addressLoading= true;
  @Input()
  set addressFormValue(value) {
    this._addressFormValue.next(value);
  }
  get addressFormValue() {
    return this._addressFormValue.getValue();
  }
  
  constructor(private preventScroll:ScrollingService, private fb: FormBuilder, private commonFormService: CommonFormService,private store: Store<any>,private sharedService:SharedService,
    private toastr: ToastrService) { 


    
    this._addressFormValue.subscribe(data => {
      // data.postalCode = 500000
      this.addressFormValue = data
   
    // if(this.addressFormData){
    //       const params = {
    //         "deliveryType": "reverse",
    //         "pickup_pincode" : 500000
    //         // "pickup_pincode" : this.addressFormData.postalCode
    //       }
    //       this.commonFormService.getCityState1(params)
    //       .then(data => {
    //         this.ReversePickupPincode = data.isReversePickup
    //         console.log("data",data,this.ReversePickupPincode)        
    //       }).catch(err => {
    //           this.setInvalidError();
    //         //throw err;
    //       });
    //     }
        })
        
      
       
    }

  ngOnInit() {
  
    this.store.select(state => state.orderPlaced.userSettings).subscribe(result =>   {
      if (result) {
              this.userSettings = result;
             this.getColor=this.userSettings.actionColor;
      }
    }); 
    this.sharedService.currentMessage.subscribe(message =>
       (this.selectedMessage= message)); //<= Always get current value!
    if(this.selectedMessage=='hidePincode'){
      this.showPincode=false
    }
    else{
      this.showPincode=true

    }

    this.pickupAddress= LocalStorageService.get('pickupAddress')
    this.dynamicdata=false;
    this.addressInfoForm = this.fb.group({
      firstName:null,
      lastName:null,
      pinCode: null,
      state: null,
      address:null,
      // town: null,
      city: null,
      mobile:null,
      email: null,
      oldAddress: null,
      addressType: null,
      addressLine2:null,
      notes:null
    });
    
    // Emit the form group to the father to do whatever it wishes
    
    this.formReady.emit(this.addressInfoForm);
    if (this.dynamicdata) {
      // this.checkLocalData();
      this.showHideAddressSection(this.dynamicdata);
    }

    if (this.parentFormType === 'return'){
       this.checkLocalData();
    }
    
  }


  checkLocalData() {
    if (this.addressFormValue && !this.showPickupAddress) {
      this.addressInfoForm.patchValue({
        firstName: this.addressFormValue.customerName.split(' ')[0],
        lastName: this.addressFormValue.customerName.split(' ')[1],
        pinCode: this.addressFormValue.postalCode,
        state: this.addressFormValue.state,
        city: this.addressFormValue.city,
        address: this.addressFormValue.addressLine1,
        email: this.addressFormValue.email,
        addressLine2: this.addressFormValue.addressLine2,
        notes:this.addressFormValue.notes,
        // town: this.addressFormValue.addressLine2,
        mobile: this.addressFormValue.contactPhone,
        addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
      });
    }




    if (this.pickupAddress && this.showPickupAddress) {
      this.addressInfoForm.patchValue({
        firstName: this.pickupAddress.customerName.split(' ')[0],
        lastName: this.pickupAddress.customerName.split(' ')[1],
        pinCode: this.pickupAddress.postalCode,
        state: this.pickupAddress.state,
        city: this.pickupAddress.city,
        address: this.pickupAddress.addressLine1,
        // landmark:this.addressFormValue.landmark,
        addressLine2: this.pickupAddress.addressLine2,
        mobile: this.pickupAddress.contactPhone,
        addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
      });
    }
  }
  
  showHideAddressSection(cond = false, checked = false) {

    this.getAddress=true;
    this.getExistingAddress.emit(this.getAddress);

    this.preventScroll.disable();
    this.dynamicdata = cond;
    if (cond === true){ 
      // this.addressInfoForm.controls.oldAddress.setValue('');
      // this.addressInfoForm.controls.oldAddress.setValidators(null);
      
      this.checkLocalData();
      this.addressInfoForm.controls.firstName.setValidators([Validators.required, Validators.pattern(global.REGX_NAME)]);
      // this.addressInfoForm.controls.lastName.setValidators([Validators.required, Validators.pattern(global.REGX_NAME)]);
      this.addressInfoForm.controls.pinCode.setValidators([Validators.required, Validators.pattern(global.REGX_PINCODE)]);
      this.addressInfoForm.controls.state.setValidators([Validators.required]);
      this.addressInfoForm.controls.address.setValidators([Validators.required]); //Validators.pattern(global.REGX_ADDRESS)
      // this.addressInfoForm.controls.landmark.setValidators([Validators.required]); //Validators.pattern(global.REGX_ADDRESS)
      // this.addressInfoForm.controls.addressLine2.setValidators([Validators.required]); //Validators.pattern(global.REGX_ADDRESS)

      // this.addressInfoForm.controls.town.setValidators([Validators.required]);  //Validators.pattern(global.REGX_ADDRESS)
      this.addressInfoForm.controls.city.setValidators([Validators.required]);
      this.addressInfoForm.controls.email.setValidators([Validators.required, Validators.pattern(global.REGX_EMAIL)]);

      this.addressInfoForm.controls.mobile.setValidators([Validators.required, Validators.pattern(global.REGX_MOBILE)]);
      this.addressInfoForm.controls.addressType.setValidators([Validators.required]);
    } else{

      // if (!checked)
      // {
      //   this.addressInfoForm.controls.oldAddress.setValidators([Validators.required]);
      // }

      this.addressInfoForm.controls.firstName.setValidators(null);
      this.addressInfoForm.controls.email.setValidators(null);

      this.addressInfoForm.controls.lastName.setValidators(null);
      this.addressInfoForm.controls.pinCode.setValidators(null);
      this.addressInfoForm.controls.state.setValidators(null);
      this.addressInfoForm.controls.address.setValidators(null);
      this.addressInfoForm.controls.landmark.setValidators(null);
      this.addressInfoForm.controls.addressLine2.setValidators(null);

      // this.addressInfoForm.controls.town.setValidators(null);
      this.addressInfoForm.controls.mobile.setValidators(null);
      this.addressInfoForm.controls.city.setValidators(null);
      // this.addressInfoForm.controls.oldAddress.setValidators(null);
      this.addressInfoForm.controls.addressType.setValidators(null);
    }
  }
  closeEditAddress(){
    this.addressLoading = false;
    this.dynamicdata=false;
    // this.reversePickuperror = true;
    if(document.getElementById('addrDivId')) {
      var myDiv = document.getElementById('addrDivId');
      myDiv.scrollTop = 0;
    }
    this.preventScroll.enable();
    if(this.pickupAddress && this.pickupAddress.postalCode){
      this.pickupAddresspostalCode = this.pickupAddress.postalCode
    }else{
      this.pickupAddresspostalCode = this.addressFormValue.postalCode
    }
    
    // this.ReversePickupPincode = false
    // if (event.currentTarget.value.length === 6) {
      const params = {
        "deliveryType": "reverse",
        "pickup_pincode" : this.pickupAddresspostalCode
      }
      this.commonFormService.getCityState1(params)
      .then(data => {
        this.addressLoading = true;
        if (data && data.isReversePickup){  
          this.ReversePickupPincode = data.isReversePickup
            this.addressInfoForm.controls.state.setValue(data.state);
            this.addressInfoForm.controls.city.setValue(data.city);
            //this condition will apply only in return process
            // if (this.parentFormType === 'return')
            // {
                if (this.isCod === 1) {
                    if ((!data.isReversePickup && data.isCod === 1) || (data.isReversePickup && data.isCod === 0)) {
                      this.setServiceableError();
                    }
                }else {
                  if (!data.isReversePickup) {
                    
                    this.setServiceableError();
                  }
                }  
            // }
         }else{
          this.ReversePickupPincode = data.isReversePickup
          this.setInvalidError();
          
         }
      }).catch(err => {
          this.setInvalidError();
        //throw err;
      });
    // }
    // else{
    //   this.addressInfoForm.controls.city.setValue('');
    // this.addressInfoForm.controls.state.setValue('');
    
    // }
  }

/**
    * City State Data using pincode
    * @method onPincodeChangeCityState
*/
 onPincodeChangeCityState(event){
  if (event.currentTarget.value.length === 0) {
    this.ReversePickupPincode = true
  }
  if (event.currentTarget.value.length === 6) {
    const params = {
      "deliveryType": "reverse",
      "pickup_pincode" : event.currentTarget.value
    }
    this.commonFormService.getCityState1(params)
    .then(data => {
      this.ReversePickupPincode = data.isReversePickup
       if (data && data.pincode){         
          this.addressInfoForm.controls.state.setValue(data.state);
          this.addressInfoForm.controls.city.setValue(data.city);

          //this condition will apply only in return process
          // if (this.parentFormType === 'return')
          // {
              if (this.isCod === 1) {
                  if ((!data.isReversePickup && data.isCod === 1) || (data.isReversePickup && data.isCod === 0)) {
                    this.setServiceableError();
                  }
              }else {
                if (!data.isReversePickup) {
                  
                  this.setServiceableError();
                }
              }  
          // }
       }else{
        this.setInvalidError();
        
       }
    }).catch(err => {
        this.setInvalidError();
      //throw err;
    });
  }
  else{
    this.addressInfoForm.controls.city.setValue('');
  this.addressInfoForm.controls.state.setValue('');
  
  }
 }
 

 /*Pincode Invalid And Serviceable Error*/
 setInvalidError(){
    this.addressInfoForm.controls.pinCode.markAsTouched();
    this.addressInfoForm.controls.pinCode.setErrors({invalid: true});
    this.addressInfoForm.controls.city.setValue('');
    this.addressInfoForm.controls.state.setValue('');
 }

 setServiceableError(){
    this.addressInfoForm.controls.pinCode.markAsTouched();
    this.addressInfoForm.controls.pinCode.setErrors({isServiceable: true});
 }

 onSubmit(){
   this.ReversePickupPincode = true;
  // this.toastr.success('Hello world!', 'Toastr fun!');
let notes=this.addressInfoForm.controls.notes.value?this.addressInfoForm.controls.notes.value:''
  this.pickupAddress={
    "contactPhone":this.addressInfoForm.controls.mobile.value,
    "customerName":this.addressInfoForm.controls.firstName.value+' '+this.addressInfoForm.controls.lastName.value,
    "addressLine1":this.addressInfoForm.controls.address.value,
    "addressLine2":this.addressInfoForm.controls.addressLine2.value+','+ notes,
    "notes":this.addressInfoForm.controls.notes.value?this.addressInfoForm.controls.notes.value:'',
    "state":this.addressInfoForm.controls.state.value,
    "postalCode":this.addressInfoForm.controls.pinCode.value,
    "city":this.addressInfoForm.controls.city.value,
    // "landmark":this.addressInfoForm.controls.landmark.value, this.addressInfoForm.controls.notes.value

    
  }  
  this.preventScroll.enable();
  this.dynamicdata=false;
  this.showPickupAddress=true;
  // this.toastr.success('', 'Pickup details have been successfully updated');

  LocalStorageService.set('pickupAddress',this.pickupAddress)
const pickpincode = {
  "ReversePickupPincode": this.ReversePickupPincode = true,
  "reversePickuperror": this.reversePickuperror = true,
}
LocalStorageService.set('pickpincode',pickpincode)

this.pincodeevent.emit(LocalStorageService.get('pickpincode'));
  //  this.addressFormValue.contactPhone=this.addressInfoForm.controls.mobile.value;
  //  this.addressFormValue.customerName= this.addressInfoForm.controls.firstName.value+' '+this.addressInfoForm.controls.lastName.value
  // this.addressFormValue.addressLine1=this.addressInfoForm.controls.address.value;
  // this.addressFormValue.addressLine2=this.addressInfoForm.controls.city.value;
  // this.addressFormValue.state=this.addressInfoForm.controls.state.value;
  // this.addressFormValue.postalCode=this.addressInfoForm.controls.pinCode.value;
  // this.addressFormValue.city=this.addressInfoForm.controls.city.value;
  }
  getStyleWithoutOpacity() {
    return {
      border: "1px solid" + this.userSettings.actionColor,
      color: this.userSettings.actionColor
      //  opacity:0.6,
    };
  }
  getStyleWithOpacity() {
    return {
      background: this.userSettings.actionColor,
      opacity: 0.05
    };
  }
}
