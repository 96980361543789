import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ROUTES, RUPEE_SYMBOL, RESOLUTION_CODE } from "../../constants/constants";
import { Store } from "@ngrx/store";
import { DecimalPipe } from '@angular/common';
import { LocalStorageService } from '../../services/index';
import { GetOrder } from "../../../order-placed/actions/order-placed.actions";
import { AppState } from '../../../app.state';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SharedService } from '../../services/shared.service';
@Component({
  selector: 'app-request-submission',
  templateUrl: './request-submission.component.html',
  styleUrls: ['./request-submission.component.sass'],
  providers: [DecimalPipe]
})
export class RequestSubmissionComponent implements OnInit {
  showLoader = true;
  rupeeSymbol = RUPEE_SYMBOL;
  showError = false;
  currentSelectedItem = LocalStorageService.get('processOrderItemDetails') ? LocalStorageService.get('processOrderItemDetails') : '';
  errorMessage = LocalStorageService.get('ErrorResponseData') ? LocalStorageService.get('ErrorResponseData') : '';
  showSuccess  = false;
  successData  = LocalStorageService.get('SuccessResponseData') ? LocalStorageService.get('SuccessResponseData') : '';
  formAction   = LocalStorageService.get('actionType') ? LocalStorageService.get('actionType') : '';
  returnFlowType = LocalStorageService.get('returnFlowType') ? LocalStorageService.get('returnFlowType') : '';
  confirmMessage: string;
  confirmTitle: string;
  orderId: any;
  orderItemId: any;
  leftPanelActive = false;
  userSettings: any;
  yellowIcon = false;
  isMobile: boolean;
  isTablet: boolean;
  footerKey: [string, unknown][];
  getColor: any;
  @Output() changeleftPanelValue = new EventEmitter();

  constructor(private router: Router, private store: Store<AppState>, 
    private sharedService:SharedService,
    private route: ActivatedRoute, private titleService: Title, private decimalPipe: DecimalPipe, private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    // this.showLoader = true;
    if (this.errorMessage){
      this.showError = true;
      // this.showLoader = false;
    } else if (this.successData) { 
      this.showSuccess = true;
      // this.showLoader = false;
    }

    setTimeout(() => {   
      this.showLoader = false;
    }, 3000);

    //dispatch order data
    this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
    this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';
    
    /*Get user settings data from state*/
    this.store.select(state => state.orderPlaced.userSettings).subscribe(result =>   {
      if (result) {
              this.userSettings = result;
              this.footerKey = Object.entries(this.userSettings.footerLinks);
              this.getColor=this.userSettings.actionColor;        
             this.titleService.setTitle("Confirmation Status");    
      }
    });
    
    //Return and cancel success message
    // if (this.formAction === 'returnProcess'){
    //   this.confirmMessage = (this.returnFlowType === 'exchange') ? 'Exchange' : (this.returnFlowType === 'replacement' ? 'Replacement' : 'Return');
    // }else {
    //   this.confirmMessage = 'Cancellation';
    // }
    if (this.formAction === 'returnProcess'){     
      this.confirmTitle = (this.returnFlowType === 'exchange') ? 'Exchange Request is Submitted' : (this.returnFlowType === 'replacement' ? 'Replacement Request is Submitted' : 'Return Request is Submitted');
      this.confirmMessage = (this.returnFlowType === 'exchange') ? 'You will receive a confirmation email shortly.' : (this.returnFlowType === 'replacement' ? 'You will receive confirmation email shortly.' : 'You will receive a confirmation email shortly.');
    }else if (this.formAction === 'failedDeliveryProcess'){
        if (this.successData && this.successData.resolutionCode && this.successData.resolutionCode === RESOLUTION_CODE.FALSE_REASON){
          this.yellowIcon = true;
          this.confirmTitle = 'Your remark has been Submitted';
          this.confirmMessage = 'Apologies for the inconvenience caused.We will connect with the courier partner and re-arrange the delivery at the earliest.'; 
        }else if (this.successData && this.successData.resolutionCode && this.successData.resolutionCode === RESOLUTION_CODE.SHIPMENT_RTO){
          this.confirmTitle = 'Cancellation Request is Submitted';
          this.confirmMessage = 'You will receive a confirmation email shortly';
        }else{
          this.confirmTitle = 'Your request has been Submitted';
          this.confirmMessage = 'You will receive a confirmation email shortly';
        }
    } else if (this.formAction === 'failedPickupProcess'){
      if (this.successData && this.successData.resolutionCode && this.successData.resolutionCode === RESOLUTION_CODE.FALSE_REASON){
        this.yellowIcon = true;
        this.confirmTitle = 'Your remark has been Submitted';
        this.confirmMessage = 'Apologies for the inconvenience caused.We will connect with the courier partner and re-arrange the delivery at the earliest.'; 
      }else if (this.successData && this.successData.resolutionCode && this.successData.resolutionCode === RESOLUTION_CODE.SHIPMENT_RTO){
        this.confirmTitle = 'Cancellation Request is Submitted';
        this.confirmMessage = 'You will receive a confirmation email shortly';
      }else{
        this.confirmTitle = 'Your request has been Submitted';
        this.confirmMessage = 'You will receive a confirmation email shortly';
      }
    } else {
      this.confirmTitle = 'Cancellation Request is Submitted';
      this.confirmMessage = 'You will receive a confirmation email shortly';
    }


    if (this.orderId){
      this.store.dispatch(new GetOrder(this.orderId));  
      // this.showLoader = false;
    }
    
  }

  sendBackToOrder()
  { 
    
    this.sharedService.setOpen(true);
    this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);

    LocalStorageService.flush('exchangeOption');
    LocalStorageService.flush('returnFlowType');
    LocalStorageService.flush('actionType');
    LocalStorageService.flush('cancelItemRequest');
    LocalStorageService.flush('returnItemRequest');
    LocalStorageService.flush('ErrorResponseData');
    LocalStorageService.flush('SuccessResponseData');
    LocalStorageService.flush('failedPickupFormRequest');
    LocalStorageService.flush('failedDeliveryFormRequest');
  }

  /*Left Panel Show Hide*/
  showLeftPanel(){    
    this.leftPanelActive = !this.leftPanelActive;
  }

  displayLeftPanel(value){
    this.leftPanelActive = value;
    console.log('value',value);
    
  }

  transformDecimal(num) {
    return this.decimalPipe.transform(num, '2.');
  }
  open_ace_com(siteUrl) {
    window.open(siteUrl, '_blank');
  }

}
