"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./order-listing.component.sass.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./order-listing.component");
var styles_OrderListingComponent = [i0.styles];
var RenderType_OrderListingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderListingComponent, data: {} });
exports.RenderType_OrderListingComponent = RenderType_OrderListingComponent;
function View_OrderListingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" order-listing works!\n"]))], null, null); }
exports.View_OrderListingComponent_0 = View_OrderListingComponent_0;
function View_OrderListingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-order-listing", [], null, null, null, View_OrderListingComponent_0, RenderType_OrderListingComponent)), i1.ɵdid(1, 114688, null, 0, i2.OrderListingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_OrderListingComponent_Host_0 = View_OrderListingComponent_Host_0;
var OrderListingComponentNgFactory = i1.ɵccf("app-order-listing", i2.OrderListingComponent, View_OrderListingComponent_Host_0, {}, {}, []);
exports.OrderListingComponentNgFactory = OrderListingComponentNgFactory;
