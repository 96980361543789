import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { select, Store } from "@ngrx/store";
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ROUTES, RESOLUTION_CODE, REGX_MOBILE } from "../shared/constants/constants";
import { LocalStorageService } from '../shared/services/index';
import { OrderReturnService } from "../shared/services/order-return.service";
import { Router } from '@angular/router';
import {formatDate} from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-order-failed-pickup',
  templateUrl: './order-failed-pickup.component.html',
  styleUrls: ['./order-failed-pickup.component.sass']
})
export class OrderFailedPickupComponent implements OnInit {

  leftPanelActive = false;
  allResolutionCode = RESOLUTION_CODE;
  public failedPickupForm: FormGroup;
  public formControls: any;
  formLoader = false;
  orderId: any;
  orderItemId: any;
  userSettings: any;
  orderDetails: any;
  showLoader = true;
  resolutionValue;
  radioSelected: any;
  resolutionFields = ['updatedAddress', 'deferredDate', 'remarks', 'contactPhoneNumber', 'landmark'];
  selectedOrderItem = LocalStorageService.get('processOrderItemDetails') ? LocalStorageService.get('processOrderItemDetails') : '';
  failedPickupFormRequest = LocalStorageService.get('failedPickupFormRequest') ? LocalStorageService.get('failedPickupFormRequest') : '';
  completeAddress: string;
  updatedAddress: string;
  deferredDateArray : Array<any> = [];
  currentDate: any;
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  };
  isMobile: boolean;
  isTablet: boolean;

  constructor(private fb: FormBuilder, private titleService: Title, private route: ActivatedRoute, private store: Store<any>, private router: Router, private orderReturnService : OrderReturnService, private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    LocalStorageService.flush('failedPickupFormRequest');
    this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
    this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';

    /*Get user settings data from state*/
    this.store.select(state => state.orderPlaced.userSettings).subscribe(result =>   {
      if (result) {
              this.userSettings = result;
              this.titleService.setTitle(this.userSettings.pageTitle.failed_pickup);    
      }
    }); 

    /*Get Order Data*/
    this.store.select(state => state.orderPlaced.orderPlaced).subscribe(result =>   {
      if (result) {
              this.orderDetails = result; 
              this.showLoader = false;          
      }
    })

    this.initializeForm();
    this.checkLocalData();
  }

  /**
   * Method to define failedPickupForm info form group
   * @method initializeForm
   */
  private initializeForm() {
    this.failedPickupForm = this.fb.group({
      resolutionCode: ['', [Validators.required]],
      updatedAddress: '',
      remarks: '',
      deferredDate: '',
      contactPhoneNumber: '',
      landmark: ''
    });
    this.formControls = this.failedPickupForm.controls;
  }


  private checkLocalData() {
    if (this.failedPickupFormRequest) {
      this.radioChange(this.failedPickupFormRequest.resolutionCode);
      this.failedPickupForm.patchValue({
        resolutionCode: this.failedPickupFormRequest.resolutionCode ? this.failedPickupFormRequest.resolutionCode : '',
        updatedAddress: this.failedPickupFormRequest.shippingDetails.updatedAddress ? this.failedPickupFormRequest.shippingDetails.updatedAddress : '',
        deferredDate: this.failedPickupFormRequest.deferredDate ? this.failedPickupFormRequest.deferredDate : '',
        remarks: this.failedPickupFormRequest.remarks ? this.failedPickupFormRequest.remarks : '',
        contactPhoneNumber:  this.failedPickupFormRequest.contactPhoneNumber ? this.failedPickupFormRequest.contactPhoneNumber : '',
        landmark: this.failedPickupFormRequest.shippingDetails.landmark ? this.failedPickupFormRequest.shippingDetails.landmark : '',
      });
      
      if (this.failedPickupFormRequest.deferredDate){
        this.radioSelected = this.failedPickupFormRequest.deferredDate; 
      }
    }
  }

  radioChange(resolutionCode){
    this.resolutionValue = resolutionCode;
    if (resolutionCode === this.allResolutionCode.DIFFERENT_ADDRESS) {
      this.formControls.updatedAddress.setValidators([Validators.required]);
      this.fieldsValidationSetNull('updatedAddress'); 
      this.radioSelected = '';
    }else if (resolutionCode === this.allResolutionCode.CONTACT_NUMBER){
      this.formControls.contactPhoneNumber.setValidators([Validators.required, Validators.pattern(REGX_MOBILE)]);
      this.fieldsValidationSetNull('contactPhoneNumber');
      this.radioSelected = '';
    }else if (resolutionCode === this.allResolutionCode.FALSE_REASON){
      this.formControls.remarks.setValidators([Validators.required]);
      this.fieldsValidationSetNull('remarks');
      this.radioSelected = '';
    }else if (resolutionCode === this.allResolutionCode.LANDMARK){
      this.formControls.landmark.setValidators([Validators.required]);
      this.fieldsValidationSetNull('landmark');
      this.radioSelected = '';
    }else if (resolutionCode === this.allResolutionCode.DELIVERY_DATE){
        const startDate = new Date(this.selectedOrderItem.statusUpdatedAt.replace(' ', 'T'));
        const newDate = startDate.getDate();
        if (startDate){
          this.deferredDateArray = [];
          for (let i = 1; i <= 3; i++){
            const nxtDate =  formatDate(startDate.setDate( newDate + i ), 'yyyy-MM-dd', 'en');
            this.deferredDateArray.push(nxtDate);
          }
        }
      this.formControls.deferredDate.setValidators([Validators.required]);
      this.fieldsValidationSetNull('deferredDate');
    }else{
      this.fieldsValidationSetNull();
      this.radioSelected = '';
    }
  }

  deferredDateChange(value){
    this.radioSelected = value;
  }

  public fieldsValidationSetNull(currentElement= null)
  {
    this.resolutionFields.forEach(element => {
      if (element !== currentElement){
        this.formControls[element].setValidators(null);
        this.formControls[element].markAsUntouched();
        this.formControls[element].patchValue('');
        this.formControls[element].setErrors(null);
      }
    });
  }

  /**
   * form submission
   * @method onSubmit
   */
  public onSubmit(): void {
    if (this.failedPickupForm.valid) { 
      this.formLoader = true;
      LocalStorageService.flush('failedPickupFormRequest');
      LocalStorageService.flush('actionType');
      if (this.formControls.landmark.value){
        this.completeAddress = this.orderDetails.shippingAddress.addressLine1 + ', ' + this.orderDetails.shippingAddress.addressLine2 + ', ' + this.orderDetails.shippingAddress.city + ', ' + this.orderDetails.shippingAddress.state + ', ' + this.orderDetails.shippingAddress.postalCode;
      }

      if (this.formControls.updatedAddress.value){
        this.updatedAddress = this.formControls.updatedAddress.value + ', ' + this.orderDetails.shippingAddress.city + ', ' + this.orderDetails.shippingAddress.state + ', ' + this.orderDetails.shippingAddress.postalCode;
      }

      const formDataObject = {
          customerOrderNumber: this.orderDetails.customerOrderNumber,
          orderItemID: this.selectedOrderItem.orderItemID,
          resolutionCode: this.formControls.resolutionCode.value,
          trackingId: this.selectedOrderItem.reverseTrackingTimeline.trackingID,
          deferredDate: this.formControls.deferredDate.value ? this.formControls.deferredDate.value : '' ,
          shippingDetails: {
            currentAddress: this.formControls.landmark.value ? this.completeAddress : '',
            updatedAddress: this.formControls.updatedAddress.value ? this.updatedAddress : '',
            landmark: this.formControls.landmark.value ? this.formControls.landmark.value : ''
          },
          contactPhoneNumber: this.formControls.contactPhoneNumber.value ? this.formControls.contactPhoneNumber.value : '',
          remarks: this.formControls.remarks.value ? this.formControls.remarks.value : '',
          timeline: this.selectedOrderItem.timeLine 
      };

      LocalStorageService.set('actionType', 'failedPickupProcess');
      LocalStorageService.set('failedPickupFormRequest', formDataObject);
       if (formDataObject) {
        this.orderReturnService.failedPickupApiRequest(formDataObject).then(data => {
          this.formLoader = false;
          LocalStorageService.set('SuccessResponseData', data);
          LocalStorageService.flush('failedDeliveryFormRequest');
          this.router.navigate([ROUTES.REQUEST_SUBMISSION, this.orderId, this.orderItemId]);
        }).catch(err => {
          this.formLoader = false;
        });
      }
    }
  }

  setBackToOrder(){
    LocalStorageService.flush('failedPickupFormRequest');
    this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
  } 

  /*Left Panel Show Hide*/
  showLeftPanel(){
    this.leftPanelActive = !this.leftPanelActive;
  }

  displayLeftPanel(value){
    this.leftPanelActive = value;
  }

}
