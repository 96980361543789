<header *ngIf="userSettings && currentRoute!=currentOrigin"  [ngClass]="(footerKey && footerKey.length == 1) ? 'one-footer-link' : ''">
  <div [ngClass]="(footerKey && footerKey.length == 0) ? 'one-footer-link' : ''" >
  <a
    target="_blank"
    (click)="open_ace_com(userSettings?.websiteUrl)"
    class="brand brand-exclusive"
    *ngIf="userSettings?.websiteUrl && userSettings?.websiteUrl != 'null'"
    style="padding: 3px;"
    (mouseover)="url_color = true ; onMouseOver()"
    (mouseout)="url_color = false ; onMouseOut()"
    [ngStyle]="{ color: url_color ? getColor : '#828282' }"
  >
    <!-- <img src="{{ userSettings && userSettings.BrandlogoUrl ? userSettings.BrandlogoUrl:'' }}" alt="" /> -->
    <!-- <h1 style="padding: 14px;color: #2DDBD1;"></h1> -->
    <!-- src="https://ik.imagekit.io/2gwij97w0o/POE/link-icon.svg" -->
    <img 
    [src]="imgSrc"
     alt="" class="link" />
    <!-- <span [style.border-bottom-color]="url_color ? getColor : ''" -->
      <span 
      >Go to store</span
    >
  </a>

  <ng-container *ngFor="let keys of footerKey; let i = index">
    <a
      target="_blank"
      class="brand"
      (click)="open_ace_com(keys[1])"
      (mouseover)="mouseEnter(keys[0])"
      (mouseout)="mouseLeave(keys[0])"
      [ngStyle]="{ color: value == keys[0] ? getColor : '#828282' }"
    >
      {{ keys[0] }}
      <br />
    </a>
  </ng-container>
</div>
  <!-- <a  class="brand" *ngIf="userSettings">
      Privacy Policy    </a> -->
</header>
<div class="poweredby" *ngIf="userSettings && currentRoute!=currentOrigin">
  <a href="https://www.eshopbox.com/" target="_blank">
    <p style="word-wrap: no-wrap;">
      <a href="https://www.eshopbox.com/" target="_blank">
        <img src="https://ik.imagekit.io/2gwij97w0o/POE/eshopbox-monogram.svg" alt=""/></a
      >Powered by Eshopbox
    </p>
  </a>
</div>
<div class="page-loader" *ngIf="showLoader">
  <div class="loader-icon"></div>
</div>
