"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var store_1 = require("@ngrx/store");
// import { ValidationService } from '../../services/validation.service';
var global = require("../../../shared/constants/constants");
var cityOptionData = require("../../interceptors/mockdata/city.json");
var stateOptionData = require("../../interceptors/mockdata/state.json");
var services_1 = require("../../services");
var scrolling_service_1 = require("../../services/scrolling.service");
var shared_service_1 = require("../../services/shared.service");
var ngx_toastr_1 = require("ngx-toastr");
var rxjs_1 = require("rxjs");
var ChangeAddressComponent = /** @class */ (function () {
    function ChangeAddressComponent(preventScroll, fb, commonFormService, store, sharedService, toastr) {
        var _this = this;
        this.preventScroll = preventScroll;
        this.fb = fb;
        this.commonFormService = commonFormService;
        this.store = store;
        this.sharedService = sharedService;
        this.toastr = toastr;
        // public myGroup: FormGroup;
        this.cityData = cityOptionData;
        this.stateData = stateOptionData;
        this.showAddAddressForm = false;
        // @Input() addressFormValue: any;
        this.formReady = new core_1.EventEmitter();
        this.getExistingAddress = new core_1.EventEmitter;
        this.pincodeevent = new core_1.EventEmitter;
        this.showPincode = true;
        // ReversePickupPincode = true;
        this._addressFormValue = new rxjs_1.BehaviorSubject({});
        this.addressLoading = true;
        this._addressFormValue.subscribe(function (data) {
            // data.postalCode = 500000
            _this.addressFormValue = data;
            // if(this.addressFormData){
            //       const params = {
            //         "deliveryType": "reverse",
            //         "pickup_pincode" : 500000
            //         // "pickup_pincode" : this.addressFormData.postalCode
            //       }
            //       this.commonFormService.getCityState1(params)
            //       .then(data => {
            //         this.ReversePickupPincode = data.isReversePickup
            //         console.log("data",data,this.ReversePickupPincode)        
            //       }).catch(err => {
            //           this.setInvalidError();
            //         //throw err;
            //       });
            //     }
        });
    }
    Object.defineProperty(ChangeAddressComponent.prototype, "addressFormValue", {
        get: function () {
            return this._addressFormValue.getValue();
        },
        set: function (value) {
            this._addressFormValue.next(value);
        },
        enumerable: true,
        configurable: true
    });
    ChangeAddressComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.select(function (state) { return state.orderPlaced.userSettings; }).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                _this.getColor = _this.userSettings.actionColor;
            }
        });
        this.sharedService.currentMessage.subscribe(function (message) {
            return (_this.selectedMessage = message);
        }); //<= Always get current value!
        if (this.selectedMessage == 'hidePincode') {
            this.showPincode = false;
        }
        else {
            this.showPincode = true;
        }
        this.pickupAddress = services_1.LocalStorageService.get('pickupAddress');
        this.dynamicdata = false;
        this.addressInfoForm = this.fb.group({
            firstName: null,
            lastName: null,
            pinCode: null,
            state: null,
            address: null,
            // town: null,
            city: null,
            mobile: null,
            email: null,
            oldAddress: null,
            addressType: null,
            addressLine2: null,
            notes: null
        });
        // Emit the form group to the father to do whatever it wishes
        this.formReady.emit(this.addressInfoForm);
        if (this.dynamicdata) {
            // this.checkLocalData();
            this.showHideAddressSection(this.dynamicdata);
        }
        if (this.parentFormType === 'return') {
            this.checkLocalData();
        }
    };
    ChangeAddressComponent.prototype.checkLocalData = function () {
        if (this.addressFormValue && !this.showPickupAddress) {
            this.addressInfoForm.patchValue({
                firstName: this.addressFormValue.customerName.split(' ')[0],
                lastName: this.addressFormValue.customerName.split(' ')[1],
                pinCode: this.addressFormValue.postalCode,
                state: this.addressFormValue.state,
                city: this.addressFormValue.city,
                address: this.addressFormValue.addressLine1,
                email: this.addressFormValue.email,
                addressLine2: this.addressFormValue.addressLine2,
                notes: this.addressFormValue.notes,
                // town: this.addressFormValue.addressLine2,
                mobile: this.addressFormValue.contactPhone,
                addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
            });
        }
        if (this.pickupAddress && this.showPickupAddress) {
            this.addressInfoForm.patchValue({
                firstName: this.pickupAddress.customerName.split(' ')[0],
                lastName: this.pickupAddress.customerName.split(' ')[1],
                pinCode: this.pickupAddress.postalCode,
                state: this.pickupAddress.state,
                city: this.pickupAddress.city,
                address: this.pickupAddress.addressLine1,
                // landmark:this.addressFormValue.landmark,
                addressLine2: this.pickupAddress.addressLine2,
                mobile: this.pickupAddress.contactPhone,
                addressType: this.addressFormValue.addressType ? this.addressFormValue.addressType : '1',
            });
        }
    };
    ChangeAddressComponent.prototype.showHideAddressSection = function (cond, checked) {
        if (cond === void 0) { cond = false; }
        if (checked === void 0) { checked = false; }
        this.getAddress = true;
        this.getExistingAddress.emit(this.getAddress);
        this.preventScroll.disable();
        this.dynamicdata = cond;
        if (cond === true) {
            // this.addressInfoForm.controls.oldAddress.setValue('');
            // this.addressInfoForm.controls.oldAddress.setValidators(null);
            this.checkLocalData();
            this.addressInfoForm.controls.firstName.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.REGX_NAME)]);
            // this.addressInfoForm.controls.lastName.setValidators([Validators.required, Validators.pattern(global.REGX_NAME)]);
            this.addressInfoForm.controls.pinCode.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.REGX_PINCODE)]);
            this.addressInfoForm.controls.state.setValidators([forms_1.Validators.required]);
            this.addressInfoForm.controls.address.setValidators([forms_1.Validators.required]); //Validators.pattern(global.REGX_ADDRESS)
            // this.addressInfoForm.controls.landmark.setValidators([Validators.required]); //Validators.pattern(global.REGX_ADDRESS)
            // this.addressInfoForm.controls.addressLine2.setValidators([Validators.required]); //Validators.pattern(global.REGX_ADDRESS)
            // this.addressInfoForm.controls.town.setValidators([Validators.required]);  //Validators.pattern(global.REGX_ADDRESS)
            this.addressInfoForm.controls.city.setValidators([forms_1.Validators.required]);
            this.addressInfoForm.controls.email.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.REGX_EMAIL)]);
            this.addressInfoForm.controls.mobile.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.REGX_MOBILE)]);
            this.addressInfoForm.controls.addressType.setValidators([forms_1.Validators.required]);
        }
        else {
            // if (!checked)
            // {
            //   this.addressInfoForm.controls.oldAddress.setValidators([Validators.required]);
            // }
            this.addressInfoForm.controls.firstName.setValidators(null);
            this.addressInfoForm.controls.email.setValidators(null);
            this.addressInfoForm.controls.lastName.setValidators(null);
            this.addressInfoForm.controls.pinCode.setValidators(null);
            this.addressInfoForm.controls.state.setValidators(null);
            this.addressInfoForm.controls.address.setValidators(null);
            this.addressInfoForm.controls.landmark.setValidators(null);
            this.addressInfoForm.controls.addressLine2.setValidators(null);
            // this.addressInfoForm.controls.town.setValidators(null);
            this.addressInfoForm.controls.mobile.setValidators(null);
            this.addressInfoForm.controls.city.setValidators(null);
            // this.addressInfoForm.controls.oldAddress.setValidators(null);
            this.addressInfoForm.controls.addressType.setValidators(null);
        }
    };
    ChangeAddressComponent.prototype.closeEditAddress = function () {
        var _this = this;
        this.addressLoading = false;
        this.dynamicdata = false;
        // this.reversePickuperror = true;
        if (document.getElementById('addrDivId')) {
            var myDiv = document.getElementById('addrDivId');
            myDiv.scrollTop = 0;
        }
        this.preventScroll.enable();
        if (this.pickupAddress && this.pickupAddress.postalCode) {
            this.pickupAddresspostalCode = this.pickupAddress.postalCode;
        }
        else {
            this.pickupAddresspostalCode = this.addressFormValue.postalCode;
        }
        // this.ReversePickupPincode = false
        // if (event.currentTarget.value.length === 6) {
        var params = {
            "deliveryType": "reverse",
            "pickup_pincode": this.pickupAddresspostalCode
        };
        this.commonFormService.getCityState1(params)
            .then(function (data) {
            _this.addressLoading = true;
            if (data && data.isReversePickup) {
                _this.ReversePickupPincode = data.isReversePickup;
                _this.addressInfoForm.controls.state.setValue(data.state);
                _this.addressInfoForm.controls.city.setValue(data.city);
                //this condition will apply only in return process
                // if (this.parentFormType === 'return')
                // {
                if (_this.isCod === 1) {
                    if ((!data.isReversePickup && data.isCod === 1) || (data.isReversePickup && data.isCod === 0)) {
                        _this.setServiceableError();
                    }
                }
                else {
                    if (!data.isReversePickup) {
                        _this.setServiceableError();
                    }
                }
                // }
            }
            else {
                _this.ReversePickupPincode = data.isReversePickup;
                _this.setInvalidError();
            }
        }).catch(function (err) {
            _this.setInvalidError();
            //throw err;
        });
        // }
        // else{
        //   this.addressInfoForm.controls.city.setValue('');
        // this.addressInfoForm.controls.state.setValue('');
        // }
    };
    /**
        * City State Data using pincode
        * @method onPincodeChangeCityState
    */
    ChangeAddressComponent.prototype.onPincodeChangeCityState = function (event) {
        var _this = this;
        if (event.currentTarget.value.length === 0) {
            this.ReversePickupPincode = true;
        }
        if (event.currentTarget.value.length === 6) {
            var params = {
                "deliveryType": "reverse",
                "pickup_pincode": event.currentTarget.value
            };
            this.commonFormService.getCityState1(params)
                .then(function (data) {
                _this.ReversePickupPincode = data.isReversePickup;
                if (data && data.pincode) {
                    _this.addressInfoForm.controls.state.setValue(data.state);
                    _this.addressInfoForm.controls.city.setValue(data.city);
                    //this condition will apply only in return process
                    // if (this.parentFormType === 'return')
                    // {
                    if (_this.isCod === 1) {
                        if ((!data.isReversePickup && data.isCod === 1) || (data.isReversePickup && data.isCod === 0)) {
                            _this.setServiceableError();
                        }
                    }
                    else {
                        if (!data.isReversePickup) {
                            _this.setServiceableError();
                        }
                    }
                    // }
                }
                else {
                    _this.setInvalidError();
                }
            }).catch(function (err) {
                _this.setInvalidError();
                //throw err;
            });
        }
        else {
            this.addressInfoForm.controls.city.setValue('');
            this.addressInfoForm.controls.state.setValue('');
        }
    };
    /*Pincode Invalid And Serviceable Error*/
    ChangeAddressComponent.prototype.setInvalidError = function () {
        this.addressInfoForm.controls.pinCode.markAsTouched();
        this.addressInfoForm.controls.pinCode.setErrors({ invalid: true });
        this.addressInfoForm.controls.city.setValue('');
        this.addressInfoForm.controls.state.setValue('');
    };
    ChangeAddressComponent.prototype.setServiceableError = function () {
        this.addressInfoForm.controls.pinCode.markAsTouched();
        this.addressInfoForm.controls.pinCode.setErrors({ isServiceable: true });
    };
    ChangeAddressComponent.prototype.onSubmit = function () {
        this.ReversePickupPincode = true;
        // this.toastr.success('Hello world!', 'Toastr fun!');
        var notes = this.addressInfoForm.controls.notes.value ? this.addressInfoForm.controls.notes.value : '';
        this.pickupAddress = {
            "contactPhone": this.addressInfoForm.controls.mobile.value,
            "customerName": this.addressInfoForm.controls.firstName.value + ' ' + this.addressInfoForm.controls.lastName.value,
            "addressLine1": this.addressInfoForm.controls.address.value,
            "addressLine2": this.addressInfoForm.controls.addressLine2.value + ',' + notes,
            "notes": this.addressInfoForm.controls.notes.value ? this.addressInfoForm.controls.notes.value : '',
            "state": this.addressInfoForm.controls.state.value,
            "postalCode": this.addressInfoForm.controls.pinCode.value,
            "city": this.addressInfoForm.controls.city.value,
        };
        this.preventScroll.enable();
        this.dynamicdata = false;
        this.showPickupAddress = true;
        // this.toastr.success('', 'Pickup details have been successfully updated');
        services_1.LocalStorageService.set('pickupAddress', this.pickupAddress);
        var pickpincode = {
            "ReversePickupPincode": this.ReversePickupPincode = true,
            "reversePickuperror": this.reversePickuperror = true,
        };
        services_1.LocalStorageService.set('pickpincode', pickpincode);
        this.pincodeevent.emit(services_1.LocalStorageService.get('pickpincode'));
        //  this.addressFormValue.contactPhone=this.addressInfoForm.controls.mobile.value;
        //  this.addressFormValue.customerName= this.addressInfoForm.controls.firstName.value+' '+this.addressInfoForm.controls.lastName.value
        // this.addressFormValue.addressLine1=this.addressInfoForm.controls.address.value;
        // this.addressFormValue.addressLine2=this.addressInfoForm.controls.city.value;
        // this.addressFormValue.state=this.addressInfoForm.controls.state.value;
        // this.addressFormValue.postalCode=this.addressInfoForm.controls.pinCode.value;
        // this.addressFormValue.city=this.addressInfoForm.controls.city.value;
    };
    ChangeAddressComponent.prototype.getStyleWithoutOpacity = function () {
        return {
            border: "1px solid" + this.userSettings.actionColor,
            color: this.userSettings.actionColor
            //  opacity:0.6,
        };
    };
    ChangeAddressComponent.prototype.getStyleWithOpacity = function () {
        return {
            background: this.userSettings.actionColor,
            opacity: 0.05
        };
    };
    return ChangeAddressComponent;
}());
exports.ChangeAddressComponent = ChangeAddressComponent;
