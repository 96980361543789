"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./mob-header.component.sass.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./mob-header.component");
var i3 = require("@angular/router");
var i4 = require("../../services/auth.service");
var i5 = require("@ngrx/store");
var styles_MobHeaderComponent = [i0.styles];
var RenderType_MobHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobHeaderComponent, data: {} });
exports.RenderType_MobHeaderComponent = RenderType_MobHeaderComponent;
function View_MobHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_co.userSettings && _co.userSettings.brandLogoUrl) ? _co.userSettings.brandLogoUrl : ""), ""); _ck(_v, 0, 0, currVal_0); }); }
exports.View_MobHeaderComponent_0 = View_MobHeaderComponent_0;
function View_MobHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mob-header", [], null, null, null, View_MobHeaderComponent_0, RenderType_MobHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i2.MobHeaderComponent, [i3.Router, i4.AuthService, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MobHeaderComponent_Host_0 = View_MobHeaderComponent_Host_0;
var MobHeaderComponentNgFactory = i1.ɵccf("app-mob-header", i2.MobHeaderComponent, View_MobHeaderComponent_Host_0, {}, {}, []);
exports.MobHeaderComponentNgFactory = MobHeaderComponentNgFactory;
