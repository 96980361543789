import { Component, OnInit } from '@angular/core';
import { AuthService, LocalStorageService } from "../shared/services";
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.sass']
})
export class RedirectionComponent implements OnInit {
  encodedKey: any;
  showLoader = true;
  isMobile: boolean;
  isTablet: boolean;
  constructor(private activatedRoute: ActivatedRoute, private auth: AuthService, private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet(); 

    const urlParams = new URLSearchParams(window.location.search);

    const params = {
      'action': urlParams.get('action') ? urlParams.get('action') : localStorage.getItem("action"),
      'shipmentId': urlParams.get('s') ? urlParams.get('s') : localStorage.getItem("shipmentId"),
      'authToken': urlParams.get('authToken'),
      'proxyHost': urlParams.get('proxyHost'),
      'key' : urlParams.get('key'),
      'orderItemId' : urlParams.get('orderItemId'),
      'filters' : urlParams.get('filters'),
      'lastViewedItem' : urlParams.get('lastViewedItem'),
      'isDeliveryFailed' : urlParams.get('isDeliveryFailed'),
      'isCancelPickup' : urlParams.get('isCancelPickup'),
      'isCancelItem' : urlParams.get('isCancelItem'),
      'isReturnInitiated' : urlParams.get('isReturnInitiated'),
      'isReAttemptAction' : urlParams.get('isReAttemptAction'),
      'isaction' : urlParams.get('isaction')
    };

    //Get Encoded key from url
    this.encodedKey = this.activatedRoute.snapshot.paramMap.get('encodedKey') ? this.activatedRoute.snapshot.paramMap.get('encodedKey') : '';
    if (this.encodedKey) {
      LocalStorageService.flush();
      LocalStorageService.flush('token');
      this.auth.generateTokenWithEncodedKey(atob(this.encodedKey),params);
    }
  }

}
