import { Injectable } from "@angular/core";
import {
    HttpHeaders,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HTTP_INTERCEPTORS
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AlertService, AuthService } from "../services";
import { environment } from "../../../environments/environment";
import { CONSTANTS, ROUTES, PROXY_HEADER } from "../constants/constants";
import { Configuration } from "../configuration/config";
import { LocalStorageService } from "../../shared/services/index";
@Injectable({
    providedIn: "root"
})
export class TokenInterceptor implements HttpInterceptor {
    public headers = new HttpHeaders();
    constructor(
        public auth: AuthService,
        private alertService: AlertService,
        private config: Configuration
    ) { }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const hostName =
            window.location.hostname === "localhost"
                ? environment.LOCALHOST_API_URL
                : window.location.hostname;
        // const apiUrl = window.location.hostname === 'localhost' ? 'http://' : 'https://';
        if (!navigator.onLine) {
            this.alertService.showError(
                CONSTANTS.ALERT_MESSAGES.NO_INTERNET_CONNECTION
            );
        } else {
            // todo hack to be removed
            if (request.responseType !== "blob") {
                const token = this.auth.getAuthToken();
                const proxyHost =
                    window.location.hostname === "localhost" 
                        ? environment.LOCALHOST_API_URL
                        : window.location.origin;
                if (request.url.includes("update-status")) {
                    request = request.clone({
                        url: request.url,
                        setHeaders: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                            proxyHost: proxyHost,
                            "Access-Control-Allow-Origin": "*"
                        }
                    });
                } else if (token) {
                    //For LocalHost Env Only
                    if (hostName === environment.LOCALHOST_API_URL) {
                        request = request.clone({
                            url: environment.PROTOCOL + hostName + request.url,
                            setHeaders: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                                proxyHost: proxyHost,
                                "Access-Control-Allow-Origin": "*"
                            }
                        });
                    } else {
                        //For Staging And Poduction Env
                        // if (request.url.includes("/api/v1/tracking-url")) {
                        //   request = request.clone({
                        //     url: request.url,
                        //     setHeaders: {
                        //       "Content-Type": "application/json",
                        //       "Access-Control-Allow-Origin": "*"
                        //     }
                        //   });
                        // } else if (request.url.includes("/api/v1/poe-setting")) {
                        //   request = request.clone({
                        //     url: request.url
                        //   });
                        // } else {
                        request = request.clone({
                            url: environment.PROTOCOL + hostName + request.url,
                            setHeaders: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                                "Access-Control-Allow-Origin": "*"
                            }
                        });
                        // }
                    }
                } else {
                    // if (request.url.includes("/api/v1/tracking-url")) {
                    //   request = request.clone({
                    //     url: request.url,
                    //     setHeaders: {
                    //       "Content-Type": "application/json",
                    //       "Access-Control-Allow-Origin": "*"
                    //     }
                    //   });
                    // } else if (request.url.includes("/api/v1/poe-setting")) {
                    //   request = request.clone({
                    //     url: request.url
                    //   });
                    // } else {
                    request = request.clone({
                        url: environment.PROTOCOL + hostName + request.url,
                        setHeaders: {
                            "Content-Type": "application/json",
                            proxyHost: proxyHost,
                            "Access-Control-Allow-Origin": "*"
                        }
                    });
                    // }
                }
            }
            // if (!request.url.includes(this.config.API_URL)) {
            //   // this.sharedService.showHideLoader(true);
            // }
            return next.handle(request);
        }
    }
}
export let tokenProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
};