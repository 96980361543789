"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ReverseValuePipe = /** @class */ (function () {
    function ReverseValuePipe() {
    }
    ReverseValuePipe.prototype.transform = function (value) {
        return value.slice().reverse();
    };
    return ReverseValuePipe;
}());
exports.ReverseValuePipe = ReverseValuePipe;
