"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("../shared/services");
var router_1 = require("@angular/router");
var ngx_device_detector_1 = require("ngx-device-detector");
var RedirectionComponent = /** @class */ (function () {
    function RedirectionComponent(activatedRoute, auth, deviceService) {
        this.activatedRoute = activatedRoute;
        this.auth = auth;
        this.deviceService = deviceService;
        this.showLoader = true;
    }
    RedirectionComponent.prototype.ngOnInit = function () {
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        var urlParams = new URLSearchParams(window.location.search);
        var params = {
            'action': urlParams.get('action') ? urlParams.get('action') : localStorage.getItem("action"),
            'shipmentId': urlParams.get('s') ? urlParams.get('s') : localStorage.getItem("shipmentId"),
            'authToken': urlParams.get('authToken'),
            'proxyHost': urlParams.get('proxyHost'),
            'key': urlParams.get('key'),
            'orderItemId': urlParams.get('orderItemId'),
            'filters': urlParams.get('filters'),
            'lastViewedItem': urlParams.get('lastViewedItem'),
            'isDeliveryFailed': urlParams.get('isDeliveryFailed'),
            'isCancelPickup': urlParams.get('isCancelPickup'),
            'isCancelItem': urlParams.get('isCancelItem'),
            'isReturnInitiated': urlParams.get('isReturnInitiated'),
            'isReAttemptAction': urlParams.get('isReAttemptAction'),
            'isaction': urlParams.get('isaction')
        };
        //Get Encoded key from url
        this.encodedKey = this.activatedRoute.snapshot.paramMap.get('encodedKey') ? this.activatedRoute.snapshot.paramMap.get('encodedKey') : '';
        if (this.encodedKey) {
            services_1.LocalStorageService.flush();
            services_1.LocalStorageService.flush('token');
            this.auth.generateTokenWithEncodedKey(atob(this.encodedKey), params);
        }
    };
    return RedirectionComponent;
}());
exports.RedirectionComponent = RedirectionComponent;
