import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import filestack from "filestack-js";
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { HttpClient } from "@angular/common/http";
// const client = filestack.init(environment.FILESTACK_APIKEY);
@Injectable({
  providedIn: "root"
})
export class SharedService {
  constructor(private httpClient: HttpClient) {}
  actionColour = new Subject();
  loaderSource = new Subject<{ status: boolean }>();
  loaderListner = this.loaderSource.asObservable();
  showHideLoader(status: boolean) {
    this.loaderSource.next({ status: status });
  }

  private isOpen: boolean;

  public subject = new Subject<any>();
  private messageSource = new BehaviorSubject("test");
  currentMessage = this.messageSource.asObservable();

  setOpen(data) {
    this.isOpen = data;
  }
  getData() {
    return this.isOpen;
  }
  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  // uploadDocument()
  // {
  //   return client
  //   .pick({
  //     maxFiles: 10,
  //     accept: [".pdf", ".jpg", ".xls", ".csv", ".xlsx", ".png", ".jpeg"],
  //     uploadInBackground: false,
  //     startUploadingWhenMaxFilesReached: true,
  //     fromSources: [
  //       "local_file_system",
  //       "imagesearch",
  //       "googledrive",
  //       "dropbox"
  //     ],
  //     onOpen: () => {},
  //     rejectOnCancel: true
  //     // storeTo: {
  //     //   location: "gcs",
  //     //   path: "/import_jobs/"
  //     // }
  //   })
  // }
  getPoeData(apiUrl) {
    // const apiUrl = "http://test_test_ajeet98.auperator.net/api/v1/poe-settingV2";
    return this.httpClient.get(apiUrl);
  }
}
