"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var global = require("../../../shared/constants/constants");
var forms_1 = require("@angular/forms");
var index_1 = require("../../../shared/services/index");
var scrolling_service_1 = require("../../../shared/services/scrolling.service");
var ngx_device_detector_1 = require("ngx-device-detector");
var CancelStepOneComponent = /** @class */ (function () {
    function CancelStepOneComponent(preventScroll, fb, store, titleService, router, route, orderReturnService, alertService, deviceService) {
        this.preventScroll = preventScroll;
        this.fb = fb;
        this.store = store;
        this.titleService = titleService;
        this.router = router;
        this.route = route;
        this.orderReturnService = orderReturnService;
        this.alertService = alertService;
        this.deviceService = deviceService;
        this.isBoxshadow = false;
        this.rupeeSymbol = global.RUPEE_SYMBOL;
        this.leftPanelActive = false;
        this.itemsArray = [];
        this.loading = false;
        this.showLoader = true;
        this.ifscShowLoader = false;
        this.accountTypes = global.ACCOUNT_TYPES;
        this.cancelOrderItemDetails = index_1.LocalStorageService.get('processOrderItemDetails') ? index_1.LocalStorageService.get('processOrderItemDetails') : '';
        this.bankDetailsControls = ['accountHolderName', 'accountNumber', 'confirmAccountNumber', 'ifscCode', 'bankName', 'branch', 'accountType'];
        this.isHelpOpen = false;
        this.isHelpOpendelay = false;
        this.ifarrowTop = true;
    }
    CancelStepOneComponent.prototype.ngOnInit = function () {
        var _this = this;
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('top-header');
        this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
        this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';
        /*Get user settings data from state*/
        this.store.select(function (state) { return state.orderPlaced.userSettings; }).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                // console.log("userSettings",this.userSettings)
                if (_this.userSettings.footerLinks) {
                    _this.footerKey = Object.entries(_this.userSettings.footerLinks);
                }
                _this.getColor = _this.userSettings.actionColor;
                _this.titleService.setTitle("Cancel Item");
            }
        });
        /*Get Order Data*/
        this.store.select(function (state) { return state.orderPlaced.orderPlaced; }).subscribe(function (result) {
            if (result) {
                _this.orderDetails = result;
                _this.addressFormValue = _this.orderDetails ? _this.orderDetails.shippingAddress : '';
            }
        });
        this.initializeForm();
    };
    CancelStepOneComponent.prototype.onSelectShowOrderItemDetail = function (item) {
        this.selectedItem = item;
        if (this.selectedItem.originalOrderItemId && this.selectedItem.originalOrderItemId > 0) {
            this.originalOrderItemId = this.selectedItem.originalOrderItemId;
        }
    };
    /**
     * Method to define cancelItemForm info form group
     * @method initializeForm
     */
    CancelStepOneComponent.prototype.initializeForm = function () {
        this.cancelItemForm = this.fb.group({
            // cancelReason: ['', [Validators.required]],
            additionalReason: [''],
            refundMode: ['originalPaymentMode', [forms_1.Validators.required]],
            accountHolderName: [''],
            accountNumber: [''],
            confirmAccountNumber: [''],
            ifscCode: [''],
            bankName: [''],
            bankCity: [''],
            branch: [''],
            addressInfo: [''],
            accountType: [''],
        }, {
            validator: index_1.ValidationService.MustMatch('accountNumber', 'confirmAccountNumber')
        });
        this.formControls = this.cancelItemForm.controls;
        // console.log("formControls",this.formControls)
    };
    CancelStepOneComponent.prototype.formInitialized = function (name, form) {
        this.cancelItemForm.setControl(name, form);
    };
    /**
     * form submission
     * @method onSubmit
     */
    CancelStepOneComponent.prototype.onSubmit = function () {
        var _this = this;
        //  console.log("this.cancelItemForm.",LocalStorageService.get('AdditionalComments'),
        //  LocalStorageService.get('cancellationReasons'),this.cancelItemForm)
        index_1.LocalStorageService.flush('cancelItemRequest');
        index_1.LocalStorageService.flush('actionType');
        var itemDetails = {
            lineItemSequenceNumber: this.cancelOrderItemDetails.lineItemSequenceNumber ? this.cancelOrderItemDetails.lineItemSequenceNumber : this.selectedItem.lineItemSequenceNumber,
            orderItemID: this.cancelOrderItemDetails.orderItemID ? this.cancelOrderItemDetails.orderItemID : this.selectedItem.orderItemID,
            itemID: this.cancelOrderItemDetails.itemID ? this.cancelOrderItemDetails.itemID : this.selectedItem.itemID,
            quantity: this.cancelOrderItemDetails.quantity ? this.cancelOrderItemDetails.quantity.toString() : this.selectedItem.quantity.toString(),
            productName: this.cancelOrderItemDetails.productName ? this.cancelOrderItemDetails.productName : this.selectedItem.productName,
            remark: 'cancelled by customer',
            additionalInfo: {
                customerUploadImageUrl: "",
                comment: ""
            }
        };
        if (this.formControls.accountNumber.value) {
            this.refundDetails = {
                beneficiaryName: this.formControls.accountHolderName.value,
                beneficiaryAccountNo: this.formControls.accountNumber.value,
                beneficiaryIfscCode: this.formControls.ifscCode.value,
                beneficiaryBankName: this.formControls.bankName.value,
                beneficiaryBranch: this.formControls.branch.value,
                beneficiaryAccountType: this.formControls.accountType.value,
                beneficiaryBankCity: this.formControls.bankCity.value
            };
        }
        if (this.formControls.addressInfo.controls) {
            this.pickupAddress = {
                "customerName": this.formControls.addressInfo.controls.firstName.value + ' ' + this.formControls.addressInfo.controls.lastName.value,
                "addressLine1": this.formControls.addressInfo.controls.address.value,
                "addressLine2": '',
                "city": this.formControls.addressInfo.controls.city.value,
                "state": this.formControls.addressInfo.controls.state.value,
                "postalCode": this.formControls.addressInfo.controls.pinCode.value,
                "countryCode": '',
                "countryName": '',
                "contactPhone": this.formControls.addressInfo.controls.mobile.value,
                "email": ''
            };
        }
        // if (this.cancelItemForm.valid) { }
        // if (this.cancelItemRequest) {
        this.cancelItemRequest = {
            actor: localStorage.getItem("clientPortalemail") ? localStorage.getItem("clientPortalemail") : index_1.LocalStorageService.get('actorcutomername'),
            actorType: localStorage.getItem("clientPortalemail") ? 'seller' : 'buyer',
            additionalReason: index_1.LocalStorageService.get('AdditionalComments'),
            customerOrderNumber: this.orderDetails.customerOrderNumber,
            reason: index_1.LocalStorageService.get('cancellationReasons'),
            isCOD: this.orderDetails.isCOD.toString(),
            items: this.itemsArray,
            pickupAddress: this.pickupAddress ? this.pickupAddress : null,
            refundBankAccountDetails: this.refundDetails ? this.refundDetails : null,
            refundMode: this.formControls.refundMode.value,
        };
        this.itemsArray.push(itemDetails);
        // console.log("cancelItemRequest",this.cancelItemRequest)
        if (this.cancelItemRequest) {
            this.loading = true;
            index_1.LocalStorageService.set('actionType', 'cancelProcess');
            index_1.LocalStorageService.set('cancelItemRequest', this.cancelItemRequest);
            this.orderReturnService.cancelItemsApiRequest(this.cancelItemRequest)
                .then(function (data) {
                // console.log("data",data)
                _this.loading = false;
                index_1.LocalStorageService.set('SuccessResponseData', data);
                index_1.LocalStorageService.flush('cancelItemRequest');
                index_1.LocalStorageService.set("cancelItemRequestTrue", true);
                _this.router.navigate([
                    global.ROUTES.ORDER_PLACED,
                    _this.orderId,
                    _this.orderItemId
                ]);
                // this.router.navigate([global.ROUTES.REQUEST_SUBMISSION, this.orderId, this.orderItemId]);
            }).catch(function (err) {
                _this.loading = false;
                // console.log("err",err)
                _this.alertService.showInfo(err.error.error.message);
                // throw err;
            });
        }
    };
    CancelStepOneComponent.prototype.displayLeftPanel = function (value) {
        this.leftPanelActive = value;
    };
    CancelStepOneComponent.prototype.onScroll = function () {
        this.scrollTopPosition = this.scrollElement.nativeElement.scrollTop;
        if (this.scrollTopPosition > 0) {
            this.isBoxshadow = true;
        }
        else {
            this.isBoxshadow = false;
        }
    };
    CancelStepOneComponent.prototype.setBackToOrder = function () {
        this.router.navigate([global.ROUTES.ORDER_CANCEL, this.orderId, this.orderItemId]);
    };
    CancelStepOneComponent.prototype.openHelpModal = function () {
        this.preventScroll.disable();
        this.isHelpOpen = !this.isHelpOpen;
        this.isHelpOpendelay = !this.isHelpOpendelay;
        document.querySelector("body").classList.add("stop-scrolling");
    };
    CancelStepOneComponent.prototype.closeHelpModal = function () {
        var _this = this;
        this.isHelpOpen = false;
        // this.preventScroll.enable();
        if (!this.deviceService.isDesktop()) {
            setTimeout(function () {
                _this.isHelpOpendelay = false;
            }, 350);
        }
        else
            this.isHelpOpendelay = false;
        document.querySelector("body").classList.remove("stop-scrolling");
    };
    CancelStepOneComponent.prototype.mouseEnter = function (value) {
        this.Helpcolor = true;
    };
    CancelStepOneComponent.prototype.mouseLeave = function (value) {
        this.Helpcolor = false;
    };
    CancelStepOneComponent.prototype.callIfscCodeCheck = function (event) {
        var _this = this;
        if (event.currentTarget.value.length === 11) {
            this.ifscShowLoader = true;
            this.orderReturnService.getIfscCodeDetails(event.currentTarget.value)
                .then(function (data) {
                if (data) {
                    _this.ifscShowLoader = false;
                    _this.formControls.bankName.setValue(data.BANK);
                    _this.formControls.bankCity.setValue(data.CITY);
                    _this.formControls.branch.setValue(data.BRANCH);
                }
                else {
                    _this.formControls.ifscCode.markAsTouched();
                    _this.formControls.ifscCode.setErrors({ invalid: true });
                    _this.formControls.bankName.setValue(null);
                    _this.formControls.bankCity.setValue(null);
                    _this.formControls.branch.setValue(null);
                }
            }).catch(function (err) {
                _this.formControls.ifscCode.markAsTouched();
                _this.formControls.ifscCode.setErrors({ invalid: true });
                _this.formControls.bankName.setValue(null);
                _this.formControls.bankCity.setValue(null);
                _this.formControls.branch.setValue(null);
                _this.ifscShowLoader = false;
                throw err;
            });
        }
    };
    CancelStepOneComponent.prototype.radioChange = function ($event) {
        // console.log($event.value);
        var _this = this;
        if ($event.value === 'bank') {
            // Do whatever you want here
            this.bankDetails = true;
            if ($event.value === "bank") {
                // console.log("this.formControls",this.formControls,this.bankDetailsControls   )
                // this.showBankDetailsForm = true;
                // this.cancelItemForm.patchValue({ accountHolderName : this.orderDetails.billingAddress.customerName});
                this.formControls.accountHolderName.setValidators([forms_1.Validators.required]);
                this.formControls.accountNumber.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.NUMBERS_ONLY_REGEX), forms_1.Validators.minLength(5), forms_1.Validators.maxLength(25)]);
                this.formControls.confirmAccountNumber.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.NUMBERS_ONLY_REGEX)]);
                this.formControls.ifscCode.setValidators([forms_1.Validators.required, forms_1.Validators.minLength(11), forms_1.Validators.pattern(global.IFSC_CODE_REGEX)]);
                // this.formControls.bankName.setValidators([Validators.required]); //, Validators.pattern(global.REGX_NAME)
                // this.formControls.branch.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(40)]);
                this.formControls.accountType.setValidators([forms_1.Validators.required]);
            }
            else {
                // this.showBankDetailsForm = false;  
                this.bankDetailsControls.forEach(function (element) {
                    _this.formControls[element].setValidators(null);
                    _this.formControls[element].markAsUntouched();
                    _this.formControls[element].patchValue('');
                    _this.formControls[element].setErrors(null);
                });
            }
        }
        if ($event.value === 'originalPaymentMode' || $event.value === 'storeCredit') {
            this.bankDetails = false;
            this.bankDetailsControls.forEach(function (element) {
                _this.formControls[element].setValidators(null);
                _this.formControls[element].markAsUntouched();
                _this.formControls[element].patchValue('');
                _this.formControls[element].setErrors(null);
            });
        }
    };
    /**
    * Refund Mode
    * @method onPaymentModeChange
    */
    CancelStepOneComponent.prototype.onPaymentModeChange = function (value, onchange) {
        var _this = this;
        if (onchange === void 0) { onchange = false; }
        if (value === "bank") {
            // this.showBankDetailsForm = true;
            // this.cancelItemForm.patchValue({ accountHolderName : this.orderDetails.billingAddress.customerName});
            this.formControls.accountHolderName.setValidators([forms_1.Validators.required]);
            this.formControls.accountNumber.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.NUMBERS_ONLY_REGEX), forms_1.Validators.minLength(5), forms_1.Validators.maxLength(25)]);
            this.formControls.confirmAccountNumber.setValidators([forms_1.Validators.required, forms_1.Validators.pattern(global.NUMBERS_ONLY_REGEX)]);
            this.formControls.ifscCode.setValidators([forms_1.Validators.required, forms_1.Validators.minLength(11), forms_1.Validators.pattern(global.IFSC_CODE_REGEX)]);
            // this.formControls.bankName.setValidators([Validators.required]); //, Validators.pattern(global.REGX_NAME)
            // this.formControls.branch.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(40)]);
            this.formControls.accountType.setValidators([forms_1.Validators.required]);
        }
        else {
            // this.showBankDetailsForm = false;  
            this.bankDetailsControls.forEach(function (element) {
                _this.formControls[element].setValidators(null);
                _this.formControls[element].markAsUntouched();
                _this.formControls[element].patchValue('');
                _this.formControls[element].setErrors(null);
            });
        }
    };
    CancelStepOneComponent.prototype.showFooterClick = function () {
        this.showFooter = true;
        this.ifarrowTop = false;
        this.ifarrowBottom = true;
    };
    CancelStepOneComponent.prototype.hideFooterClick = function () {
        this.ifarrowTop = true;
        this.ifarrowBottom = false;
        this.showFooter = false;
    };
    return CancelStepOneComponent;
}());
exports.CancelStepOneComponent = CancelStepOneComponent;
