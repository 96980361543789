"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./notice-message-header.component.sass.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./notice-message-header.component");
var i4 = require("@angular/router");
var i5 = require("@ngrx/store");
var i6 = require("../../services/alert.service");
var i7 = require("@angular/platform-browser");
var i8 = require("ngx-device-detector");
var styles_NoticeMessageHeaderComponent = [i0.styles];
var RenderType_NoticeMessageHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoticeMessageHeaderComponent, data: {} });
exports.RenderType_NoticeMessageHeaderComponent = RenderType_NoticeMessageHeaderComponent;
function View_NoticeMessageHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "topstirp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ". "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.userSettings == null) ? null : _co.userSettings.noticeMessage); _ck(_v, 2, 0, currVal_0); }); }
function View_NoticeMessageHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoticeMessageHeaderComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.userSettings == null) ? null : _co.userSettings.notifyCustomers); _ck(_v, 2, 0, currVal_0); }, null); }
function View_NoticeMessageHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["class", "black-top-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "../../../../assets/images/info-white.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You are taking action on behalf of the customer. "]))], null, null); }
function View_NoticeMessageHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoticeMessageHeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoticeMessageHeaderComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.userSettings == null) ? null : _co.userSettings.noticeMessage) != "null"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showStrip; _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_NoticeMessageHeaderComponent_0 = View_NoticeMessageHeaderComponent_0;
function View_NoticeMessageHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notice-message-header", [], null, null, null, View_NoticeMessageHeaderComponent_0, RenderType_NoticeMessageHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.NoticeMessageHeaderComponent, [i4.Router, i5.Store, i6.AlertService, i4.ActivatedRoute, i7.Title, i8.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_NoticeMessageHeaderComponent_Host_0 = View_NoticeMessageHeaderComponent_Host_0;
var NoticeMessageHeaderComponentNgFactory = i1.ɵccf("app-notice-message-header", i3.NoticeMessageHeaderComponent, View_NoticeMessageHeaderComponent_Host_0, {}, {}, []);
exports.NoticeMessageHeaderComponentNgFactory = NoticeMessageHeaderComponentNgFactory;
