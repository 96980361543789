import { createSelector, createFeatureSelector } from "@ngrx/store";
import { OrderReturnState } from "./order-return.state";

const getReasonState = (state: OrderReturnState['returnReason']) => state.returnReason;

// export const getReturnReason = createFeatureSelector('getReasonState');

export const getReturnReason = createSelector(
  getReasonState,
  getReasonState
);


// export const getReturnItem = createSelector(
//   getReturnFormState,
//   getReturnFormState
// )
