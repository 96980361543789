"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ɵ0 = [];
exports.ɵ0 = ɵ0;
var AppMaterialModule = /** @class */ (function () {
    function AppMaterialModule() {
    }
    return AppMaterialModule;
}());
exports.AppMaterialModule = AppMaterialModule;
