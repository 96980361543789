"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var store_1 = require("@ngrx/store");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var constants_1 = require("../shared/constants/constants");
var index_1 = require("../shared/services/index");
var order_return_service_1 = require("../shared/services/order-return.service");
var router_2 = require("@angular/router");
var ngx_device_detector_1 = require("ngx-device-detector");
var shared_service_1 = require("../shared/services/shared.service");
var common_1 = require("@angular/common");
var scrolling_service_1 = require("../shared/services/scrolling.service");
var OrderReturnCancelComponent = /** @class */ (function () {
    function OrderReturnCancelComponent(preventScroll, fb, titleService, decimalPipe, route, store, router, orderReturnService, deviceService, sharedService, alertService) {
        this.preventScroll = preventScroll;
        this.fb = fb;
        this.titleService = titleService;
        this.decimalPipe = decimalPipe;
        this.route = route;
        this.store = store;
        this.router = router;
        this.orderReturnService = orderReturnService;
        this.deviceService = deviceService;
        this.sharedService = sharedService;
        this.alertService = alertService;
        this.isBoxshadow = false;
        this.leftPanelActive = false;
        this.formLoader = false;
        this.showLoader = true;
        this.returnCancelItemFormRequest = index_1.LocalStorageService.get('returnCancelItemFormRequest') ? index_1.LocalStorageService.get('returnCancelItemFormRequest') : '';
        this.selectedOrderItem = index_1.LocalStorageService.get('processOrderItemDetails') ? index_1.LocalStorageService.get('processOrderItemDetails') : '';
        this.isHelpOpen = false;
        this.isHelpOpendelay = false;
        this.ifarrowTop = true;
    }
    OrderReturnCancelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        // LocalStorageService.flush('returnCancelItemFormRequest');
        this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
        this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';
        /*Get user settings data from state*/
        this.store.select(function (state) { return state.orderPlaced.userSettings; }).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                _this.footerKey = Object.entries(_this.userSettings.footerLinks);
                _this.getColor = _this.userSettings.actionColor;
                _this.titleService.setTitle("Cancel Return");
            }
        });
        /*Get Order Data*/
        this.store.select(function (state) { return state.orderPlaced.orderPlaced; }).subscribe(function (result) {
            if (result) {
                _this.orderDetails = result;
                _this.showLoader = false;
            }
        });
        this.initializeForm();
        this.returnCancellationForm.controls['returnCancelReason'].valueChanges
            .subscribe(function () {
            _this.returnCancellationForm.controls['returnCancelReason'].markAsTouched();
        });
    };
    OrderReturnCancelComponent.prototype.onSelectShowOrderItemDetail = function (item) {
        this.item = item;
    };
    /**
     * Method to define cancelItemForm info form group
     * @method initializeForm
     */
    OrderReturnCancelComponent.prototype.initializeForm = function () {
        this.returnCancellationForm = this.fb.group({
            returnCancelReason: ['', [forms_1.Validators.required, forms_1.Validators.maxLength(100)]]
        });
        this.formControls = this.returnCancellationForm.controls;
        // this.formControls.returnCancelReason= LocalStorageService.get('returnCancelReason')
        this.returnCancellationForm.controls.returnCancelReason.setValue(index_1.LocalStorageService.get('returnCancelReason'));
    };
    /**
     * form submission
     * @method onSubmit
     */
    OrderReturnCancelComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.returnCancellationForm.valid) {
            this.formLoader = true;
            index_1.LocalStorageService.flush('returnCancelItemFormRequest');
            index_1.LocalStorageService.flush('actionType');
            index_1.LocalStorageService.set('returnCancelReason', this.formControls.returnCancelReason.value);
            // const formDataObject = {
            //       "returnTrackingID": this.selectedOrderItem.reverseTrackingTimeline.trackingID,
            //       "returnCancelReason": this.formControls.returnCancelReason.value,
            //       "customerOrderNumber": this.orderDetails.customerOrderNumber,
            //       "orderItemID": this.selectedOrderItem.orderItemID
            // };
            var formDataObject = {
                orderItemIdPk: this.selectedOrderItem.id,
                actor: localStorage.getItem("clientPortalemail") ? localStorage.getItem("clientPortalemail") : index_1.LocalStorageService.get('actorcutomername'),
                actorType: localStorage.getItem("clientPortalemail") ? 'seller' : 'buyer',
                "returnTrackingID": this.selectedOrderItem.trackingID ? this.selectedOrderItem.reverseTrackingTimeline.trackingID : this.item.reverseTrackingTimeline.trackingID,
                "remarks": this.formControls.returnCancelReason.value,
                "customerOrderNumber": this.orderDetails.customerOrderNumber,
                "orderItemID": this.selectedOrderItem.orderItemID ? this.selectedOrderItem.orderItemID : this.item.orderItemID
            };
            index_1.LocalStorageService.set('actionType', 'returnCancelProcess');
            index_1.LocalStorageService.set('returnCancelItemFormRequest', formDataObject);
            if (formDataObject) {
                this.orderReturnService.returnCancelItemApiRequest(formDataObject).then(function (data) {
                    _this.formLoader = false;
                    index_1.LocalStorageService.set('SuccessResponseData', data);
                    index_1.LocalStorageService.flush('returnCancelItemFormRequest');
                    _this.router.navigate([constants_1.ROUTES.REQUEST_SUBMISSION, _this.orderId, _this.orderItemId]);
                    _this.returnCancellationForm.reset();
                }).catch(function (err) {
                    _this.formLoader = false;
                    _this.alertService.showError(err.error.message);
                });
            }
        }
    };
    OrderReturnCancelComponent.prototype.setBackToOrder = function () {
        this.sharedService.setOpen(true);
        index_1.LocalStorageService.flush('returnCancelItemFormRequest');
        index_1.LocalStorageService.flush('returnCancelReason');
        localStorage.setItem('foo', 'no reload');
        this.router.navigate([constants_1.ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
    };
    OrderReturnCancelComponent.prototype.showLeftPanel = function () {
        this.leftPanelActive = !this.leftPanelActive;
    };
    OrderReturnCancelComponent.prototype.displayLeftPanel = function (value) {
        this.leftPanelActive = value;
    };
    OrderReturnCancelComponent.prototype.onScroll = function () {
        this.scrollTopPosition = this.scrollElement.nativeElement.scrollTop;
        if (this.scrollTopPosition > 0) {
            this.isBoxshadow = true;
        }
        else {
            this.isBoxshadow = false;
        }
    };
    // Help Modal
    OrderReturnCancelComponent.prototype.openHelpModal = function () {
        this.preventScroll.disable();
        this.isHelpOpen = !this.isHelpOpen;
        this.isHelpOpendelay = !this.isHelpOpendelay;
        document.querySelector("body").classList.add("stop-scrolling");
    };
    OrderReturnCancelComponent.prototype.closeHelpModal = function () {
        var _this = this;
        // this.preventScroll.enable();
        this.isHelpOpen = false;
        if (!this.deviceService.isDesktop()) {
            setTimeout(function () {
                _this.isHelpOpendelay = false;
            }, 350);
        }
        else
            this.isHelpOpendelay = false;
        document.querySelector("body").classList.remove("stop-scrolling");
    };
    OrderReturnCancelComponent.prototype.open_ace_com = function (siteUrl) {
        window.open(siteUrl, '_blank');
    };
    OrderReturnCancelComponent.prototype.openSite = function (siteUrl) {
        window.open(siteUrl, '_blank');
    };
    OrderReturnCancelComponent.prototype.changeColor = function (value) {
        if (value) {
            this.color = true;
        }
        else {
            this.color = false;
        }
    };
    OrderReturnCancelComponent.prototype.mouseEnter = function (value) {
        this.Helpcolor = true;
    };
    OrderReturnCancelComponent.prototype.mouseLeave = function (value) {
        this.Helpcolor = false;
    };
    OrderReturnCancelComponent.prototype.transformDecimal = function (num) {
        return this.decimalPipe.transform(num, '2.');
    };
    OrderReturnCancelComponent.prototype.showFooterClick = function () {
        this.showFooter = true;
        this.ifarrowTop = false;
        this.ifarrowBottom = true;
    };
    OrderReturnCancelComponent.prototype.hideFooterClick = function () {
        this.ifarrowTop = true;
        this.ifarrowBottom = false;
        this.showFooter = false;
    };
    return OrderReturnCancelComponent;
}());
exports.OrderReturnCancelComponent = OrderReturnCancelComponent;
