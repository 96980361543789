"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("../../../environments/environment");
var config_1 = require("../configuration/config");
var api_service_1 = require("./api.service");
var i0 = require("@angular/core");
var i1 = require("./api.service");
var i2 = require("../configuration/config");
var OrderReturnService = /** @class */ (function () {
    function OrderReturnService(api, config) {
        this.api = api;
        this.config = config;
        this.API_URL = environment_1.environment.API_URL;
        this.headers = new http_1.HttpHeaders();
    }
    OrderReturnService.prototype.getReturnReason = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.get(this.API_URL + "order-items/return-reason?", {
            params: params,
            headers: this.headers
        });
    };
    OrderReturnService.prototype.returnItemApiRequest = function (body) {
        return this.api
            .post(this.API_URL + "order-item/returnOrder", body, this.headers)
            .toPromise()
            .then(function (res) {
            if (res) {
                return res;
            }
            throw res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    OrderReturnService.prototype.cancelItemsApiRequest = function (body) {
        return this.api
            .post(this.API_URL + "order-items/cancel", body, this.headers)
            .toPromise()
            .then(function (res) {
            if (res) {
                return res;
            }
            throw res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    OrderReturnService.prototype.getIfscCodeDetails = function (params) {
        if (params === void 0) { params = {}; }
        return this.api
            .get(this.API_URL + "ifscCode/" + params)
            .toPromise()
            .then(function (res) {
            if (res) {
                return res;
            }
            throw res;
        })
            .catch(function (err) {
            //throw err;
        });
    };
    OrderReturnService.prototype.generateOtp = function (mobileNumber) {
        return this.api.get(this.API_URL + "auth/otp/generate?mobile=" + mobileNumber);
    };
    OrderReturnService.prototype.verifyOtp = function (body) {
        return this.api
            .post(this.API_URL + "auth/otp/validate", body, this.headers)
            .toPromise()
            .then(function (res) {
            if (res) {
                return res;
            }
        })
            .catch(function (err) {
            throw err;
        });
    };
    OrderReturnService.prototype.getExchangeRelatedProducts = function (params) {
        if (params === void 0) { params = {}; }
        return this.api
            .get(this.API_URL + "exchange-option/" + params)
            .toPromise()
            .then(function (res) {
            if (res) {
                return res;
            }
        })
            .catch(function (err) { });
    };
    OrderReturnService.prototype.getStock = function (params) {
        if (params === void 0) { params = {}; }
        return this.api
            .get(this.API_URL + "get-stock/" + params)
            .toPromise()
            .then(function (res) {
            if (res) {
                return res;
            }
        })
            .catch(function (err) { });
    };
    OrderReturnService.prototype.returnCancelItemApiRequest = function (body) {
        return this.api
            .post(this.API_URL + "order-items/return/cancel", body, this.headers)
            .toPromise()
            .then(function (res) {
            if (res) {
                return res;
            }
            throw res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    OrderReturnService.prototype.failedDeliveryApiRequest = function (body) {
        return this.api
            .post(this.API_URL + "ndrResolution", body, this.headers)
            .toPromise()
            .then(function (res) {
            if (res) {
                return res;
            }
            throw res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    OrderReturnService.prototype.deliveryDetailsApiRequest = function (body) {
        return this.api
            .post(this.API_URL + "deliveryDetails", body, this.headers)
            .toPromise()
            .then(function (res) {
            if (res) {
                return res;
            }
            throw res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    OrderReturnService.prototype.failedPickupApiRequest = function (body) {
        return this.api
            .post(this.API_URL + "ndrResolution", body, this.headers)
            .toPromise()
            .then(function (res) {
            if (res) {
                return res;
            }
            throw res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    OrderReturnService.ngInjectableDef = i0.defineInjectable({ factory: function OrderReturnService_Factory() { return new OrderReturnService(i0.inject(i1.ApiService), i0.inject(i2.Configuration)); }, token: OrderReturnService, providedIn: "root" });
    return OrderReturnService;
}());
exports.OrderReturnService = OrderReturnService;
