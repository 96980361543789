import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, Observable } from "rxjs";
import { select, Store } from "@ngrx/store";

import { DeviceDetectorService } from 'ngx-device-detector';
import { AlertService, LocalStorageService } from '../../services';
// import { AppState } from 'src/app/app.state';
// import { AppState } from '../app.state';
@Component({
  selector: 'app-notice-message-header',
  templateUrl: './notice-message-header.component.html',
  styleUrls: ['./notice-message-header.component.sass']
})
export class NoticeMessageHeaderComponent implements OnInit {
  userSettingssubscriptions$ = new Subscription();
  userSettings: any;
  color: any;
  showStrip:boolean;

  constructor(private router: Router , private store: Store<any>, private alertService: AlertService, private route: ActivatedRoute, private titleService: Title, private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams = {
      'action': urlParams.get('action'),
      'shipmentId': urlParams.get('s'),
      'orderIdURL': urlParams.get('o'),
      'authToken': urlParams.get('authToken'),
      'email': urlParams.get('email'),
      'proxyHost': urlParams.get('proxyHost'),
      'key' : urlParams.get('key'),
      'orderItemId' : urlParams.get('orderItemId'),
      'filters' : urlParams.get('filters'),
      'lastViewedItem' : urlParams.get('lastViewedItem'),
      'isDeliveryFailed' : urlParams.get('isDeliveryFailed'),
      'isCancelPickup' : urlParams.get('isCancelPickup'),
      'isCancelItem' : urlParams.get('isCancelItem'),
      'isReturnInitiated' : urlParams.get('isReturnInitiated'),
      'actionSource': urlParams.get('m'),
      
    };
    console.log("email",urlParams.get('email'))
    console.log("urlParams",queryParams)
    if(queryParams.key === 'redirect'){
      localStorage.setItem("clientPortalemail", queryParams.email);
      localStorage.setItem("clientPortalToken", queryParams.authToken);
      localStorage.setItem("clientAccountSlug", queryParams.proxyHost);
      localStorage.setItem("clientPortalFilters",decodeURIComponent(queryParams.filters));
      localStorage.setItem("lastViewedItem", queryParams.lastViewedItem);
    }
    if(queryParams.action == 'ua'){
      localStorage.setItem("action", queryParams.action);
      localStorage.setItem('shipmentId',queryParams.shipmentId)
      localStorage.setItem('orderIdURL',queryParams.orderIdURL)
    }else{
      localStorage.removeItem("action");
      localStorage.removeItem('shipmentId');
      localStorage.removeItem('orderIdURL')

    }
    if(queryParams.actionSource){
      localStorage.setItem("actionSource", queryParams.actionSource);
    }
    
        /*Get user settings data from state*/
        this.userSettingssubscriptions$ = this.store.select(state => state.orderPlaced.userSettings).subscribe(result =>   {
          if (result) {
                  this.userSettings = result;
                  this.color=this.userSettings.noticeBackgroundColor;
                  // this.titleService.setTitle(this.userSettings.pageTitle.track_order);    
                  if(this.userSettings.noticeMessage != 'null'){
                    const body = document.getElementsByTagName('body')[0];
                    body.classList.add('noticeMessage');
                  }
          }
        });

        if(localStorage.getItem("clientPortalToken"))
        this.showStrip = true;
        if(this.showStrip){
          const body = document.getElementsByTagName('body')[0];
          body.classList.add('showstrip-black');
        }
  }

}
