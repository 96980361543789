"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("@env/environment");
var i0 = require("@angular/core");
var Configuration = /** @class */ (function () {
    function Configuration() {
        this.SERVER_URL = environment_1.environment.API_URL;
        // public baseUrl = this.SERVER_URL;
        // public API_URL = environment.DASHBOARD_API_URL;
        // public inboundUrl = environment.INBOUND_URL;
        this.UrlObj = {
            orderListApi: this.SERVER_URL + "order/"
            // completeConsignmentListApi: this.inboundUrl + "consignment?",
            // updatePohAttachment: this.baseUrl + "picklist/",
            // marConsignmentCompleteApi: this.inboundUrl + "consignment/completion ",
            // warehouseList: this.inboundUrl + "warehouse?page=1&per_page=20",
            // addAssociates: this.baseUrl + "picklistAssociates",
            // deleteAssociate: this.baseUrl + "picklistAssociates/",
            // deletePOH: this.baseUrl + "picklist/",
            // getLabelsAndInvoiceUrl: this.baseUrl + "picklistShipments/",
            // getShipment:
            //   this.API_URL + "order/shipment?page=1&per_page=100000&expectedShipDate=",
            // getOrder: this.API_URL + "order/",
            // searchOrders: this.API_URL + "order/shipment/",
            // searchByTrackingId: this.API_URL + "order/shipment?trackingID=",
            // updateShipment: this.API_URL + "order/shipment/",
            // getPackageType: this.API_URL + "package-type?page=1&per_page=1000",
            // printInvoice: this.API_URL + "pdf-print?path=",
            // addConsignmentReceiveDetail: this.inboundUrl + "consignment/receiveDetails",
            // addConsignmentBoxDetails: this.inboundUrl + "consignment/boxDetails",
            // markConsignmentReceive: this.inboundUrl + "consignment/markReceived",
            // getAccountNameList: this.inboundUrl + "account?",
            // getConsignmentReceiveDetails: this.inboundUrl + "consignment/",
            // getConsignmentByAccountId: this.inboundUrl + "consignment/ref?accountId=",
            // updateBoxDetails: this.inboundUrl + "consignment/boxDetails",
            // getBarcodeDetailsApi: this.inboundUrl + "barcode?qty="
        };
    }
    Configuration.ngInjectableDef = i0.defineInjectable({ factory: function Configuration_Factory() { return new Configuration(); }, token: Configuration, providedIn: "root" });
    return Configuration;
}());
exports.Configuration = Configuration;
