"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./alert.component");
var i3 = require("../../services/alert.service");
var i4 = require("@angular/router");
var styles_AlertComponent = [];
var RenderType_AlertComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertComponent, data: { "animation": [{ type: 7, name: "slideAnimation", definitions: [{ type: 1, expr: "* => void", animation: [{ type: 6, styles: { height: "*", opacity: "1", transform: "translateX(0)", "box-shadow": "0 1px 4px 0 rgba(0, 0, 0, 0.3)" }, offset: null }, { type: 2, steps: [{ type: 4, styles: { type: 6, styles: { height: "*", opacity: ".2", transform: "translateX(20px)", "box-shadow": "none" }, offset: null }, timings: ".25s ease" }, { type: 4, styles: { type: 6, styles: { height: "0", opacity: 0, transform: "translateX(20px)", "box-shadow": "none" }, offset: null }, timings: ".1s ease" }], options: null }], options: null }, { type: 1, expr: "void => active", animation: [{ type: 6, styles: { height: "0", opacity: "0", transform: "translateX(20px)", "box-shadow": "none" }, offset: null }, { type: 2, steps: [{ type: 4, styles: { type: 6, styles: { height: "*", opacity: ".2", transform: "translateX(20px)", "box-shadow": "none" }, offset: null }, timings: ".1s ease" }, { type: 4, styles: { type: 6, styles: { height: "*", opacity: 1, transform: "translateX(0)", "box-shadow": "0 1px 4px 0 rgba(0, 0, 0, 0.3)" }, offset: null }, timings: ".35s ease" }], options: null }], options: null }], options: {} }] } });
exports.RenderType_AlertComponent = RenderType_AlertComponent;
function View_AlertComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-warning"]], null, null, null, null, null))], null, null); }
function View_AlertComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-check"]], null, null, null, null, null))], null, null); }
function View_AlertComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-info"]], null, null, null, null, null))], null, null); }
function View_AlertComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "div", [], [[8, "className", 0], [24, "@slideAnimation", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 13, "div", [["class", "notify-body-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [["class", "notify-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "notification-body-icon"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_3)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_4)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "notify-main-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "notify-body-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "div", [["class", "notify-action-buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 0, "a", [["class", "notify-square-button notify-close-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeMessage(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var currVal_2 = (_v.context.$implicit.type == "error"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_v.context.$implicit.type == "success"); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_v.context.$implicit.type == "info"); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "notify notify-", _v.context.$implicit.type, " notify-with-icon notify-theme-default"); var currVal_1 = _v.context.$implicit.status; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = _v.context.$implicit.message; _ck(_v, 13, 0, currVal_5); }); }
function View_AlertComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "overlay-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "popup-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [["class", "title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "action-controller confirmation-buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "button", [["class", "cancel-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Cancel "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "ok-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["OK"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.confirmationContent == null) ? null : _co.confirmationContent.title); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_co.confirmationContent == null) ? null : _co.confirmationContent.subTitle); _ck(_v, 6, 0, currVal_1); }); }
function View_AlertComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_5)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertMessageCollection; var currVal_1 = _co.trackByFn; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.showConfirmation; _ck(_v, 3, 0, currVal_2); }, null); }
exports.View_AlertComponent_0 = View_AlertComponent_0;
function View_AlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-web-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i0.ɵdid(1, 245760, null, 0, i2.AlertComponent, [i3.AlertService, i4.ActivatedRoute, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AlertComponent_Host_0 = View_AlertComponent_Host_0;
var AlertComponentNgFactory = i0.ɵccf("app-web-alert", i2.AlertComponent, View_AlertComponent_Host_0, {}, {}, []);
exports.AlertComponentNgFactory = AlertComponentNgFactory;
