import * as OrderActions from "../actions/order-return.actions";
import { OrderReturnState } from "./order-return.state";

export const initialState: OrderReturnState = {
  returnReason: null
};

export function OrderReturnReducer(
  state = initialState,
  action: OrderActions.OrderReturnUnion
) {
  switch (action.type) {
    // case UserActions.UserActionTypes.GET_CURRENT_USER: {
    //   return state;
    //}

    case OrderActions.OrderReturnActionTypes.GET_RETURN_REASON: {
      return state;
    }

    case OrderActions.OrderReturnActionTypes.GET_RETURN_REASON_SUCCESS: {
      return {
        ...state,
        returnReason: action.payload
      };
    }

    case OrderActions.OrderReturnActionTypes.GET_RETURN_REASON_FAILED: {
      return state;
    }

    // case UserActions.UserActionTypes.SET_SELECTED_SECTION: {
    //   return {
    //     ...state,
    //     selectedSection: action.payload
    //   };
    // }

    default:
      return state;
  }
}
