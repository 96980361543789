"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var platform_browser_1 = require("@angular/platform-browser");
var router_2 = require("@angular/router");
var constants_1 = require("../../constants/constants");
var store_1 = require("@ngrx/store");
var common_1 = require("@angular/common");
var index_1 = require("../../services/index");
var order_placed_actions_1 = require("../../../order-placed/actions/order-placed.actions");
var ngx_device_detector_1 = require("ngx-device-detector");
var shared_service_1 = require("../../services/shared.service");
var RequestSubmissionComponent = /** @class */ (function () {
    function RequestSubmissionComponent(router, store, sharedService, route, titleService, decimalPipe, deviceService) {
        this.router = router;
        this.store = store;
        this.sharedService = sharedService;
        this.route = route;
        this.titleService = titleService;
        this.decimalPipe = decimalPipe;
        this.deviceService = deviceService;
        this.showLoader = true;
        this.rupeeSymbol = constants_1.RUPEE_SYMBOL;
        this.showError = false;
        this.currentSelectedItem = index_1.LocalStorageService.get('processOrderItemDetails') ? index_1.LocalStorageService.get('processOrderItemDetails') : '';
        this.errorMessage = index_1.LocalStorageService.get('ErrorResponseData') ? index_1.LocalStorageService.get('ErrorResponseData') : '';
        this.showSuccess = false;
        this.successData = index_1.LocalStorageService.get('SuccessResponseData') ? index_1.LocalStorageService.get('SuccessResponseData') : '';
        this.formAction = index_1.LocalStorageService.get('actionType') ? index_1.LocalStorageService.get('actionType') : '';
        this.returnFlowType = index_1.LocalStorageService.get('returnFlowType') ? index_1.LocalStorageService.get('returnFlowType') : '';
        this.leftPanelActive = false;
        this.yellowIcon = false;
        this.changeleftPanelValue = new core_1.EventEmitter();
    }
    RequestSubmissionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        // this.showLoader = true;
        if (this.errorMessage) {
            this.showError = true;
            // this.showLoader = false;
        }
        else if (this.successData) {
            this.showSuccess = true;
            // this.showLoader = false;
        }
        setTimeout(function () {
            _this.showLoader = false;
        }, 3000);
        //dispatch order data
        this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
        this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';
        /*Get user settings data from state*/
        this.store.select(function (state) { return state.orderPlaced.userSettings; }).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                _this.footerKey = Object.entries(_this.userSettings.footerLinks);
                _this.getColor = _this.userSettings.actionColor;
                _this.titleService.setTitle("Confirmation Status");
            }
        });
        //Return and cancel success message
        // if (this.formAction === 'returnProcess'){
        //   this.confirmMessage = (this.returnFlowType === 'exchange') ? 'Exchange' : (this.returnFlowType === 'replacement' ? 'Replacement' : 'Return');
        // }else {
        //   this.confirmMessage = 'Cancellation';
        // }
        if (this.formAction === 'returnProcess') {
            this.confirmTitle = (this.returnFlowType === 'exchange') ? 'Exchange Request is Submitted' : (this.returnFlowType === 'replacement' ? 'Replacement Request is Submitted' : 'Return Request is Submitted');
            this.confirmMessage = (this.returnFlowType === 'exchange') ? 'You will receive a confirmation email shortly.' : (this.returnFlowType === 'replacement' ? 'You will receive confirmation email shortly.' : 'You will receive a confirmation email shortly.');
        }
        else if (this.formAction === 'failedDeliveryProcess') {
            if (this.successData && this.successData.resolutionCode && this.successData.resolutionCode === constants_1.RESOLUTION_CODE.FALSE_REASON) {
                this.yellowIcon = true;
                this.confirmTitle = 'Your remark has been Submitted';
                this.confirmMessage = 'Apologies for the inconvenience caused.We will connect with the courier partner and re-arrange the delivery at the earliest.';
            }
            else if (this.successData && this.successData.resolutionCode && this.successData.resolutionCode === constants_1.RESOLUTION_CODE.SHIPMENT_RTO) {
                this.confirmTitle = 'Cancellation Request is Submitted';
                this.confirmMessage = 'You will receive a confirmation email shortly';
            }
            else {
                this.confirmTitle = 'Your request has been Submitted';
                this.confirmMessage = 'You will receive a confirmation email shortly';
            }
        }
        else if (this.formAction === 'failedPickupProcess') {
            if (this.successData && this.successData.resolutionCode && this.successData.resolutionCode === constants_1.RESOLUTION_CODE.FALSE_REASON) {
                this.yellowIcon = true;
                this.confirmTitle = 'Your remark has been Submitted';
                this.confirmMessage = 'Apologies for the inconvenience caused.We will connect with the courier partner and re-arrange the delivery at the earliest.';
            }
            else if (this.successData && this.successData.resolutionCode && this.successData.resolutionCode === constants_1.RESOLUTION_CODE.SHIPMENT_RTO) {
                this.confirmTitle = 'Cancellation Request is Submitted';
                this.confirmMessage = 'You will receive a confirmation email shortly';
            }
            else {
                this.confirmTitle = 'Your request has been Submitted';
                this.confirmMessage = 'You will receive a confirmation email shortly';
            }
        }
        else {
            this.confirmTitle = 'Cancellation Request is Submitted';
            this.confirmMessage = 'You will receive a confirmation email shortly';
        }
        if (this.orderId) {
            this.store.dispatch(new order_placed_actions_1.GetOrder(this.orderId));
            // this.showLoader = false;
        }
    };
    RequestSubmissionComponent.prototype.sendBackToOrder = function () {
        this.sharedService.setOpen(true);
        this.router.navigate([constants_1.ROUTES.ORDER_PLACED, this.orderId, this.orderItemId]);
        index_1.LocalStorageService.flush('exchangeOption');
        index_1.LocalStorageService.flush('returnFlowType');
        index_1.LocalStorageService.flush('actionType');
        index_1.LocalStorageService.flush('cancelItemRequest');
        index_1.LocalStorageService.flush('returnItemRequest');
        index_1.LocalStorageService.flush('ErrorResponseData');
        index_1.LocalStorageService.flush('SuccessResponseData');
        index_1.LocalStorageService.flush('failedPickupFormRequest');
        index_1.LocalStorageService.flush('failedDeliveryFormRequest');
    };
    /*Left Panel Show Hide*/
    RequestSubmissionComponent.prototype.showLeftPanel = function () {
        this.leftPanelActive = !this.leftPanelActive;
    };
    RequestSubmissionComponent.prototype.displayLeftPanel = function (value) {
        this.leftPanelActive = value;
        console.log('value', value);
    };
    RequestSubmissionComponent.prototype.transformDecimal = function (num) {
        return this.decimalPipe.transform(num, '2.');
    };
    RequestSubmissionComponent.prototype.open_ace_com = function (siteUrl) {
        window.open(siteUrl, '_blank');
    };
    return RequestSubmissionComponent;
}());
exports.RequestSubmissionComponent = RequestSubmissionComponent;
