<ng-container [formGroup]="addressInfoForm">

 <div class="address-main-box editAddressShow pop-container" >
 <div class="address-main-box" [class.editAddressShow]="dynamicdata">
    <div class="address-top-box">
        <h2 class="main-head" >Please confirm your delivery details before you proceed</h2>
        <button (click)="closeEditAddress()"></button>
    </div>
    
    <div class="address-formbox" id="addrDivId">
        <div id="goUp" #goUp></div>
        <div class="errorInfo"  *ngIf="errorhighscorePopup">
            <div class="error-icon"></div>
                <div class="errorcontent">
                    <p>
                        It seems that the address you provided has a high chance of failed delivery. Please try
                        <br>updating the address with more details.<span class="question">
                            <ng-container *ngTemplateOutlet="loading"></ng-container>
                        </span><br>
                        <span *ngIf="(poeSetting.supportEmail != 'null'&& poeSetting.supportEmail != null && poeSetting.supportEmail != '') 
                                    && (poeSetting.supportPhone == 'null' ||  poeSetting.supportPhone == null || poeSetting.supportPhone == '')">

                            For further assistance, you may reach out to us via email at {{poeSetting.supportEmail}}.
                        </span>
                        <span *ngIf="(poeSetting.supportPhone != 'null'&& poeSetting.supportPhone != null && poeSetting.supportPhone != '') 
                                && (poeSetting.supportEmail == 'null' ||  poeSetting.supportEmail == null || poeSetting.supportEmail == '')">
                            For further assistance, you may reach out to us at {{poeSetting.supportPhone}}.
                        </span>
                        <span *ngIf="(poeSetting.supportPhone != 'null'&& poeSetting.supportPhone != null && poeSetting.supportPhone != '') 
                                && (poeSetting.supportEmail != 'null' &&  poeSetting.supportEmail != null && poeSetting.supportEmail != '')">
                                For further assistance, you may reach out to us at {{poeSetting.supportPhone}} or via email at {{poeSetting.supportEmail}}
                        </span>
                    </p>
                </div>
            <div class="closeError" (click)="errorhighscorePopup=false"></div>
        </div>
        <div class="addressInfo"  >
            <div class="info-icon"></div>
            <div class="addresscontent">
                Please ensure that your address includes landmark, street name, building/apartment number and city/town.
            </div>
        </div>
        <div  class="form-row-box flex-parent-row mb-12 mobile_full_wd">
            <div class="flex-one-row">
                <label class="custom-label-2">First Name</label>
                <mat-form-field class="p-full-width" [ngStyle]="{border:(addressInfoForm.controls.firstName.touched && addressInfoForm.controls.firstName.invalid)?'1px solid red':''}">
                    <input matInput  id="firstName" name="firstName"
                        formControlName="firstName" mask="S*" placeholder="First Name">
                    <mat-error
                        *ngIf="addressInfoForm.controls.firstName.touched && addressInfoForm.controls.firstName.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.firstName.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.firstName.errors.pattern">
                            Please Enter Valid Name</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="flex-one-row">
                <label class="custom-label-2">Last Name</label>
                <mat-form-field class="p-full-width" [ngStyle]="{border:(addressInfoForm.controls.lastName.touched && addressInfoForm.controls.lastName.invalid)?'1px solid red':''}">
                    <input matInput placeholder="Last Name" id="lastName" name="lastName" formControlName="lastName" mask="S*">
                    <mat-error
                        *ngIf="addressInfoForm.controls.lastName.touched && addressInfoForm.controls.lastName.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.lastName.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.lastName.errors.pattern">Please
                            Enter Valid Name</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row-box flex-parent-row mb-12 mobile_full_wd">
            <div class="flex-one-row">
                <label class="custom-label-2">Phone Number</label>
                <mat-form-field class="p-full-width" [ngStyle]="{border:(addressInfoForm.controls.mobile.touched && addressInfoForm.controls.mobile.invalid)?'1px solid red':''}">
                    <input matInput id="mobile" placeholder="Phone Number" name="mobile" formControlName="mobile" mask="9999999999">
                    <mat-error *ngIf="addressInfoForm.controls.mobile.touched && addressInfoForm.controls.mobile.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.mobile.errors.required">This field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.mobile.errors.pattern">Please Enter Valid Number</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="flex-one-row " [ngClass]="!editdetails ? 'blur_label':''">
                <label class="custom-label-2">Email ID</label>
                <mat-form-field class="p-full-width tooltip-exclusive" [ngStyle]="{border:(addressInfoForm.controls.email.touched && addressInfoForm.controls.email.invalid)?'1px solid red':''}">
                    <span class="tooltip email-tooptip" *ngIf="!editdetails">Email can’t be edited</span>
                    <input [readonly]="!editdetails" matInput id="email" name="email" formControlName="email" class="" [ngClass]="!editdetails ? 'input_field_blur':''">
                    <mat-error *ngIf="addressInfoForm.controls.email.touched && addressInfoForm.controls.email.invalid">
                        <!-- <mat-error class="error-message" *ngIf="addressInfoForm.controls.email.errors.required">This field is mandatory</mat-error> -->
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.email.errors.pattern">Please Enter Valid Email</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    <div class="form-row-box flex-parent-row ">
        <div class=" flex-two-row mobile-row-one" [ngClass]="!editdetails ? 'blur_label':''">
            <label class="custom-label-2">Pin code</label>
            <mat-form-field class="p-full-width tooltip-exclusive pincodeflex" [ngStyle]="{border:(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid)?'1px solid red':''}" *ngIf="showPincode">
                <span class="tooltip" *ngIf="!editdetails">Pin code can’t be edited</span>
                <input [readonly]="!editdetails" matInput id="pinCode" name="pinCode" formControlName="pinCode"
                    (input)="onPincodeChangeCityState($event)" mask="999999" placeholder="Pin Code" class=" text" [ngClass]="!editdetails ? 'input_field_blur':''">
                    <!-- <p matSuffix style="display: inline;"></p> -->
                    <span class="pincodeLoader" *ngIf="loadingPincode"><mat-spinner matSuffix [diameter]="18" ></mat-spinner></span>
                    <img *ngIf="loadingPincode != undefined &&  !loadingPincode && !addressInfoForm.controls.pinCode.errors?.isServiceable" src="https://ik.imagekit.io/2gwij97w0o/POE/check.svg">
                <mat-error class="pincode-error"
                    *ngIf="addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid"> 
                     <!-- <mat-error class="error-message pincode-error" *ngIf="addressInfoForm.controls.pinCode.errors.required">This
                        field is mandatory</mat-error> -->
                    <!-- <mat-error class="error-message pincode-error" *ngIf="addressInfoForm.controls.pinCode.errors.invalid">Invalid
                        Pincode</mat-error> -->
                    <!-- <mat-error class="error-message pincode-error" *ngIf="addressInfoForm.controls.pinCode.errors?.isReversePickup">
                        Service not available at this Pincode</mat-error> -->
                    <!-- <mat-error class="error-message pincode-error" *ngIf="addressInfoForm.controls.pinCode.errors?.isServiceable">
                        Not serviceable for delivery at the moment. Try updating the pincode.</mat-error> -->
                </mat-error>
                <ng-container *ngIf="!editdetails">
                    <img src="../../../../assets/images/correct-pin.svg" alt="" *ngIf="!(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid)" class="error-pin">
                    <img src="../../../../assets/images/wrong-pin.svg" alt="" *ngIf="addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid" class="error-pin">
                </ng-container>

            </mat-form-field>
            <div  class="form-row-box flex-parent-row  mobile_full_wd" *ngIf="ismobile">
                <span>
                    <mat-hint  *ngIf="addressInfoForm.controls.pinCode.errors?.isServiceable">
                        <p class="suggeetionerrors">Not serviceable for delivery at the moment. Try updating the pincode.</p>    
                    </mat-hint>
                </span>
                <!-- <mat-hint *ngIf="addressInfoForm.controls.pinCode.invalid">
                    <p class="suggeetionmessage">Not serviceable for delivery at the moment. Try updating the pincode.</p>    
                </mat-hint> -->
            </div>




            <mat-form-field class="p-full-width tooltip-exclusive" [ngStyle]="{border:(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid)?'1px solid red':''}" *ngIf="!showPincode">
                <span class="tooltip">Pincode can’t be edited</span>
                <input matInput id="pinCode" name="pinCode" formControlName="pinCode" class="input_field_blur text"
                    (input)="onPincodeChangeCityState($event)" mask="999999" placeholder="Pin Code" readonly>
               
               <img src="../../../../assets/images/correct-pin.svg" alt="" *ngIf="!(addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid)" class="error-pin">
                <img src="../../../../assets/images/wrong-pin.svg" alt="" *ngIf="addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid" class="error-pin">

            </mat-form-field>



        </div>
        <div class=" flex-two-row mobile-row-two blur_label city_label">
            <label class="custom-label-2">City</label>
            <mat-form-field class="p-full-width tooltip-exclusive">
                <span class="tooltip">Please enter the pincode to update the city.</span>
                <input matInput id="city" name="city" readonly formControlName="city" class="input_field_blur text">
                <!-- <mat-error *ngIf="addressInfoForm.controls.city.touched && addressInfoForm.controls.city.invalid">
                    <mat-error class="error-message" *ngIf="addressInfoForm.controls.city.errors.required">This
                        field is mandatory</mat-error>
                </mat-error> -->
            </mat-form-field>
        </div>
        <div class="flex-two-row mobile-row-two blur_label state_label">
            <label class="custom-label-2 ">State</label>
            <mat-form-field class="p-full-width tooltip-exclusive">
                <span class="tooltip">Please enter the pincode to update the state.</span>
                <input matInput id="state" name="state" readonly formControlName="state" class="input_field_blur text">
                <!-- <mat-error *ngIf="addressInfoForm.controls.state.touched && addressInfoForm.controls.state.invalid">
                    <mat-error class="error-message" *ngIf="addressInfoForm.controls.state.errors.required">This
                        field is mandatory</mat-error>
                </mat-error> -->
            </mat-form-field>
        </div>
    </div>   
    <div  class="form-row-box flex-parent-row  mobile_full_wd" *ngIf="!ismobile">
        <span>
            <mat-hint  *ngIf="addressInfoForm.controls.pinCode.errors?.isServiceable">
                <p class="suggeetionerrors">Not serviceable for delivery at the moment. Try updating the pincode.</p>    
            </mat-hint>
        </span>
        <!-- <mat-hint *ngIf="addressInfoForm.controls.pinCode.invalid">
            <p class="suggeetionmessage">Not serviceable for delivery at the moment. Try updating the pincode.</p>    
        </mat-hint> -->
    </div>
    
    
  
        <div class="form-row-box address_div mt-12">
            <div class=" flex-three-row">
                <label class="custom-label-2">Address</label>
                <mat-form-field class="p-full-width example-form-field" [ngStyle]="{border:(addressInfoForm.controls.address.touched && addressInfoForm.controls.address.invalid)?'1px solid red':''}">
                    <textarea matInput matInput (focus)="isAddressLine1 = true" (focusout)="isAddressLine1 = false"  placeholder="Address" id="address" name="address" formControlName="address"></textarea>
                    <mat-error
                        *ngIf="addressInfoForm.controls.address.touched && addressInfoForm.controls.address.invalid">
                        <!-- <mat-error class="error-message" *ngIf="addressInfoForm.controls.address.errors.required">This
                            field is mandatory</mat-error> -->
                        <!-- <mat-error class="error-message" *ngIf="addressInfoForm.controls.address.errors.pattern">
                            Address</mat-error> -->
                        <!-- <mat-error class="error-message" *ngIf="addressInfoForm.controls.address.errors.pattern">Please enter 
                            flat, apartment, house, building number</mat-error>-->
                        </mat-error> 
                </mat-form-field>
                <mat-hint *ngIf="addressInfoForm.controls.address.invalid">
                    <p class="suggeetionmessage">Please enter flat, apartment, house, building number</p>    
                </mat-hint>
            </div>
        </div>
        <div class="form-row-box address_div second_line">
            <div class=" flex-three-row">
                <!-- <label class="custom-label-2">Address 2</label> -->
                <mat-form-field class="p-full-width example-form-field" [ngStyle]="{border:(addressInfoForm.controls.addressLine2.touched && addressInfoForm.controls.addressLine2.invalid)?'1px solid red':''}">
                    <textarea matInput (focus)="isAddressLine2 = true" (focusout)="isAddressLine2 = false" placeholder="Address 2" id="addressLine2" name="addressLine2" formControlName="addressLine2"></textarea>
                    <mat-error
                        *ngIf="addressInfoForm.controls.addressLine2.touched && addressInfoForm.controls.addressLine2.invalid">
                        <!-- <mat-error class="error-message" *ngIf="addressInfoForm.controls.addressLine2.errors.required">This
                            field is mandatory</mat-error> -->
                        <!-- <mat-error class="error-message" *ngIf="addressInfoForm.controls.addressLine2.errors.pattern">Invalid
                            Address</mat-error> -->
                    </mat-error>
                </mat-form-field>
                <mat-hint *ngIf="addressInfoForm.controls.addressLine2.invalid">
                    <p class="suggeetionmessage">Please enter area, street, sector, village</p>    
                </mat-hint>
            </div>
        </div>
<br>
<div class="form-row-box">
<div class=" flex-three-row">
    <label class="custom-label-2">Landmark (Optional)</label>
    <mat-form-field class="p-full-width example-form-field" ><!--[ngStyle]="{border:(addressInfoForm.controls.addressLine2.touched && addressInfoForm.controls.addressLine2.invalid)?'1px solid red':''}"-->
        <input matInput  (focus)="islandmarkfocused = true" (focusout)="islandmarkfocused = false" placeholder="Eg. Near IT park" id="landmark" name="landmark" formControlName="landmark">
        <!-- <mat-error
            *ngIf="addressInfoForm.controls.addressLine2.touched && addressInfoForm.controls.addressLine2.invalid">
            <mat-error class="error-message" *ngIf="addressInfoForm.controls.addressLine2.errors.required">This
                field is mandatory</mat-error>
            <mat-error class="error-message" *ngIf="addressInfoForm.controls.addressLine2.errors.pattern">Invalid
                Landmark</mat-error>
        </mat-error> -->
        
    </mat-form-field>
    <mat-hint *ngIf="(addressInfoForm.controls.landmark.value == null || addressInfoForm.controls.landmark.value == '')">
        <p class="suggeetionmessage">Please enter landmark</p>    
    </mat-hint>
    <!-- <div ></div> -->
</div>
</div>
<br>
<div class="form-row-box">
<div class=" flex-three-row">
    <label class="custom-label-2">Additional Delivery Notes (Optional)</label>
    <mat-form-field class="p-full-width" ><!--[ngStyle]="{border:(addressInfoForm.controls.addressLine2.touched && addressInfoForm.controls.addressLine2.invalid)?'1px solid red':''}"-->
        
        <textarea  matInput placeholder="Enter special instructions or tips we need to know to locate your address" id="notes" name="notes" formControlName="notes" ></textarea>
        <!-- <input matInput  placeholder="Describe the exact location of your address, for eg: 'In front of City park'." id="notes" name="notes" formControlName="notes"> -->
        <!-- <mat-error
            *ngIf="addressInfoForm.controls.addressLine2.touched && addressInfoForm.controls.addressLine2.invalid">
            <mat-error class="error-message" *ngIf="addressInfoForm.controls.addressLine2.errors.required">This
                field is mandatory</mat-error>
            <mat-error class="error-message" *ngIf="addressInfoForm.controls.addressLine2.errors.pattern">Invalid
                Landmark</mat-error>
        </mat-error> -->
    </mat-form-field>
</div>
</div>



        
    </div>
    <div class="btn-bottom btn-bottom-custom btn-bottom-custom-1">
        <input type="button" value="Cancel" class="btn-cancel" (click)="closeEditAddress()" />       
     <ng-container *ngIf="formLoader;else continueButton">
          <figure class="d-flex btn-loader btn-continue"  [ngStyle]="{'background-color':addressInfoForm.valid ? getColor :'getColor' }">
            <div class="loader-css" ></div>
          </figure>
        </ng-container>
        <!-- <ng-template #continueButton>
          <input class="btn-continue" type="button" (click)="onSubmit()" [ngStyle]="{background:!addressInfoForm.valid?'#E0E0E0':getColor} "  [disabled]='!addressInfoForm.valid'  value="Save" />
        </ng-template>    -->
        <ng-template #continueButton >
              <input
              class="btn-continue"
              type="button"
              (click)="onSubmit()"
                value="Confirm"
                [disabled]="!addressInfoForm.valid"
                [ngStyle]="{'background-color':addressInfoForm.valid ? getColor :'getColor' }"
            />
          </ng-template>
        
    </div>
</div>
</div>


<!-- 
    <ng-container *ngIf="dynamicdata">
        <div class="form-row-box mb-12">
            <div class="p-form-row mr-18">
                <mat-form-field class="p-full-width">
                    <input matInput placeholder="First Name*" id="firstName" name="firstName"
                        formControlName="firstName" mask="S*">
                    <mat-error
                        *ngIf="addressInfoForm.controls.firstName.touched && addressInfoForm.controls.firstName.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.firstName.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.firstName.errors.pattern">
                            Please Enter Valid Name</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="p-form-row">
                <mat-form-field class="p-full-width">
                    <input matInput placeholder="Last Name" id="lastName" name="lastName" formControlName="lastName" mask="S*">
                    <mat-error
                        *ngIf="addressInfoForm.controls.lastName.touched && addressInfoForm.controls.lastName.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.lastName.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.lastName.errors.pattern">Please
                            Enter Valid Name</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row-box mb-12">
            <div class="p-form-row mr-18">
                <mat-form-field class="p-full-width">
                    <input matInput placeholder="Pin Code*" id="pinCode" name="pinCode" formControlName="pinCode"
                        (input)="onPincodeChangeCityState($event)" mask="999999">
                    <mat-error
                        *ngIf="addressInfoForm.controls.pinCode.touched && addressInfoForm.controls.pinCode.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.pinCode.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.pinCode.errors.invalid">Invalid
                            Pincode</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.pinCode.errors.isServiceable">
                            Area Not Serviceable</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="p-form-row">
                <mat-form-field class="p-full-width">
                
                    <input matInput placeholder="State*" id="state" name="state" formControlName="state">
                    <mat-error *ngIf="addressInfoForm.controls.state.touched && addressInfoForm.controls.state.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.state.errors.required">This
                            field is mandatory</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row-box mb-12">
            <div class="p-form-row exclusive-full-width">
                <mat-form-field class="p-full-width">
                    <input matInput placeholder="Address*" id="address" name="address" formControlName="address">
                    <mat-error
                        *ngIf="addressInfoForm.controls.address.touched && addressInfoForm.controls.address.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.address.errors.required">This
                            field is mandatory</mat-error>
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.address.errors.pattern">Invalid
                            Address</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row-box mb-12">
        
            <div class="p-form-row mr-18">
                <mat-form-field class="p-full-width">
                 
                    <input matInput placeholder="City*" id="city" name="city" formControlName="city">
                    <mat-error *ngIf="addressInfoForm.controls.city.touched && addressInfoForm.controls.city.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.city.errors.required">This
                            field is mandatory</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        
        <div class="p-form-row">
            <mat-form-field class="p-full-width">
                <input matInput placeholder="Mobile No.*" id="mobile" name="mobile" formControlName="mobile" mask="9999999999">
                <mat-error *ngIf="addressInfoForm.controls.mobile.touched && addressInfoForm.controls.mobile.invalid">
                    <mat-error class="error-message" *ngIf="addressInfoForm.controls.mobile.errors.required">This field is mandatory</mat-error>
                    <mat-error class="error-message" *ngIf="addressInfoForm.controls.mobile.errors.pattern">Please Enter Valid Number</mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        </div>
        <div class="form-row">
          
            <div class="form-column">
                <div class="radio-placeholder">Type of Address *</div>
                <mat-radio-group aria-label="Select an option" formControlName="addressType">
                    <mat-radio-button matInput id="home-address" name="addressType" value="1">Home</mat-radio-button>
                    <mat-radio-button matInput id="office-address" name="addressType" value="2">Office
                    </mat-radio-button>
                    <mat-error
                        *ngIf="addressInfoForm.controls.addressType.touched && addressInfoForm.controls.addressType.invalid">
                        <mat-error class="error-message" *ngIf="addressInfoForm.controls.addressType.errors.required">
                            This field is mandatory</mat-error>
                    </mat-error>
                </mat-radio-group>
            </div>
            
        </div>
    </ng-container> -->
</ng-container>
<ng-template #loading>
    <div class="user-deatil">
      <h3>More information</h3>
      <p>It appears that the delivery address you have provided is incomplete or inaccurate, possibly due to missing information. Please ensure that your address includes the following details:</p>
      <div class="row">
        <div class="infoAddress">
          <div class="landmark">
          </div>   
          A landmark
        </div>
        <div class="infoAddress">
          <div class="street">
          </div>   
          Street name
        </div>
        <div class="infoAddress">
          <div class="building">
          </div>   
          Building or apartment number
        </div>
        <div class="infoAddress">
          <div class="city">
          </div>   
          City, town, or village
        </div>
      </div>
      <!-- <hr> -->
      <!-- <div class="button">
        <a (click)="showEditDetails()"><div class="edit"></div> Update address</a>
      </div> -->
    </div>
  </ng-template>
  