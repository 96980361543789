"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var getReasonState = function (state) { return state.returnReason; };
var ɵ0 = getReasonState;
exports.ɵ0 = ɵ0;
// export const getReturnReason = createFeatureSelector('getReasonState');
exports.getReturnReason = store_1.createSelector(getReasonState, getReasonState);
// export const getReturnItem = createSelector(
//   getReturnFormState,
//   getReturnFormState
// )
