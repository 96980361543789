"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var crypto_js_1 = require("crypto-js");
var rxjs_1 = require("rxjs");
var constants_1 = require("../constants/constants");
var i0 = require("@angular/core");
var LocalStorageService = /** @class */ (function () {
    function LocalStorageService() {
    }
    /**
     * Set value in local storage
     * @method set
     */
    LocalStorageService.set = function (key, value, prefix) {
        if (prefix === void 0) { prefix = true; }
        value = LocalStorageService.bsEncrypt(value);
        LocalStorageService.storage.setItem(key, value);
    };
    /**
     * Get value form local storage
     * @method get
     */
    LocalStorageService.get = function (key, prefix) {
        if (prefix === void 0) { prefix = true; }
        var data = LocalStorageService.storage.getItem(key);
        data = LocalStorageService.bsDecrypt(data);
        if (data != null && data !== '{}') {
            try {
                return JSON.parse(data);
            }
            catch (error) {
            }
        }
        return null;
    };
    /**
     * clear local storage
     * @method flush
     */
    LocalStorageService.flush = function (key, prefix) {
        if (prefix === void 0) { prefix = true; }
        if (key != null && key !== undefined) {
            LocalStorageService.storage.removeItem(key);
        }
        else {
            LocalStorageService.storage.clear();
        }
    };
    /**
     * use to encrypt data
     * @method bsEncrypt
     */
    LocalStorageService.bsEncrypt = function (data) {
        var newString = JSON.stringify(data);
        return crypto_js_1.AES.encrypt(newString, constants_1.SECRET).toString();
    };
    /**
     * use to decrypt data
     * @method bsDecrypt
     */
    LocalStorageService.bsDecrypt = function (data) {
        if (!data) {
            return data;
        }
        var newString = crypto_js_1.AES.decrypt(data, constants_1.SECRET);
        return newString.toString(crypto_js_1.enc.Utf8);
    };
    LocalStorageService.searchOrderResetToken = new rxjs_1.Subject();
    LocalStorageService.storage = sessionStorage;
    LocalStorageService.ngInjectableDef = i0.defineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(); }, token: LocalStorageService, providedIn: "root" });
    return LocalStorageService;
}());
exports.LocalStorageService = LocalStorageService;
