import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Configuration } from "../configuration/config";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root"
})
export class OrderReturnService {
  private API_URL = environment.API_URL;
  public headers = new HttpHeaders();

  constructor(private api: ApiService, private config: Configuration) {}

  getReturnReason(params = {}) {
    return this.api.get(`${this.API_URL}order-items/return-reason?`, {
      params: params,
      headers: this.headers
    });
  }

  public returnItemApiRequest(body): Promise<any> {
    return this.api
      .post(`${this.API_URL}order-item/returnOrder`, body, this.headers)
      .toPromise()
      .then(res => {
        if (res) {
          return res;
        }
        throw res;
      })
      .catch(err => {
        throw err;
      });
  }

  public cancelItemsApiRequest(body): Promise<any> {
    return this.api
      .post(`${this.API_URL}order-items/cancel`, body, this.headers)
      .toPromise()
      .then(res => {
        if (res) {
          return res;
        }
        throw res;
      })
      .catch(err => {
        throw err;
      });
  }

  getIfscCodeDetails(params = {}): Promise<any> {
    return this.api
      .get(`${this.API_URL}ifscCode/${params}`)
      .toPromise()
      .then(res => {
        if (res) {
          return res;
        }
        throw res;
      })
      .catch(err => {
        //throw err;
      });
  }

  generateOtp(mobileNumber:any) {
    return this.api.get(`${this.API_URL}auth/otp/generate?mobile=${mobileNumber}`);
  }

  public verifyOtp(body): Promise<any> {
    return this.api
      .post(`${this.API_URL}auth/otp/validate`, body, this.headers)
      .toPromise()
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        throw err;
      });
  }

  getExchangeRelatedProducts(params = {}): Promise<any> {
    return this.api
      .get(`${this.API_URL}exchange-option/${params}`)
      .toPromise()
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {});
  }

  getStock(params = {}): Promise<any> {
    return this.api
      .get(`${this.API_URL}get-stock/${params}`)
      .toPromise()
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {});
  }

  public returnCancelItemApiRequest(body): Promise<any> {
    return this.api
      .post(`${this.API_URL}order-items/return/cancel`, body, this.headers)
      .toPromise()
      .then(res => {
        if (res) {
          return res;
        }
        throw res;
      })
      .catch(err => {
        throw err;
      });
  }

  public failedDeliveryApiRequest(body): Promise<any> {
    return this.api
      .post(`${this.API_URL}ndrResolution`, body, this.headers)
      .toPromise()
      .then(res => {
        if (res) {
          return res;
        }
        throw res;
      })
      .catch(err => {
        throw err;
      });
  }

  public deliveryDetailsApiRequest(body): Promise<any> {
    return this.api
      .post(`${this.API_URL}deliveryDetails`, body, this.headers)
      .toPromise()
      .then(res => {
        if (res) {
          return res;
        }
        throw res;
      })
      .catch(err => {
        throw err;
      });
  }

  public failedPickupApiRequest(body): Promise<any> {
    return this.api
      .post(`${this.API_URL}ndrResolution`, body, this.headers)
      .toPromise()
      .then(res => {
        if (res) {
          return res;
        }
        throw res;
      })
      .catch(err => {
        throw err;
      });
  }

  // getIfscCodeDetails(params= {}) {
  //   return this.api
  //     .get(`${this.API_URL}ifscCode?`, {'params': params});
  // }
  // public getIfscCodeDetails(body): Promise<any>{
  //   return this.api.post(`${this.API_URL}ifscCode`, body, this.headers)
  //   .toPromise().then(res => {
  //       if (res) {
  //         console.log(res);
  //         return res;
  //       }
  //       throw res;
  //     }).catch(err => { throw err; });
  // }
}
