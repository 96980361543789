<div class="container" [class.left-panel-active]="leftPanelActive">
        <div class="row">
            <div class="panel-left p_panel-left display-none">
                    <app-order-sidebar (changeleftPanelValue)='displayLeftPanel($event)'></app-order-sidebar>
            </div>
            <div class="panel-right otp-right-panel p_panel-right mobile-move">
              

                <div class="ordered-items">


                    <div class="item-list">
                  <div class="item-info">
                    <div class="item-name">
            
            <button class="close-track" (click)="sendBackToOrder()"></button>
            
                     <span class="order-heading">Confirmation Status</span>
                     
                    </div>
                    </div>
                    </div>
                </div>

                    <!-- <div class="left-panel-show" (click)="showLeftPanel()">
                        <figure><img src="assets/images/arrow-left.png" alt=""></figure>
                        <span> Order </span>
                     </div> -->

                     
                     <div class="success-mob-wrp">
                        <div class="product-detail-mob" *ngIf="currentSelectedItem">
                            <div class="prod-img">
                                <img src="{{ currentSelectedItem && currentSelectedItem.productImageUrl ? currentSelectedItem.productImageUrl:'assets/images/item-image.jpg' }}" alt="product image">
                              </div>
                              <div class="prod-info">
                                  <h4>{{ currentSelectedItem ? currentSelectedItem.productName:''}}</h4>
                                  <p>{{rupeeSymbol}} <Span> {{ currentSelectedItem ? transformDecimal(currentSelectedItem.customerPrice):''}}</Span></p>
                              </div>
                          </div>
                    <!-- confirmed section   -->
                    <!--  -->
                       <div class="otp-section success-page" *ngIf="showSuccess">
                            <figure>
                                <img [src]="yellowIcon ? 'assets/images/yellow-success.png' :'assets/images/success-submission.svg'" alt="">
                            </figure>
                            <!-- <h2>{{ confirmMessage ? confirmMessage :'' }} Request is Submitted</h2> -->
                            <h2>{{ confirmTitle ? confirmTitle :'' }}</h2>
                            <!-- <div class="request-number">Request number <span>{{ (successData && successData.customerReturnNumber) ? successData.customerReturnNumber:'' }}</span></div> -->
                            <!-- <div class="confirmation-message">You will receive a {{ confirmMessage ? confirmMessage :'' }} confirmation email shortly </div> -->
                            <div class="confirmation-message"> {{ confirmMessage ? confirmMessage :'' }}  </div>
                            <input type="button" value="Back to Order" (click)="sendBackToOrder()" class="btn-primary" [ngStyle]="{background:getColor} ">
                        </div>
        
                        <!-- Error Section  -->
                        <div class="otp-section error-page" *ngIf="false">
                                <figure>
                                    <img src="assets/images/cancel-image.png" alt="">
                                </figure>
                                <h2>{{ errorMessage }}</h2>
                                <div class="confirmation-message">Request can not be complete due to error - {{ errorMessage }} </div>
                                <input type="button" value="Back to Order" (click)="sendBackToOrder()" class="btn-primary" [ngStyle]="{background:getColor} ">
                        </div>
                     </div>
                     <!-- <div class="mob-footer">
                        <p> Need further assistance? <a href="/contact-us"> Contact us </a> </p>
                        <app-mob-header></app-mob-header>
                    </div> -->
            </div>
        </div>
    </div>
    
    <div class="mob-footer">
        <div class="footer-underline">
            <p>
                <ng-container *ngFor="let keys of footerKey;let i=index" >
                    <a target="_blank"    (click)="open_ace_com(keys[1])">
                        {{keys[0]}}
                      </a>
                    </ng-container>
             
            </p>
          

        </div>

        <div *ngIf="userSettings?.websiteUrl">
            <p class="second-para" >
                <a (click)="open_ace_com(userSettings?.websiteUrl)">
                 <span class="mobile-store-link">  Go to store </span> </a>

            </p>
</div>
</div>
    
    <div class="loader-layover" *ngIf="showLoader">
        <figure>
            <img [src]="(isTablet || isMobile) ? 'assets/images/mobile-loader.gif' :'assets/images/track-page-loader.gif'" alt="Loader">
        </figure>
    </div>

    <div class="poweredby-mobile">
        <a href="https://www.eshopbox.com/" target="_blank">
            <p style="word-wrap: no-wrap;"><a href="https://www.eshopbox.com/" target="_blank">   <img src="https://ik.imagekit.io/2gwij97w0o/POE/eshopbox-monogram.svg" alt=""></a>Powered by Eshopbox</p>
        
        </a>       
     
    </div>