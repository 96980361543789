<!-- <p>
  information-popover works!
</p> -->
<div class="user-deatil">
  <h3>More information</h3>
  <p>It appears that the delivery address you have provided is incomplete or inaccurate, possibly due to missing information. Please ensure that your address includes the following details:</p>
  <div class="row">
    <div class="infoAddress">
      <div class="landmark">
      </div>   
      A landmark
    </div>
    <div class="infoAddress">
      <div class="street">
      </div>   
      Street name
    </div>
    <div class="infoAddress">
      <div class="building">
      </div>   
      Building or apartment number
    </div>
    <div class="infoAddress">
      <div class="city">
      </div>   
      City, town, or village
    </div>
  </div>
  <hr>
  <div class="button">
    <!-- <a (click)="showEditDetails()"><div class="edit"></div> Update address</a> -->
  </div>
</div>