"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
// import { Router } from "@angular/router";
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var order_placed_actions_1 = require("../../../order-placed/actions/order-placed.actions");
var selectors_1 = require("../../../order-placed/reducers/selectors");
var environment_1 = require("@env/environment");
var shared_service_1 = require("../../services/shared.service");
var common_1 = require("@angular/common");
var ngx_device_detector_1 = require("ngx-device-detector");
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(
    // public router: Router,
    // public auth: AuthService,
    store, sharedService, location, deviceService) {
        this.store = store;
        this.sharedService = sharedService;
        this.location = location;
        this.deviceService = deviceService;
        this.showLoader = false;
        this.subscriptions$ = new rxjs_1.Subscription();
        this.poeDataApi = "/api/v1/poe-setting";
        this.currentRoute = window.location.href;
        this.currentOrigin = window.location.origin + "/";
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isDesktopDevice = this.deviceService.isDesktop();
        // this.currentDomain = this.location["_platformLocation"]["location"][
        //   "origin"
        // ];
        this.currentDomain =
            "http://" + this.location["_platformLocation"]["location"]["host"];
        this.hostName = this.location["_platformLocation"]["location"]["hostname"]; //Current running domain
        this.hostName = this.hostName.substring(0, this.hostName.indexOf("."));
        // this.finalPoeDataApi = "http://test_test_ajeet98.auperator.net" + this.poeDataApi;
        this.finalPoeDataApi = this.currentDomain + this.poeDataApi;
        // this.subscriptions$ = this.store
        //   .select(state => state.orderPlaced.orderPlaced)
        //   .subscribe(result => {
        //     if (result) {
        //       this.orderData = result;
        //       LocalStorageService.set("orderTotal", this.orderData.orderTotal);
        //     }
        //   });
        // this.sharedService.getPoeData(this.finalPoeDataApi).subscribe(response => {
        //   if (response) {
        //     this.poeData = response;
        //     console.log(response);
        //     if (
        //       this.poeData.faviconUrl == undefined ||
        //       this.poeData.faviconUrl == null ||
        //       this.poeData.faviconUrl == "null" ||
        //       this.poeData.faviconUrl == ""
        //     ) {
        //       this.setFavicon("../../../../assets/images/Eshopbox Icon.svg");
        //     } else {
        //       this.setFavicon(this.poeData.faviconUrl);
        //     }
        //   }
        // });
        // this.sharedService.loaderListner.subscribe( showLoader => {
        //   this.showLoader = showLoader.status;
        // });
        //user settings
        this.store.dispatch(new order_placed_actions_1.GetUserSettings());
        this.subscriptions$.add(this.store.pipe(store_1.select(selectors_1.getUserSettings)).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                _this.actionColour = _this.userSettings.actionColor;
                _this.sharedService.actionColour.next(_this.actionColour);
                if (_this.userSettings.faviconUrl == undefined ||
                    _this.userSettings.faviconUrl == null ||
                    _this.userSettings.faviconUrl == "null" ||
                    _this.userSettings.faviconUrl == "") {
                    _this.change_favicon("../../../../assets/images/Eshopbox Icon.svg");
                }
                else {
                    _this.change_favicon(_this.userSettings.faviconUrl);
                }
            }
            else {
                _this.userSettings = [];
            }
            _this.showHeader();
        }));
        // this.store
        //   .select(state => state.orderPlaced.userSettings)
        //   .subscribe(result => {
        //     if (result) {
        //       this.userSettings = result;
        //       if (
        //         this.userSettings.faviconUrl == undefined ||
        //         this.userSettings.faviconUrl == null ||
        //         this.userSettings.faviconUrl == "null" ||
        //         this.userSettings.faviconUrl == ""
        //       ) {
        //         this.change_favicon("../../../../assets/images/Eshopbox Icon.svg");
        //       } else {
        //         this.change_favicon(this.userSettings.faviconUrl);
        //       }
        //     }
        //   });
        // if (localStorage.getItem("clientPortalToken")) this.showGoBackButton = true;
    };
    HeaderComponent.prototype.showHeader = function () {
        if (this.location["_platformLocation"]["location"]["pathname"].includes("/track") ||
            this.currentRoute == this.currentOrigin) {
            this.showHeaderInMobile = true;
        }
    };
    HeaderComponent.prototype.change_favicon = function (img) {
        var favicon = document.querySelector('link[rel="shortcut icon"]');
        if (!favicon) {
            favicon = document.createElement("link");
            favicon.setAttribute("rel", "shortcut icon");
            var head = document.querySelector("head");
            head.appendChild(favicon);
        }
        favicon.setAttribute("type", "image/png");
        favicon.setAttribute("href", img);
    };
    // open_ace_com(siteUrl) {
    //   window.open(siteUrl, "_blank");
    // }
    // goBackToClientPortal() {
    //   let clientAccountSlug = localStorage.getItem("clientAccountSlug");
    //   let clientPortalFilters = localStorage.getItem("clientPortalFilters");
    //   let lastViewedItem = localStorage.getItem("lastViewedItem");
    //   let key = "redirect";
    //   if (environment.prodEnv) {
    //     window.open(
    //       `https://${clientAccountSlug}.myeshopbox.com/order/list?${clientPortalFilters}` +
    //         "&lastViewedItem=" +
    //         lastViewedItem +
    //         "&key=" +
    //         key,
    //       "_self"
    //     );
    //   } else {
    //     window.open(
    //       `https://${clientAccountSlug}.auperator.co/order/list?${clientPortalFilters}` +
    //         "&lastViewedItem=" +
    //         lastViewedItem +
    //         "&key=" +
    //         key,
    //       "_self"
    //     );
    //   }
    // }
    /**@description apply favicon*/
    HeaderComponent.prototype.setFavicon = function (img) {
        var favicon = document.querySelector('link[rel="shortcut icon"]');
        if (!favicon) {
            favicon = document.createElement("link");
            favicon.setAttribute("rel", "shortcut icon");
            var head = document.querySelector("head");
            head.appendChild(favicon);
        }
        favicon.setAttribute("type", "image/png");
        favicon.setAttribute("href", img);
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.subscriptions$.unsubscribe();
    };
    HeaderComponent.prototype.open_ace_com = function (siteUrl) {
        window.open(siteUrl, "_blank");
    };
    HeaderComponent.prototype.goBackToClientPortal = function () {
        var clientAccountSlug = localStorage.getItem("clientAccountSlug");
        var clientPortalFilters = localStorage.getItem("clientPortalFilters");
        var lastViewedItem = localStorage.getItem("lastViewedItem");
        var key = "redirect";
        if (environment_1.environment.prodEnv) {
            window.open("https://" + clientAccountSlug + ".myeshopbox.com/order/list?" + clientPortalFilters +
                "&lastViewedItem=" +
                lastViewedItem +
                "&key=" +
                key, "_self");
        }
        else {
            window.open("https://" + clientAccountSlug + ".auperator.co/order/list?" + clientPortalFilters +
                "&lastViewedItem=" +
                lastViewedItem +
                "&key=" +
                key, "_self");
        }
    };
    return HeaderComponent;
}());
exports.HeaderComponent = HeaderComponent;
