"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var service_worker_1 = require("@angular/service-worker");
var services_1 = require("./shared/services");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var AppComponent = /** @class */ (function () {
    function AppComponent(swUpdate, auth, titleService, router, activatedRoute, renderer, store) {
        var _this = this;
        this.swUpdate = swUpdate;
        this.auth = auth;
        this.titleService = titleService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.renderer = renderer;
        this.store = store;
        this.title = "eshopbox";
        this.counter = 0;
        this.subscriptions$ = new rxjs_1.Subscription();
        this.getToken = "";
        // currentRoute: string;
        this.currentRoute = window.location.href;
        this.currentOrigin = window.location.origin + "/";
        this.store
            .select(function (state) { return state.orderPlaced.userSettings; })
            .subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
                _this.renderer.setStyle(document.body, "background", _this.userSettings.backgroundColor);
            }
        });
    }
    //code for service worker new version
    AppComponent.prototype.ngOnInit = function () {
        //set page titles
        this.setTitle();
        // this.auth.handleAuthenticationWithHash();
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(function () {
                if (confirm("New version available. Load new version?")) {
                    window.location.reload();
                }
            });
        }
        this.getToken = services_1.LocalStorageService.get("token");
    };
    AppComponent.prototype.setTitle = function () {
        var _this = this;
        var appTitle = this.titleService.getTitle();
        this.subscriptions$ = this.router.events
            .pipe(operators_1.filter(function (event) { return event instanceof router_1.NavigationEnd; }), operators_1.map(function () {
            if (_this.activatedRoute.firstChild) {
                var child = _this.activatedRoute.firstChild;
                while (child.firstChild) {
                    child = child.firstChild;
                }
                if (child.snapshot.data["title"]) {
                    return child.snapshot.data["title"];
                }
                return appTitle;
            }
        }))
            .subscribe(function (ttl) {
            _this.titleService.setTitle(ttl);
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.subscriptions$.unsubscribe();
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
