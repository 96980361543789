"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".arrow-top[_ngcontent-%COMP%]{position:absolute;right:0;top:-24px;display:none;border-radius:4px 0 0;overflow:hidden}.btn-bottom[_ngcontent-%COMP%]{position:relative}.reason-heading[_ngcontent-%COMP%]{margin-bottom:10px}.row[_ngcontent-%COMP%]{height:calc(100vh - 130px);width:100%;min-height:calc(100vh - 157px);display:flex;flex-flow:row nowrap;margin-bottom:5px}@media only screen and (max-width:991px){.arrow-top[_ngcontent-%COMP%]{display:block}@-webkit-keyframes slide{0%{-webkit-transform:translateX(100%);transform:translateX(100%)}100%{-webkit-transform:translateX(0);transform:translateX(0)}}@keyframes slide{0%{-webkit-transform:translateX(100%);transform:translateX(100%)}100%{-webkit-transform:translateX(0);transform:translateX(0)}}  app-order-return-choice .mob-footer{bottom:38px}.btn-bottom[_ngcontent-%COMP%]{position:fixed;bottom:0;padding:16px}.btn-bottom.fix-bottom[_ngcontent-%COMP%]{bottom:84px}  app-order-return-cancel .row{height:100vh!important}.p_panel-right[_ngcontent-%COMP%]   .ordered-items[_ngcontent-%COMP%]   .item-list[_ngcontent-%COMP%]   .item-info[_ngcontent-%COMP%]{margin-bottom:9px;background:#fff}  .panel-right.p_panel-right.mobile-move{padding:0;height:calc(100vh - 110px)}  .return-mob-wrp.scroll-content{padding-bottom:0}.row[_ngcontent-%COMP%]{min-height:auto!important;height:calc(100vh - 130px)!important}.fix-page[_ngcontent-%COMP%]   .panel-right.p_panel-right.mobile-move[_ngcontent-%COMP%]{height:calc(100vh - 200px)}}"];
exports.styles = styles;
