import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../..';
import { MobHeaderComponent } from './mob-header.component';

@NgModule({
  declarations: [
    MobHeaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
  ],
  bootstrap: [MobHeaderComponent],
  exports: [
      MobHeaderComponent
  ]
})
export class MobHeaderModule {}
