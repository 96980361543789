<!-- Forward tracking timeLine -->
<div class="new-update-design">
  <ng-container
    *ngIf="
      seeUpdatesData && selectedItemDetails && timelineStatus === 'forward'
    "
  >
    <div
      class="each-update-box"
      *ngFor="
        let status of (seeUpdatesData | keyvalue: originalOrder);
        let i = index
      "
      [ngClass]="
        selectedItemDetails.latestStatus == 'cancelled'
          ? 'order-canceled'
          : '' || selectedItemDetails.latestStatus == 'failed_delivery'
          ? 'Failed-delivery'
          : ''
      "
    >
      <app-see-update-icons
        [status]="status"
        [isForward]="true"
      ></app-see-update-icons>
      <div class="right-content">
        <div class="top-content-div">
          <div>{{ formatStatus(status.key) == 'ndr_resolution_submitted' 
            ? (getStatus(status.key, "forward", status?.value[0]?.actionCode) | replaceString)
            : (getStatus(status.key, "forward") | replaceString) }}</div>
          <!-- <ng-container
            *ngIf="
              (formatStatus(status.key) == 'intransit' ||
                formatStatus(status.key) == 'failed_delivery' ||
                formatStatus(status.key) == 'out_for_delivery') &&
              (status.value.length > 0 && status.value[0].remark)
            "
          > -->
        </div>
        <div class="bottom-content-div">
          <!-- <p
            class="time-block"
            *ngIf="
              !(
                status.value.length == 1 &&
                (formatStatus(status.key) == 'intransit' ||
                  formatStatus(status.key) == 'failed_delivery' ||
                  formatStatus(status.key) == 'out_for_delivery') &&
                (status.value.length > 0 && status.value[0].remark)
              )
            "
          > -->
          <p
            class="time-block"
          >
            <!-- <img src="https://ik.imagekit.io/2gwij97w0o/POE/clock-icon.svg" alt=""><span>{{status.value[0].updatedAt.substring(11, 19)  | convert24hrsTo12hrs}} |  {{ replaceDay(status.value[0].updatedAt)}}</span> -->
            <!-- <ng-container *ngIf="!browserSafari">  |  date:'EE, d MMM' -->
            <img src="https://ik.imagekit.io/2gwij97w0o/POE/clock-icon.svg" alt="" /><span
              >{{ replaceTime(status.value[status.value.length-1].updatedAt) }} |
              {{ replaceDay(status.value[status.value.length-1].updatedAt) }}</span
            >
            <!-- </ng-container> -->
          </p>
          <!-- <ng-container
            *ngIf="
              selectedItemDetails.latestStatus != 'out_for_delivery' &&
              status.key == 'intransit'
            "
          >
            <p
              class="note-seeupdate"
              *ngIf="
                selectedItemDetails.latestRevisedExpectedDeliveryDate >
                selectedItemDetails.expectedDeliveryDate
              "
            >
              <span *ngIf="selectedItemDetails.latestStatus != 'delivered'">
                Due to intransit delay, the expected delivery date has been
                revised from "{{
                  selectedItemDetails.expectedDeliveryDate
                    | date: "EE MMM d, y"
                }}" to "<b style="font-weight: normal;"
                  >{{
                    selectedItemDetails?.latestRevisedExpectedDeliveryDate
                      | date: "EE MMM d, y"
                  }}"</b
                >
              </span>
            </p>
          </ng-container> -->
        </div>
        <div class="top-content-div">
          <ng-container *ngIf="status.value.length > 0 && status.value[0].remark">
            <a
              class="updates"
              [ngClass]="currentindex == i ? 'uparrow-css' : ''"
              (click)="updateclick(i)"
            >
              {{ status.value.length }}
              {{ status.value.length == 1 ? "Update" : "Updates" }} available
            </a>
          </ng-container>
        </div>
        ​<ng-container *ngIf="status.value[0].remark">
          <div
            class="detail-timeline"
            [ngClass]="currentindex == i ? 'show-remark' : 'hide-remark'"
          >
            <ul>
              <li *ngFor="let status of reverseStatus(status.value)">
                <!-- <ng-container *ngIf="!browserSafari"> -->
                <!-- |  date:'EE, d MMM' -->
                <span>
                  {{ replaceDay(status.updatedAt) }} <br />{{
                    replaceTime(status.updatedAt)
                  }}</span
                >
                <!-- </ng-container> -->
                <span class="browserSafari-span">
                  <ng-container *ngIf="status.status == 'ndr_resolution_submitted' && status?.remark; else elRemark">
                    <p>Customer action: </p>
                    {{status?.remark}}
                  </ng-container>
                  <ng-template #elRemark>
                    {{status?.remark}}
                  </ng-template>
                  <!-- {{
                    status.status != 'ndr_resolution_submitted'
                    ? status.remark
                    : (status.status == 'ndr_resolution_submitted' && status.remark.length<=75
                      ? 'Customer action: '+status.remark
                      : ''
                      )
                  }}
                  <div  class="remarkc" *ngIf="status.status == 'ndr_resolution_submitted' && status.remark.length>75">
                    <p [class.show]="show">
                      {{'Customer action: '+status.remark}}
                    </p>                    
                  </div>
                  <a (click)="show = !show">{{ show ? 'show less': 'show more' }}</a> -->
                  <ng-container *ngIf="status?.status == 'ndr_resolution_submitted' && status?.customerRemark">
                    
                    <p style="margin-top: 8px;">Customer remark: </p>
                    {{status?.customerRemark}}
                  </ng-container>
                  <p>{{ status.location }}</p>
                </span>
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <!-- Reverse tracking timeLine -->
  <ng-container
    *ngIf="
      seeUpdatesData && selectedItemDetails && timelineStatus === 'reverse'
    "
  >
    <div
      class="each-update-box"
      *ngFor="
        let status of (seeUpdatesData | keyvalue: originalOrder);
        let i = index
      "
      [ngClass]="
        selectedItemDetails.latestStatus == 'cancelled' ||
        selectedItemDetails.latestStatus == 'return_cancelled'
          ? 'order-canceled'
          : '' || selectedItemDetails.latestStatus == 'failed_delivery'
          ? 'Failed-delivery'
          : ''
      "
    >
      <app-see-update-icons
        [status]="status"
        [isForward]="false"
      ></app-see-update-icons>
      <div class="right-content">
        <div class="top-content-div">
          <div>
            {{ getStatus(status.key, "reverse") | replaceString }}
            
          </div>
            <!-- <ng-container
              *ngIf="
                (formatStatus(status.key) == 'intransit' ||
                  formatStatus(status.key) == 'failed_delivery' ||
                  formatStatus(status.key) == 'out_for_delivery') &&
                (status.value.length > 0 && status.value[0].remark)
              "
            > -->
        </div>
        <div class="bottom-content-div">
          <!-- <p
            class="time-block"
            *ngIf="
              !(
                status.value.length == 1 &&
                (formatStatus(status.key) == 'intransit' ||
                  formatStatus(status.key) == 'failed_delivery' ||
                  formatStatus(status.key) == 'out_for_delivery') &&
                (status.value.length > 0 && status.value[0].remark)
              )
            "
          > -->
          <p
            class="time-block"
          >
            <!-- <img src="https://ik.imagekit.io/2gwij97w0o/POE/clock-icon.svg" alt="" /><span
              >{{
                status.value[0].updatedAt.substring(11, 19)
                  | convert24hrsTo12hrs
              }}
              | {{ status.value[0].updatedAt | date: "EE, d MMM" }}</span
            > -->
            <img src="https://ik.imagekit.io/2gwij97w0o/POE/clock-icon.svg" alt="" /><span
              >{{ replaceTime(status.value[status.value.length-1].updatedAt) }} |
              {{ replaceDay(status.value[status.value.length-1].updatedAt) }}</span
            >
          </p>
          <!-- <ng-container
            *ngIf="
              selectedItemDetails.latestStatus != 'out_for_delivery' &&
              status.key == 'intransit'
            "
          >
            <p
              class="note-seeupdate"
              *ngIf="
                selectedItemDetails.latestRevisedExpectedDeliveryDate >
                selectedItemDetails.expectedDeliveryDate    
              "
            > isPickupDelay
              <span *ngIf="selectedItemDetails.latestStatus != 'delivered'">
                Due to intransit delay, the expected delivery date has been
                revised from "{{
                  selectedItemDetails.expectedDeliveryDate
                    | date: "EE MMM d, y"
                }}" to "<b style="font-weight: normal;"
                  >{{
                    selectedItemDetails?.latestRevisedExpectedDeliveryDate
                      | date: "EE MMM d, y"
                  }}"</b
                >
              </span>
            </p>
          </ng-container> -->
        </div>
        <div class="top-content-div">
          <ng-container *ngIf="status.value.length > 0 && status.value[0].remark">
            <a
              class="updates"
              [ngClass]="currentindex == i ? 'uparrow-css' : ''"
              (click)="updateclick(i)"
            >
              {{ status.value.length }}
              {{ status.value.length == 1 ? "Update" : "Updates" }} available
            </a>
          </ng-container>
        </div>
        ​<ng-container *ngIf="status.value[0].remark">
          <div
            class="detail-timeline"
            [ngClass]="currentindex == i ? 'show-remark' : 'hide-remark'"
          >
            <ul>
              <li *ngFor="let status of reverseStatus(status.value)">
                <!-- <span>
                  {{ status.updatedAt | date: "EE, d MMM" }}<br />{{
                    status.updatedAt.substring(11, 19) | convert24hrsTo12hrs
                  }}</span
                > -->
                <span>
                  {{ replaceDay(status.updatedAt) }} <br />{{
                    replaceTime(status.updatedAt)
                  }}</span
                >
                <span>
                  {{ status.remark }}
                  <br />
                  <p>{{ status.location }}</p>
                </span>
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <!-- Return tracking timeLine -->
  <ng-container
    *ngIf="seeUpdatesData && selectedItemDetails && timelineStatus === 'return'"
  >
    <div
      class="each-update-box"
      *ngFor="
        let status of (seeUpdatesData | keyvalue: originalOrder);
        let i = index
      "
      [ngClass]="
        selectedItemDetails.latestStatus == 'cancelled'
          ? 'order-canceled'
          : '' || selectedItemDetails.latestStatus == 'failed_delivery'
          ? 'Failed-delivery'
          : ''
      "
    >
      <app-see-update-icons
        [status]="status"
        [isForward]="false"
      ></app-see-update-icons>
      <div class="right-content">
        <div class="top-content-div">
          <div>{{ getStatus(status.key, "reverse") | replaceString }}</div>
          <!-- <ng-container
            *ngIf="
              (formatStatus(status.key) == 'intransit' ||
                formatStatus(status.key) == 'failed_delivery' ||
                formatStatus(status.key) == 'out_for_delivery') &&
              (status.value.length > 0 && status.value[0].remark)
            "
          > -->
        </div>
        <div class="bottom-content-div">
          <!-- <p
            class="time-block"
            *ngIf="
              !(
                status.value.length == 1 &&
                (formatStatus(status.key) == 'intransit' ||
                  formatStatus(status.key) == 'failed_delivery' ||
                  formatStatus(status.key) == 'out_for_delivery') &&
                (status.value.length > 0 && status.value[0].remark)
              )
            "
          > -->
          <p
            class="time-block"
          >
            <!-- <img src="https://ik.imagekit.io/2gwij97w0o/POE/clock-icon.svg" alt="" /><span
              >{{
                status.value[0].updatedAt.substring(11, 19)
                  | convert24hrsTo12hrs
              }}
              | {{ status.value[0].updatedAt | date: "EE, d MMM" }}</span
            > -->
            <img src="https://ik.imagekit.io/2gwij97w0o/POE/clock-icon.svg" alt="" /><span
              >{{ replaceTime(status.value[status.value.length-1].updatedAt) }} |
              {{ replaceDay(status.value[status.value.length-1].updatedAt) }}</span
            >
          </p>
          <!-- <ng-container
            *ngIf="
              selectedItemDetails.latestStatus != 'out_for_delivery' &&
              status.key == 'intransit'
            "
          >
            <p
              class="note-seeupdate"
              *ngIf="
                selectedItemDetails.latestRevisedExpectedDeliveryDate >
                selectedItemDetails.expectedDeliveryDate    
              "
            > isPickupDelay
              <span *ngIf="selectedItemDetails.latestStatus != 'delivered'">
                Due to intransit delay, the expected delivery date has been
                revised from "{{
                  selectedItemDetails.expectedDeliveryDate
                    | date: "EE MMM d, y"
                }}" to "<b style="font-weight: normal;"
                  >{{
                    selectedItemDetails?.latestRevisedExpectedDeliveryDate
                      | date: "EE MMM d, y"
                  }}"</b
                >
              </span>
            </p>
          </ng-container> -->
        </div>
        <div class="top-content-div">
          <ng-container *ngIf="status.value.length > 0 && status.value[0].remark">
            <a
              class="updates"
              [ngClass]="currentindex == i ? 'uparrow-css' : ''"
              (click)="updateclick(i)"
            >
              {{ status.value.length }}
              {{ status.value.length == 1 ? "Update" : "Updates" }} available
            </a>
          </ng-container>
        </div>
        
        ​<ng-container *ngIf="status.value[0].remark">
          <div
            class="detail-timeline"
            [ngClass]="currentindex == i ? 'show-remark' : 'hide-remark'"
          >
            <ul>
              <li *ngFor="let status of reverseStatus(status.value)">
                <!-- <span>
                  {{ status.updatedAt | date: "EE, d MMM" }}<br />{{
                    status.updatedAt.substring(11, 19) | convert24hrsTo12hrs
                  }}</span
                > -->
                <span>
                  {{ replaceDay(status.updatedAt) }} <br />{{
                    replaceTime(status.updatedAt)
                  }}</span
                >
                <span>
                  {{ status.remark }}
                  <br />
                  <p>{{ status.location }}</p>
                </span>
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
