"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["header[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:hover{color:#666!important}header[_ngcontent-%COMP%]{padding-right:30px;padding-left:30px;max-width:100%}header[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]{display:flex;align-items:center;justify-content:space-between;width:100%}header[_ngcontent-%COMP%]   .one-footer-link[_ngcontent-%COMP%], header.one-footer-link[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]{justify-content:space-evenly}"];
exports.styles = styles;
