"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./footer.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./footer.component");
var i4 = require("@ngrx/store");
var i5 = require("@angular/router");
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
exports.RenderType_FooterComponent = RenderType_FooterComponent;
function View_FooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "brand brand-exclusive"], ["style", "padding: 3px;"], ["target", "_blank"]], null, [[null, "click"], [null, "mouseover"], [null, "mouseout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open_ace_com(((_co.userSettings == null) ? null : _co.userSettings.websiteUrl)) !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        _co.url_color = true;
        var pd_1 = (_co.onMouseOver() !== false);
        ad = (pd_1 && ad);
    } if (("mouseout" === en)) {
        _co.url_color = false;
        var pd_2 = (_co.onMouseOut() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { color: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "link"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go to store"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_co.url_color ? _co.getColor : "#828282")); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imgSrc; _ck(_v, 3, 0, currVal_1); }); }
function View_FooterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "brand"], ["target", "_blank"]], null, [[null, "click"], [null, "mouseover"], [null, "mouseout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open_ace_com(_v.context.$implicit[1]) !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (_co.mouseEnter(_v.context.$implicit[0]) !== false);
        ad = (pd_1 && ad);
    } if (("mouseout" === en)) {
        var pd_2 = (_co.mouseLeave(_v.context.$implicit[0]) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { color: 0 }), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((_co.value == _v.context.$implicit[0]) ? _co.getColor : "#828282")); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit[0]; _ck(_v, 4, 0, currVal_1); }); }
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "header", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_3)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.footerKey && (_co.footerKey.length == 1)) ? "one-footer-link" : ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.footerKey && (_co.footerKey.length == 0)) ? "one-footer-link" : ""); _ck(_v, 3, 0, currVal_1); var currVal_2 = (((_co.userSettings == null) ? null : _co.userSettings.websiteUrl) && (((_co.userSettings == null) ? null : _co.userSettings.websiteUrl) != "null")); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.footerKey; _ck(_v, 7, 0, currVal_3); }, null); }
function View_FooterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "poweredby"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["href", "https://www.eshopbox.com/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [["style", "word-wrap: no-wrap;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "https://www.eshopbox.com/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["src", "https://ik.imagekit.io/2gwij97w0o/POE/eshopbox-monogram.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Powered by Eshopbox "]))], null, null); }
function View_FooterComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "page-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "loader-icon"]], null, null, null, null, null))], null, null); }
function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.userSettings && (_co.currentRoute != _co.currentOrigin)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.userSettings && (_co.currentRoute != _co.currentOrigin)); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.showLoader; _ck(_v, 5, 0, currVal_2); }, null); }
exports.View_FooterComponent_0 = View_FooterComponent_0;
function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i3.FooterComponent, [i4.Store, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_FooterComponent_Host_0 = View_FooterComponent_Host_0;
var FooterComponentNgFactory = i1.ɵccf("app-footer", i3.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
exports.FooterComponentNgFactory = FooterComponentNgFactory;
