
<div class="container" [class.left-panel-active]="leftPanelActive">
  <div class="row custom-row">
      <div class="panel-left p_panel-left display-none">
          <!-- <div class="left-panel-hide" (click)="hideLeftPanel()">
                      <figure><img src="assets/images/close.png" alt=""></figure>
                  </div> -->
          <app-order-sidebar (changeleftPanelValue)='displayLeftPanel($event)' (selectedItemData)="onSelectShowOrderItemDetail($event)"  (formReady)="formInitialized('addressInfo', $event)"></app-order-sidebar>
      </div>
      <div class="panel-right p_panel-right mobile-move mobile-move1 mobile-panel">
        <!-- {{cancelOrderItemDetails | json}} -->
          <form [formGroup]="cancelItemForm">
              <div class="ordered-items">


                  <div class="item-list">
                <div class="item-info">
                  <div class="item-name">
          
          <button class="close-track" (click)=" setBackToOrder()"></button>
          
                   <span class="order-heading">
                    <!-- <a (click)=" setBackToOrder()"><img src="https://ik.imagekit.io/2gwij97w0o/POE/icon-arrow-left.svg" /></a> -->
                     Cancel Item</span>
                   <a  (click)="openHelpModal()" (mouseover) ="mouseEnter('addColor') "  (mouseout) ="mouseLeave('removeColor')"  [ngStyle]="{color:Helpcolor?getColor:'#828282'} ">Help ?</a>
                   <!-- <ng-container *ngIf="(userSettings?.supportPhone && userSettings?.supportPhone!='null') || (userSettings?.supportEmail && userSettings?.supportEmail!='null') || (userSettings?.supportUrl && userSettings?.supportUrl!='null')">
                      <a  (click)="openHelpModal()" (mouseover) ="mouseEnter('addColor') "  (mouseout) ="mouseLeave('removeColor')"  [ngStyle]="{color:Helpcolor?getColor:'#828282'} ">Help ?</a>
                   </ng-container> -->
                  
            
                  </div>
                  </div>
                  </div>
                  </div>


              <!-- <div class="left-panel-show" (click)="showLeftPanel()">
                  <figure><img src="assets/images/arrow-left.png" alt=""></figure>
                  <span> Order </span>
              </div> -->
              <div class="cancel-order-wrp scroll-content" #scroll (scroll)="onScroll()" [class.box-shadowcss]="isBoxshadow">
              <!-- <div class="product-detail-mob" *ngIf="cancelOrderItemDetails">
                  <div class="prod-img">
                      <img src="{{ cancelOrderItemDetails && cancelOrderItemDetails.productImageUrl ? cancelOrderItemDetails.productImageUrl:'assets/images/item-image.jpg' }}"
                          alt="product image">
                  </div>
                  <div class="prod-info">
                      <h4>{{ cancelOrderItemDetails ? cancelOrderItemDetails.productName:''}}</h4>
                      <p>
                        {{rupeeSymbol}}
                         <Span>
                              {{ cancelOrderItemDetails ? transformDecimal(cancelOrderItemDetails.customerPrice):''}}</Span>
                              <span class="size-class" *ngIf="cancelOrderItemDetails.productSize">{{cancelOrderItemDetails ? cancelOrderItemDetails.productSize:''}}</span>
                              <span  *ngIf="cancelOrderItemDetails.productSize"> • Size {{cancelOrderItemDetails ? cancelOrderItemDetails.productSize:''}}</span>

                          </p>
                  </div>
              </div> -->
              <div class=" cancle-order">
                  <!-- Bank Details Start -->
                  <h5>How would you like the refund?</h5>
                  <p class="special-para">Please select your mode of refund</p>
                  <div class="cancel-container">
                      <mat-radio-group class="custom-radio-btn-group" formControlName="refundMode" 
                      (change)="radioChange($event)">
                              <mat-radio-button *ngIf="userSettings && userSettings.cancellationRefundPolicy && 
                              userSettings.cancellationRefundPolicy.originalPaymentMode" value="originalPaymentMode" matInput class="custom-radio-btn" [checked]="true">
                                Back to the account used in payment
                              </mat-radio-button>
                              <mat-radio-button *ngIf="userSettings && userSettings.cancellationRefundPolicy && userSettings.cancellationRefundPolicy.bank" 
                               value="bank" matInput class="custom-radio-btn">
                                Add bank account details
                              </mat-radio-button>
                              <div class="account-form" *ngIf="bankDetails">
                                <div class="form-row-box mb-20">
                                  <div class="p-form-row mr-18">
                                    <label class="custom-label-new">Account holder name</label>
                                    <mat-form-field class="p-full-width" (selectionChange)="onreasonChange($event.value,true)" [ngStyle]="{border:(formControls.accountHolderName.touched &&
                                      formControls.accountHolderName.invalid)?'1px solid #EB5757':''}">
                                        <!-- readonly="true" value="{{
                                          (orderDetails.billingAddress
                                            ? orderDetails.billingAddress.customerName
                                            : '') | titlecase
                                        }}"-->
                                      <input matInput placeholder="Enter account holder name" name="accountHolderName"
                                        id="accountHolderName" formControlName="accountHolderName" />
                                      <mat-error *ngIf="
                                          formControls.accountHolderName.touched &&
                                          formControls.accountHolderName.invalid
                                        ">
                                        <mat-error class="error-message" *ngIf="formControls.accountHolderName.errors.required">This field
                                          is mandatory</mat-error>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="p-form-row">
                                    <label class="custom-label-new">IFSC code</label>
                                    <mat-form-field class="p-full-width" (selectionChange)="onreasonChange($event.value,true)" [ngStyle]="{border:(formControls.ifscCode.touched &&
                                      formControls.ifscCode.invalid)?'1px solid #EB5757':''}">
                                      <input matInput placeholder="Enter IFSC code" autocomplete="off" name="ifscCode" id="ifscCode" formControlName="ifscCode"
                                        (input)="callIfscCodeCheck($event)" />
                                      <mat-error *ngIf="
                                          formControls.ifscCode.touched &&
                                          formControls.ifscCode.invalid
                                        ">
                                        <mat-error class="error-message" *ngIf="formControls.ifscCode.errors.required">This field is
                                          mandatory</mat-error>
                                        <mat-error class="error-message" *ngIf="formControls.ifscCode.errors.pattern">This field is invalid
                                        </mat-error>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>

                                <div class="form-row-box mb-20">
                                  <div class="p-form-row mr-18">
                                    <label class="custom-label-new">Account number</label>
                                    <mat-form-field class="p-full-width" (selectionChange)="onreasonChange($event.value,true)" [ngStyle]="{border:(formControls.accountNumber.touched &&
                                      formControls.accountNumber.invalid)?'1px solid #EB5757':''}">
                                      <input matInput placeholder="Enter account number" autocomplete="off" name="accountNumber" id="accountNumber"
                                        formControlName="accountNumber" />
                                      <mat-error *ngIf="
                                          formControls.accountNumber.touched &&
                                          formControls.accountNumber.invalid
                                        ">
                                        <mat-error class="error-message" *ngIf="formControls.accountNumber.errors.required">This field is
                                          mandatory</mat-error>
                                        <mat-error class="error-message" *ngIf="formControls.accountNumber.errors.pattern">This field is
                                          invalid</mat-error>
                                        <mat-error class="error-message" *ngIf="formControls.accountNumber.errors.minlength">Min Length - 5
                                        </mat-error>
                                        <mat-error class="error-message" *ngIf="formControls.accountNumber.errors.maxlength">Max Length - 25
                                        </mat-error>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="p-form-row ">
                                    <label class="custom-label-new">Confirm account number</label>
                                    <mat-form-field class="p-full-width" [ngStyle]="{border:(formControls.confirmAccountNumber.touched &&
                                      formControls.confirmAccountNumber.invalid)?'1px solid #EB5757':''}">
                                      <input matInput placeholder="Re-enter account number" autocomplete="off" name="confirmAccountNumber"
                                        id="confirmAccountNumber" formControlName="confirmAccountNumber" />
                                      <mat-error *ngIf="
                                          formControls.confirmAccountNumber.touched &&
                                          formControls.confirmAccountNumber.invalid
                                        ">
                                        <mat-error class="error-message" *ngIf="formControls.confirmAccountNumber.errors.required">This
                                          field is mandatory</mat-error>
                                        <mat-error class="error-message" *ngIf="formControls.confirmAccountNumber.errors.pattern">This field
                                          is invalid</mat-error>
                                        <mat-error class="error-message" *ngIf="formControls.confirmAccountNumber.errors.mustMatch">Account number and Confirm account number does not match</mat-error>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>

                                <input type="hidden" formControlName="bankCity" />
                                <div class="form-row-box mb-20">
                                  <div class="p-form-row mr-18">
                                    <label class="custom-label-new">Bank name</label>
                                    <mat-form-field class="p-full-width">
                                      <input matInput readonly="true"  placeholder="Bank name" name="bankName" id="bankName"
                                        formControlName="bankName" />
                                      <input type="hidden"  formControlName="bankName" />
                                   
                                    </mat-form-field>
                                  </div>
                                  <div class="p-form-row mr-18">
                                    <label class="custom-label-new">Branch</label>
                                   
                                    <mat-form-field class="p-full-width">
                                      <input matInput readonly="true"  placeholder="Branch" name="branch" id="branch"
                                        formControlName="branch" />
                                      <input type="hidden"  formControlName="branch" />               
                                    </mat-form-field>
                                  </div>
                                </div>

                                <div class="form-row-box mb-20">
                                  <div class="p-form-row">
                                    <label class="custom-label-new">Account type</label>
                                    <mat-form-field class="p-full-width" [ngStyle]="{border:(formControls.accountType.touched &&
                                      formControls.accountType.invalid)?'1px solid #EB5757':''}">
                                      <mat-label class="custom-label">Select account type</mat-label>
                                      <mat-select disableOptionCentering  name="accountType" id="accountType" formControlName="accountType">
                                        <mat-option *ngFor="let account of (accountTypes | keyvalue)" value="{{ account.key }}">
                                          {{ account.value }}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="
                                          formControls.accountType.touched &&
                                          formControls.accountType.invalid
                                        ">
                                        <mat-error class="error-message" *ngIf="formControls.accountType.errors.required">Account type is mandatory</mat-error>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>

                              </div>
                              <mat-radio-button *ngIf="userSettings && userSettings.cancellationRefundPolicy && userSettings?.cancellationRefundPolicy?.storeCredit"
                               value="storeCredit"  matInput class="custom-radio-btn">
                                Back as store credits
                              </mat-radio-button>
                          
                      </mat-radio-group>

                     
                  </div>
                

                 
              </div>

           


      </div>
      <div class="btn-bottom">
        <!-- <a class="arrow-top" (click)="showFooterClick()" *ngIf="ifarrowTop"><img src="https://ik.imagekit.io/2gwij97w0o/POE/arrow-top.svg" /></a>  
        <a class="arrow-top" (click)="hideFooterClick()" *ngIf="ifarrowBottom"><img src="https://ik.imagekit.io/2gwij97w0o/POE/arrow-bottom.svg" /></a>
                -->
          <input type="button" value="back" class="btn-cancel" (click)="setBackToOrder()">  
          <!-- <input type="button" value="Continue" class="btn-continue" disabled> -->
          <ng-container *ngIf="loading;else continueButton" >
              <figure class="d-flex btn-loader btn-continue" >
                  <div class="loader-css"></div>
              </figure>
          </ng-container>
          <ng-template #continueButton>
              <input class="btn-continue" type="button" (click)="onSubmit()" [disabled]="!cancelItemForm.valid" 
                  value="Continue" >
          </ng-template>
         
      </div>
      </form>
  </div>
</div>
<!-- <div class="mob-footer">
  <p> Need further assistance? <a href="mailto:{{ (userSettings && userSettings.contactMailId) ? userSettings.contactMailId:'' }}"> Contact us </a> </p>
  <app-mob-header></app-mob-header>
</div> -->
</div>
<!-- <div class="mob-footer" *ngIf="showFooter">
      <div class="footer-underline">
          <p>
              <ng-container *ngFor="let keys of footerKey;let i=index" >
                  <a target="_blank"    (click)="open_ace_com(keys[1])">
                      {{keys[0]}}
                    </a>
                  </ng-container>
           
          </p>
        

      </div>

      <div *ngIf="userSettings?.websiteUrl">
          <p class="second-para">
              <a (click)="open_ace_com(userSettings?.websiteUrl)">
                   <span class="mobile-store-link"> Go to store </span> </a>

          </p>
</div>
</div>

<div class="poweredby-mobile">
  <a href="https://www.eshopbox.com/" target="_blank">
      <p style="word-wrap: no-wrap;"><a href="https://www.eshopbox.com/" target="_blank">   <img src="https://ik.imagekit.io/2gwij97w0o/POE/eshopbox-monogram.svg" alt=""></a>Powered by Eshopbox</p>

  </a>
  

</div> -->

  
<div class="help-box" (click)="closeHelpModal()" [class.helpShow]="isHelpOpendelay">
</div>
  <div class="main-helpbox" [ngStyle]="{display:isHelpOpendelay ? 'block': ''} " [class.helpShow]="isHelpOpen">
      <div class="help-top-box" *ngIf="(userSettings?.supportPhone && userSettings?.supportPhone!='null') || (userSettings?.supportEmail && userSettings?.supportEmail!='null') || (userSettings?.supportUrl && userSettings?.supportUrl!='null')">
          <h2>Help ?</h2>
          <button (click)="closeHelpModal()"></button>
      </div>

      <div class="help-bottom-box">
          <div class="text-block" *ngIf="userSettings?.supportUrl && userSettings?.supportUrl!='null'">
              <h1>Support URL</h1>
              <a (click)="openSite(userSettings?.supportUrl)">{{userSettings?.supportUrl}}</a>
          </div>
          <div class="text-block" *ngIf="userSettings?.supportEmail && userSettings?.supportEmail!='null'">
              <h1>Support email</h1>
              <a href="mailto:{{ (userSettings && userSettings.supportEmail) ? userSettings.supportEmail:'' }}">{{userSettings?.supportEmail}}</a>
          </div>
          <div class="text-block" *ngIf="userSettings?.supportPhone && userSettings?.supportPhone!='null'">
              <h1>Support phone number</h1>
              <a  href="tel:{{userSettings?.supportPhone}}">{{userSettings?.supportPhone}}</a>
          </div>
      </div>
  </div>

