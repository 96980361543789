<!-- <header class="ss">
  <head>
    <p style="    padding: 3px 600px 5px;
    width: 144%;
    background: #d94b2a;
    color: white;
    margin: -13px;
    font-family: unset;
    letter-spacing: .5px;">{{userSettings?.noticeMessage}}</p>

    </head>
</header> -->
<ng-container *ngIf="userSettings && userSettings.brandLogoUrl">
<header *ngIf="isDesktopDevice || showHeaderInMobile">
  <div class="container d-flex">
    <!-- <span *ngIf="showGoBackButton" (click)="goBackToClientPortal()" class="back-btn" onmouseover="style='text-decoration:underline;color:rgb(45,219,209)'" onmouseout="style='text-decoration:none'">  
   <img src="https://ik.imagekit.io/2gwij97w0o/POE/link-icon.svg" />
  Back To Eshopbox Workspace


     </span>  -->
    <ng-container
      *ngIf="
        userSettings?.brandLogoUrl ==
          'https://storage.googleapis.com/poe-settings-default/YOUR%20BRAND.png';
        else other
      "
    >
      <div>
        <h1 class="channelSlugText">
          {{ (orderData?.channelSlug ? orderData?.channelSlug : hostName) | uppercase }}
        </h1>
      </div>
    </ng-container>
    
    <ng-template #other>
      <head>
        <img
          src="{{
            userSettings && userSettings.brandLogoUrl
              ? 'https://ik.imagekit.io/2gwij97w0o/customer-portal/' + userSettings.brandLogoUrl
              : ''
          }}"
          alt="{{hostName}}"
        />
      </head>
    </ng-template>
  </div>
</header>

</ng-container>
<!-- <header>
  <div class="container d-flex header-logo">
    <ng-container *ngIf="poeData?.brandLogoUrl; else other">
      <div>
        <head>
          <img src="{{ poeData?.brandLogoUrl }}" alt="Logo" />
        </head>
      </div>
    </ng-container>
  </div>
  <ng-template #other>
    <div *ngIf="hostName" class="host-name">
      <h1 class="channelSlugText">{{ hostName | uppercase }}</h1>
    </div>
  </ng-template>
</header> -->
<div class="page-loader" *ngIf="showLoader">
  <div class="loader-icon"></div>
</div>
