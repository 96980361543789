"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("./api.service");
var environment_1 = require("@env/environment");
var i0 = require("@angular/core");
var i1 = require("./api.service");
var CommonFormService = /** @class */ (function () {
    function CommonFormService(api) {
        this.api = api;
        this.API_URL = environment_1.environment.API_URL;
        this.PINCODE = environment_1.environment.PINCODE;
    }
    CommonFormService.prototype.getCityState = function (params) {
        if (params === void 0) { params = {}; }
        return this.api
            .get(this.PINCODE + "pincode/" + params)
            .toPromise().then(function (res) {
            if (res) {
                return res;
            }
            throw res;
        }).catch(function (err) {
        });
    };
    CommonFormService.prototype.getIfscCodeDetails = function (params) {
        if (params === void 0) { params = {}; }
        return this.api
            .get(this.API_URL + "ifscCode/" + params)
            .toPromise().then(function (res) {
            if (res) {
                return res;
            }
            throw res;
        }).catch(function (err) {
        });
    };
    CommonFormService.prototype.getCityState1 = function (body) {
        return this.api.post(this.PINCODE + "pincode-serviceability", body, '')
            .toPromise().then(function (res) {
            if (res) {
                return res;
            }
            throw res;
        }).catch(function (err) { throw err; });
    };
    CommonFormService.ngInjectableDef = i0.defineInjectable({ factory: function CommonFormService_Factory() { return new CommonFormService(i0.inject(i1.ApiService)); }, token: CommonFormService, providedIn: "root" });
    return CommonFormService;
}());
exports.CommonFormService = CommonFormService;
